import { createSlice } from '@reduxjs/toolkit';
import { _setDataGridCounts } from './dataGridCountsAction';

const initialState = {
  dataGridCounts: [],
};

export const dataGridCountsSlice = createSlice({
  name: 'delta/dataGridCounts',
  initialState,
  reducers: {
    setDataGridCounts: _setDataGridCounts,
    resetDataGridCounts: () => initialState,
  },
});

export const { setDataGridCounts, resetDataGridCounts } =
  dataGridCountsSlice.actions;

export default dataGridCountsSlice.reducer;
