import { createSlice } from '@reduxjs/toolkit';

const statsSlice = createSlice({
  name: 'delta',
  initialState: null,
  reducers: {
    getStatsDetails: (state, action) => action.payload,
    resetStatsDetails: () => null,
  },
});

export const { getStatsDetails, resetStatsDetails } = statsSlice.actions;

export default statsSlice.reducer;
