import React from 'react';
import { useMaterialUIController } from 'context';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import CalendarRoot from 'examples/Calendar/CalendarRoot';

const TutoringCalendarTab = (props) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <>
      <CalendarRoot p={2} ownerState={{ darkMode }}>
        <FullCalendar
          headerToolbar={{
            left: 'title',
            center: '',
            right: 'prev next',
          }}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          displayEventTime={false}
          selectable={true}
          initialView="dayGridMonth"
          height="100%"
        />
      </CalendarRoot>
    </>
  );
};

export default TutoringCalendarTab;
