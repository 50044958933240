import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import { PaperPropsCheck } from 'constants';
import GeneralInfoForm from './GeneralInfoForm';
import EmergencyContactForm from './EmergencyContactForm';
import { GridForm, BoxWrapper } from 'components/Ui/styled';
import { getDialogHeading, getSaveforlaterContinue } from 'utils/commonUtils';
import EmploymentDialog from './EmploymentDialog/EmploymentDialog';

const StaffDialog = (props) => {
  const { isStaffDialog, setIsStaffDialog } = props;

  const [loadingButton, setLoadingButton] = useState(false);
  const [isEmploymentDialog, setIsEmploymentDialog] = useState(false);

  const defaultValue = {
    firstName: '',
    lastName: '',
    workEmail: '',
    phoneNumber: '',
    homeAddress: '',
    dateOfBirth: '',
    vonageExt: '',
    emergencyFirstName: '',
    emergencyLastName: '',
    emergencyEmail: '',
    emergencyPhoneNumber: '',
    emergencyHomeAddress: '',
    emergencyDateOfBirth: '',
    emergencyRelation: '',
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
  });

  const { handleSubmit, reset, control } = useFunction;

  useEffect(() => {
    reset(defaultValue);
  }, [
    defaultValue.firstName,
    defaultValue.lastName,
    defaultValue.workEmail,
    defaultValue.phoneNumber,
    defaultValue.homeAddress,
    defaultValue.dateOfBirth,
    defaultValue.vonageExt,
    defaultValue.emergencyFirstName,
    defaultValue.emergencyLastName,
    defaultValue.emergencyEmail,
    defaultValue.emergencyPhoneNumber,
    defaultValue.emergencyHomeAddress,
    defaultValue.emergencyDateOfBirth,
    defaultValue.emergencyRelation,
  ]);

  const handleClose = () => {
    reset(defaultValue);
    setIsStaffDialog(false);
  };

  const handleContinue = async (data) => {
    //console.log('data', data);
    setIsEmploymentDialog(true);
  };

  const handleSaveForLater = () => {
    setIsStaffDialog(false);
  };

  return (
    <>
      <Dialog open={isStaffDialog} PaperProps={PaperPropsCheck}>
        {getDialogHeading('Staff', handleClose, 'staffHeading')}
        <BoxWrapper>
          <GeneralInfoForm control={control} />
          <EmergencyContactForm control={control} />
        </BoxWrapper>
        <GridForm container spacing={2}>
          <Grid item xs={12}>
            {getSaveforlaterContinue(
              handleSaveForLater,
              handleSubmit(handleContinue),
              loadingButton
            )}
          </Grid>
        </GridForm>
        <EmploymentDialog
          isEmploymentDialog={isEmploymentDialog}
          setIsEmploymentDialog={setIsEmploymentDialog}
        />
      </Dialog>
    </>
  );
};

export default StaffDialog;
