import { createSlice } from '@reduxjs/toolkit';
import initialState from './announcementInitialState';
import {
  _getAnnouncementForm,
  _setNewAnnouncement,
  _setTitle,
  _setMsg,
  _setStartsAt,
  _setEndsAt,
  _setGroups,
} from './announcementAction';

export const announcementSlice = createSlice({
  name: 'delta',
  initialState,
  reducers: {
    getAnnouncementForm: _getAnnouncementForm,
    setNewAnnouncement: _setNewAnnouncement,
    setTitle: _setTitle,
    setMsg: _setMsg,
    setStartsAt: _setStartsAt,
    setEndsAt: _setEndsAt,
    setGroups: _setGroups,
    resetAnnouncement: () => initialState,
  },
});

export const {
  getAnnouncementForm,
  setNewAnnouncement,
  setTitle,
  setMsg,
  setStartsAt,
  setEndsAt,
  setGroups,
  resetAnnouncement,
} = announcementSlice.actions;

export default announcementSlice.reducer;
