import { ActionWrapperBox } from 'components/Common/common.styled';
import { ButtonIcon, EditActionIcon } from 'components/Ui/styled';

export const renderStatusAction = (params, handleRemoveStatus) => {
  return (
    <ActionWrapperBox>
      <ButtonIcon onClick={() => handleRemoveStatus(params)}>
        <EditActionIcon />
      </ButtonIcon>
    </ActionWrapperBox>
  );
};
