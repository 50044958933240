export const _updateLocationData = (state, action) => action.payload;

export const _setNewLocationFlag = (state, action) => {
  state.newLocationFlag = action.payload;
};

export const _setBuildingId = (state, action) => {
  state.buildingId = action.payload;
};

export const _setLocation = (state, action) => {
  state.location = action.payload;
};

export const _setTypeId = (state, action) => {
  state.typeId = action.payload;
};
