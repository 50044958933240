import React from 'react';
import { Controller } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export const FormInputTextIcon = (props) => {
  const {
    name,
    control,
    label,
    type,
    shrink,
    multiline,
    rows,
    disabled,
    register,
    minValue,
    maxValue,
    errors,
    helperText,
    placeholder,
    readOnly,
    onKeyDown,
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      register={register}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <Grid container spacing={1.5}>
          {value.map((studentId, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <TextField
                value={studentId}
                disabled={disabled}
                label={label}
                type={type}
                variant='outlined'
                multiline={multiline}
                error={errors}
                helperText={helperText}
                placeholder={placeholder}
                rows={rows}
                InputLabelProps={{
                  shrink: shrink,
                }}
                InputProps={{
                  inputProps: { min: minValue, max: maxValue },
                  readOnly: readOnly,
                  onKeyDown: onKeyDown,
                  endAdornment: !readOnly && (
                    <IconButton
                      aria-label='Remove'
                      onClick={() => {
                        const updatedStudentIds = [...value];
                        updatedStudentIds.splice(index, 1);
                        onChange(updatedStudentIds);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  ),
                }}
                style={{ width: '100%' }}
              />
            </Grid>
          ))}
        </Grid>
      )}
    />
  );
};
