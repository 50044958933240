import React from 'react';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import { GridForm, ButtonWrapper } from 'components/Ui/styled';
import { PaperProps } from 'constants';
import { useForm } from 'react-hook-form';
import { postCalender } from './services/calenderApi';
import { getDialogHeading } from 'utils/commonUtils';

const CalenderDialog = (props) => {
  const { openCalender, setOpen, getCalenderData } = props;
  const handleClose = () => {
    setOpen(false);
  };

  const useFunction = useForm({});

  const onSubmit = async (data) => {
    const payload = { calendar: data };
    await postCalender(payload);
    getCalenderData();
    setOpen(false);
  };

  const { handleSubmit, control } = useFunction;
  return (
    <>
      <Dialog open={openCalender} PaperProps={PaperProps}>
        {getDialogHeading('Calendar', handleClose)}
        <form onSubmit={handleSubmit(onSubmit)}>
          <GridForm container>
            <FormInputText
              name='name'
              type='text'
              control={control}
              label='calendar'
            />
            <ButtonWrapper container>
              <Button variant='text' type='submit'>
                Save
              </Button>
              <Button variant='text' onClick={() => handleClose()}>
                Cancel
              </Button>
            </ButtonWrapper>
          </GridForm>
        </form>
      </Dialog>
    </>
  );
};

export default CalenderDialog;
