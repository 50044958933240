import React, { useState, useEffect } from 'react';
import MDBox from 'components/MDBox';
import { intialPageSize } from 'constants';
import { useNotification, pageSizeChangeHandler } from 'utils/commonUtils';
import { getFolders } from 'layouts/documents/services/documentsApi';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  getDocumentFoldersListData,
  documentsFoldersColumns,
} from './DocumentsTabDataGrid';
import {
  resetFolderPath,
  setFolderPath,
} from 'layouts/documents/store/folderPathSlice/folderPathSlice';
import {
  setFolderData,
  setUploadDocumentPath,
  setUploadDocumentNewPath,
} from 'layouts/documents/store/uploadDocumentSlice/uploadDocumentSlice';
import { getFolderBrowse } from 'layouts/documents/services/documentsApi';
import { getSubFoldersData } from 'layouts/documents/store/subFoldersSlice/subFoldersSlice';
import DeltaDataGrid from 'utils/CommonDataGrid/CommonDataGrid';

const DocumentsTab = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();

  const [documentsFolders, setDocumentsFolders] = useState({
    isLoading: false,
    rows: [],
    pageSize: intialPageSize,
  });

  useEffect(() => {
    fetchDocumentFolders();
  }, []);

  const fetchDocumentFolders = async () => {
    setDocumentsFolders({ ...documentsFolders, isLoading: true });
    try {
      const documentFoldersData = await getFolders();
      setDocumentsFolders({ ...documentsFolders, isLoading: false });
      setDocumentsFolders((prev) => ({
        ...prev,
        rows: getDocumentFoldersListData(documentFoldersData),
      }));
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
      setDocumentsFolders({ ...documentsFolders, isLoading: false });
    }
  };

  const handleUploadViewDocuments = async (params) => {
    const { row } = params;
    dispatch(resetFolderPath([]));
    navigate(`/documents/folders/subFolder`);
    const rootPath = {
      path: '/',
      name: 'Root',
    };
    dispatch(setFolderPath(rootPath));
    dispatch(setFolderData(rootPath));
    dispatch(setUploadDocumentPath(rootPath.path));
    dispatch(setUploadDocumentNewPath(rootPath.path));
    const subfoldersData = await getFolderBrowse(row.id, '/');
    dispatch(getSubFoldersData(subfoldersData));
  };

  const pageSizeChange = pageSizeChangeHandler(setDocumentsFolders);

  return (
    <>
      <MDBox pt={2}></MDBox>
      <DeltaDataGrid
        listData={documentsFolders}
        columns={documentsFoldersColumns(handleUploadViewDocuments)}
        setListData={pageSizeChange}
      />
      <NotificationPopup />
    </>
  );
};

export default DocumentsTab;
