import React from 'react';
import Box from '@mui/material/Box';
import { noInfoAssets } from 'constants';
import ListItems from 'components/Common/ListItems';
import { useRenderNoInfo } from 'utils/commonUtils';

const DashboardAssets = (props) => {
  const { myProfile } = props;

  const noInfoComponent = useRenderNoInfo(
    myProfile?.assignedAssets,
    noInfoAssets
  );

  return (
    <>
      {noInfoComponent}
      {myProfile?.assignedAssets?.map((item, index) => {
        const { assetTag, serial, type } = item;
        const title = `${type.val} ( Tag: ${assetTag}, Serial: ${serial} )`;
        return (
          <Box key={index}>
            <ListItems title={title} />
          </Box>
        );
      })}
    </>
  );
};

export default DashboardAssets;
