import React, { useState, useEffect } from 'react';
import MDBox from 'components/MDBox';
import { useNavigate } from 'react-router-dom';
import {
  RoundedIconFolder,
  DocumentGrid,
  FullCard,
  SubFolderHeading,
  SubfoldersFilesGrid,
  NoSubfoldersFilesGrid,
  WhiteTooltip,
  TextTypography,
  FilesIconBox,
} from 'components/Ui/styled';
import { useSelector, useDispatch } from 'react-redux';
import {
  showLoader,
  showBackdropLoader,
  useResetSnackbar,
  truncateText,
  getSnackBar,
  getFileIcon,
} from 'utils/commonUtils';
import {
  getSubFoldersData,
  resetSubFoldersData,
} from '../store/subFoldersSlice/subFoldersSlice';
import { getFolderBrowse, putFileDownload } from '../services/documentsApi';
import FolderPath from './FolderPath';
import { setFolderPath } from '../store/folderPathSlice/folderPathSlice';
import { displaySnackbar } from 'components/snackbar/store/SnackbarSlice';
import Button from '@mui/material/Button';
import DocumentsUploadDialog from './DocumentsUploadDialog';
import {
  setFolderData,
  setUploadDocumentNewPath,
  setUploadDocumentPath,
} from '../store/uploadDocumentSlice/uploadDocumentSlice';
import Typography from '@mui/material/Typography';
import spriteFileTypeIcons from 'assets/images/icons/spriteFileTypeIcons.jpg';

const SubFolderList = () => {
  useResetSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(false);

  const subFolders = useSelector((state) => state.subFolders);
  const foldersPath = useSelector((state) => state.foldersPath.folderPath);

  useEffect(() => {
    if (subFolders) {
      setIsLoading(false);
    }
  }, [subFolders]);

  useEffect(() => {
    return () => {
      dispatch(resetSubFoldersData());
    };
  }, []);

  const handleFolder = async (item) => {
    setIsLoadingBackDrop(true);
    navigate(`/documents/folders/subFolder`);
    const subfoldersData = await getFolderBrowse(
      subFolders?.sharedFolderId,
      item.path
    );
    dispatch(getSubFoldersData(subfoldersData));
    dispatch(setFolderData(item));
    dispatch(setUploadDocumentPath(item.path));
    dispatch(setUploadDocumentNewPath(item.path));
    setIsLoadingBackDrop(false);
    const checkPathExists = (newElement) => {
      return foldersPath?.some(
        (previousElement) => previousElement.name === newElement.name
      );
    };
    const isPathExists = checkPathExists(item);
    if (!isPathExists) {
      dispatch(setFolderPath(item));
    }
  };

  const handleUpload = () => {
    setOpenUploadDialog(true);
  };

  const handleFileDownload = async (item) => {
    const snackBar = getSnackBar('File downloaded successfully');
    setIsLoadingBackDrop(true);
    const { path, key } = item;
    const payload = {
      path,
      key,
    };
    const downloadUrl = await putFileDownload(
      payload,
      subFolders?.sharedFolderId
    );
    await fetch(downloadUrl?.url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = key;
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error('Error downloading the file:', error);
      });
    setIsLoadingBackDrop(false);
    dispatch(displaySnackbar(snackBar));
  };

  return (
    <>
      {isLoading ? (
        showLoader()
      ) : (
        <>
          {showBackdropLoader(isLoadingBackDrop)}
          <MDBox>
            <FullCard>
              <SubFolderHeading>
                <FolderPath
                  folderPath={foldersPath}
                  setIsLoadingBackDrop={setIsLoadingBackDrop}
                />
                <Button variant="text" onClick={handleUpload}>
                  Upload Document
                </Button>
              </SubFolderHeading>
              <SubfoldersFilesGrid container spacing={1}>
                {subFolders?.contents?.subFolders?.map((item, index) => {
                  return (
                    <DocumentGrid
                      item
                      xs={12}
                      sm={4}
                      md={1}
                      key={index}
                      onClick={() => handleFolder(item)}
                    >
                      <RoundedIconFolder />
                      <WhiteTooltip title={item.name}>
                        <TextTypography variant="body2">
                          {truncateText(item.name)}
                        </TextTypography>
                      </WhiteTooltip>
                    </DocumentGrid>
                  );
                })}
                {subFolders?.contents?.files?.map((item, index) => {
                  const fileIcon = getFileIcon(item.key);
                  return (
                    <DocumentGrid
                      item
                      xs={12}
                      sm={4}
                      md={1}
                      key={index}
                      onClick={() => handleFileDownload(item)}
                    >
                      <FilesIconBox
                        fileextension={fileIcon}
                        url={spriteFileTypeIcons}
                      ></FilesIconBox>
                      <WhiteTooltip title={item.key}>
                        <TextTypography variant="body2">
                          {truncateText(item.key)}
                        </TextTypography>
                      </WhiteTooltip>
                    </DocumentGrid>
                  );
                })}
              </SubfoldersFilesGrid>
              {subFolders?.contents?.subFolders?.length === 0 &&
                subFolders?.contents?.files?.length === 0 && (
                  <NoSubfoldersFilesGrid container spacing={1}>
                    <Typography variant="body2">
                      No sub folders and files
                    </Typography>
                  </NoSubfoldersFilesGrid>
                )}
            </FullCard>
          </MDBox>
          <DocumentsUploadDialog
            open={openUploadDialog}
            setOpen={setOpenUploadDialog}
            fetchFolders={handleFolder}
          />
        </>
      )}
    </>
  );
};

export default SubFolderList;
