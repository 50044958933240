import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import StaffProfileInfo from './StaffProfileInfo';
import StaffProfileTabs from './staffProfileTabs/StaffProfileTabs';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import { getUser } from 'layouts/users/services/userApis';
import { FormInputAutoCompleteSearch } from 'components/Common/Forms/FormAutoCompleteSearch';
import { useNotification } from 'utils/commonUtils';

const StaffProfileComponent = () => {
  const { NotificationPopup, handleErrorResponse } = useNotification();

  const [staffSearchQuery, setStaffSearchQuery] = useState('');
  const [selectedStaff, setSelectedStaff] = useState(false);

  const availableUsersList = useSelector((state) => state.availableUsersList);

  const defaultValue = {
    user: '',
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
  });

  const { reset } = useFunction;

  useEffect(() => {
    reset(defaultValue);
  }, [defaultValue.user]);

  const availableUsersDatas = availableUsersList?.map((item) => ({
    id: item?.username,
    value: item?.username,
  }));

  const availableUsersData = staffSearchQuery ? availableUsersDatas : [];

  const handleSearchStaffChange = async (value) => {
    try {
      const userValue = value?.value;
      if (value) {
        setSelectedStaff(true);
        setStaffSearchQuery(value);
        await getUser(userValue);
      } else {
        setSelectedStaff(false);
      }
    } catch (error) {
      handleErrorResponse(error?.response?.data?.message);
    }
  };

  const handleOnClose = () => {
    setSelectedStaff(false);
  };

  return (
    <Card style={{ padding: '16px 24px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={12}>
          <FormInputAutoCompleteSearch
            label="Search Staff"
            setSelectedInput={handleSearchStaffChange}
            options={availableUsersData}
            getOptionLabel={(option) => option.value}
            setInputSearchQuery={setStaffSearchQuery}
            setSelectedStaff={setSelectedStaff}
            onClose={handleOnClose}
          />
        </Grid>
      </Grid>
      {selectedStaff && (
        <>
          {/* <StaffProfileInfo /> */}
          <StaffProfileTabs />
        </>
      )}
      <NotificationPopup />
    </Card>
  );
};

export default StaffProfileComponent;
