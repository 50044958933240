import { createSlice } from '@reduxjs/toolkit';
import {
  _setStudentProfilePagination,
  _setStudentProfilePaginationPreviousKey,
} from './studentProfileActions';

const initialState = {
  studentProfilePagination: [],
  studentProfilePaginationPreviousKey: '',
};

export const studentProfilePaginationSlice = createSlice({
  name: 'delta/studentProfilePagination',
  initialState,
  reducers: {
    setStudentProfilePagination: _setStudentProfilePagination,
    setStudentProfilePaginationPreviousKey:
      _setStudentProfilePaginationPreviousKey,
    resetStudentProfilePagination: () => initialState,
  },
});

export const {
  setStudentProfilePagination,
  setStudentProfilePaginationPreviousKey,
  resetStudentProfilePagination,
} = studentProfilePaginationSlice.actions;

export default studentProfilePaginationSlice.reducer;
