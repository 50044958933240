export const _setColor = (state, action) => {
  state.color = action.payload;
};

export const _setCategory = (state, action) => {
  state.name = action.payload;
};
export const _setCategories = (state, action) => {
  state.categories = action.payload;
};

export const _newCalenderCategory = (state, action) => action.payload;

export const _updateCalenderCategory = (state, action) => action.payload;
