import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import MDBox from 'components/MDBox';
import Typography from '@mui/material/Typography';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import { FormInputDate } from 'components/Common/Forms/FormInputDate';
import { FormInputSwitch } from 'components/Common/Forms/FormInputSwitch';
import * as yup from 'yup';
import {
  setTitle,
  setDescription,
  setStart,
  setEnd,
  setStartTime,
  setEndTime,
  setLocation,
  setAllDay,
  setCategoryId,
} from 'layouts/applications/calendar/components/CalendarEvents/store/calenderEventSlice';
import {
  GridContainer,
  CalendarEventButtonGrid,
  CalendarEventButton,
  CalendarEventDeleteButton,
  EventDetailsGrid,
} from 'components/Ui/styled';
import FormInputDropdown from 'components/Common/Forms/FormInputDropDown';
import {
  deleteCalenderEvent,
  postCalenderEvents,
  putCalenderEvent,
} from '../../services/calendarEventsApi';
import moment from 'moment';
import { displaySnackbar } from 'components/snackbar/store/SnackbarSlice';
import CustomizedSnackbars from 'components/snackbar/Snackbar';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  requiredField,
  EventPaperProps,
  eventUpdateSuccess,
  eventSuccess,
  characterLimit,
  dateFormat,
} from 'constants';
import {
  getSnackBar,
  dropDownValidation,
  fieldValidation,
  useNotification,
  formatTimeZoneDate,
  timezoneOffset,
} from 'utils/commonUtils';
import CommonDeleteDialog from 'components/Common/CommonDeleteDialog/CommonDeleteDialog';
import { ButtonSpinner, ButtonSpinnerProgress } from 'components/Ui/styled';
import Box from '@mui/material/Box';

const AddEventDialog = (props) => {
  const {
    open,
    setOpen,
    fetchCalendarDataOfParticularMonth,
    eventDetailsFlag,
  } = props;
  const [openEventDeleteDialog, setOpenEventDeleteDialog] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const currentDate = new Date();
  const yearMonth = useSelector((state) => state.yearMonth);

  const current = new Date(yearMonth);
  const getCurrentYear = `${current.getFullYear()}`;
  const getCurrentMonth = `${current.getMonth() + 1}`;

  const calendarCategorie = useSelector((state) => state.calendarCategory);
  const { categories } = calendarCategorie;

  const currentCalenderId = useSelector(
    (state) => state?.Calender?.selectCalendar
  );

  const dispatch = useDispatch();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const calendarEvent = useSelector((state) => state.calendarEvent);
  let {
    newEvent,
    title,
    description,
    startDate,
    endDate,
    startTime,
    endTime,
    location,
    isAllDayEvent,
    categoryId,
    eventId,
  } = calendarEvent;

  if (timezoneOffset !== 1) {
    const addOneDay = (date) =>
      date ? moment(date).add(1, 'day').format(dateFormat) : '';

    startDate = addOneDay(startDate);
    endDate = addOneDay(endDate);
  }

  const defaultValue = {
    title: title,
    description: description,
    start: startDate,
    end: endDate,
    startTime: startTime,
    endTime: endTime,
    location: location,
    allDay: isAllDayEvent,
    category: categoryId,
    eventId: eventId,
  };

  const eventSchema = yup.object().shape({
    title: fieldValidation(characterLimit),
    start: yup.date().required().typeError(requiredField),
    end: yup.date().required().typeError(requiredField),
    description: fieldValidation(4000),
    location: fieldValidation(characterLimit),
    category: dropDownValidation,
    startTime: !defaultValue.allDay
      ? yup.string().required(requiredField).nullable()
      : '',
    endTime: !defaultValue.allDay
      ? yup.string().required(requiredField).nullable()
      : '',
  });

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
    resolver: yupResolver(eventSchema),
  });
  const { handleSubmit, reset, control, register, formState } = useFunction;
  const { errors } = formState;

  useEffect(() => {
    reset(defaultValue);
  }, [
    defaultValue.title,
    defaultValue.description,
    defaultValue.start,
    defaultValue.end,
    defaultValue.startTime,
    defaultValue.endTime,
    defaultValue.location,
    defaultValue.allDay,
    defaultValue.category,
    defaultValue.eventId,
  ]);

  const handleClose = () => {
    setOpen(false);
    reset(defaultValue);
  };

  const onSubmit = async (data) => {
    setLoadingButton(true);
    const message = newEvent ? eventSuccess : eventUpdateSuccess;
    const snackBar = getSnackBar(message);
    const payload = {
      event: {
        title,
        description,
        startDate: formatTimeZoneDate(startDate),
        endDate: formatTimeZoneDate(endDate),
        isAllDayEvent,
        startTime: isAllDayEvent === false ? startTime : null,
        endTime: isAllDayEvent === false ? endTime : null,
        location,
        categoryId,
      },
    };
    try {
      if (newEvent) {
        await postCalenderEvents(payload, currentCalenderId);
      } else {
        await putCalenderEvent(payload, currentCalenderId, eventId);
      }
      fetchCalendarDataOfParticularMonth(getCurrentYear, getCurrentMonth);
      dispatch(displaySnackbar(snackBar));
      setOpen(false);
    } catch (e) {
      setOpenNotification(true);
      setNotificationMessage(e.response.data.message);
    }
    setLoadingButton(false);
  };

  const postParams = {
    event: {
      eventId,
    },
  };

  const renderEventForm = () => {
    return (
      <>
        {eventDetailsFlag ? (
          <CalendarEventButtonGrid container>
            <Typography variant="h6">Event Details</Typography>
          </CalendarEventButtonGrid>
        ) : (
          <CalendarEventButtonGrid container>
            <CalendarEventButton variant="text" onClick={handleClose}>
              Cancel
            </CalendarEventButton>
            <Typography variant="h6">
              {newEvent ? 'New event' : 'Edit event'}
            </Typography>
            <ButtonSpinner>
              <Box sx={{ m: 0, position: 'relative' }}>
                <CalendarEventButton
                  variant="text"
                  onClick={handleSubmit(onSubmit)}
                  disabled={loadingButton}
                >
                  {newEvent ? 'Add' : 'Save'}
                </CalendarEventButton>
                {loadingButton && <ButtonSpinnerProgress size={24} />}
              </Box>
            </ButtonSpinner>
          </CalendarEventButtonGrid>
        )}
        <FormInputText
          name="title"
          type="text"
          control={control}
          label="Title"
          helperText={errors?.title?.message}
          errors={!!errors.title}
          register={register('title', {
            onChange: (e) => dispatch(setTitle(e.target.value)),
          })}
        />
        <FormInputText
          name="description"
          type="text"
          control={control}
          label="Description"
          helperText={errors?.description?.message}
          errors={!!errors.description}
          register={register('description', {
            onChange: (e) => dispatch(setDescription(e.target.value)),
          })}
        />
        <FormInputDate
          name="start"
          control={control}
          label="Start date"
          minDate={currentDate}
          helperText={errors?.start?.message}
          errors={!!errors.start}
          value={startDate}
          register={register('start', {
            onChange: (e) => dispatch(setStart(e.target.value)),
          })}
        />
        <FormInputDate
          name="end"
          control={control}
          label="End date"
          minDate={currentDate}
          helperText={errors?.end?.message}
          errors={!!errors.end}
          value={endDate}
          register={register('end', {
            onChange: (e) => dispatch(setEnd(e.target.value)),
          })}
        />
        <FormInputSwitch
          control={control}
          name="allDay"
          label="All Day"
          checked={defaultValue.allDay}
          register={register('allDay', {
            onChange: (e) => dispatch(setAllDay(e.target.value)),
          })}
        />
        {!defaultValue.allDay && (
          <>
            <FormInputText
              name="startTime"
              type="time"
              shrink={true}
              control={control}
              label="Start time"
              helperText={errors?.startTime?.message}
              errors={!!errors.startTime}
              register={register('startTime', {
                onChange: (e) => dispatch(setStartTime(e.target.value)),
              })}
            />
            <MDBox pb={2}></MDBox>
            <FormInputText
              name="endTime"
              type="time"
              shrink={true}
              control={control}
              label="End time"
              helperText={errors?.endTime?.message}
              errors={!!errors.endTime}
              register={register('endTime', {
                onChange: (e) => dispatch(setEndTime(e.target.value)),
              })}
            />
          </>
        )}
        <FormInputText
          name="location"
          type="text"
          control={control}
          label="Location"
          helperText={errors?.location?.message}
          errors={!!errors.location}
          register={register('location', {
            onChange: (e) => dispatch(setLocation(e.target.value)),
          })}
        />
        <FormInputDropdown
          name="category"
          type="text"
          control={control}
          options={categories}
          isNameHasValue={true}
          isPropName={true}
          nameProperKey="categoryId"
          propName="name"
          label="Categories"
          helperText={errors?.category?.message}
          errors={!!errors.category}
          register={register('category', {
            onChange: (e) => dispatch(setCategoryId(e.target.value)),
          })}
        />
        {!newEvent && !eventDetailsFlag && (
          <Grid container>
            <CalendarEventDeleteButton
              variant="outlined"
              onClick={() => setOpenEventDeleteDialog(true)}
            >
              Delete event
            </CalendarEventDeleteButton>
          </Grid>
        )}
        <CommonDeleteDialog
          openDeleteDialog={openEventDeleteDialog}
          setOpen={setOpen}
          setOpenDeleteDialog={setOpenEventDeleteDialog}
          contentText={
            'Are you sure you want to delete this Calendar Event ' + title + '?'
          }
          fetchData={fetchCalendarDataOfParticularMonth}
          getCurrentYear={getCurrentYear}
          getCurrentMonth={getCurrentMonth}
          snakBarName="Add Event"
          commonDeleteApi={deleteCalenderEvent}
          commonDeleteId={postParams}
          currentCalenderId={currentCalenderId}
        />
      </>
    );
  };

  return (
    <>
      <CustomizedSnackbars />
      {eventDetailsFlag ? (
        <EventDetailsGrid container>{renderEventForm()}</EventDetailsGrid>
      ) : (
        <Dialog open={open} PaperProps={EventPaperProps}>
          <GridContainer container>{renderEventForm()}</GridContainer>
        </Dialog>
      )}
      <NotificationPopup />
    </>
  );
};

export default AddEventDialog;
