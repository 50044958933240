const initialState = {
  selectedSemester: '',
  isChanged: false,
  fullSemester: true,
  watchedOnly: false,
  drop: false,
  instructor: '',
  tabValue: 0,
  isAttendanceAudit: false,
};

export default initialState;
