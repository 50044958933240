import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import { Controller } from 'react-hook-form';
import { emptyFunction } from 'utils/commonUtils';

export const FormInputSwitch = ({
  name,
  control,
  label,
  checked,
  register,
  readOnly,
}) => {
  return (
    <FormControl size={'small'} variant={'outlined'} style={{ width: '106%' }}>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          style={{ fontSize: '14px', fontWeight: '400', color: '#7b809a' }}
        >
          {label}
        </Typography>
        <FormControlLabel
          control={
            <Controller
              name={name}
              control={control}
              register={register}
              render={({ field: { onChange, value } }) => (
                <Switch
                  onChange={readOnly ? emptyFunction : onChange}
                  value={value}
                  checked={value}
                />
              )}
            />
          }
        />
      </Box>
    </FormControl>
  );
};
