import React, { useState } from 'react';
import MDBox from 'components/MDBox';
import Card from '@mui/material/Card';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import CustomizedSnackbars from 'components/snackbar/Snackbar';
import {
  getDataGridHeading,
  useResetSnackbar,
  useNotification,
  pageSizeChangeHandler,
} from 'utils/commonUtils';
import { intialPageSize } from 'constants';
import { announcementsColumns } from './AnnouncementsDataGrid';
import TeacherHeading from './TeacherHeading';
import DeltaDataGrid from 'utils/CommonDataGrid/CommonDataGrid';
import { assignmentsColumns } from './AssignmentsDataGrid';
import AssignmentDialog from './AssignmentDialog';

const TeacherComponent = () => {
  useResetSnackbar();
  const dispatch = useDispatch();
  const [openAssignmentDialog, setOpenAssignmentDialog] = useState(false);
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();

  const defaultValue = {
    chooseClass: '',
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
  });

  const { control } = useFunction;

  const [assignments, setAssignments] = useState({
    isLoading: false,
    rows: [
      {
        id: 1,
        assignment: '3.0 Graphing on a coordinate plane',
        uploadDate: '10/05/2024',
        dueDate: '15/09/2024',
      },
      {
        id: 2,
        assignment: 'Practice test 1. B',
        uploadDate: '04/11/2024',
        dueDate: '12/04/2024',
      },
      {
        id: 3,
        assignment: '3.0 Graphing on a coordinate plane',
        uploadDate: '10/05/2024',
        dueDate: '16/02/2024',
      },
      {
        id: 4,
        assignment: 'Practice test 1. B',
        uploadDate: '04/11/2024',
        dueDate: '18/08/2024',
      },
    ],
    pageSize: intialPageSize,
  });

  const [announcements, setAnnouncements] = useState({
    isLoading: false,
    rows: [
      {
        id: 1,
        title: 'Holiday',
        msg: 'static message',
      },
      {
        id: 2,
        title: 'Exams',
        msg: 'static message',
      },
    ],
    pageSize: intialPageSize,
  });

  const pageSizeChangeAssignments = pageSizeChangeHandler(setAssignments);
  const pageSizeChangeAnnouncements = pageSizeChangeHandler(setAnnouncements);
  const handleNewAssignment = () => {
    setOpenAssignmentDialog(true);
  };

  const handleUploadAssignments = (params) => {
    const { row } = params;
    console.log('Upload', row);
  };

  return (
    <>
      <CustomizedSnackbars />
      <MDBox>
        <Card>
          <TeacherHeading control={control} />
          <DeltaDataGrid
            listData={announcements}
            columns={announcementsColumns}
            setListData={pageSizeChangeAnnouncements}
          />
          {getDataGridHeading(
            'Assignments',
            'New Assignment',
            handleNewAssignment
          )}
          <DeltaDataGrid
            listData={assignments}
            columns={assignmentsColumns(handleUploadAssignments)}
            setListData={pageSizeChangeAssignments}
          />
        </Card>
      </MDBox>
      <AssignmentDialog
        openAssignmentDialog={openAssignmentDialog}
        setOpenAssignmentDialog={setOpenAssignmentDialog}
      />
      <NotificationPopup />
    </>
  );
};

export default TeacherComponent;
