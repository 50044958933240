import { createSlice } from '@reduxjs/toolkit';
import initialState from './logoutInitialState';
import { _setIsLogout, _setIsLogoutTiming } from './logutAction';

export const logoutSlice = createSlice({
  name: 'delta/logout',
  initialState,
  reducers: {
    setIsLogout: _setIsLogout,
    setIsLogoutTiming: _setIsLogoutTiming,
    resetIsLogout: () => initialState,
  },
});

export const { setIsLogout, setIsLogoutTiming, resetIsLogout } =
  logoutSlice.actions;

export default logoutSlice.reducer;
