import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { clearSnackbar } from './store/SnackbarSlice';
import { autoHideDurationTime } from 'constants';

const CustomizedSnackbars = () => {
  const dispatch = useDispatch();
  const snackbar = useSelector((state) => state.snackbar);

  const { vertical, horizontal, snackbarOpen, snackbarMessage } = snackbar;

  const handleClose = () => {
    dispatch(clearSnackbar());
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={snackbarOpen}
      autoHideDuration={autoHideDurationTime}
      onClose={handleClose}
    >
      <Alert elevation={6} variant="filled" onClose={handleClose}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
};

export default CustomizedSnackbars;
