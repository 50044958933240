export const SchoolGrades = [
  // {
  //   gradeName: 'Drops',
  //   gradeIndex: 13,
  //   tabIndex: 0,
  // },
  {
    gradeName: '12th Grade',
    gradeIndex: 12,
    tabIndex: 0,
  },
  {
    gradeName: '11th Grade',
    gradeIndex: 11,
    tabIndex: 1,
  },
  {
    gradeName: '10th Grade',
    gradeIndex: 10,
    tabIndex: 2,
  },
  {
    gradeName: '9th Grade',
    gradeIndex: 9,
    tabIndex: 3,
  },
  {
    gradeName: '8th Grade',
    gradeIndex: 8,
    tabIndex: 4,
  },
  {
    gradeName: '7th Grade',
    gradeIndex: 7,
    tabIndex: 5,
  },
  {
    gradeName: '6th Grade',
    gradeIndex: 6,
    tabIndex: 6,
  },
  //{
  //  gradeName: 'DayHome Students ',
  //  gradeIndex: 14,
  //  tabIndex: 8,
  //},
];

export const gradeValues = [12, 11, 10, 9, 8, 7, 6];

export const attendanceStatuses = [
  {
    key: '',
    value: '-Clear-',
  },
  {
    key: 'Zoom',
    value: 'Zoom',
  },
  {
    key: 'Email',
    value: 'Email',
  },
  {
    key: 'Call',
    value: 'Call',
  },
  {
    key: 'Progress',
    value: 'Progress',
  },
  {
    key: 'On Campus',
    value: 'On Campus',
  },
  {
    key: 'Lab',
    value: 'Lab',
  },
  {
    key: 'Other',
    value: 'Other',
  },
  {
    key: 'Not Enrolled',
    value: 'Not Enrolled',
  },
];

export const noteExtender = '-Note';
export const statusExtender = '-Status';

// Below information has to be moved to global state
export const fixedSemester = '2022-2';

export const attendanceTags = [
  { key: 'iep', value: 'IEP', id: 'IEP' },
  { key: 'ell', value: 'ELL', id: 'ELL' },
  { key: '504', value: '504', id: '504' },
  { key: 'ssh', value: 'SSH', id: 'SSH' },
  { key: 'early', value: 'Early', id: 'Early' },
];

export const attendanceStatusOptions = [
  {
    id: '',
    value: '-Clear-',
  },
  {
    id: 'Zoom',
    value: 'Zoom',
  },
  {
    id: 'Email',
    value: 'Email',
  },
  {
    id: 'Call',
    value: 'Call',
  },
  {
    id: 'Progress',
    value: 'Progress',
  },
  {
    id: 'On Campus',
    value: 'On Campus',
  },
  {
    id: 'Lab',
    value: 'Lab',
  },
  {
    id: 'Other',
    value: 'Other',
  },
  {
    id: 'Not Enrolled',
    value: 'Not Enrolled',
  },
  {
    id: 'Text Message',
    value: 'Text Message',
  },
];
