import React, { useState, useEffect } from 'react';
import MDBox from 'components/MDBox';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import MDTypography from 'components/MDTypography';
import { getMassUpdateAsset, putAsset } from '../assets/services/assetApis';
import { HeadingWrapperBox } from '../Inventory.styled';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import FormInputDropdown from 'components/Common/Forms/FormInputDropDown';
import Typography from '@mui/material/Typography';
import { getAssetType } from '../assetType/services/assetTypeApi';
import { getLocationData } from '../location/services/LocationApi';
import { getFundingType } from '../fundingTypes/services/FundingTypeApi';
import { getBuildingOfSpecifiedLocation } from '../Building/services/BuildingApi';
import { useDispatch, useSelector } from 'react-redux';
import { FormInputDate } from 'components/Common/Forms/FormInputDate';
import {
  dateFormat,
  assetAttributes,
  intialPageSize,
  massUpdateSuccess,
  assetTagError,
  updateAssetsConformation,
} from '../../../constants';
import {
  ButtonSpinner,
  MassGrid,
  MassBox,
  DropDownBox,
  OutlinedIconClose,
  ButtonIcon,
} from 'components/Ui/styled';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {
  getSnackBar,
  useNotification,
  percentageLoader,
  pageSizeChangeHandler,
} from 'utils/commonUtils';
import { displaySnackbar } from 'components/snackbar/store/SnackbarSlice';
import ConfirmationDialog from 'components/Common/ConfirmationDialog/ConfirmationDialog';
import CustomizedSnackbars from 'components/snackbar/Snackbar';
import {
  removeAsset,
  resetBulkAssets,
  setBulkAssets,
} from './store/bulkAssetsSlice/bulkAssetsSlice';
import {
  resetAssets,
  setAcquired,
  setBuildingId,
  setFundingId,
  setIpAddress,
  setLocationId,
  setMacAddress,
  setManu,
  setModel,
  setSerial,
  setTypeId,
  setVerified,
} from '../assets/Store/assetSlice';
import {
  resetAssetTags,
  setAssetTags,
} from './store/assetTagSlice/assetTagsSlice';
import { renderCellExpand } from 'components/Common/GridCell/GridCellExpand';
import DeltaDataGrid from 'utils/CommonDataGrid/CommonDataGrid';

const MassUpdateList = () => {
  const dispatch = useDispatch();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const [progress, setProgress] = useState(0);
  const [searchedAssetTag, setSearchedAssetTag] = useState('');
  const [assetTypeDropDownList, setAssetTypeDropDownList] = useState();
  const [fundingTypeDropDownList, setFundingTypeDropDownList] = useState();
  const [locationDropDownList, setLocationTypeDropDownList] = useState();
  const [buildingDropDownList, setBuildingDropDownList] = useState();
  const [loadingButton, setLoadingButton] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [massUpdateList, setMassUpdateList] = useState({
    isLoading: false,
    rows: [],
    pageSize: intialPageSize,
    page: 1,
  });

  const { pageSize, page, rows } = massUpdateList;

  const bulkAssets = useSelector((state) => state.bulkAssets.bulkAssets);
  const assetTags = useSelector((state) => state.assetTags.assetTags);
  const assetForm = useSelector((state) => state.assetForm);

  const {
    assetTag,
    id,
    model,
    serial,
    notes,
    acquired,
    verified,
    manu,
    typeId,
    locationId,
    buildingId,
    fundingId,
    macAddress,
    ipAddress,
    assignedUser,
  } = assetForm;

  const defaultValue = {
    searchedAssetTag: searchedAssetTag,
    assetTag: assetTag,
    model: model,
    serial: serial,
    notes: notes,
    acquired: acquired,
    id: id,
    verified: verified,
    manu: manu,
    macAddress: macAddress,
    ipAddress: ipAddress,
    typeId: typeId ? typeId : '',
    locationId: locationId ? locationId : '',
    buildingId: buildingId ? buildingId : '',
    fundingId: fundingId ? fundingId : '',
    assignedUser: assignedUser,
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
  });

  const {
    handleSubmit,
    control,
    register,
    reset,
    getValues,
    setValue,
    formState,
  } = useFunction;

  const { errors } = formState;

  const assetAttribute = getValues().assetAttribute;

  useEffect(() => {
    fetchAssetTypeData();
    fetchLocationTypeData();
    fetchFundingTypeData();
    dispatch(resetAssets());
  }, []);

  useEffect(() => {
    if (locationId) {
      fetchBuildingData();
    }
  }, [locationId]);

  useEffect(() => {
    reset(defaultValue);
  }, [defaultValue.searchedAssetTag]);

  const fetchAssetTypeData = async () => {
    try {
      const assetTypeList = await getAssetType();
      setAssetTypeDropDownList(assetTypeList);
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error?.response?.data?.message);
    }
  };

  const fetchLocationTypeData = async () => {
    try {
      const locationList = await getLocationData();
      setLocationTypeDropDownList(locationList);
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error?.response?.data?.message);
    }
  };

  const fetchFundingTypeData = async () => {
    try {
      const FundingTypeList = await getFundingType();
      setFundingTypeDropDownList(FundingTypeList);
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error?.response?.data?.message);
    }
  };

  const fetchBuildingData = async () => {
    try {
      const buildingList = await getBuildingOfSpecifiedLocation(locationId);
      setBuildingDropDownList(buildingList);
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error?.response?.data?.message);
    }
  };

  const assetTypeData = assetTypeDropDownList?.items.map((item) => ({
    id: item.id,
    value: item.type,
  }));

  const fundingTypeData = fundingTypeDropDownList?.items.map((item) => ({
    id: item.id,
    value: item.funding,
  }));

  const locationData = locationDropDownList?.items.map((item) => ({
    id: item.id,
    value: item.location,
  }));

  const buildingData = [
    {
      id: buildingDropDownList?.item?.building?.id,
      value: buildingDropDownList?.item?.building?.val,
    },
  ];

  useEffect(() => {
    if (bulkAssets) {
      fetchMassUpdateAsset();
    }
  }, [pageSize, page, bulkAssets]);

  useEffect(() => {
    if (
      !rows.find((row) => row.assetTag === searchedAssetTag) &&
      searchedAssetTag
    ) {
      fetchSearchedAsset();
    }
  }, [searchedAssetTag]);

  const fetchSearchedAsset = async () => {
    const assetTagsArray = searchedAssetTag
      .split(',')
      .map((tag) => tag.trim())
      .filter((tag) => tag.length > 0);
    const assetTagsWithNoItems = [];
    const totalTags = assetTagsArray.length;

    try {
      for (let i = 0; i < totalTags; i++) {
        const assetTag = assetTagsArray[i];
        setLoading(true);

        const searchedAsset = await getMassUpdateAsset(assetTag);
        const getSearchedAsset = searchedAsset?.items?.[0];
        const assetTagNumber = searchedAsset?.items?.[0]?.assetTag;

        if (searchedAsset?.items?.length === 0) {
          assetTagsWithNoItems.push(assetTag);
          setOpenNotification(true);
          setNotificationMessage(assetTagError(assetTagsWithNoItems));
        } else {
          dispatch(setBulkAssets(getSearchedAsset));
          dispatch(setAssetTags(assetTagNumber));
        }

        const progress = ((i + 1) / totalTags) * 100;
        setProgress(progress);
        setLoading(false);
      }
      setSearchedAssetTag('');
    } catch (error) {
      setLoading(false);
      setOpenNotification(true);
      setNotificationMessage(error?.response?.data?.message);
    }
    setProgress(0);
  };

  const fetchMassUpdateAsset = async () => {
    setMassUpdateList((previous) => ({
      ...previous,
      rows: [
        // Use a Set to store unique IDs
        ...new Set(bulkAssets?.map((item) => item?.id)),
      ]
        .map((id) => {
          // Find the first occurrence of each unique ID
          const item = bulkAssets?.find((asset) => asset?.id === id);
          if (item && item.id) {
            return {
              id: item.id,
              assetTag: item.assetTag,
              type: item.type?.val,
              location: item.location?.val,
              manu: item.manu,
              model: item.model,
              typeId: item.type?.id,
              locationId: item.location?.id,
              buildingId: item.building?.id,
              fundingId: item.funding?.id,
              acquired: item.acquired,
              verified: item.verified,
              macAddress: item.macAddress,
              ipAddress: item.ipAddress,
              serial: item.serial,
            };
          } else {
            return null;
          }
        })
        .filter(Boolean),
    }));
  };

  const handleRemoveAsset = (params) => {
    dispatch(removeAsset(params.row));
  };

  const columns = [
    {
      headerName: 'Action',
      field: 'action',
      width: 100,
      align: 'center',
      renderCell: (params) => (
        <ButtonIcon onClick={() => handleRemoveAsset(params)}>
          <OutlinedIconClose />
        </ButtonIcon>
      ),
    },
    {
      headerName: 'Asset Tag',
      field: 'assetTag',
      width: 140,
      renderCell: renderCellExpand,
    },
    { headerName: 'Type', field: 'type', flex: 1 },
    {
      headerName: 'Model',
      field: 'model',
      flex: 1,
      renderCell: renderCellExpand,
    },
    { headerName: 'Location', field: 'location', flex: 1 },
  ];

  useEffect(() => {
    return () => {
      dispatch(resetAssets());
      dispatch(resetBulkAssets());
      dispatch(resetAssetTags());
    };
  }, [dispatch]);

  const handleAssetAttribute = (e) => {
    dispatch(resetAssets());
    reset(defaultValue);
    setValue('assetAttribute', e.target.value);
  };

  const handleAssetTag = (e) => {
    if (e.key === 'Enter') {
      const value = e.target.value.replace(/^0+/, '');
      setSearchedAssetTag(value);
    }
  };

  const handleResetPage = () => {
    setValue('assetAttribute', 'none');
    setSearchedAssetTag('');
    dispatch(resetAssets());
    dispatch(resetBulkAssets());
    dispatch(resetAssetTags());
    reset(defaultValue);
  };

  useEffect(() => {
    if (loadingButton) {
      const timer = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 0 : prevProgress + 1
        );
      }, 100);
      return () => {
        clearInterval(timer);
      };
    }
  }, [loadingButton]);

  const handleConfirmationDialog = () => {
    setOpenConfirmationDialog(true);
  };

  const onSubmit = async () => {
    setOpenConfirmationDialog(false);
    setLoadingButton(true);
    setSearchedAssetTag('');
    const snackBar = getSnackBar(massUpdateSuccess);
    const updatedItems = massUpdateList?.rows?.map((item) => {
      return {
        ...item,
        assetTag: assetTag === null ? item.assetTag : assetTag,
        manu: manu === null ? item.manu : manu,
        model: model === null ? item.model : model,
        typeId: typeId === '' ? item.typeId : typeId,
        locationId: locationId === '' ? item.locationId : locationId,
        buildingId: buildingId === '' ? item.buildingId : buildingId,
        fundingId: fundingId === '' ? item.fundingId : fundingId,
        acquired:
          acquired === '' ? item.acquired : moment(acquired).format(dateFormat),
        verified:
          verified === '' ? item.verified : moment(verified).format(dateFormat),
        macAddress: macAddress === null ? item.macAddress : macAddress,
        ipAddress: ipAddress === null ? item.ipAddress : ipAddress,
        serial: serial === null ? item.serial : serial,
      };
    });
    try {
      const totalIterations = updatedItems.length * assetTags.length;
      let currentIteration = 0;
      for (let i = 0; i < updatedItems.length; i++) {
        const payload = {
          data: updatedItems[i],
        };
        await putAsset(payload, updatedItems[i]?.id);
        currentIteration++;
        setProgress((currentIteration / totalIterations) * 100);
      }
      dispatch(resetBulkAssets());
      fetchMassUpdateAsset();
      dispatch(resetAssets());
      for (let i = 0; i < updatedItems.length; i++) {
        const retriveSearchedAsset = await getMassUpdateAsset(
          updatedItems[i]?.id
        );
        const getRetriveSearchedAsset = retriveSearchedAsset?.items?.[0];
        dispatch(setBulkAssets(getRetriveSearchedAsset));
        currentIteration++;
        setProgress((currentIteration / totalIterations) * 100);
      }
      setValue('assetAttribute', 'none');
      dispatch(displaySnackbar(snackBar));
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error?.response?.data?.message);
    }
    setLoadingButton(false);
    setProgress(0);
  };

  const isDisabled = !(
    (assetTag ||
      manu ||
      model ||
      typeId ||
      (locationId && buildingId) ||
      fundingId ||
      (acquired && verified) ||
      macAddress ||
      ipAddress ||
      serial) &&
    rows.length > 0
  );

  if (loadingButton || loading) {
    return percentageLoader(progress);
  }

  const pageSizeChange = pageSizeChangeHandler(setMassUpdateList);

  return (
    <>
      <CustomizedSnackbars />
      <MDBox>
        <Card>
          <HeadingWrapperBox>
            <MDTypography variant="h5" fontWeight="medium">
              Bulk Update Assets
            </MDTypography>
            <DropDownBox>
              <FormInputText
                name="searchedAssetTag"
                type="text"
                control={control}
                label="Asset Tag"
                placeholder="Use commas to search multiple tags."
                register={register('searchedAssetTag')}
                onKeyDown={handleAssetTag}
              />
            </DropDownBox>
          </HeadingWrapperBox>
          <DeltaDataGrid
            listData={massUpdateList}
            columns={columns}
            setListData={pageSizeChange}
          />
          <MassBox>
            <Typography variant="h6">Bulk Update Form</Typography>
          </MassBox>
          <MassGrid container spacing={2}>
            <Grid item xs={12} sm={4.5}>
              <FormInputDropdown
                name="assetAttribute"
                type="text"
                control={control}
                options={assetAttributes}
                label="Asset Attribute"
                register={register('assetAttribute', {
                  onChange: (e) => {
                    handleAssetAttribute(e);
                  },
                  value: assetAttribute || 'none',
                })}
              />
              {assetAttribute === 'typeId' && (
                <FormInputDropdown
                  name="typeId"
                  type="text"
                  control={control}
                  options={assetTypeData}
                  defaultValue={defaultValue?.typeId}
                  label="Asset Type"
                  register={register('typeId', {
                    onChange: (e) => dispatch(setTypeId(e.target.value)),
                  })}
                />
              )}
              {assetAttribute === 'locationId' && (
                <FormInputDropdown
                  name="locationId"
                  type="text"
                  control={control}
                  options={locationData}
                  defaultValue={defaultValue?.locationId}
                  label="Location"
                  register={register('locationId', {
                    onChange: (e) => dispatch(setLocationId(e.target.value)),
                  })}
                />
              )}
              {assetAttribute === 'locationId' && (
                <FormInputDropdown
                  name="buildingId"
                  type="text"
                  control={control}
                  options={buildingData}
                  defaultValue={defaultValue?.buildingId}
                  label="Building Type"
                  register={register('buildingId', {
                    onChange: (e) => dispatch(setBuildingId(e.target.value)),
                  })}
                />
              )}
              {assetAttribute === 'manu' && (
                <FormInputText
                  name="manu"
                  type="text"
                  control={control}
                  label="Manufacturer"
                  register={register('manu', {
                    onChange: (e) => {
                      dispatch(setManu(e.target.value));
                    },
                  })}
                />
              )}
              {assetAttribute === 'acquired' && (
                <FormInputDate
                  name="acquired"
                  control={control}
                  label="Date Acquired"
                  maxDate={defaultValue.verified}
                  value={acquired}
                  helperText={errors?.acquired?.message}
                  errors={!!errors.acquired}
                  register={register('acquired', {
                    onChange: (e) => dispatch(setAcquired(e.target.value)),
                  })}
                />
              )}
              {assetAttribute === 'macAddress' && (
                <FormInputText
                  name="macAddress"
                  type="text"
                  control={control}
                  label="Mac Address"
                  register={register('macAddress', {
                    onChange: (e) => {
                      dispatch(setMacAddress(e.target.value));
                    },
                  })}
                />
              )}
              {assetAttribute === 'serial' && (
                <FormInputText
                  name="serial"
                  type="text"
                  control={control}
                  label="Serial"
                  register={register('serial', {
                    onChange: (e) => {
                      dispatch(setSerial(e.target.value));
                    },
                  })}
                />
              )}
              {assetAttribute === 'fundingId' && (
                <FormInputDropdown
                  name="fundingId"
                  type="text"
                  control={control}
                  options={fundingTypeData}
                  defaultValue={defaultValue?.fundingId}
                  label="Funding Type"
                  register={register('fundingId', {
                    onChange: (e) => dispatch(setFundingId(e.target.value)),
                  })}
                />
              )}
              {assetAttribute === 'model' && (
                <FormInputText
                  name="model"
                  type="text"
                  control={control}
                  label="Model"
                  register={register('model', {
                    onChange: (e) => {
                      dispatch(setModel(e.target.value));
                    },
                  })}
                />
              )}
              {assetAttribute === 'acquired' && (
                <FormInputDate
                  name="verified"
                  control={control}
                  label="Date Verified"
                  minDate={defaultValue.acquired}
                  value={verified}
                  helperText={errors?.verified?.message}
                  errors={!!errors.verified}
                  register={register('verified', {
                    onChange: (e) => dispatch(setVerified(e.target.value)),
                  })}
                />
              )}
              {assetAttribute === 'ipAddress' && (
                <FormInputText
                  name="ipAddress"
                  type="text"
                  control={control}
                  label="Ip Address"
                  register={register('ipAddress', {
                    onChange: (e) => {
                      dispatch(setIpAddress(e.target.value));
                    },
                  })}
                />
              )}
              <ButtonSpinner style={{ display: 'flex', justifyContent: 'end' }}>
                <Button variant="text" onClick={handleResetPage}>
                  Reset Page
                </Button>
                <Box sx={{ position: 'relative' }}>
                  <Button
                    variant="text"
                    onClick={handleConfirmationDialog}
                    disabled={isDisabled}
                  >
                    Save
                  </Button>
                </Box>
              </ButtonSpinner>
            </Grid>
          </MassGrid>
        </Card>
      </MDBox>
      <NotificationPopup />
      <ConfirmationDialog
        openConfirmationDialog={openConfirmationDialog}
        setOpenConfirmationDialog={setOpenConfirmationDialog}
        title={updateAssetsConformation}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        loadingButton={false}
        isSubmit={true}
      />
    </>
  );
};

export default MassUpdateList;
