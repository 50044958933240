import { createSlice } from '@reduxjs/toolkit';
import initialState from './trackingInitialState';
import { _setTrackingForm, _setIsNewTracking } from './trackingAction';

export const trackingSlice = createSlice({
  name: 'delta/trackingForm',
  initialState,
  reducers: {
    setTrackingForm: _setTrackingForm,
    setIsNewTracking: _setIsNewTracking,
    resetTrackingForm: () => initialState,
  },
});

export const { setTrackingForm, setIsNewTracking, resetTrackingForm } =
  trackingSlice.actions;

export default trackingSlice.reducer;
