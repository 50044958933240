export const _setAttendanceAuditsArchivePagination = (state, action) => {
  state.attendanceAuditsArchivePagination = [
    ...state.attendanceAuditsArchivePagination,
    action.payload,
  ];
};

export const _setAttendanceAuditsArchivePaginationPreviousKey = (
  state,
  action
) => {
  state.attendanceAuditsArchivePaginationPreviousKey = action.payload;
};
