import React, { useState } from 'react';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
// import { useDispatch, useSelector } from 'react-redux';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Card from '@mui/material/Card';
import { useEffect } from 'react';
import CustomizedSnackbars from 'components/snackbar/Snackbar';
import { renderActionItems } from 'components/Common/GridAction';
import CommonDeleteDialog from 'components/Common/CommonDeleteDialog/CommonDeleteDialog';
import { intialPageSize } from 'constants';
import {
  HeadingWrapperBox,
  GridWrapper,
} from 'layouts/inventory/Inventory.styled';
import { getMyStudentsDataGrid } from './MyStudentsListGrid';
const MyStudentsList = () => {
  const [myStudentsData, setMyStudentstData] = useState({
    isLoading: false,
    rows: [
      {
        id: 1,
        Name: 'Juno',
        Grade: 35,
        OverallGrade: 35,
        Progress: 'Completed',
      },
      {
        id: 2,
        Name: 'Abrar',
        Grade: 42,
        OverallGrade: 35,
        Progress: 'Completed',
      },
      {
        id: 3,
        Name: 'Hidayathulla',
        Grade: 45,
        OverallGrade: 35,
        Progress: 'In-progress',
      },
    ],
    pageSize: intialPageSize,
  });
  // to fetch the My Students data on page load
  useEffect(() => {}, []);
  const editClickHandler = (params) => {
    console.log(params);
  };
  const deleteClickHandler = (params) => {
    console.log(params);
  };
  const columns = [
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      disableColumnMenu: true,
      width: 80,
      align: 'center',
      // this renderActionItems returns action items EditIcon and DeleteIcon
      renderCell: (params) =>
        renderActionItems(params, editClickHandler, deleteClickHandler),
    },
    // this is type column in grid
    { headerName: 'Name', field: 'Name', flex: 1 },
    { headerName: 'Grade', field: 'Grade', flex: 1 },
    { headerName: 'Overall Grade', field: 'OverallGrade', flex: 1 },
    { headerName: 'Progress', field: 'Progress', flex: 1 },
  ];
  return (
    <DashboardLayout>
      <CustomizedSnackbars />
      <DashboardNavbar />
      <MDBox>
        <Card>
          <HeadingWrapperBox>
            <MDTypography variant='h5' fontWeight='medium'>
              My Students
            </MDTypography>
          </HeadingWrapperBox>
          {getMyStudentsDataGrid(myStudentsData, columns, setMyStudentstData)}
        </Card>
      </MDBox>
      {/* <MyStudentsDialog
        open={openMyStudentsDialog}
        setOpen={setopenMyStudentsDialog}
      /> */}
      <CommonDeleteDialog
        // openDeleteDialog={openInventoryDeleteDialog}
        // setOpenDeleteDialog={setOpenInventoryDeleteDialog}
        title='Delete Asset Type'
        contentText='Are you sure you want to delete this Student ?'
        snakBarName='Asset type'
        // commonDeleteApi={'commonDeleteApi'}
        // commonDeleteId={id}
      />
    </DashboardLayout>
  );
};
export default MyStudentsList;
