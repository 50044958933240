import { useSelector } from 'react-redux';

const permissionMapping = {
  addCalendar: {
    allowedPermission: [],
    isAppAdminAllowed: true,
  },
  updateCalendar: {
    allowedPermission: ['admin'],
    isAppAdminAllowed: true,
  },
  deleteCalendar: {
    allowedPermission: ['admin'],
    isAppAdminAllowed: true,
  },
  addCategory: {
    allowedPermission: ['admin'],
    isAppAdminAllowed: false,
  },
  updateCategory: {
    allowedPermission: ['admin'],
    isAppAdminAllowed: false,
  },
  deleteCategory: {
    allowedPermission: ['admin'],
    isAppAdminAllowed: false,
  },
  addEvent: {
    allowedPermission: ['admin', 'write'],
    isAppAdminAllowed: false,
  },
  updateEvent: {
    allowedPermission: ['admin', 'write'],
    isAppAdminAllowed: false,
  },
  deleteEvent: {
    allowedPermission: ['admin', 'write'],
    isAppAdminAllowed: false,
  },
  managePermission: {
    allowedPermission: ['admin'],
    isAppAdminAllowed: true,
  },
};

export const useCalendarPermissionCustomHook = (permissionName) => {
  const username = useSelector((state) => state.userDetails.username);
  const myPermissions = useSelector(
    (state) => state?.myPermissions?.permissions
  );
  const myCalendarPermissions = useSelector(
    (state) => state?.calendarDetails?.calendar?.permissions
  );
  const getPermissionName = () => {
    const getPermission = myCalendarPermissions?.find(
      (item) => item.username === username
    );
    return getPermission?.permission;
  };
  const permissionAccess = getPermissionName();
  const isAppAdmin = (myPermissions) => {
    if (myPermissions?.includes('CAN_MANAGE_CALENDARS')) {
      return true;
    } else {
      return false;
    }
  };
  const featurePermission = permissionMapping[permissionName];
  const { allowedPermission, isAppAdminAllowed } = featurePermission;
  return (
    allowedPermission?.includes(permissionAccess) ||
    (isAppAdminAllowed && isAppAdmin(myPermissions))
  );
};
