import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import { Controller } from 'react-hook-form';
import { GridForm, GridButtonWrapper } from 'components/Ui/styled';
import { getDialogHeading } from 'utils/commonUtils';
import { useFieldArray, useForm } from 'react-hook-form';
import { PaperPropsCheck } from 'constants';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { renderSaveCancelButtons } from 'utils/commonUtils';
import FileUploadField from 'components/Common/Forms/FileUploadField';
import MultiFormDateField from 'components/Common/Forms/MultiFormInputDate';
import { BoxWrapper } from 'components/Ui/styled';
import { companyOptions } from 'constants';
import MultiFormDropdownField from 'components/Common/Forms/MultiFormDropDown';

const EmploymentDialog = (props) => {
  const { isEmploymentDialog, setIsEmploymentDialog } = props;
  const [loadingButton, setLoadingButton] = useState(false);

  const handleEmployer = () => {
    append({});
  };

  const defaultValues = {
    items: [],
  };

  const { control, handleSubmit, register, reset, getValues, setValue } =
    useForm({
      mode: 'onChange',
      defaultValues,
    });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  });

  useEffect(() => {
    if (isEmploymentDialog) {
      append({});
    }
  }, [isEmploymentDialog]);

  const handleContinue = async (data) => {
    console.log('data', data);
  };

  const handleSaveForLater = () => {
    setIsEmploymentDialog(false);
  };

  const handleClose = () => {
    setIsEmploymentDialog(false);
    reset(defaultValues);
  };

  const onSubmit = async (data) => {
    console.log('data', data);
  };

  return (
    <>
      <Dialog open={isEmploymentDialog} PaperProps={PaperPropsCheck}>
        {getDialogHeading('Employment', handleClose, 'staffHeading')}
        <BoxWrapper>
          <GridForm container spacing={2}>
            {fields.map((field, index) => (
              <Grid item xs={12} key={index}>
                <MultiFormDropdownField
                  name="company"
                  label="Company"
                  index={index}
                  control={control}
                  options={companyOptions}
                  field={field}
                />
                <Controller
                  name={`items[${index}].jobTitle`}
                  control={control}
                  defaultValue={field.jobTitle || ''}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Job Title"
                      variant="standard"
                      style={{ width: '100%' }}
                    />
                  )}
                />
                <FileUploadField
                  name="contract"
                  label="Upload Contract"
                  control={control}
                  index={index}
                />
                <FileUploadField
                  name="resume"
                  label="Upload Resume"
                  control={control}
                  index={index}
                />
                <FileUploadField
                  name="w4"
                  label="Upload W-4"
                  control={control}
                  index={index}
                />
                <FileUploadField
                  name="i9"
                  label="Upload I-9"
                  control={control}
                  index={index}
                />
                <FileUploadField
                  name="directDepositForm"
                  label="Upload Direct Deposit Form"
                  control={control}
                  index={index}
                />
                <MultiFormDateField
                  name="expDate"
                  label="Expiration Date"
                  control={control}
                  index={index}
                />
              </Grid>
            ))}
            <Button variant="text" onClick={handleEmployer}>
              Add Employer
            </Button>
          </GridForm>
          <GridButtonWrapper container>
            {renderSaveCancelButtons(
              handleClose,
              handleSubmit(onSubmit),
              loadingButton,
              false,
              'Skip for Now And Save'
            )}
          </GridButtonWrapper>
        </BoxWrapper>
      </Dialog>
    </>
  );
};

export default EmploymentDialog;
