import { createSlice } from "@reduxjs/toolkit";
import { _updateLocationTypeData, _updateTypeData,_setLocationTypeData } from "./locationTypeAction";
import initialState from "./locationTypeInitialState";

export const locationTypeSlice = createSlice({
  name: "locationType",
  initialState,
  reducers: {
    updateLocationTypeData: _updateLocationTypeData,
    updateTypeData: _updateTypeData,
    setLocationTypeData: _setLocationTypeData,
  },
});

export const { updateLocationTypeData, updateTypeData, setLocationTypeData } =
  locationTypeSlice.actions;

export default locationTypeSlice.reducer;
