import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import {
  HeadingMuiTypography,
  GridWrapper,
  ButtonGrid,
} from 'components/Ui/styled';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import { useSelector, useDispatch } from 'react-redux';
import {
  getDashboardPassword,
  resetDashboardPassword,
} from 'layouts/studentManagement/services/studentProfileApi';
import {
  showBackdropLoader,
  useNotification,
  onSave,
  getButtonSpinner,
  emptyFunction,
} from 'utils/commonUtils';
import PasswordToggle from 'components/Common/PasswordToggle';
import {
  resetDashboardPasswordSuccess,
  resetDashboardPasswordConformation,
} from 'constants';
import ConfirmationDialog from 'components/Common/ConfirmationDialog/ConfirmationDialog';
import { usePermissionCustomHook } from 'layouts/users/userPermissions/utils/permissionsUtils';

const DashboardPasswordTab = () => {
  const dispatch = useDispatch();
  const [dashboardPassword, setDashboardPassword] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();

  const canResetStudentDashboardPasswords = usePermissionCustomHook(
    'CAN_RESET_STUDENT_DASHBOARD_PASSWORDS'
  );

  const studentDetails = useSelector((state) => state.studentDetails);

  const handleShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    fetchDashboardPassword();
  }, []);

  const fetchDashboardPassword = async () => {
    setIsLoading(true);
    try {
      const dashboardPassword = await getDashboardPassword(
        studentDetails?.profile?.resourceId
      );
      setDashboardPassword(dashboardPassword?.dashboardPassword);
      setIsLoading(false);
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
      setIsLoading(false);
    }
  };

  const defaultValue = {
    dashboardPassword: dashboardPassword,
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
  });

  const { handleSubmit, reset, control } = useFunction;

  useEffect(() => {
    reset(defaultValue);
  }, [defaultValue.dashboardPassword]);

  const handleResetPassword = () => {
    setOpenConfirmationDialog(true);
  };

  const handleClose = () => {
    setOpenConfirmationDialog(false);
  };

  const onSubmit = async () => {
    onSave(
      resetDashboardPasswordSuccess,
      true,
      resetDashboardPassword,
      emptyFunction,
      studentDetails?.profile?.resourceId,
      '',
      fetchDashboardPassword,
      handleClose,
      setOpenNotification,
      setNotificationMessage,
      dispatch,
      setLoadingButton,
      loadingButton
    );
  };

  return (
    <>
      {isLoading && showBackdropLoader(isLoading)}
      <HeadingMuiTypography variant="h6">
        Dashboard Password
      </HeadingMuiTypography>
      <GridWrapper container spacing={2}>
        <Grid item xs={12} md={10}>
          <Grid item xs={12} md={4.7}>
            <FormInputText
              name="dashboardPassword"
              type={showPassword ? 'text' : 'password'}
              control={control}
              label="Dashboard Password"
              readOnly={true}
              endAdornment={
                <PasswordToggle
                  onClick={handleShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  showPassword={showPassword}
                />
              }
            />
          </Grid>
          <ButtonGrid item xs={12} md={4.7}>
            {getButtonSpinner(
              false,
              handleResetPassword,
              'Reset Dashboard Password',
              !canResetStudentDashboardPasswords
            )}
          </ButtonGrid>
        </Grid>
      </GridWrapper>
      <ConfirmationDialog
        openConfirmationDialog={openConfirmationDialog}
        setOpenConfirmationDialog={setOpenConfirmationDialog}
        title={resetDashboardPasswordConformation}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        loadingButton={loadingButton}
        isSubmit={true}
      />
      <NotificationPopup />
    </>
  );
};

export default DashboardPasswordTab;
