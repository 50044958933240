export const _setStudentProfilePagination = (state, action) => {
  state.studentProfilePagination = [
    ...state.studentProfilePagination,
    action.payload,
  ];
};

export const _setStudentProfilePaginationPreviousKey = (state, action) => {
  state.studentProfilePaginationPreviousKey = action.payload;
};
