import { DataGrid } from '@mui/x-data-grid';
import MDBox from 'components/MDBox';
import { GridWrapper } from 'components/Ui/styled';

const TutoringList = () => {
  const columns = [
    {
      field: 'studentId',
      headerName: 'Student ID',
      flex: 1,
    },
    {
      field: 'studentName',
      headerName: 'Student Name',
      flex: 1,
    },

    {
      field: 'teacherName',
      headerName: 'Teacher Name',
      flex: 1,
    },
    {
      field: 'subject',
      headerName: 'Subject',
      flex: 1,
    },
    {
      field: 'date',
      headerName: 'Date',
      flex: 1,
    },
    {
      field: 'time',
      headerName: 'Time',
      flex: 1,
    },
  ];

  const rows = [
    {
      id: 1,
      studentName: 'Bobby Johnson',
      studentId: '11360722',
      teacherName: 'Mr. Mancuso',
      subject: 'Math',
      date: '2/3/2024',
      time: '10AM',
    },
    {
      id: 2,
      studentName: 'Bobby Johnson',
      studentId: '1234567',
      teacherName: 'Mr. Mancuso',
      subject: 'English',
      date: '4/2/2024',
      time: '11AM',
    },
    {
      id: 3,
      studentName: 'Tucker Owens',
      studentId: '11360722',
      teacherName: 'Mr. Mancuso',
      subject: 'Science',
      date: '2/5/2024',
      time: '10:15AM',
    },
    {
      id: 4,
      studentName: 'Tucker Owens',
      studentId: '11360722',
      teacherName: 'Mr. Mancuso',
      subject: 'Math',
      date: '5/2/2024',
      time: '10AM',
    },
    {
      id: 5,
      studentName: 'Alexis Reyes',
      studentId: '1234567',
      teacherName: 'Mr. Mancuso',
      subject: 'Math',
      date: '2/2/2024',
      time: '9AM',
    },
  ];

  return (
    <>
      <GridWrapper>
        <MDBox pt={3}></MDBox>
        <DataGrid autoHeight rows={rows} columns={columns} />
      </GridWrapper>
    </>
  );
};

export default TutoringList;
