import React from 'react';
import { Box } from '@mui/material';
import { PaperProps } from 'constants';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import { BoxWrapper } from 'components/Ui/styled';
import ListItems from 'components/Common/ListItems';
import { getDialogHeading } from 'utils/commonUtils';
import { resetRenderedList } from 'utils/commonSlice/renderListSlice';

const RenderListDialog = (props) => {
  const dispatch = useDispatch();
  const { open, setOpen, dialogName, setDialogName } = props;

  const renderList = useSelector((state) => state.renderList);

  const handleClose = () => {
    dispatch(resetRenderedList());
    setDialogName('');
    setOpen(false);
  };

  const heading = dialogName ? dialogName : 'Groups';

  return (
    <>
      <Dialog open={open} PaperProps={PaperProps}>
        {getDialogHeading(heading, handleClose)}
        <BoxWrapper>
          {renderList?.map((item, index) => {
            return (
              <Box key={index}>
                <ListItems title={item} />
              </Box>
            );
          })}
        </BoxWrapper>
      </Dialog>
    </>
  );
};

export default RenderListDialog;
