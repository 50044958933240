import { createSlice } from '@reduxjs/toolkit';
import initialState from './bulkAssetsInitialState';
import { _setBulkAssets, _removeAsset } from './bulkAssetsAction';

export const bulkAssetsSlice = createSlice({
  name: 'bulkAssets',
  initialState,
  reducers: {
    setBulkAssets: _setBulkAssets,
    removeAsset: _removeAsset,
    resetBulkAssets: () => initialState,
  },
});

export const { setBulkAssets, removeAsset, resetBulkAssets } =
  bulkAssetsSlice.actions;

export default bulkAssetsSlice.reducer;
