import React from 'react';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import MDBox from 'components/MDBox';
import MyStudents from './components/MyStudents/MyStudents';

const Teacher = () => {
  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox>
        <MyStudents />
        {/* <MDBox pt={2}></MDBox>
        <Grid container spacing={2}>
          <TeacherAnnouncements />
          <TeacherCalendars />
        </Grid> */}
      </MDBox>
    </DashboardLayout>
  );
};

export default Teacher;
