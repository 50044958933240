import React from 'react';
import Box from '@mui/material/Box';
import { noInfoLocations } from 'constants';
import ListItems from 'components/Common/ListItems';
import { useRenderNoInfo } from 'utils/commonUtils';

const DashboardLocations = (props) => {
  const { myProfile } = props;

  const noInfoComponent = useRenderNoInfo(
    myProfile?.user?.assignedLocations,
    noInfoLocations
  );

  return (
    <>
      {noInfoComponent}
      {myProfile?.user?.assignedLocations?.map((item, index) => {
        const { val } = item;
        return (
          <Box key={index}>
            <ListItems title={val} />
          </Box>
        );
      })}
    </>
  );
};

export default DashboardLocations;
