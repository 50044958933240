import { createSlice } from '@reduxjs/toolkit';
import initialState from './studentTrackerCellInitialState';
import {
  _setStudentTrackerCellData,
  _setStudentTrackerColumnName,
} from './studentTrackerCellAction';

export const studentTrackerCellSlice = createSlice({
  name: 'delta/studentTrackerCell',
  initialState,
  reducers: {
    setStudentTrackerCellData: _setStudentTrackerCellData,
    setStudentTrackerColumnName: _setStudentTrackerColumnName,
  },
});

export const { setStudentTrackerCellData, setStudentTrackerColumnName } =
  studentTrackerCellSlice.actions;

export default studentTrackerCellSlice.reducer;
