/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export const calendarEventsData = {
  data: [
    {
      title: 'All day conference',
      description: 'All day conference should be done',
      start: '2022-11-13',
      end: '2022-11-13',
      startTime: '2022-11-13',
      endTime: '2022-11-13',
      allDay: true,
      location: 'London',
    },
    {
      title: 'Meeting with Mary',
      description: 'Meeting with Mary should be done',
      start: '2022-11-15',
      end: '2022-11-15',
      startTime: '2022-11-15',
      endTime: '2022-11-15',
      allDay: false,
      location: 'New york',
    },
  ],
};

export default calendarEventsData;
