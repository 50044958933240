import { API, Auth } from 'aws-amplify';
import { ASSET_BASE_URL_APPEND } from 'layouts/inventory/constants';

export const getBuilding = async () => {
  const LocationType = await API.get(
    'delta-api',
    `${ASSET_BASE_URL_APPEND}buildings`,
    {}
  );
  return LocationType;
};

export const getBuildingOfSpecifiedLocation = async (locationId) => {
  const buildingDataOfSpecifiedLocation = await API.get(
    'delta-api',
    `${ASSET_BASE_URL_APPEND}locations/${locationId}`,
    {}
  );
  return buildingDataOfSpecifiedLocation;
};

export const postBuilding = async (postParams) => {
  const authToken = Auth.user.signInUserSession.accessToken.jwtToken;
  const myInit = {
    body: postParams,
  };
  await API.post('delta-api', `${ASSET_BASE_URL_APPEND}buildings`, myInit);
};

export const putBuilding = async (payload, id) => {
  const authToken = Auth.user.signInUserSession.accessToken.jwtToken;
  const myInit = {
    queryStringParameters: payload,
    body: payload,
  };

  await API.put('delta-api', `${ASSET_BASE_URL_APPEND}buildings/${id}`, myInit);
};

export const deleteBuilding = async (id) => {
  const myInit = {};
  await API.del(
    'delta-api',
    `${ASSET_BASE_URL_APPEND}buildings/${id}`,
    {},
    myInit
  );
};
