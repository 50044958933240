import { createSlice } from '@reduxjs/toolkit';
import {
  _setAttendanceAuditsArchivePagination,
  _setAttendanceAuditsArchivePaginationPreviousKey,
} from './attendanceAuditsArchivePaginationAction';

const initialState = {
  attendanceAuditsArchivePagination: [],
  attendanceAuditsArchivePaginationPreviousKey: '',
};

export const attendanceAuditsArchivePaginationSlice = createSlice({
  name: 'delta/attendanceAuditsArchivePagination',
  initialState,
  reducers: {
    setAttendanceAuditsArchivePagination: _setAttendanceAuditsArchivePagination,
    setAttendanceAuditsArchivePaginationPreviousKey:
      _setAttendanceAuditsArchivePaginationPreviousKey,
    resetAttendanceAuditsArchivePagination: () => initialState,
  },
});

export const {
  setAttendanceAuditsArchivePagination,
  setAttendanceAuditsArchivePaginationPreviousKey,
  resetAttendanceAuditsArchivePagination,
} = attendanceAuditsArchivePaginationSlice.actions;

export default attendanceAuditsArchivePaginationSlice.reducer;
