import React from 'react';
import Grid from '@mui/material/Grid';
import MDTypography from 'components/MDTypography';
import { GridHeading, EndGrid } from 'components/Ui/styled';
import FormInputDropdown from 'components/Common/Forms/FormInputDropDown';
import Button from '@mui/material/Button';

const TeacherHeading = (props) => {
  const { control } = props;

  return (
    <GridHeading container spacing={2}>
      <Grid item xs={12} sm={6} md={2.4}>
        <MDTypography variant="h5" fontWeight="medium">
          Teachers
        </MDTypography>
      </Grid>
      <Grid item xs={12} sm={6} md={2.4}></Grid>
      <Grid item xs={12} sm={6} md={2.4}></Grid>
      <EndGrid item xs={12} sm={6} md={2.4}>
        <FormInputDropdown
          name="chooseClass"
          type="text"
          control={control}
          label="Choose Class"
        />
      </EndGrid>
      <EndGrid item xs={12} sm={6} md={2.4}>
        <Button variant="text">New Announcement</Button>
      </EndGrid>
    </GridHeading>
  );
};

export default TeacherHeading;
