import { createSlice } from '@reduxjs/toolkit';

const initialState = [
  {
    username: '',
    userId: '',
    email: '',
    givenName: '',
    familyName: '',
  },
];

const availableUsersSlice = createSlice({
  name: 'delta/availableUsers',
  initialState: initialState,
  reducers: {
    availableUsers: (state, action) => action.payload,
    resetAvailableUsers: () => [],
  },
});

export const { resetAvailableUsers, availableUsers } =
  availableUsersSlice.actions;

export default availableUsersSlice.reducer;
