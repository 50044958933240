import { createSlice } from '@reduxjs/toolkit';
import { _setStudentProfileFilterOptions } from './studentProfileFilterOptionsAction';

export const studentProfileFilterOptionsSlice = createSlice({
  name: 'delta',
  initialState: null,
  reducers: {
    setStudentProfileFilterOptions: _setStudentProfileFilterOptions,
    resetStudentProfileFilterOptions: () => null,
  },
});

export const {
  setStudentProfileFilterOptions,
  resetStudentProfileFilterOptions,
} = studentProfileFilterOptionsSlice.actions;

export default studentProfileFilterOptionsSlice.reducer;
