/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// react-router components
import { useLocation, useNavigate } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @material-ui core components
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Icon from '@mui/material/Icon';
import MenuItem from '@mui/material/MenuItem';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDBadge from 'components/MDBadge';

// Material Dashboard 2 PRO React examples
import Breadcrumbs from 'examples/Breadcrumbs';
import NotificationItem from 'examples/Items/NotificationItem';

import { useAuthenticator } from '@aws-amplify/ui-react';

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from 'examples/Navbars/DashboardNavbar/styles';

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from 'context';
import { setNotificationBadge } from 'layouts/dashboards/analytics/store/announcementNotification/announcementNotificationsSlice';
import { getMyProfileData } from 'layouts/myProfile/store/myProfileSlice';
import { getMyProfile } from 'layouts/myProfile/service/myProfileApi';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { resetSelectSemester } from 'layouts/applications/attendance/store/semesterSlice';
import { resetIsLogout, setIsLogout } from '../store/logoutSlice';
import { resetMyPermissionsData } from 'layouts/users/userPermissions/store/myPermissionsSlice';
import { resetCalenderData } from 'layouts/applications/calendar/store/CalenderSlice';

function DashboardNavbar({ absolute, light, isMini }) {
  const dispatched = useDispatch();
  const navigate = useNavigate();
  const [navbarType, setNavbarType] = useState();
  //const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentNavbar,
    fixedNavbar,
    openConfigurator,
    darkMode,
  } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [profileMenu, setProfileMenu] = useState(null);
  const route = useLocation().pathname.split('/').slice(1);
  const routeUseLocation = useLocation();

  const announcementNotificationData = useSelector(
    (state) => state.announcementNotificationData
  );
  const totalNotifications = announcementNotificationData?.notificationBadge;

  const { user, signOut } = useAuthenticator((context) => [context.user]);

  const openProfileMenu = Boolean(profileMenu);

  const handleClick = (event) => {
    setProfileMenu(event.currentTarget);
  };

  const handleMyProfile = async () => {
    navigate(`/my-profile`);
    const myProfileData = await getMyProfile();
    dispatched(getMyProfileData(myProfileData));
  };

  // const handleStaffProfile = async () => {
  //   navigate(`/staff-profile`);
  // };

  const handleClose = () => {
    setProfileMenu(null);
  };

  const handleSignOut = () => {
    dispatched(setIsLogout(true));
    dispatched(resetSelectSemester());
    navigate('/');
    signOut();
    setTimeout(() => {
      dispatched(resetIsLogout());
      dispatched(resetMyPermissionsData());
      dispatched(resetCalenderData());
    }, 2000);
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType('sticky');
    } else {
      setNavbarType('static');
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener('scroll', handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener('scroll', handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget);
    dispatched(setNotificationBadge(0));
  };
  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      {announcementNotificationData?.items?.map((item, index) => {
        return (
          <NotificationItem
            key={index}
            icon={<FiberManualRecordRoundedIcon />}
            title={item.msg}
          />
        );
      })}
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <>
      <AppBar
        position={absolute ? 'absolute' : navbarType}
        color="inherit"
        sx={(theme) =>
          navbar(theme, { transparentNavbar, absolute, light, darkMode })
        }
      >
        <Toolbar sx={(theme) => navbarContainer(theme)}>
          <MDBox
            color="inherit"
            mb={{ xs: 1, md: 0 }}
            sx={(theme) => navbarRow(theme, { isMini })}
          >
            <ArrowBackRoundedIcon onClick={handleBack} cursor="pointer" />
            <Breadcrumbs
              icon="home"
              title={route[route.length - 1]}
              route={route}
              routeUseLocation={routeUseLocation}
              light={light}
            />
            <IconButton
              sx={navbarDesktopMenu}
              onClick={handleMiniSidenav}
              size="small"
              disableRipple
            >
              <Icon fontSize="medium" sx={iconsStyle}>
                {miniSidenav ? 'menu_open' : 'menu'}
              </Icon>
            </IconButton>
          </MDBox>
          {isMini ? null : (
            <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
              {/*
            <MDBox pr={1}>
              <MDInput label="Search here" />
            </MDBox>
            */}
              <MDBox color={light ? 'white' : 'inherit'}>
                <IconButton
                  onClick={handleClick}
                  sx={navbarIconButton}
                  size="small"
                  disableRipple
                >
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarMobileMenu}
                  onClick={handleMiniSidenav}
                >
                  <Icon sx={iconsStyle} fontSize="medium">
                    {miniSidenav ? 'menu_open' : 'menu'}
                  </Icon>
                </IconButton>
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenMenu}
                >
                  <MDBadge
                    badgeContent={totalNotifications}
                    color="error"
                    size="xs"
                    circular
                  >
                    <Icon sx={iconsStyle}>notifications</Icon>
                  </MDBadge>
                </IconButton>
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  onClick={handleConfiguratorOpen}
                >
                  <Icon sx={iconsStyle}>settings</Icon>
                </IconButton>
                {renderMenu()}
              </MDBox>
            </MDBox>
          )}
          <Menu
            id="basic-menu"
            anchorEl={profileMenu}
            open={openProfileMenu}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={handleMyProfile}>My Profile</MenuItem>
            {/* <MenuItem onClick={handleStaffProfile}>Staff Profile</MenuItem> */}
            <MenuItem onClick={handleSignOut}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
