import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import MDBox from 'components/MDBox';
import MDBadgeDot from 'components/MDBadgeDot';
import PieChart from 'examples/Charts/PieChart';
import { useSelector } from 'react-redux';

const StudentsInComplianceChart = () => {
  const onlineAttendanceStats = useSelector((state) => state.statsData);

  const studentsInComplianceChartData = {
    labels: ['In Compliance Passing', 'In Compliance Failing'],
    datasets: {
      label: 'Compliance',
      backgroundColors: ['info', 'primary'],
      data: [
        onlineAttendanceStats?.numStudentsInCompliancePassing,
        onlineAttendanceStats?.numStudentsInComplianceFailing,
      ],
    },
  };

  return (
    <Card sx={{ height: '70%' }}>
      <MDBox mt={3}>
        <Grid container alignItems='center'>
          <Grid item xs={6}>
            <PieChart chart={studentsInComplianceChartData} height='12.5rem' />
          </Grid>
          <Grid item xs={6}>
            <MDBox pr={1}>
              <MDBox mb={1}>
                <MDBadgeDot
                  color='info'
                  size='sm'
                  badgeContent={`In Compliance Passing: ${onlineAttendanceStats?.numStudentsInCompliancePassing}`}
                />
              </MDBox>
              <MDBox mb={1}>
                <MDBadgeDot
                  color='primary'
                  size='sm'
                  badgeContent={`In Compliance Failing: ${onlineAttendanceStats?.numStudentsInComplianceFailing}`}
                />
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox
        pt={4}
        pb={2}
        px={2}
        display='flex'
        flexDirection={{ xs: 'column', sm: 'row' }}
        mt='auto'
      ></MDBox>
    </Card>
  );
};

export default StudentsInComplianceChart;
