import { createSlice } from '@reduxjs/toolkit';
import { _setStudentScores } from './studentScoresAction';

export const studentScoresSlice = createSlice({
  name: 'delta/studentScoresSlice',
  initialState: null,
  reducers: {
    setStudentScores: _setStudentScores,
    resetStudentScores: () => null,
  },
});

export const { setStudentScores, resetStudentScores } =
studentScoresSlice.actions;

export default studentScoresSlice.reducer;
