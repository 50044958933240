const initialState = {
  studentType: null,
  instructor: null,
  course: null,
  gradeLevel: null,
  schoolYears: [],
  testCategory: null,
  testSubcategories: [],
  studentNumbers: [],
  filterOptions: {
    proficiencyLevel: [],
  },
  testSubcategoriesList: [],
  byCategory: [],
  isRawResults: false,
};

export default initialState;
