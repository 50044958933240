import { API } from 'aws-amplify';
import { STUDENT_PROFILE_FILTER_OPTION_BASE_URL_APPEND } from 'constants';

export const getStudentsProfile = async (
  pageSize,
  paginationKey,
  studentType,
  instructor,
  course,
  gradeLevel
) => {
  const queryStringParameters = {
    limit: pageSize,
    paginationKey: paginationKey,
  };
  if (studentType) {
    queryStringParameters.studentType = studentType;
  }
  if (instructor) {
    queryStringParameters.instructor = instructor;
  }
  if (course) {
    queryStringParameters.course = course;
  }
  if (gradeLevel) {
    queryStringParameters.gradeLevel = gradeLevel;
  }

  const studentsProfile = await API.get(
    'delta-api',
    `${STUDENT_PROFILE_FILTER_OPTION_BASE_URL_APPEND}?`,
    {
      headers: {},
      queryStringParameters: queryStringParameters,
    }
  );
  return studentsProfile;
};

export const getStudentProfileFilterOptions = async () => {
  const studentProfileFilterOptions = await API.get(
    'delta-api',
    `${STUDENT_PROFILE_FILTER_OPTION_BASE_URL_APPEND}filterOptions`
  );
  return studentProfileFilterOptions;
};

export const getDashboardPassword = async (studentId) => {
  const dashboardPassword = await API.get(
    'delta-api',
    `${STUDENT_PROFILE_FILTER_OPTION_BASE_URL_APPEND}${studentId}/dashboardPassword`
  );
  return dashboardPassword;
};

export const resetDashboardPassword = async (studentId) => {
  await API.post(
    'delta-api',
    `${STUDENT_PROFILE_FILTER_OPTION_BASE_URL_APPEND}${studentId}/resetDashboardPassword`
  );
};

export const getScoresOptions = async () => {
  const scoresOptions = await API.get('delta-api', `test-data/options`);
  return scoresOptions;
};

export const getStudentScores = async (studentId) => {
  const studentScores = await API.get(
    'delta-api',
    `/test-data/studentScores/${studentId}`
  );
  return studentScores;
};

export const postScores = async (payload) => {
  const myInit = {
    body: payload,
  };
  const scores = await API.post('delta-api', 'test-data/scores', myInit);

  return scores;
};

export const exportScores = async (payload) => {
  const myInit = {
    body: payload,
  };
  const scores = await API.post('delta-api', 'test-data/exportScores', myInit);

  return scores;
};
