import { createSlice } from '@reduxjs/toolkit';
import { _setNotificationBadge } from './announcementNotificationAction';
import initialState from './announcementNotificationsInitialState';

const announcementNotificationsSlice = createSlice({
  name: 'delta',
  initialState,
  reducers: {
    getAnnouncementNotifications: (state, action) => action.payload,
    setNotificationBadge: _setNotificationBadge,
    resetAnnouncementNotifications: () => initialState,
  },
});

export const {
  getAnnouncementNotifications,
  setNotificationBadge,
  resetAnnouncementNotifications,
} = announcementNotificationsSlice.actions;

export default announcementNotificationsSlice.reducer;
