import React from 'react';
import MDTypography from 'components/MDTypography';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import { AnnouncementsWrapper, TypographyText } from 'components/Ui/styled';

const ListItems = (props) => {
  const { title } = props;
  return (
    <AnnouncementsWrapper>
      <MDTypography variant='body1' color='secondary' lineHeight={0.75}>
        <FiberManualRecordRoundedIcon />
      </MDTypography>
      <TypographyText>{title}</TypographyText>
    </AnnouncementsWrapper>
  );
};

export default ListItems;
