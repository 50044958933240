export const _resetUserReference = (state, action) => {};

export const _setEmail = (state, action) => {
  state.email = action.payload;
};

export const _setUserName = (state, action) => {
  state.username = action.payload;
};

export const _setGivenName = (state, action) => {
  state.givenName = action.payload;
};

export const _setFamilyName = (state, action) => {
  state.familyName = action.payload;
};
