import { API } from 'aws-amplify';
import { ASSET_EXPORT_URL } from 'constants';
import { ASSET_BASE_URL_APPEND } from 'layouts/inventory/constants';

export const getAsset = async (
  pageSize,
  assetsPageKey,
  page,
  filterBy,
  filter,
  filterKey
) => {
  let assets = '';
  let url = '';
  if (
    (filterBy === 'type' ||
      filterBy === 'location' ||
      filterBy === 'assetTag') &&
    !filter &&
    !filterKey
  ) {
    url += `assets?limit=${pageSize}`;
  } else if (
    (filterBy === 'type' || filterBy === 'location') &&
    filter &&
    page === 1
  ) {
    url += `assets?limit=${pageSize}&filterBy=${filterBy}&filter=${filter}`;
  } else if (
    (filterBy === 'type' || filterBy === 'location') &&
    filter &&
    page !== 1
  ) {
    url += `assets?limit=${pageSize}&paginationKey=${assetsPageKey}&filterBy=${filterBy}&filter=${filter}`;
  } else if (filterBy === 'assetTag' && filterKey) {
    url += `assets?limit=${pageSize}&filterBy=${filterBy}&filter=${filterKey}`;
  } else if (page !== 1 && !filter) {
    url += `assets?limit=${pageSize}&paginationKey=${assetsPageKey}`;
  } else {
    url += `assets?limit=${pageSize}`;
  }
  assets = await API.get('delta-api', `${ASSET_BASE_URL_APPEND}${url}`);
  return assets;
};

export const postAsset = async (payload) => {
  const myInit = {
    body: payload,
  };
  await API.post('delta-api', `${ASSET_BASE_URL_APPEND}assets`, myInit);
};

export const exportAsset = async () => {
  return API.post('delta-api', `${ASSET_BASE_URL_APPEND}${ASSET_EXPORT_URL}`);
};

export const putAsset = async (payload, id) => {
  const myInit = {
    body: payload,
  };
  await API.put('delta-api', `${ASSET_BASE_URL_APPEND}assets/${id}`, myInit);
};

export const deleteAsset = async (id) => {
  await API.del('delta-api', `${ASSET_BASE_URL_APPEND}assets/${id}`, {});
};

export const getAssetAssigHistory = async (assetId) => {
  const assetAssigHistory = await API.get(
    'delta-api',
    `${ASSET_BASE_URL_APPEND}assets/${assetId}/assignments`
  );
  return assetAssigHistory;
};

export const postAssetAssignment = async (payload, id) => {
  const myInit = {
    body: payload,
  };
  await API.post(
    'delta-api',
    `${ASSET_BASE_URL_APPEND}assets/${id}/assignments`,
    myInit
  );
};

export const deleteAssetAssignment = async (payload, id) => {
  const myInit = {
    body: payload,
  };
  await API.del(
    'delta-api',
    `${ASSET_BASE_URL_APPEND}assets/${id}/assignments`,
    myInit
  );
};

export const getMassUpdateAsset = async (searchedAssetTag) => {
  const assetAssigHistory = await API.get(
    'delta-api',
    `${ASSET_BASE_URL_APPEND}assets?filterBy=assetTag&filter=${searchedAssetTag}`
  );
  return assetAssigHistory;
};
