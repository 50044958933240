import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import ListItems from 'components/Common/ListItems';

const DashboardAnnouncements = () => {
  const announcementNotificationData = useSelector(
    (state) => state.announcementNotificationData
  );
  return (
    <>
      {announcementNotificationData?.items?.map((item, index) => {
        return (
          <Box key={index}>
            <ListItems title={item.msg} />
          </Box>
        );
      })}
    </>
  );
};

export default DashboardAnnouncements;
