import { createSlice } from '@reduxjs/toolkit';
import { _setCategoryColor, _setCategoryName } from './categoryAdminAction';
import initialState from './categoryAdminInitialState';

export const categoryAdminSlice = createSlice({
  name: 'categoryAdmin',
  initialState,
  reducers: {
    updateCategoryForm: (state, action) => action.payload,
    newCategoryForm: (state, action) => action.payload,
    deleteCategoryForm: (state, action) => action.payload,
    setCategoryName: _setCategoryName,
    setCategoryColor: _setCategoryColor,
  },
});

export const {
  setCategoryName,
  setCategoryColor,
  deleteCategoryForm,
  updateCategoryForm,
  newCategoryForm,
} = categoryAdminSlice.actions;

export default categoryAdminSlice.reducer;
