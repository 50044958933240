export const _setUploadDocumentPath = (state, action) => {
  state.uploadDocumentPath = action.payload;
};

export const _setFolderData = (state, action) => {
  state.folderData = action.payload;
};

export const _setNewSubFolder = (state, action) => {
  state.newSubFolder = action.payload;
};

export const _setSubFolderName = (state, action) => {
  state.subFolderName = action.payload;
  state.uploadDocumentNewPath = state.uploadDocumentPath + state.subFolderName;
};

export const _setUploadDocumentNewPath = (state, action) => {
  state.uploadDocumentNewPath = action.payload;
};
