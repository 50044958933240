export const _getStudentNote = (state, action) => action.payload;

export const _setWeek = (state, action) => {
  state.week = action.payload;
};

export const _setLabel = (state, action) => {
  state.label = action.payload;
};

export const _setNote = (state, action) => {
  state.note = action.payload;
};

export const _setInCompliance = (state, action) => {
  state.inCompliance = action.payload;
};
