import { createSlice } from '@reduxjs/toolkit';
import {
  _setCalenderData,
  _setCalenderId,
  _setSelectCalendar,
  _setIsDashboardCalendar,
} from './CalenderAction';
import initialState from './CalenderInitialState';

export const calenderSlice = createSlice({
  name: 'Calender',
  initialState,
  reducers: {
    setCalenderData: _setCalenderData,
    setCalenderId: _setCalenderId,
    setSelectCalendar: _setSelectCalendar,
    setIsDashboardCalendar: _setIsDashboardCalendar,
    resetCalenderData: () => initialState,
  },
});

export const {
  setCalenderData,
  setCalenderId,
  setSelectCalendar,
  setIsDashboardCalendar,
  resetCalenderData,
} = calenderSlice.actions;

export default calenderSlice.reducer;
