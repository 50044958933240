import { createSlice } from '@reduxjs/toolkit';
import initialState from './globalPermissionInitialState';
import {
  _getGlobalPermissionForm,
  _setGlobalPermissions,
} from './globalPermissionAction';

export const globalPermissionSlice = createSlice({
  name: 'delta',
  initialState,
  reducers: {
    getGlobalPermissionForm: _getGlobalPermissionForm,
    setGlobalPermissions: _setGlobalPermissions,
    resetGlobalPermissions: () => initialState,
  },
});

export const {
  getGlobalPermissionForm,
  setGlobalPermissions,
  resetGlobalPermissions,
} = globalPermissionSlice.actions;

export default globalPermissionSlice.reducer;
