import { createSlice } from '@reduxjs/toolkit';
import {
  _setTaskPagination,
  _setTaskPaginationPreviousKey,
} from './taskAction';

const initialState = {
  taskPagination: [],
  taskPaginationPreviousKey: '',
};

export const taskPaginationSlice = createSlice({
  name: 'delta/taskPagination',
  initialState,
  reducers: {
    setTaskPagination: _setTaskPagination,
    setTaskPaginationPreviousKey: _setTaskPaginationPreviousKey,
    resetTaskPagination: () => initialState,
  },
});

export const {
  setTaskPagination,
  setTaskPaginationPreviousKey,
  resetTaskPagination,
} = taskPaginationSlice.actions;

export default taskPaginationSlice.reducer;
