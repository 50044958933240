import { createSlice } from '@reduxjs/toolkit';
import { _setAttendanceFilterOptions } from './attendanceFilterOptionsAction';

export const attendanceFilterOptionsSlice = createSlice({
  name: 'delta',
  initialState: null,
  reducers: {
    setAttendanceFilterOptions: _setAttendanceFilterOptions,
    resetAttendanceFilterOptions: () => null,
  },
});

export const { setAttendanceFilterOptions, resetAttendanceFilterOptions } =
  attendanceFilterOptionsSlice.actions;

export default attendanceFilterOptionsSlice.reducer;
