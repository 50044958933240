import { createSlice } from '@reduxjs/toolkit';
import { _setNewFundingType } from './fundingTypeAction';
import initialState from './fundingTypeInitialState';

export const fundingTypeSlice = createSlice({
  name: 'fundingType',
  initialState,
  reducers: {
    setFundingTypeForm: (state, action) => action.payload,
    setNewFundingType: _setNewFundingType,
    resetFundingTypeForm: () => initialState,
  },
});

export const { setFundingTypeForm, resetFundingTypeForm, setNewFundingType } =
  fundingTypeSlice.actions;

export default fundingTypeSlice.reducer;
