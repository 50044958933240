import React, { useState } from 'react';
import { GridWrapper, HeadingMuiTypography } from 'components/Ui/styled';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import FormInputDropdown from 'components/Common/Forms/FormInputDropDown';

function GroupsTab() {
  const [attendance, setAttendance] = useState('');
  const [adminGroups, setAdminGroups] = useState('');
  const [assetManagement, setAssetManagement] = useState('');
  const [department, setDepartment] = useState('');
  const defaultValue = {
    attendance: attendance,
    adminGroups: adminGroups,
    assetManagement: assetManagement,
    department: department,
  };

  const useFunction = useForm({
    defaultValues: defaultValue,
  });
  const { control, register, reset } = useFunction;

  const assignList = [
    {
      id: 1,
      value: 'Online Attendance Admin',
    },
    {
      id: 2,
      value: 'Online Attendance Tag Editors',
    },
  ];
  const adminGroupsList = [
    {
      id: 1,
      value: 'Admin group One',
    },
    {
      id: 2,
      value: 'Admin group Two',
    },
  ];
  const assetManagementList = [
    {
      id: 1,
      value: 'Asset Management One',
    },
    {
      id: 2,
      value: 'Asset Management Two',
    },
  ];
  const departmentList = [
    {
      id: 1,
      value: 'Department One',
    },
    {
      id: 2,
      value: 'Department two',
    },
  ];

  return (
    <>
      <HeadingMuiTypography variant="h6">Attendance</HeadingMuiTypography>
      <GridWrapper container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormInputDropdown
            name="attendance"
            type="text"
            control={control}
            options={assignList}
            label="Attendance"
          />
          <FormInputDropdown
            name="adminGroupsList"
            type="text"
            control={control}
            options={adminGroupsList}
            label="Admin Groups"
          />
          <FormInputDropdown
            name="assetManagementList"
            type="text"
            control={control}
            options={assetManagementList}
            label="Asset Management"
          />
          <FormInputDropdown
            name="departmentList"
            type="text"
            control={control}
            options={departmentList}
            label="Departments"
          />
        </Grid>
      </GridWrapper>
    </>
  );
}

export default GroupsTab;
