/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import MainDashboard from 'layouts/dashboards/MainDashboard';
//import Teacher from 'layouts/teachers/TeacherDashboard';

// @mui icons
import Icon from '@mui/material/Icon';

import React from 'react';
import LoadingWraper from 'components/Loader/LoadingWraper';
import { CalendarAdmin } from 'layouts/applications/calenderAdmin/calendars/Calendar';
import { CategoryAdmin } from 'layouts/applications/calenderAdmin/categoryAdmin/categoryAdmin';
import FundingType from 'layouts/inventory/fundingTypes/FundingType';
import Calendar from 'layouts/applications/calendar';
import MyStudentsList from 'layouts/teachers/components/MyStudents/MyStudentsList';
import Schools from 'layouts/applications/schools/components/Schools';
import MyProfile from 'layouts/myProfile/MyProfile';
import MyGroups from 'layouts/myProfile/components/myGroups/MyGroups';
import MyWatchingSchools from 'layouts/myProfile/components/myWatchingSchools/MyWatchingSchools';
import MyWatchingStudents from 'layouts/myProfile/components/myWatchingStudents/MyWatchingStudents';
import UserLocations from 'layouts/users/userLocations/UserLocations';
import LocationUsers from 'layouts/users/locationUsers/LocationUsers';
import TaskManagement from 'layouts/taskManagement/TaskManagement';
import ActiveTasks from 'layouts/taskManagement/ActiveTasks';
import AllOverdueTasks from 'layouts/taskManagement/AllOverdueTasks';
import MassUpdate from 'layouts/inventory/massUpdate/MassUpdate';
import AttendanceAudit from 'layouts/applications/attendance/attendanceAudit/AttendanceAudit';
import AttendanceAuditReport from 'layouts/applications/attendance/attendanceAudit/attendanceAuditReport/AttendanceAuditReport';
import AuditManagement from 'layouts/auditManagement/AuditManagement';
import AttendanceAuditsArchive from 'layouts/auditManagement/components/attendanceAuditsArchive/AttendanceAuditsArchive';
import SystemConfiguration from 'layouts/applications/systemConfiguration/SystemConfiguration';
import SharedFolders from 'layouts/documentManagement/components/sharedFolders/SharedFolders';
import SharedFoldersArchive from 'layouts/documentManagement/components/sharedFoldersArchive/SharedFoldersArchive';
import Folders from 'layouts/documents/components/Folders';
import SubFolders from 'layouts/documents/components/SubFolders';
import StaffProfile from 'layouts/staffProfile/StaffProfile';
import StudentManagement from 'layouts/studentManagement/StudentManagement';
import StudentProfile from 'layouts/studentManagement/components/studentProfile/StudentProfile';
import Reports from 'layouts/reports/Reports';
import Tutoring from 'layouts/tutoring/Tutoring';
import MyTeacher from 'layouts/myTeachers/MyTeacher';
import StudentTracking from 'layouts/studentTracking/StudentTracking';
import StudentTracker from 'layouts/studentTracking/components/studentTracker/StudentTracker';
import AllStaff from 'layouts/staffProfile/components/allStaff/AllStaff';

const AssetType = React.lazy(() =>
  import('layouts/inventory/assetType/AssetType')
);
const LocationType = React.lazy(() =>
  import('layouts/inventory/locationType/LocationType')
);
const Location = React.lazy(() =>
  import('layouts/inventory/location/Location')
);
//const Calendar = React.lazy(() => import('layouts/applications/calendar'));
const Attendance = React.lazy(() => import('layouts/applications/attendance'));
const Assets = React.lazy(() => import('layouts/inventory/assets/Assets'));
// const AvailablePermissions = React.lazy(() =>
//   import(
//     'layouts/users/userPermissions/components/availablePermissions/AvailablePermissions'
//   )
// );
// const AllPermissions = React.lazy(() =>
//   import(
//     'layouts/users/userPermissions/components/allPermissions/AllPermissions'
//   )
// );
const ListUsers = React.lazy(() => import('layouts/users/list/index'));
const ListGroups = React.lazy(() => import('layouts/groups/list/index'));
const DocumentAdmin = React.lazy(() =>
  import('layouts/documentAdmin/DocumentAdmin')
);
const Building = React.lazy(() =>
  import('layouts/inventory/Building/Building')
);
const Announcements = React.lazy(() =>
  import('layouts/applications/manageAnnouncements/Announcements')
);
// const TeacherCalendars = React.lazy(() =>
//   import('layouts/teachers/components/TeacherCalendars/TeacherCalendars')
// );
// const TeacherAnnouncements = React.lazy(() =>
//   import(
//     'layouts/teachers/components/TeacherAnnouncements/TeacherAnnouncements'
//   )
// );

const GlobalPermissions = React.lazy(() =>
  import(
    'layouts/users/userPermissions/components/globalPermissions/GlobalPermissions'
  )
);

const getComponent = (component) => {
  return <LoadingWraper>{component}</LoadingWraper>;
};

const routes = [
  // {
  //   name: 'Dashboard',
  //   key: 'dashboard',
  //   icon: <Icon fontSize='medium'>dashboard</Icon>,
  //   route: '/dashboard',
  //   component: <MainDashboard />,
  // },
  //{
  //  type: "collapse",
  //  name: "Brooklyn Alice",
  //  key: "brooklyn-alice",
  //  icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
  //  collapse: [
  //    {
  //      name: "My Profile",
  //      key: "my-profile",
  //      route: "/pages/profile/profile-overview",
  //      component: <ProfileOverview />,
  //    },
  //    {
  //      name: "Settings",
  //      key: "profile-settings",
  //      route: "/pages/account/settings",
  //      component: <Settings />,
  //    },
  //    {
  //      name: "Logout",
  //      key: "logout",
  //      route: "/authentication/sign-in/basic",
  //      component: <SignInBasic />,
  //    },
  //  ],
  //},
  //{ type: "divider", key: "divider-0" },
  {
    key: 'myProfile',
    route: '/my-profile',
    component: <MyProfile />,
  },
  {
    key: 'myGroups',
    route: '/my-profile/groups',
    component: <MyGroups />,
  },
  {
    key: 'myGroups',
    route: '/my-profile/watching-schools',
    component: <MyWatchingSchools />,
  },
  {
    key: 'myGroups',
    route: '/my-profile/watching-students',
    component: <MyWatchingStudents />,
  },
  {
    key: 'myStudents',
    route: '/my-students',
    component: <MyStudentsList />,
  },
  {
    key: 'attendance-audit-report',
    route: '/applications/attendance-audit-report',
    component: <AttendanceAuditReport />,
  },
  {
    key: 'subFolder',
    route: '/documents/folders/subFolder',
    component: <SubFolders />,
  },
  // {
  //   key: 'staffProfile',
  //   route: '/staff-profile',
  //   component: <StaffProfile />,
  // },
  {
    key: 'student-profile',
    route: '/student-management/student-search/student-profile',
    component: <StudentProfile />,
  },
  {
    key: 'student-profile',
    route: '/reports/testing-reports/student-profile',
    component: <StudentProfile />,
  },
  {
    type: 'notCollapsed',
    name: 'Dashboard',
    key: 'dashboard',
    icon: <Icon fontSize="medium">dashboard</Icon>,
    route: '/dashboard',
    component: <MainDashboard />,
    //  collapse: [
    // {
    //   name: 'Sales',
    //   key: 'sales',
    //   route: '/dashboards/sales',
    //   component: <Sales />,
    // },
    // {
    //   name: 'Stats',
    //   key: 'stats',
    //   route: '/dashboards/stats',
    //   component: <Analytics />,
    // },
    // {
    //   type: 'collapse',
    //   name: 'Teachers',
    //   key: 'teachers',
    //   icon: <Icon fontSize='medium'>apps</Icon>,
    //   collapse: [
    //     {
    //       name: 'Calendars',
    //       key: 'teacherCalendars',
    //       route: '/teachers/calendars',
    //       component: getComponent(<TeacherCalendars />),
    //     },
    //     {
    //       name: 'Announcements',
    //       key: 'teacherAnnouncement',
    //       route: '/teachers/announcements',
    //       component: getComponent(<TeacherAnnouncements />),
    //     },
    //  ],
    // },
    // ],
  },
  //{ type: "title", title: "Pages", key: "title-pages" },
  {
    type: 'collapse',
    name: 'Applications',
    key: 'applications',
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      //{
      //  name: "Kanban",
      //  key: "kanban",
      //  route: "/applications/kanban",
      //  component: <Kanban />,
      //},
      //{
      //  name: "Wizard",
      //  key: "wizard",
      //  route: "/applications/wizard",
      //  component: <Wizard />,
      //},
      {
        type: 'collapse',
        name: 'Inventory',
        key: 'inventory',
        permission: 'CAN_READ_ASSET_DATA',
        icon: <Icon fontSize="medium">apps</Icon>,
        collapse: [
          {
            name: 'Assets',
            key: 'assets',
            route: '/applications/assets',
            component: getComponent(<Assets />),
          },
          {
            name: 'Bulk Update Assets',
            key: 'bulk update',
            permission: 'CAN_WRITE_ASSET_DATA',
            route: '/applications/bulk-Update',
            component: getComponent(<MassUpdate />),
          },
          {
            name: 'Asset Type',
            key: 'asset Type',
            permission: 'CAN_WRITE_ASSET_DATA',
            route: '/applications/assetType',
            component: getComponent(<AssetType />),
          },
          {
            name: 'Location',
            key: 'location',
            permission: 'CAN_WRITE_ASSET_DATA',
            route: '/applications/location',
            component: getComponent(<Location />),
          },
          {
            name: 'Building',
            key: 'Building',
            permission: 'CAN_WRITE_ASSET_DATA',
            route: '/applications/Building',
            component: getComponent(<Building />),
          },
          {
            name: 'Location Type',
            key: 'LocationType',
            permission: 'CAN_WRITE_ASSET_DATA',
            route: '/applications/LocationType',
            component: getComponent(<LocationType />),
          },
          {
            name: 'Funding Type',
            key: 'FundingType',
            permission: 'CAN_WRITE_ASSET_DATA',
            route: '/applications/FundingType',
            component: getComponent(<FundingType />),
          },
          {
            name: 'User Locations',
            key: 'user-locations',
            route: '/applications/user-locations',
            icon: <span className="material-icons-outlined">people</span>,
            component: getComponent(<UserLocations />),
          },
          {
            name: 'Location Users',
            key: 'location-users',
            route: '/applications/location-users',
            icon: <span className="material-icons-outlined">people</span>,
            component: getComponent(<LocationUsers />),
          },
        ],
      },
      {
        type: 'collapse',
        name: 'Calendar Admin',
        key: 'calendar-Admin',
        permission: 'CAN_MANAGE_CALENDARS',
        route: '/applications/calendar-Admin',
        icon: <Icon fontSize="medium">Calendar Admin</Icon>,
        collapse: [
          {
            name: 'Calendars',
            key: 'calendars',
            route: '/applications/calendars',
            component: getComponent(<CalendarAdmin />),
          },
          {
            name: 'Category',
            key: 'Category',
            route: '/applications/category',
            component: getComponent(<CategoryAdmin />),
          },
        ],
      },
      {
        name: 'Online Attendance',
        key: 'online-attendance',
        permission: 'CAN_VIEW_ATTENDANCE_DATA',
        route: '/applications/online-attendance',
        component: getComponent(<Attendance />),
      },
      {
        name: 'Attendance Audit',
        key: 'attendance-audit',
        permission: 'CAN_VIEW_ATTENDANCE_AUDIT_RESULTS',
        route: '/applications/attendance-audit',
        component: getComponent(<AttendanceAudit />),
      },
      {
        name: 'Calendar',
        key: 'calendar',
        permission: 'CAN_VIEW_CALENDARS',
        route: '/applications/calendar',
        component: <Calendar />,
      },
      {
        name: 'Announcements',
        key: 'announcements',
        //permission: 'CAN_MANAGE_ANNOUNCEMENTS',
        route: '/applications/announcements',
        component: getComponent(<Announcements />),
      },
      {
        name: 'Schools',
        key: 'schools',
        permission: 'CAN_VIEW_ATTENDANCE_DATA',
        route: '/applications/schools',
        component: getComponent(<Schools />),
      },
    ],
  },
  {
    type: 'collapse',
    name: 'User Management',
    key: 'users',
    //permission: 'CAN_MANAGE_GROUP_MEMBERSHIP',
    //permission: 'CAN_MANAGE_USERS',
    icon: <span className="material-icons-outlined">manage_accounts</span>,
    collapse: [
      {
        type: 'collapse',
        name: 'User Permissions',
        key: 'userPermissions',
        icon: <Icon fontSize="medium">apps</Icon>,
        collapse: [
          // {
          //   name: 'Available Permissions',
          //   key: 'availablePermissions',
          //   route: '/users/available-Permissions',
          //   component: getComponent(<AvailablePermissions />),
          // },
          // {
          //   name: 'All Permissions',
          //   key: 'allPermissions',
          //   route: '/users/all-Permissions',
          //   component: getComponent(<AllPermissions />),
          // },
          {
            name: 'Global Permissions',
            key: 'globalPermissions',
            route: '/users/global-Permissions',
            component: getComponent(<GlobalPermissions />),
          },
        ],
      },
      {
        name: 'Users',
        key: 'users',
        route: '/user-management/users',
        //permission: 'CAN_MANAGE_USERS',
        icon: <span className="material-icons-outlined">people</span>,
        component: getComponent(<ListUsers />),
      },
      {
        name: 'Groups',
        key: 'groups',
        route: '/user-management/groups',
        //permission: 'CAN_MANAGE_GROUPS',
        icon: <span className="material-icons-outlined">people</span>,
        component: getComponent(<ListGroups />),
      },
      // {
      //   name: 'Document Admin',
      //   key: 'document-admin',
      //   route: '/user-management/document-admin',
      //   component: getComponent(<DocumentAdmin />),
      // },
    ],
  },
  {
    type: 'collapse',
    name: 'Task Management',
    key: 'task',
    icon: <span className="material-icons-outlined">task</span>,
    collapse: [
      {
        name: 'Tasks',
        key: 'tasks',
        route: '/task-management/tasks',
        icon: <span className="material-icons-outlined">people</span>,
        component: getComponent(<TaskManagement />),
      },
      {
        name: 'Active Tasks',
        key: 'active-tasks',
        route: '/task-management/active-tasks',
        icon: <span className="material-icons-outlined">people</span>,
        component: getComponent(<ActiveTasks />),
      },
      {
        name: 'All Overdue Tasks',
        key: 'all-overdue-tasks',
        route: '/task-management/all-overdue-tasks',
        permission: 'CAN_SUPERVISE_TASKS',
        icon: <span className="material-icons-outlined">people</span>,
        component: getComponent(<AllOverdueTasks />),
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Audit Management',
    key: 'audit',
    permission: 'CAN_MANAGE_ATTENDANCE_AUDITS',
    icon: <span className="material-icons-outlined">fact_check</span>,
    collapse: [
      {
        name: 'Attendance Audits',
        key: 'attendance-audits',
        route: '/audit-management/attendance-audits',
        icon: <span className="material-icons-outlined">people</span>,
        component: getComponent(<AuditManagement />),
      },
      {
        name: 'Audits Archived',
        key: 'attendance-audits-archived',
        route: '/audit-management/attendance-audits-archived',
        icon: <span className="material-icons-outlined">people</span>,
        component: getComponent(<AttendanceAuditsArchive />),
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Doc Management',
    key: 'document',
    permission: 'CAN_MANAGE_SHARED_FOLDERS',
    icon: <span className="material-icons-outlined">description</span>,
    collapse: [
      {
        name: 'Shared Folders',
        key: 'shared-folders',
        route: '/document-management/shared-folders',
        icon: <span className="material-icons-outlined">people</span>,
        component: getComponent(<SharedFolders />),
      },
      {
        name: 'Folders Archived',
        key: 'shared-folders-archived',
        route: '/document-management/shared-folders-archived',
        icon: <span className="material-icons-outlined">people</span>,
        component: getComponent(<SharedFoldersArchive />),
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Documents',
    key: 'documents',
    permission: 'CAN_ACCESS_SHARED_FOLDERS',
    icon: <span className="material-icons-outlined">article</span>,
    collapse: [
      {
        name: 'Folders',
        key: 'folders',
        route: '/documents/folders',
        icon: <span className="material-icons-outlined">people</span>,
        component: getComponent(<Folders />),
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Teachers',
    key: 'teachers',
    permission: 'CAN_ACCESS_DEVELOPER_FEATURES',
    icon: <span className="material-icons-outlined">person</span>,
    collapse: [
      {
        name: 'My Teacher',
        key: 'my-teacher',
        route: '/teachers/my-teacher',
        icon: <span className="material-icons-outlined">people</span>,
        component: getComponent(<MyTeacher />),
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Tracking',
    key: 'tracking',
    permission: 'CAN_VIEW_STUDENT_TRACKER_DATA',
    icon: <span className="material-icons-outlined">insights</span>,
    collapse: [
      {
        name: 'Trackers',
        key: 'trackers',
        route: '/tracking/trackers',
        icon: <span className="material-icons-outlined">people</span>,
        component: getComponent(<StudentTracking />),
      },
      {
        name: 'Student Tracker',
        key: 'student-tracker',
        route: '/tracking/student-tracker',
        icon: <span className="material-icons-outlined">people</span>,
        component: getComponent(<StudentTracker />),
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Manage Students',
    key: 'manage-students',
    permission: 'CAN_VIEW_STUDENT_PROFILE',
    icon: <span className="material-icons-outlined">school</span>,
    collapse: [
      {
        name: 'Students',
        key: 'student-search',
        route: '/student-management/student-search',
        icon: <span className="material-icons-outlined">people</span>,
        component: getComponent(<StudentManagement />),
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Staff Management',
    key: 'staff-management',
    permission: 'CAN_ACCESS_DEVELOPER_FEATURES',
    icon: <span className="material-icons-outlined">groups</span>,
    collapse: [
      {
        name: 'Profile',
        key: 'profile',
        route: '/staff/profile',
        icon: <span className="material-icons-outlined">people</span>,
        component: getComponent(<StaffProfile />),
      },
      {
        name: 'All Staff',
        key: 'all-staff',
        route: '/staff/all-staff',
        icon: <span className="material-icons-outlined">people</span>,
        component: getComponent(<AllStaff />),
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Tutoring',
    key: 'tutoring',
    permission: 'CAN_ACCESS_DEVELOPER_FEATURES',
    icon: <span className="material-icons-outlined">laptop</span>,
    collapse: [
      {
        name: 'Tutoring Sessions',
        key: 'tutoring-sessions',
        route: '/tutoring/tutoring-sessions',
        icon: <span className="material-icons-outlined">people</span>,
        component: getComponent(<Tutoring />),
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Reports',
    key: 'reports',
    //permission: 'CAN_MANAGE_SYSTEM_CONFIGURATION',
    icon: <span className="material-icons-outlined">report</span>,
    collapse: [
      {
        name: 'Testing Reports',
        key: 'testing-reports',
        route: '/reports/testing-reports',
        icon: <span className="material-icons-outlined">people</span>,
        component: getComponent(<Reports />),
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Settings',
    key: 'settings',
    permission: 'CAN_MANAGE_SYSTEM_CONFIGURATION',
    icon: <span className="material-icons-outlined">settings</span>,
    collapse: [
      {
        name: 'System Configuration',
        key: 'system-configuration',
        route: '/settings/system-configuration',
        icon: <span className="material-icons-outlined">people</span>,
        component: getComponent(<SystemConfiguration />),
      },
    ],
  },
];

export default routes;
