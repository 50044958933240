import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';

export const UserGroupHeaderBox = styled(Box)`
  &.MuiBox-root {
    padding: ${(props) =>
      `${props.theme.palette.spacing[1] * 2}px ${
        props.theme.palette.spacing[1] * 3
      }px`};
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const UserCountList = styled(List)`
  width: 270px;
  height: 230px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 9px;
  }
  ::-webkit-scrollbar-thumb {
    background: #dcdcdc;
    border-radius: 10px;
  }
`;

export const GroupButtonWrapper = styled(Grid)`
  justify-content: end;
  margin-top: 15px;
`;

export const LeftShiftButton = styled(Button)`
  color: #8e9095;
  border-color: #8e9095;
`;

export const RightShiftButton = styled(LeftShiftButton)``;

export const CardDivider = styled(Divider)`
  &.MuiDivider-root {
    opacity: 0.75;
  }
`;

export const CardCheckbox = styled(Checkbox)`
  .MuiSvgIcon-root {
    border: 0.0625rem solid #8c939b;
  }
`;
