export const _setNewCalendar = (state, action) => {
  state.name = action.payload;
};
export const _updateCalendarData = (state, action) => action.payload;

export const _setPermissions = (state, action) => {
  state.permissions = [...state.permissions, action.payload];
};

export const _removePermissions = (state, action) => {
  return {
    ...state,
    permissions: state.permissions.filter(
      (item) => item.username !== action.payload.username
    ),
  };
};
