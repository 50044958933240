import { API } from 'aws-amplify';

export const getAvailablePermissions = async () => {
  const availablePermissions = await API.get(
    'delta-api',
    `permissions/availablePermissions`
  );
  return availablePermissions;
};

export const getGlobalPermissions = async () => {
  const globalPermissions = await API.get(
    'delta-api',
    `permissions/globalPermissions`
  );
  return globalPermissions;
};

export const putGlobalPermissions = async (payload) => {
  const myData = {
    body: payload,
  };
  await API.put('delta-api', `permissions/globalPermissions`, myData);
};

export const getGroupPermissionsByGroupName = async (groupName) => {
  const groupPermissionsByGroupName = await API.get(
    'delta-api',
    `permissions/groupPermissions/${groupName}`
  );
  return groupPermissionsByGroupName;
};

export const putGroupPermissionsByGroupName = async (payload, groupName) => {
  const myData = {
    body: payload,
  };
  await API.put(
    'delta-api',
    `permissions/groupPermissions/${groupName}`,
    myData
  );
};

export const getWhoCanManagePermissions = async (whoCanManagePermissions) => {
  const whoCanManagePermissionsData = await API.get(
    'delta-api',
    `permissions/whoCan/${whoCanManagePermissions}`
  );
  return whoCanManagePermissionsData;
};

export const getCanIManagePermissions = async (canIManagePermissions) => {
  const canIManagePermissionsData = await API.get(
    'delta-api',
    `permissions/canI/${canIManagePermissions}`
  );
  return canIManagePermissionsData;
};

export const getMyPermissions = async () => {
  const myPermissions = await API.get('delta-api', `permissions/myPermissions`);
  return myPermissions;
};
