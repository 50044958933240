import { createSlice } from '@reduxjs/toolkit';
import { _setDescription, _setGroupName, _setIsNewGroup } from './groupActions';
import initialState from './groupInitialState';

const groupFormSlice = createSlice({
  name: 'delta/groupForm',
  initialState,
  reducers: {
    getGroups: (state, action) => action.payload,
    deleteGroupForm: (state, action) => action.payload,
    setGroupName: _setGroupName,
    setDescription: _setDescription,
    setIsNewGroup: _setIsNewGroup,
    resetGroup: () => initialState,
  },
});

export const {
  getGroups,
  setGroupName,
  setDescription,
  setIsNewGroup,
  resetGroup,
  deleteGroupForm,
} = groupFormSlice.actions;

export default groupFormSlice.reducer;
