export const _setCategoryName = (state, action) => {
  state.name = action.payload;
};

export const _setCategoryColor = (state, action) => {
  state.color = action.payload;
};

export const _setCategoryCalenderId = (state, action) => {
  state.calenderId = action.payload;
};
