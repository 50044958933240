import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import {
  getDataGridHeading,
  showLoader,
  useNotification,
} from 'utils/commonUtils';
import { useDispatch } from 'react-redux';
import { getScoresOptions } from 'layouts/studentManagement/services/studentProfileApi';
import { setScoresOptions } from 'layouts/studentManagement/store/scores/scoresOptionsSlice/scoresOptionsSlice';
import { intialPageSize } from 'constants';
import ReportFilters from '../ReportFilters/ReportFilters';

const TestingReports = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [reportsList, setReportsList] = useState({
    isLoading: false,
    rows: [],
    pageSize: intialPageSize,
  });

  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();

  useEffect(() => {
    fetchScoresOptions();
  }, []);

  const fetchScoresOptions = async () => {
    try {
      setIsLoading(true);
      const scoresOptions = await getScoresOptions();
      dispatch(setScoresOptions(scoresOptions));
      setIsLoading(false);
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return showLoader();
  }

  return (
    <>
      <Card data-testid="reports-card">
        {getDataGridHeading('Testing Reports')}
        <ReportFilters
          reportsList={reportsList}
          setReportsList={setReportsList}
          setIsLoading={setIsLoading}
        />
      </Card>
      <NotificationPopup />
    </>
  );
};

export default TestingReports;
