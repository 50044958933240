import React, { useState, useEffect } from 'react';
import MDBox from 'components/MDBox';
import Card from '@mui/material/Card';
import { useForm } from 'react-hook-form';
import MDTypography from 'components/MDTypography';
import { DropDownBox } from 'layouts/inventory/Inventory.styled';
import { useDispatch, useSelector } from 'react-redux';
import FormInputDropdown from 'components/Common/Forms/FormInputDropDown';
import {
  intialPageSize,
  booleanOptions,
  attendanceAuditUnArchiveSuccess,
} from 'constants';
import {
  useResetSnackbar,
  useNotification,
  emptyFunction,
  useListDialog,
  onSave,
} from 'utils/commonUtils';
import {
  setDataGridCounts,
  resetDataGridCounts,
} from 'utils/commonSlice/dataGridCounts/dataGridCountsSlice';
import {
  setAttendanceAuditsArchivePagination,
  setAttendanceAuditsArchivePaginationPreviousKey,
  resetAttendanceAuditsArchivePagination,
} from 'layouts/auditManagement/store/attendanceAuditsArchivePaginationSlice/attendanceAuditsArchivePaginationSlice';
import { AttendanceHeadingBox } from 'layouts/applications/attendance/Attendance.styled';
import {
  attendanceAuditColumns,
  getAttendanceAuditListData,
} from '../attendanceAudits/AttendanceAuditDataGrid';
import { getAttendanceAuditsArchive } from 'layouts/auditManagement/services/attendanceAuditsApi';
import { postAttendanceAuditsUnarchive } from 'layouts/auditManagement/services/attendanceAuditsApi';
import { getRenderedList } from 'utils/commonSlice/renderListSlice';
import DeltaDataGrid from 'utils/CommonDataGrid/CommonDataGrid';

const AttendanceAuditsArchiveList = () => {
  useResetSnackbar();
  const dispatch = useDispatch();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const { setDialogName, setOpenViewDialog, ListDialog } = useListDialog();
  const [attendanceAuditsArchivePageKey, setAttendanceAuditsArchivePageKey] =
    useState('');
  const [oldestFirst, setOldestFirst] = useState(false);
  // const [loadingRowId, setLoadingRowId] = useState(null);
  const [unArchiveLoading, setUnArchiveLoading] = useState(false);

  const [attendanceAuditsArchiveList, setAttendanceAuditsArchiveList] =
    useState({
      isLoading: false,
      rows: [],
      pageSize: intialPageSize,
      page: 1,
      total: 3000,
      currentPaginationKey: '',
      nextPaginationKey: '',
      count: '',
    });
  const { pageSize, page, nextPaginationKey, count } =
    attendanceAuditsArchiveList;

  const dataGridCounts = useSelector(
    (state) => state?.totalDataGridCounts?.dataGridCounts
  );
  const totalNumber = dataGridCounts?.reduce((acc, curr) => acc + curr, 0);

  const attendanceAuditsArchivePagination = useSelector(
    (state) =>
      state?.attendanceAuditsArchivePaginationKeyList
        ?.attendanceAuditsArchivePagination
  );

  useEffect(() => {
    if (page === 1) {
      dispatch(resetAttendanceAuditsArchivePagination());
      dispatch(resetDataGridCounts());
      dispatch(setDataGridCounts(pageSize));
    }
  }, []);

  useEffect(() => {
    fetchAttendanceAuditsArchive();
  }, [pageSize, page, oldestFirst]);

  const defaultValue = {
    oldestFirst: oldestFirst,
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
  });

  const { reset, control, register } = useFunction;

  const fetchAttendanceAuditsArchive = async () => {
    setAttendanceAuditsArchiveList({
      ...attendanceAuditsArchiveList,
      isLoading: true,
    });
    try {
      const attendanceAuditsArchiveData = await getAttendanceAuditsArchive(
        pageSize,
        attendanceAuditsArchivePageKey,
        oldestFirst
      );
      setAttendanceAuditsArchiveList({
        ...attendanceAuditsArchiveList,
        isLoading: false,
      });
      setAttendanceAuditsArchiveList({
        ...attendanceAuditsArchiveList,
        currentPaginationKey: attendanceAuditsArchiveData?.currentPaginationKey,
        nextPaginationKey: attendanceAuditsArchiveData?.nextPaginationKey,
        count: attendanceAuditsArchiveData?.count,
      });
      setAttendanceAuditsArchiveList((prev) => ({
        ...prev,
        rows: getAttendanceAuditListData(attendanceAuditsArchiveData),
      }));
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
      setAttendanceAuditsArchiveList({
        ...attendanceAuditsArchiveList,
        isLoading: false,
      });
    }
  };

  useEffect(() => {
    if (!attendanceAuditsArchiveList?.isLoading) {
      if (count < pageSize) {
        setAttendanceAuditsArchiveList((prveState) => ({
          ...prveState,
          total: totalNumber,
        }));
      }
    }
  }, [count, pageSize]);

  useEffect(() => {
    reset(defaultValue);
  }, [defaultValue.oldestFirst]);

  const redirect = () => {
    dispatch(resetAttendanceAuditsArchivePagination());
    dispatch(resetDataGridCounts());
    dispatch(setDataGridCounts(pageSize));
    setAttendanceAuditsArchiveList((prveState) => ({
      ...prveState,
      page: 1,
      total: 3000,
    }));
    setAttendanceAuditsArchivePageKey('');
  };

  const handleOldestFirst = (e) => {
    setOldestFirst(e.target.value);
    redirect();
    setAttendanceAuditsArchiveList((prveState) => ({
      ...prveState,
      page: 1,
      total: totalNumber,
    }));
  };

  const handelPageChange = (page) => {
    setAttendanceAuditsArchiveList((prveState) => ({
      ...prveState,
      page: page + 1,
    }));
    const paginationIdValue = {
      id: page,
      value: nextPaginationKey,
    };
    if (attendanceAuditsArchivePagination?.find((item) => item.id === page)) {
      const getPreviousPaginationKey = () => {
        const getPaginationKey = attendanceAuditsArchivePagination?.find(
          (item) => item.id === page
        );
        return getPaginationKey?.value;
      };
      const previousPaginationKey = getPreviousPaginationKey();
      dispatch(
        setAttendanceAuditsArchivePaginationPreviousKey(previousPaginationKey)
      );
      setAttendanceAuditsArchivePageKey(previousPaginationKey);
    } else {
      dispatch(setDataGridCounts(count));
      dispatch(setAttendanceAuditsArchivePagination(paginationIdValue));
      setAttendanceAuditsArchivePageKey(nextPaginationKey);
    }
    if (page < 1) {
      setAttendanceAuditsArchiveList((prveState) => ({
        ...prveState,
        total: 3000,
      }));
      dispatch(resetAttendanceAuditsArchivePagination());
      dispatch(resetDataGridCounts());
      dispatch(setDataGridCounts(pageSize));
      setAttendanceAuditsArchivePageKey('');
    }
  };

  const handleOnPageSizeChange = (newPageSize) => {
    if (count < pageSize) {
      setAttendanceAuditsArchiveList((prveState) => ({
        ...prveState,
        pageSize: newPageSize,
        page: 1,
        total: totalNumber,
      }));
    } else {
      setAttendanceAuditsArchiveList((prveState) => ({
        ...prveState,
        pageSize: newPageSize,
        page: 1,
        total: 3000,
      }));
      dispatch(resetAttendanceAuditsArchivePagination());
      dispatch(resetDataGridCounts());
      dispatch(setDataGridCounts(newPageSize));
    }
  };

  const getHeadingWithFormFields = () => {
    return (
      <>
        <AttendanceHeadingBox>
          <MDTypography variant="h5" fontWeight="medium">
            Attendance Audits Archived
          </MDTypography>
          <DropDownBox>
            <FormInputDropdown
              name="oldestFirst"
              type="text"
              control={control}
              options={booleanOptions}
              defaultValue={defaultValue?.oldestFirst}
              label="Oldest First"
              register={register('oldestFirst', {
                onChange: (e) => {
                  handleOldestFirst(e);
                },
              })}
            />
          </DropDownBox>
        </AttendanceHeadingBox>
      </>
    );
  };

  const handleStudents = (params) => {
    const { row } = params;
    dispatch(getRenderedList(row.studentList));
    setDialogName('Students');
    setOpenViewDialog(true);
  };

  // const handleAccessUsers = (params) => {
  //   const { row } = params;
  //   console.log('ROW', row);
  // };

  const handleUnarchiveAttendanceAudit = (params) => {
    const { row } = params;
    onSave(
      attendanceAuditUnArchiveSuccess,
      true,
      postAttendanceAuditsUnarchive,
      emptyFunction,
      row.id,
      '',
      fetchAttendanceAuditsArchive,
      emptyFunction,
      setOpenNotification,
      setNotificationMessage,
      dispatch,
      setUnArchiveLoading,
      unArchiveLoading
    );
  };

  return (
    <>
      <MDBox>
        <Card>
          {getHeadingWithFormFields()}
          <DeltaDataGrid
            listData={attendanceAuditsArchiveList}
            columns={attendanceAuditColumns(
              emptyFunction,
              handleStudents,
              emptyFunction,
              emptyFunction,
              emptyFunction,
              false,
              false,
              emptyFunction,
              handleUnarchiveAttendanceAudit,
              emptyFunction
            )}
            handelPageChange={handelPageChange}
            setListData={handleOnPageSizeChange}
          />
        </Card>
      </MDBox>
      <ListDialog />
      <NotificationPopup />
    </>
  );
};

export default AttendanceAuditsArchiveList;
