import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import { useSelector } from 'react-redux';
import { intialPageSize } from 'constants';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import {
  getDataGridHeading,
  showLoader,
  pageSizeChangeHandler,
} from 'utils/commonUtils';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DeltaDataGrid from 'utils/CommonDataGrid/CommonDataGrid';

const MyWatchingStudents = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [myWatchingStudents, setMyWatchingStudents] = useState({
    isLoading: false,
    rows: [],
    pageSize: intialPageSize,
    page: 1,
  });
  const { pageSize, page } = myWatchingStudents;

  const myProfile = useSelector((state) => state.myProfile);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (myProfile) {
      fetchMyProfile();
    }
  }, [pageSize, page, myProfile]);

  const fetchMyProfile = () => {
    setMyWatchingStudents((previous) => ({
      ...previous,
      rows: myProfile?.metadata?.watchingStudents?.map((item, index) => ({
        id: index,
        myStudents: item,
      })),
    }));
  };

  const columns = [
    {
      headerName: 'My Students',
      field: 'myStudents',
      flex: 1,
    },
  ];

  const pageSizeChange = pageSizeChangeHandler(setMyWatchingStudents);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        {isLoading ? (
          showLoader()
        ) : (
          <>
            <Card>
              {getDataGridHeading('My Students')}
              <DeltaDataGrid
                listData={myWatchingStudents}
                columns={columns}
                setListData={pageSizeChange}
              />
            </Card>
          </>
        )}
      </DashboardLayout>
    </>
  );
};

export default MyWatchingStudents;
