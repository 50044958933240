import React from 'react';
import Dialog from '@mui/material/Dialog';
import { getDialogHeading } from 'utils/commonUtils';
import { DataGridDialog } from 'constants';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import { GridForm, FieldText, BoxWrapper } from 'components/Ui/styled';
import MDBox from 'components/MDBox';
import ReportsField from './ReportsField';

const ReportsDialog = (props) => {
  const { openReportsDialog, setOpenReportsDialog } = props;

  const reportsForm = useSelector((state) => state.reportsForm) || {};

  const handleClose = () => {
    setOpenReportsDialog(false);
  };

  return (
    <>
      <Dialog open={openReportsDialog} PaperProps={DataGridDialog}>
        {getDialogHeading('Report', handleClose)}
        <BoxWrapper>
          <GridForm container spacing={2}>
            {Object.entries(reportsForm).map(([label, value], index) => (
              <Grid item xs={12} md={4} key={index}>
                <ReportsField value={value ?? ''} label={label} />
              </Grid>
            ))}
          </GridForm>
          <MDBox pt={2}></MDBox>
        </BoxWrapper>
      </Dialog>
    </>
  );
};

export default ReportsDialog;
