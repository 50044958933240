import React, { useState, useEffect } from 'react';
import Analytics from './analytics';
import { Auth } from 'aws-amplify';
import Teacher from 'layouts/teachers/TeacherDashboard';

const MainDashboard = () => {
  const [userGroups, setUserGroups] = useState([]);
  useEffect(() => {
    fetchGroups();
  }, []);

  const fetchGroups = async () => {
    const user = await Auth.currentAuthenticatedUser();
    setUserGroups(
      user.signInUserSession.accessToken.payload['cognito:groups'] ?? []
    );
  };

  return (
    <>
      <Analytics />
      {userGroups.indexOf('teachers') > -1 && <Teacher />}
    </>
  );
};

export default MainDashboard;
