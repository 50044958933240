import { API } from 'aws-amplify';
import { ASSET_BASE_URL_APPEND } from 'layouts/inventory/constants';

export const getLocationData = async () => {
  const Location = await API.get(
    'delta-api',
    `${ASSET_BASE_URL_APPEND}locations`,
    {}
  );
  return Location;
};

export const putLocationData = async (payload, id) => {
  const myInit = {
    queryStringParameters: payload,
    body: payload,
  };

  await API.put('delta-api', `${ASSET_BASE_URL_APPEND}locations/${id}`, myInit);
};

export const postLocationData = async (payload) => {
  const myInit = {
    body: payload,
  };
  await API.post('delta-api', `${ASSET_BASE_URL_APPEND}locations`, myInit);
};

export const deleteLocationData = async (id) => {
  await API.del('delta-api', `${ASSET_BASE_URL_APPEND}locations/${id}`, {});
};
