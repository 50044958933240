import { createSlice } from '@reduxjs/toolkit';

const subFoldersSlice = createSlice({
  name: 'delta/subFolders',
  initialState: null,
  reducers: {
    getSubFoldersData: (state, action) => action.payload,
    resetSubFoldersData: () => null,
  },
});

export const { getSubFoldersData, resetSubFoldersData } =
  subFoldersSlice.actions;

export default subFoldersSlice.reducer;
