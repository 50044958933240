import { createSlice } from '@reduxjs/toolkit';
import initialState from './studentProfileInitialState';
import {
  _setStudentType,
  _setInstructor,
  _setCourse,
  _setGradeLevel,
} from './studentProfileFilterAction';

export const studentProfileFilterSlice = createSlice({
  name: 'delta/studentProfileFilter',
  initialState,
  reducers: {
    setStudentType: _setStudentType,
    setInstructor: _setInstructor,
    setCourse: _setCourse,
    setGradeLevel: _setGradeLevel,
    resetStudentProfileFilter: () => initialState,
  },
});

export const {
  setStudentType,
  setInstructor,
  setCourse,
  setGradeLevel,
  resetStudentProfileFilter,
} = studentProfileFilterSlice.actions;

export default studentProfileFilterSlice.reducer;
