import React from 'react';
import { useForm } from 'react-hook-form';
import { HeadingMuiTypography, GridWrapper } from 'components/Ui/styled';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import Grid from '@mui/material/Grid';

function NotesTab() {
  const defaultValue = {
    attendance: '',
  };

  const useFunction = useForm({
    defaultValues: defaultValue,
  });

  const { control, register, errors, reset } = useFunction;

  return (
    <>
      <HeadingMuiTypography variant="h6">Notes</HeadingMuiTypography>
      <GridWrapper container spacing={2}>
        <Grid item xs={12}>
          <FormInputText
            name="description"
            type="text"
            control={control}
            label="Enter Note for Jane..."
            multiline={true}
            rows={2}
          />
        </Grid>
      </GridWrapper>
    </>
  );
}

export default NotesTab;
