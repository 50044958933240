import { combineReducers } from '@reduxjs/toolkit';
import userDetails from './userSlice';
import calendarDetails from 'examples/Calendar/store/calendarDetailsSlice';
import calendars from 'examples/Calendar/store/calendarsSlice';
import calendarEvent from 'layouts/applications/calendar/components/CalendarEvents/store/calenderEventSlice';
import calendarCategory from 'layouts/applications/calendar/components/Categories/store/calenderCategorySlice';
import userManagementForm from 'layouts/users/store/userManagementSliceForm';
import assetForm from 'layouts/inventory/assets/Store/assetSlice';
import autoAssetForm from 'layouts/inventory/assets/Store/autoAssetForm/autoAssetSlice';
import Building from 'layouts/inventory/Building/store/BuildingSlice';
import assetType from 'layouts/inventory/assetType/store/assetTypeSlice';
import snackbar from 'components/snackbar/store/SnackbarSlice';
import groupUsers from 'layouts/groups/store/groupUsersSlice';
import availableUsersList from 'layouts/groups/store/availableUsersSlice';
import groupForm from 'layouts/groups/store/groupFormSlice';
import Location from 'layouts/inventory/location/store/LocationSlice';
import LocationType from 'layouts/inventory/locationType/store/locationTypeSlice';
import fundingType from 'layouts/inventory/fundingTypes/store/fundingTypeSlice';
import Calender from 'layouts/applications/calendar/store/CalenderSlice';
import CalenderAdmin from 'layouts/applications/calenderAdmin/store/AdminSlice';
import CategoryAdminSlice from 'layouts/applications/calenderAdmin/categoryAdmin/store/categoryAdminSlice';
import statsData from 'layouts/dashboards/analytics/store/statsSlice';
import CategoryAdminCalenderId from 'layouts/applications/calenderAdmin/categoryAdmin/store/calenderIdCategorySlice';
import yearMonth from 'examples/Calendar/store/yearMonthSlice';
import semesterList from 'layouts/applications/attendance/store/semesterSlice';
import studentNote from 'layouts/applications/attendance/store/studentNote/studentNoteSlice';
import announcementForm from 'layouts/applications/manageAnnouncements/store/announcementSlice';
import announcementPaginationKeyList from 'layouts/applications/manageAnnouncements/store/announcementPaginationDataSlice';
import AnnouncementPaginationKey from 'layouts/applications/manageAnnouncements/store/announcementPaginationSlice';
import announcementNotificationData from 'layouts/dashboards/analytics/store/announcementNotification/announcementNotificationsSlice';
import assetsPaginationKeyList from 'layouts/inventory/assets/Store/assetsPaginationDataSlice';
import studentDetails from 'layouts/students/store/studentDetailsSlice';
import myPermissions from 'layouts/users/userPermissions/store/myPermissionsSlice';
import groupPermissions from 'layouts/groups/store/groupPermissions/groupPermissionSlice';
import globalPermissions from 'layouts/users/userPermissions/store/globalPermissions/globalPermissionSlice';
import myProfile from 'layouts/myProfile/store/myProfileSlice';
import groupMembers from 'layouts/groups/store/groupMembers/groupMemberSlice';
import taskForm from 'layouts/taskManagement/store/taskSlice/taskSlice';
import taskPaginationKeyList from 'layouts/taskManagement/store/taskSlice/taskPaginationSlice';
import taskFilters from 'layouts/taskManagement/store/taskFilterSlice/taskFilterSlice';
import totalDataGridCounts from 'utils/commonSlice/dataGridCounts/dataGridCountsSlice';
import attendanceAuditReportId from 'layouts/applications/attendance/attendanceAudit/store/attendanceAuditReportIdSlice';
import attendanceAuditReport from 'layouts/applications/attendance/attendanceAudit/store/attendanceAuditReportSlice';
import bulkAssets from 'layouts/inventory/massUpdate/store/bulkAssetsSlice/bulkAssetsSlice';
import assetTags from 'layouts/inventory/massUpdate/store/assetTagSlice/assetTagsSlice';
import attendanceAuditsArchivePaginationKeyList from 'layouts/auditManagement/store/attendanceAuditsArchivePaginationSlice/attendanceAuditsArchivePaginationSlice';
import replaceAccess from 'layouts/auditManagement/store/replaceAccessSlice/replaceAccessSlice';
import attendanceAuditForm from 'layouts/auditManagement/store/attendanceAuditSlice/attendanceAuditSlice';
import systemConfigurationForm from 'layouts/applications/systemConfiguration/store/systemConfigurationSlice';
import sharedFolderForm from 'layouts/documentManagement/store/sharedFolderSlice/sharedFolderSlice';
import sharedFoldersArchivePaginationKeyList from 'layouts/documentManagement/store/sharedFoldersArchivePaginationSlice/sharedFoldersArchivePaginationSlice';
import renderList from 'utils/commonSlice/renderListSlice';
import logout from 'examples/Navbars/store/logoutSlice';
import folders from 'layouts/documents/store/foldersSlice/foldersSlice';
import subFolders from 'layouts/documents/store/subFoldersSlice/subFoldersSlice';
import foldersPath from 'layouts/documents/store/folderPathSlice/folderPathSlice';
import uploadDocumentForm from 'layouts/documents/store/uploadDocumentSlice/uploadDocumentSlice';
import studentProfileFilterOptions from 'layouts/studentManagement/store/studentProfileFilterOptionsSlice/studentProfileFilterOptionsSlice';
import studentProfileFilters from 'layouts/studentManagement/store/studentProfileFilterSlice/studentProfileFilterSlice';
import studentProfilePaginationKeyList from 'layouts/studentManagement/store/studentProfileSlice/studentProfilePaginationSlice';
import attendanceFilterOptions from 'layouts/applications/attendance/store/attendanceFilterOptionsSlice/attendanceFilterOptionsSlice';
import studentProfileDetails from 'layouts/studentManagement/store/studentProfileDetailsSlice/studentProfileDetailsSlice';
import scoresOptions from 'layouts/studentManagement/store/scores/scoresOptionsSlice/scoresOptionsSlice';
import studentScores from 'layouts/studentManagement/store/scores/studentScoresSlice/studentScoresSlice';
import reportsFilter from 'layouts/reports/store/reportsFilterSlice/reportsFilterSlice';
import reportsForm from 'layouts/reports/store/reportsSlice/reportsSlice';
import trackingForm from 'layouts/studentTracking/store/trackingSlice/trackingSlice';
import studentTrackerCell from 'layouts/studentTracking/store/studentTrackerCellSlice/studentTrackerCellSlice';
import studentTrackerFilters from 'layouts/studentTracking/store/studentTrackerFilterSlice/studentTrackerFilterSlice';
import trackerStatus from 'layouts/studentTracking/store/trackerStatusSlice/trackerStatusSlice';
import userLocation from 'layouts/users/userLocations/store/userLocationSlice';

export const rootReducer = combineReducers({
  myProfile,
  userDetails,
  myPermissions,
  calendars,
  calendarDetails,
  calendarEvent,
  calendarCategory,
  userManagementForm,
  groupForm,
  groupUsers,
  availableUsersList,
  assetForm,
  autoAssetForm,
  Building,
  assetType,
  snackbar,
  Location,
  LocationType,
  Calender,
  CalenderAdmin,
  CategoryAdminSlice,
  statsData,
  fundingType,
  CategoryAdminCalenderId,
  AnnouncementPaginationKey,
  yearMonth,
  semesterList,
  studentNote,
  announcementForm,
  announcementPaginationKeyList,
  announcementNotificationData,
  assetsPaginationKeyList,
  studentDetails,
  groupPermissions,
  globalPermissions,
  groupMembers,
  taskForm,
  taskPaginationKeyList,
  taskFilters,
  totalDataGridCounts,
  attendanceAuditReportId,
  attendanceAuditReport,
  bulkAssets,
  assetTags,
  attendanceAuditsArchivePaginationKeyList,
  sharedFoldersArchivePaginationKeyList,
  replaceAccess,
  attendanceAuditForm,
  systemConfigurationForm,
  sharedFolderForm,
  renderList,
  logout,
  folders,
  subFolders,
  foldersPath,
  uploadDocumentForm,
  studentProfileFilterOptions,
  studentProfileFilters,
  studentProfilePaginationKeyList,
  attendanceFilterOptions,
  studentProfileDetails,
  scoresOptions,
  studentScores,
  reportsFilter,
  reportsForm,
  trackingForm,
  studentTrackerCell,
  studentTrackerFilters,
  trackerStatus,
  userLocation
});
