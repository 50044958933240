import { systemConfigurationSuccess } from 'constants';
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import FormInputDropdown from 'components/Common/Forms/FormInputDropDown';
import {
  BoxCenter,
  GridCenter,
  ButtonWrapper,
  ButtonSpinner,
  HeadingMuiTypography,
  ButtonSpinnerProgress,
} from 'components/Ui/styled';
import {
  onSave,
  showLoader,
  emptyFunction,
  formatDateText,
  useNotification,
  DropdownInputList,
} from 'utils/commonUtils';
import {
  setIepResponsibleUsers,
  getSystemConfigurationForm,
  resetSystemConfigurationForm,
} from '../store/systemConfigurationSlice';
import {
  getSystemConfigurations,
  putSystemConfigurations,
} from '../services/systemConfigurationApi';

const SystemConfigurationForm = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();

  useEffect(() => {
    fetchSystemConfigurations();
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetSystemConfigurationForm());
    };
  }, []);

  const fetchSystemConfigurations = async () => {
    const systemConfigurationData = await getSystemConfigurations();
    dispatch(getSystemConfigurationForm(systemConfigurationData));
    setIsLoading(false);
  };

  const systemConfigurationForm = useSelector(
    (state) => state.systemConfigurationForm
  );

  const availableUsersList = useSelector((state) => state.availableUsersList);

  const { id, iepResponsibleUsers, version, modifiedBy, modifiedAt } =
    systemConfigurationForm;

  const defaultValue = {
    systemConfigurationId: id,
    iepResponsibleUsers: iepResponsibleUsers,
    version: version,
    modifiedBy: modifiedBy,
    modifiedAt: formatDateText(modifiedAt),
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
    //resolver: yupResolver(attendanceAuditSchema),
  });

  const { handleSubmit, reset, control, register, formState } = useFunction;
  const { errors } = formState;

  useEffect(() => {
    reset(defaultValue);
  }, [
    defaultValue.systemConfigurationId,
    defaultValue.iepResponsibleUsers,
    defaultValue.version,
    defaultValue.modifiedBy,
    defaultValue.modifiedAt,
  ]);

  const availableUsersData = availableUsersList?.map((item) => ({
    id: item.username,
    value: item.username,
  }));

  const handleIepResponsibleUsersChange = ({ target: { value } }) => {
    const iepResponsibleUsers =
      typeof value === 'string' ? value.split(',') : value;
    dispatch(setIepResponsibleUsers(iepResponsibleUsers));
  };

  const onSubmit = async () => {
    const payload = {
      key: 'iepResponsibleUsers',
      value: iepResponsibleUsers,
    };
    onSave(
      systemConfigurationSuccess,
      false,
      emptyFunction,
      putSystemConfigurations,
      payload,
      '',
      fetchSystemConfigurations,
      emptyFunction,
      setOpenNotification,
      setNotificationMessage,
      dispatch,
      setLoadingButton,
      loadingButton
    );
  };

  return (
    <>
      {isLoading ? (
        showLoader()
      ) : (
        <>
          <GridCenter container>
            <Grid item xs={12} sm={7}>
              <Card>
                <HeadingMuiTypography variant="h6">
                  System Configuration
                </HeadingMuiTypography>
                <BoxCenter>
                  <FormInputText
                    name="systemConfigurationId"
                    type="text"
                    control={control}
                    label="System Configuration Id"
                    disabled={true}
                  />
                  <FormInputDropdown
                    name="iepResponsibleUsers"
                    type="text"
                    control={control}
                    multiple={true}
                    checked={defaultValue.iepResponsibleUsers}
                    renderValue={(selected) => (
                      <DropdownInputList selectedValues={selected} />
                    )}
                    options={availableUsersData}
                    label="Iep Responsible Users"
                    register={register('iepResponsibleUsers', {
                      onChange: (e) => handleIepResponsibleUsersChange(e),
                    })}
                  />
                  <FormInputText
                    name="version"
                    type="text"
                    control={control}
                    label="Version"
                    disabled={true}
                  />
                  <FormInputText
                    name="modifiedBy"
                    type="text"
                    control={control}
                    label="Modified By"
                    disabled={true}
                  />
                  <FormInputText
                    name="modifiedAt"
                    type="text"
                    control={control}
                    label="Modified At"
                    disabled={true}
                  />
                </BoxCenter>
                <ButtonWrapper container>
                  <ButtonSpinner>
                    <Box sx={{ m: 1, position: 'relative' }}>
                      <Button
                        variant="text"
                        onClick={handleSubmit(onSubmit)}
                        disabled={loadingButton}
                      >
                        Save
                      </Button>
                      {loadingButton && <ButtonSpinnerProgress size={24} />}
                    </Box>
                  </ButtonSpinner>
                </ButtonWrapper>
              </Card>
            </Grid>
          </GridCenter>
        </>
      )}
      <NotificationPopup />
    </>
  );
};

export default SystemConfigurationForm;
