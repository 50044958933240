import { ActionWrapperBox } from 'components/Common/common.styled';
import { ButtonIcon, EditActionIcon } from 'components/Ui/styled';
import Tooltip from '@mui/material/Tooltip';
import ViewWeekRoundedIcon from '@mui/icons-material/ViewWeekRounded';
import CompareArrowsRoundedIcon from '@mui/icons-material/CompareArrowsRounded';

export const renderTrackingAction = (
  params,
  handleRenameTracker,
  handleRenameColumn,
  handleStatusOption
) => {
  return (
    <ActionWrapperBox>
      <ButtonIcon onClick={() => handleRenameTracker(params)}>
        <Tooltip title="Edit Tracker Name" arrow={false} placement="top">
          <EditActionIcon />
        </Tooltip>
      </ButtonIcon>
      <ButtonIcon
        disabled={params.row.noOfColumns === 10 ? true : false}
        onClick={() => handleRenameColumn(params)}
      >
        <Tooltip title="Edit Column" arrow={false} placement="top">
          <ViewWeekRoundedIcon />
        </Tooltip>
      </ButtonIcon>
      <ButtonIcon onClick={() => handleStatusOption(params)}>
        <Tooltip title="Replace Status" arrow={false} placement="top">
          <CompareArrowsRoundedIcon />
        </Tooltip>
      </ButtonIcon>
    </ActionWrapperBox>
  );
};
