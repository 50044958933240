import { renderAssignmentsAction } from './AssignmentsAction';

export const assignmentsColumns = (handleUploadAssignments) => [
  {
    field: 'action',
    headerName: 'Action',
    sortable: false,
    disableColumnMenu: true,
    width: 120,
    renderCell: (params) =>
      renderAssignmentsAction(params, handleUploadAssignments),
  },
  {
    headerName: 'Assignments',
    field: 'assignment',
    flex: 1,
  },
  {
    headerName: 'Upload Date',
    field: 'uploadDate',
    flex: 1,
  },
  {
    headerName: 'Due Date',
    field: 'dueDate',
    flex: 1,
  },
];

export const getAssignmentsListData = (assignmentsData) => {
  const items = assignmentsData?.items;
  if (!items || items.length === 0) {
    return [];
  }
  return items.map((item) => ({
    ...item,
    id: item.id,
  }));
};
