import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import { PaperProps } from 'constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDialogHeading,
  useNotification,
  renderSaveCancelButtons,
  onSave,
} from 'utils/commonUtils';
import { GridForm } from 'components/Ui/styled';
import {
  accessToOptions,
  accessLevelOptions,
  sharedFolderAccessSuccess,
} from 'constants';
import FormInputDropdown from 'components/Common/Forms/FormInputDropDown';
import { emptyFunction } from 'utils/commonUtils';
import {
  putSharedFolderUserAccess,
  putSharedFolderGroupAccess,
} from 'layouts/documentManagement/services/documentManagementApi';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { requiredField } from 'constants';

const shareFoldersAccessSchema = yup.object().shape({
  accessTo: yup.string().required(requiredField),
  username: yup.string().when('accessTo', {
    is: 'user',
    then: yup.string().required(requiredField),
  }),
  groupName: yup.string().when('accessTo', {
    is: 'group',
    then: yup.string().required(requiredField),
  }),
  accessLevel: yup.string().required(requiredField),
});

const SharedFolderAccessDialog = (props) => {
  const {
    open,
    setOpen,
    groupsDropDownList,
    sharedFolderId,
    setSharedFolderId,
    fetchSharedFolders,
  } = props;

  const dispatch = useDispatch();

  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();

  const [accessTo, setAccessTo] = useState('');
  const [loadingButton, setLoadingButton] = useState(false);

  const availableUsersList = useSelector((state) => state.availableUsersList);

  const availableUsersData = availableUsersList?.map((item) => ({
    id: item.username,
    value: item.username,
  }));

  const groupData = groupsDropDownList?.map((item) => ({
    id: item.groupName,
    value: item.groupName,
  }));

  const defaultValue = {
    accessTo: accessTo,
    username: '',
    groupName: '',
    accessLevel: '',
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
    resolver: yupResolver(shareFoldersAccessSchema),
  });

  const { handleSubmit, reset, control, register, formState } = useFunction;
  const { errors } = formState;

  useEffect(() => {
    reset(defaultValue);
  }, [
    defaultValue.accessTo,
    defaultValue.username,
    defaultValue.groupName,
    defaultValue.accessLevel,
  ]);

  const handleClose = () => {
    reset(defaultValue);
    setSharedFolderId(null);
    setAccessTo('');
    setOpen(false);
  };

  const handleAccessTo = (e) => {
    if (e.target.value === 'user') {
      setAccessTo(e.target.value);
    } else {
      setAccessTo(e.target.value);
    }
  };

  const onSubmit = (data) => {
    const { username, groupName, accessLevel } = data;

    const userPayload = {
      username: username,
      accessLevel: accessLevel,
    };
    const groupPayload = {
      groupName: groupName,
      accessLevel: accessLevel,
    };
    const payload = accessTo === 'user' ? userPayload : groupPayload;
    const putFunction =
      accessTo === 'user'
        ? putSharedFolderUserAccess
        : putSharedFolderGroupAccess;

    onSave(
      sharedFolderAccessSuccess,
      false,
      emptyFunction,
      putFunction,
      payload,
      sharedFolderId,
      fetchSharedFolders,
      handleClose,
      setOpenNotification,
      setNotificationMessage,
      dispatch,
      setLoadingButton,
      loadingButton
    );
  };

  return (
    <>
      <Dialog open={open} PaperProps={PaperProps}>
        {getDialogHeading('Shared Folder Access', handleClose)}
        <GridForm container spacing={2}>
          <Grid item xs={12}>
            <FormInputDropdown
              name='accessTo'
              type='text'
              control={control}
              options={accessToOptions}
              label='Access To'
              helperText={errors?.accessTo?.message}
              errors={!!errors.accessTo}
              register={register('accessTo', {
                onChange: (e) => handleAccessTo(e),
              })}
            />
            {accessTo === 'user' && (
              <FormInputDropdown
                name='username'
                type='text'
                control={control}
                options={availableUsersData}
                label='User Name'
                helperText={errors?.username?.message}
                errors={!!errors.username}
              />
            )}
            {accessTo === 'group' && (
              <FormInputDropdown
                name='groupName'
                type='text'
                control={control}
                options={groupData}
                label='Group Name'
                helperText={errors?.groupName?.message}
                errors={!!errors.groupName}
              />
            )}
            <FormInputDropdown
              name='accessLevel'
              type='text'
              control={control}
              options={accessLevelOptions}
              label='Access Level'
              helperText={errors?.accessLevel?.message}
              errors={!!errors.accessLevel}
            />
            {renderSaveCancelButtons(
              handleClose,
              handleSubmit(onSubmit),
              loadingButton
            )}
          </Grid>
        </GridForm>
      </Dialog>
      <NotificationPopup />
    </>
  );
};

export default SharedFolderAccessDialog;
