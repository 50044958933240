import React, { useState } from 'react';
import Tab from '@mui/material/Tab';
import TabPanel, { a11yProps } from 'components/Common/TabPanel/TabPanel';
import { StickyTab, TabContainer } from 'components/Ui/styled';
import TutoringCalendarTab from './TutoringCalendarTab';
import TutoringList from './TutoringList';

const TutoringTab = () => {
  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <TabContainer>
      <StickyTab
        value={value}
        onChange={handleTabChange}
        aria-label="tutoringTab"
      >
        <Tab label="Calendar" {...a11yProps(0)} />
        <Tab label="List" {...a11yProps(1)} />
      </StickyTab>
      <TabPanel value={value} index={0}>
        <TutoringCalendarTab />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TutoringList />
      </TabPanel>
    </TabContainer>
  );
};

export default TutoringTab;
