import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Draggable } from 'react-beautiful-dnd';
import { ItemTextList } from 'components/Ui/styled';

const ReorderColumnDraggable = (props) => {
  const { item, index } = props;

  return (
    <Draggable key={item} draggableId={item} index={index}>
      {(provided) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <ListItemButton>
            <ListItemIcon>
              <DragIndicatorIcon />
            </ListItemIcon>
            <ItemTextList disableTypography primary={item} />
          </ListItemButton>
        </ListItem>
      )}
    </Draggable>
  );
};

export default ReorderColumnDraggable;
