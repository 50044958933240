import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import Footer from 'examples/Footer';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import { useEffect, useState } from 'react';
import CustomizedSnackbars from 'components/snackbar/Snackbar';
import { useResetSnackbar, pageSizeChangeHandler } from 'utils/commonUtils';
import {
  deleteCategoryAdmin,
  getCategoryAdmin,
} from './services/categoryAdminApi';
import { CategoryColor } from 'components/Ui/styled';
import CategoryAdminDialog from './categoryAdminDialog';
import {
  deleteCategoryForm,
  newCategoryForm,
  updateCategoryForm,
} from './store/categoryAdminSlice';
import { useDispatch, useSelector } from 'react-redux';
import { CategoryAction } from './CategoryAction';
import FormInputDropdown from 'components/Common/Forms/FormInputDropDown';
import { useForm } from 'react-hook-form';
import {
  resetCategoryCalenderId,
  setCategoryCalenderId,
} from './store/calenderIdCategorySlice';
import { DropDownBox, HeadingWrapperBox } from './categoryAdminStyled';
import { intialPageSize } from 'constants';
import { getCalendarsForUsers } from 'examples/Calendar/services/calendarsApi';
import CommonDeleteDialog from 'components/Common/CommonDeleteDialog/CommonDeleteDialog';
import DeltaDataGrid from 'utils/CommonDataGrid/CommonDataGrid';

export const CategoryAdmin = () => {
  useResetSnackbar();
  const dispatch = useDispatch();
  const [calendarDropDownList, setCalendarDropDownList] = useState();
  const [openCategoryAdminDialog, setOpenCategoryAdminDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [calendarAdminCategories, setCalendarAdminCategories] = useState({
    rows: [],
    isloading: false,
    pageSize: intialPageSize,
  });

  const calendarOptionList = calendarDropDownList?.calendars?.map(
    (item, index) => ({
      id: item.calendarId,
      value: item.name,
    })
  );
  const calendarCategory = useSelector((state) => state.CategoryAdminSlice);
  const { categoryId } = calendarCategory;

  const currentCalenderId = useSelector(
    (state) => state?.CategoryAdminCalenderId?.calenderId
  );

  useEffect(() => {
    fetchCalendarListData();
  }, []);

  useEffect(() => {
    if (calendarDropDownList) {
      dispatch(
        setCategoryCalenderId(calendarDropDownList?.calendars[0]?.calendarId)
      );
    }
  }, [calendarDropDownList]);

  useEffect(() => {
    if (currentCalenderId) {
      fetchData();
    }
  }, [currentCalenderId]);

  const fetchCalendarListData = async () => {
    const calendarList = await getCalendarsForUsers();
    setCalendarDropDownList(calendarList);
  };

  const defaultValue = {
    selectCalendar: currentCalenderId ? currentCalenderId : '',
  };

  const useFunction = useForm({
    defaultValues: defaultValue,
  });
  const { control, register, reset } = useFunction;

  const fetchData = async () => {
    setCalendarAdminCategories((prveState) => ({
      ...prveState,
      isloading: true,
    }));
    const categoriesData = await getCategoryAdmin(currentCalenderId);
    const { categories } = categoriesData;

    setCalendarAdminCategories((prveState) => ({
      ...prveState,
      rows: categories?.map((item, index) => ({
        ...item,
        id: index,
        color: item.color,
        name: item.name,
      })),
    }));
    setCalendarAdminCategories((prveState) => ({
      ...prveState,
      isloading: false,
    }));
  };

  const editClickHandler = async (params) => {
    const { row } = params;
    const { name, color, categoryId, calenderId } = row;
    const payload = {
      name,
      color,
      calenderId,
      categoryId,
    };

    dispatch(updateCategoryForm(payload));
    setOpenCategoryAdminDialog(true);
  };

  const addNewClickHandler = (e) => {
    const newCategory = {
      newCategory: true,
      color: '',
      category: '',
      name: null,
      categoryId: '',
    };
    dispatch(newCategoryForm(newCategory));
    setOpenCategoryAdminDialog(true);
  };

  const dialogDeleteHandler = async (params) => {
    const { row } = params;
    const { name, color, categoryId, calenderId } = row;
    const category = {
      name,
      color,
      calenderId,
      categoryId,
    };
    dispatch(deleteCategoryForm(category));

    setOpenDeleteDialog(true);
  };

  const renderActionItems = (params) => {
    return (
      <CategoryAction
        params={params}
        updateCalendar={editClickHandler}
        deleteCalendar={dialogDeleteHandler}
      />
    );
  };

  const columns = [
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      width: 100,
      align: 'center',
      renderCell: (params) =>
        renderActionItems(params, editClickHandler, dialogDeleteHandler),
    },
    { headerName: 'Category Name', field: 'name', flex: 1 },
    {
      headerName: 'Category Color',
      field: 'color',
      flex: 1,
      renderCell: (params) => <CategoryColor color={params.row.color} />,
    },
  ];

  useEffect(() => {
    reset(defaultValue);
  }, [defaultValue.selectCalendar]);

  useEffect(() => {
    return () => {
      dispatch(resetCategoryCalenderId());
    };
  }, [dispatch]);

  const category = {
    categoryId,
  };

  const pageSizeChange = pageSizeChangeHandler(setCalendarAdminCategories);

  return (
    <DashboardLayout>
      <CustomizedSnackbars />
      <DashboardNavbar />
      <MDBox>
        <Card>
          <HeadingWrapperBox>
            <MDTypography variant="h5" fontWeight="medium">
              Category
            </MDTypography>
            <DropDownBox>
              <FormInputDropdown
                name="selectCalendar"
                type="text"
                control={control}
                options={calendarOptionList}
                label="Select Calendar"
                register={register('selectCalendar', {
                  onChange: (e) =>
                    dispatch(setCategoryCalenderId(e.target.value)),
                })}
              />
            </DropDownBox>
            <Button variant="text" onClick={addNewClickHandler}>
              New Category
            </Button>
          </HeadingWrapperBox>
          <DeltaDataGrid
            listData={calendarAdminCategories}
            columns={columns}
            setListData={pageSizeChange}
          />
        </Card>
        <CategoryAdminDialog
          open={openCategoryAdminDialog}
          setOpen={setOpenCategoryAdminDialog}
          fetchData={fetchData}
          selectCalendarId={currentCalenderId}
        />
        <CommonDeleteDialog
          openDeleteDialog={openDeleteDialog}
          setOpenDeleteDialog={setOpenDeleteDialog}
          title="Delete Asset Type"
          contentText="Are you sure you want to delete this asset type ?"
          fetchData={fetchData}
          snakBarName="Category"
          commonDeleteApi={deleteCategoryAdmin}
          commonDeleteId={category}
          currentCalenderId={currentCalenderId}
        />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};
