import * as yup from 'yup';
import MDBox from 'components/MDBox';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import AssetNotes from './AttendanceNotes';
import { PaperPropsCheck, onlineAttendanceSuccess } from 'constants';
import { GridForm } from 'components/Ui/styled';
import React, { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { attendanceStatusOptions } from './common/constants';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import { postStudentAttendanceNote } from './services/attendanceApis';
import { FormInputSwitch } from 'components/Common/Forms/FormInputSwitch';
import FormInputDropdown from 'components/Common/Forms/FormInputDropDown';
import { displaySnackbar } from 'components/snackbar/store/SnackbarSlice';
import {
  getDialogHeading,
  renderSaveCancelButtons,
  showBackdropLoader,
  fieldValidation,
  dropDownValidation,
  useNotification,
  getSnackBar,
} from 'utils/commonUtils';
import {
  getStudentNote,
  setWeek,
  setLabel,
  setNote,
  setInCompliance,
} from './store/studentNote/studentNoteSlice';

const inputSchema = yup.object().shape({
  label: dropDownValidation,
  note: fieldValidation(4000),
});

const AttendanceDialogEdit = (props) => {
  const { id, field, row, api, setIsEditAttendanceCell, getTableData } = props;
  const dispatch = useDispatch();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const [isAttendanceEditDialog, setIsAttendanceEditDialog] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);

  const studentNote = useSelector((state) => state.studentNote);
  const isAttendanceAudit = useSelector(
    (state) => state.semesterList.isAttendanceAudit
  );

  const { studentId, semester, week, notes } = studentNote;

  const exactWeek = week.split('-', 1);

  const getNotes = () => {
    const getNotesDetails = notes?.find((item) => item.week === exactWeek[0]);
    return getNotesDetails;
  };

  const notesData = getNotes();

  const defaultValue = {
    label: notesData?.label,
    note: notesData?.note,
    inCompliance:
      notesData?.inCompliance === undefined ? true : notesData?.inCompliance,
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
    resolver: yupResolver(inputSchema),
  });

  const { handleSubmit, reset, control, register, formState } = useFunction;
  const { errors } = formState;

  useEffect(() => {
    dispatch(getStudentNote(row));
    dispatch(setWeek(field));
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    reset(defaultValue);
  }, [defaultValue.label, defaultValue.note, defaultValue.inCompliance]);

  const handleClose = () => {
    api.setCellMode(id, field, 'view');
    setIsAttendanceEditDialog(false);
  };

  const onSubmit = async () => {
    setLoadingButton(true);
    const snackBar = getSnackBar(onlineAttendanceSuccess);

    const payload = {
      semester: semester,
      week: exactWeek[0],
      label:
        studentNote?.label === undefined
          ? notesData?.label
          : studentNote?.label,
      note:
        studentNote?.note === undefined ? notesData?.note : studentNote?.note,
      inCompliance:
        studentNote?.inCompliance === undefined
          ? defaultValue?.inCompliance
          : studentNote?.inCompliance,
    };
    await postStudentAttendanceNote(payload, studentId)
      .then((response) => {
        dispatch(displaySnackbar(snackBar));
        api.setCellMode(id, field, 'view');
        dispatch(displaySnackbar(snackBar));
        setIsEditAttendanceCell(false);
        setIsAttendanceEditDialog(false);
        getTableData();
      })
      .catch((e) => {
        setOpenNotification(true);
        setNotificationMessage(e.response.data.message);
      });
    setLoadingButton(false);
  };

  return (
    <>
      <Dialog open={isAttendanceEditDialog} PaperProps={PaperPropsCheck}>
        {isLoading ? (
          showBackdropLoader(isLoading)
        ) : (
          <>
            {getDialogHeading('Set Attendance', handleClose)}
            <GridForm container spacing={2}>
              <Grid item xs={12}>
                <FormInputDropdown
                  name="label"
                  type="text"
                  control={control}
                  options={attendanceStatusOptions}
                  readOnly={isAttendanceAudit}
                  label="Attendance Status"
                  helperText={errors?.label?.message}
                  errors={!!errors.label}
                  register={register('label', {
                    onChange: (e) => dispatch(setLabel(e.target.value)),
                  })}
                />
                <FormInputText
                  name="note"
                  type="text"
                  control={control}
                  multiline={true}
                  rows={3}
                  readOnly={isAttendanceAudit}
                  label="Attendance Note"
                  helperText={errors?.note?.message}
                  errors={!!errors.note}
                  register={register('note', {
                    onChange: (e) => dispatch(setNote(e.target.value)),
                  })}
                />
                {!isAttendanceAudit && (
                  <>
                    <MDBox pt={4}></MDBox>
                    <FormInputSwitch
                      control={control}
                      name="inCompliance"
                      label="In Compliance"
                      checked={defaultValue.inCompliance}
                      register={register('inCompliance', {
                        onChange: (e) =>
                          dispatch(setInCompliance(e.target.value)),
                      })}
                    />
                  </>
                )}
              </Grid>
            </GridForm>
            <MDBox pt={2}></MDBox>
            <GridForm container spacing={2}>
              <Grid item xs={12}>
                <AssetNotes notesData={notesData} />
                {isAttendanceAudit ? (
                  <MDBox pt={4}></MDBox>
                ) : (
                  renderSaveCancelButtons(
                    handleClose,
                    handleSubmit(onSubmit),
                    loadingButton
                  )
                )}
              </Grid>
            </GridForm>
          </>
        )}
      </Dialog>
      <NotificationPopup />
    </>
  );
};

export default AttendanceDialogEdit;

export const renderAttendanceDialogEdit = (
  params,
  setIsEditAttendanceCell,
  getTableData
) => {
  return (
    <AttendanceDialogEdit
      {...params}
      setIsEditAttendanceCell={setIsEditAttendanceCell}
      getTableData={getTableData}
    />
  );
};
