import MDBox from 'components/MDBox';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MDTypography from 'components/MDTypography';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';

export const HeadingWrapperBox = styled(MDBox)`
  display: flex;
  justify-content: space-between;
  &.MuiBox-root {
    padding: ${(props) =>
      `${props.theme.palette.spacing[1] * 2}px ${
        props.theme.palette.spacing[1] * 3
      }px`};
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
`;

export const GridWrapper = styled(Grid)`
  ${(props) => (props.primary ? `` : 'height: 70vh;')}
  width: 100%;
`;

export const AddButton = styled(Button)`
  position: relative;
  left: 62rem;
  top: 10px;
`;

export const BuildingTypography = styled(MDTypography)`
  margin-top: ${(props) => `${props.theme.palette.spacing[1] * 0.5}px`};
`;

export const LoaderDiv = styled('div')`
  position: relative;
  left: 50%;
  top: 50%;
`;

export const CalenderSelect = styled(Select)`
  height: 40px;
`;

export const CalenderGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  width: 950px;
`;

export const calendarForm = styled(Grid)`
  width: 400px;
`;

export const NotesList = styled(Grid)`
  padding: 0px 10px;
  border-radius: 10px;
  background-color: #dcdcdc;
  margin-bottom: 5px;
  cursor: pointer;
`;

export const NotesHeading = styled(Grid)`
  align-items: center;
`;

export const NewNote = styled(Grid)`
  display: flex;
  justify-content: flex-end;
`;

export const NotesTypography = styled(Typography)`
  font-weight: 400;
`;

export const DropDownBox = styled(Box)`
  width: 20%;
`;

export const TextBox = styled(Box)`
  margin-top: '-13px';
`;
