import React, { useState, useEffect } from 'react';
import { GridBaselineWrapper } from 'components/Ui/styled';
import FormInputDropdown from 'components/Common/Forms/FormInputDropDown';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import {
  getDataGridSubHeading,
  emptyFunction,
  showBackdropLoader,
  pageSizeChangeHandler,
} from 'utils/commonUtils';
import { useDispatch, useSelector } from 'react-redux';
import MDBox from 'components/MDBox';
import { getStudentScores } from 'layouts/studentManagement/services/studentProfileApi';
import { setStudentScores } from 'layouts/studentManagement/store/scores/studentScoresSlice/studentScoresSlice';
import { intialPageSize, actCategorie, mapsCategorie } from 'constants';
import { extractKeyItem, filterScoresBySchoolYear } from './ScoresTabUtils';
// import DefaultLineChart from 'examples/Charts/LineCharts/DefaultLineChart';
import { getStudentScoresList, studentScoresColumns } from './ScoresDataGrid';
import DeltaDataGrid from 'utils/CommonDataGrid/CommonDataGrid';

const ScoresTab = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStudentScore, setSelectedStudentScore] = useState(null);
  const [testCategoriesField, setTestCategoriesField] = useState(actCategorie);
  const [isMaps, setIsMaps] = useState(true);
  const [isOther, setIsOther] = useState(false);
  const [studentScoresList, setStudentScoresList] = useState({
    isLoading: false,
    rows: [],
    pageSize: intialPageSize,
  });

  // const { rows } = studentScoresList;

  const scoresOptions = useSelector((state) => state.scoresOptions);
  const studentDetails = useSelector((state) => state.studentDetails);
  const scoresByCategory = useSelector(
    (state) => state.studentScores?.scoresByCategory
  );

  const { testCategories, schoolYears } = scoresOptions || {};

  const testCategorieOptions = testCategories?.map((item) => ({
    id: item,
    value: item,
  }));

  const schoolYearOptions = schoolYears?.map((item) => ({
    id: item,
    value: item,
  }));

  const defaultValue = {
    schoolYears: '',
    testCategories: testCategoriesField,
  };

  useEffect(() => {
    fetchStudentScores();
  }, []);

  useEffect(() => {
    if (scoresByCategory) {
      setSelectedStudentScore(scoresByCategory[actCategorie]);
    }
  }, [scoresByCategory]);

  const fetchStudentScores = async () => {
    setIsLoading(true);
    try {
      const studentScores = await getStudentScores(
        studentDetails?.profile?.resourceId
      );
      dispatch(setStudentScores(studentScores));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchStudentScoresList();
  }, [selectedStudentScore]);

  const fetchStudentScoresList = () => {
    setStudentScoresList((prev) => ({
      ...prev,
      rows: getStudentScoresList(selectedStudentScore),
    }));
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
  });

  const { reset, control, register } = useFunction;

  useEffect(() => {
    reset(defaultValue);
  }, [defaultValue.schoolYears, defaultValue.testCategories]);

  const handleTestCategoriesOptions = (e) => {
    setSelectedStudentScore(scoresByCategory[e.target.value]);
    setTestCategoriesField(e.target.value);
    if (e.target.value === mapsCategorie) {
      setIsMaps(false);
      setIsOther(true);
    } else {
      setIsMaps(true);
      setIsOther(false);
    }
  };

  const handleSchoolYearsOptions = (e) => {
    const schoolYearRecords = filterScoresBySchoolYear(
      scoresByCategory,
      testCategoriesField,
      e.target.value
    );
    setSelectedStudentScore(schoolYearRecords);
  };

  // const studentsByGradeData = {
  //   labels: extractKeyItem(rows, 'testName', 'testSubcategory'),
  //   datasets: [
  //     {
  //       label: 'Grade',
  //       color: 'dark',
  //       data: extractKeyItem(rows, 'ritScore', 'scoreResult'),
  //     },
  //   ],
  // };

  const pageSizeChange = pageSizeChangeHandler(setStudentScoresList);

  return (
    <>
      {isLoading && showBackdropLoader(isLoading)}
      <MDBox pt={1}></MDBox>
      <GridBaselineWrapper container spacing={2}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormInputDropdown
                name="testCategories"
                type="text"
                control={control}
                options={testCategorieOptions}
                label="Test Categories"
                register={register('testCategories', {
                  onChange: (e) => {
                    handleTestCategoriesOptions(e);
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInputDropdown
                name="schoolYears"
                type="text"
                control={control}
                options={schoolYearOptions}
                label="School Years"
                register={register('schoolYears', {
                  onChange: (e) => {
                    handleSchoolYearsOptions(e);
                  },
                })}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item xs={12} md={6}>
          {getDataGridSubHeading(emptyFunction, 'Generate')}
        </Grid> */}
      </GridBaselineWrapper>
      <DeltaDataGrid
        listData={studentScoresList}
        columns={studentScoresColumns(isMaps, isOther)}
        setListData={pageSizeChange}
      />
      {/* <MDBox mb={3}></MDBox>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <DefaultLineChart
            height="24rem"
            icon={{ component: 'insights' }}
            title="Line chart"
            description={testCategoriesField}
            chart={studentsByGradeData}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <HorizontalBarChart
            title={testCategoriesField}
            chart={studentsByGradeData}
          />
        </Grid>
      </Grid> */}
    </>
  );
};

export default ScoresTab;
