export const autoHideDurationTime = 3000;

export const PaperProps = {
  sx: {
    maxWidth: 'unset',
    width: '400px',
    maxHeight: 'auto',
  },
};

export const userCountPaperProps = {
  sx: {
    maxWidth: 'unset',
    width: '700px',
    maxHeight: 'unset',
  },
};

export const emptyGroup = [
  {
    groupName: '',
    description: '',
    isSystemGroup: '',
  },
];

export const updateGroupMessage = 'System groups may not be updated';
export const notificationHeader = 'Notification';
export const ASSET_BASE_URL_APPEND = 'asset-mgmt/';
