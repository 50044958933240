import { createSlice } from '@reduxjs/toolkit';
import { _setStudentProfileDetails } from './studentProfileDetailsAction';

export const studentProfileDetailsSlice = createSlice({
  name: 'delta/studentProfileDetails',
  initialState: null,
  reducers: {
    setStudentProfileDetails: _setStudentProfileDetails,
    resetStudentData: () => null,
  },
});

export const { setStudentProfileDetails, resetStudentData } =
  studentProfileDetailsSlice.actions;

export default studentProfileDetailsSlice.reducer;
