import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import { GridForm, GridButtonWrapper } from 'components/Ui/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import { resetSharedFolderForm } from 'layouts/documentManagement/store/sharedFolderSlice/sharedFolderSlice';
import {
  getDialogHeading,
  useNotification,
  renderSaveCancelButtons,
  showBackdropLoader,
  onSave,
  fieldValidation,
} from 'utils/commonUtils';
import { PaperProps, sharedFolderSuccess, characterLimit } from 'constants';
import {
  postSharedFolder,
  putSharedFolder,
} from 'layouts/documentManagement/services/documentManagementApi';

const sharedFolderSchema = yup.object().shape({
  name: fieldValidation(characterLimit),
  description: fieldValidation(4000),
});

const SharedFolderDialog = (props) => {
  const dispatch = useDispatch();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const [loadingButton, setLoadingButton] = useState(false);
  const { open, setOpen, isFormLoading, fetchSharedFolders } = props;

  const sharedFolderForm = useSelector((state) => state.sharedFolderForm.item);
  const { id, isNewSharedFolder, name, description } = sharedFolderForm;

  const defaultValue = {
    id: id,
    name: name,
    description: description,
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
    resolver: yupResolver(sharedFolderSchema),
  });

  const { handleSubmit, reset, control, formState } = useFunction;
  const { errors } = formState;

  useEffect(() => {
    reset(defaultValue);
  }, [defaultValue.id, defaultValue.name, defaultValue.description]);

  const handleClose = () => {
    reset(defaultValue);
    dispatch(resetSharedFolderForm());
    setOpen(false);
  };

  const onSubmit = async (data) => {
    const { name, description } = data;
    const payload = {
      name,
      description,
    };
    onSave(
      sharedFolderSuccess,
      isNewSharedFolder,
      postSharedFolder,
      putSharedFolder,
      payload,
      id,
      fetchSharedFolders,
      handleClose,
      setOpenNotification,
      setNotificationMessage,
      dispatch,
      setLoadingButton,
      loadingButton
    );
  };

  return (
    <>
      {showBackdropLoader(isFormLoading)}
      <Dialog open={open} PaperProps={PaperProps}>
        {getDialogHeading('Shared Folder', handleClose)}
        <GridForm container spacing={2}>
          <Grid item xs={12}>
            <FormInputText
              name='name'
              type='text'
              control={control}
              label='Name'
              helperText={errors?.name?.message}
              errors={!!errors.name}
            />
            <FormInputText
              name='description'
              type='text'
              control={control}
              label='Description'
              multiline={true}
              rows={3}
              helperText={errors?.description?.message}
              errors={!!errors.description}
            />
          </Grid>
        </GridForm>
        <GridButtonWrapper container>
          {renderSaveCancelButtons(
            handleClose,
            handleSubmit(onSubmit),
            loadingButton
          )}
        </GridButtonWrapper>
      </Dialog>
      <NotificationPopup />
    </>
  );
};

export default SharedFolderDialog;
