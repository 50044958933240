import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { PaperProps } from 'constants';
import { useDispatch } from 'react-redux';
import { displaySnackbar } from 'components/snackbar/store/SnackbarSlice';
import { getYesNo, useNotification } from 'utils/commonUtils';

const CommonDeleteDialog = (props) => {
  const dispatch = useDispatch();
  const {
    openDeleteDialog,
    setOpenDeleteDialog,
    contentText,
    fetchData,
    snakBarName,
    commonDeleteApi,
    commonDeleteId,
    currentCalenderId,
    getCurrentYear,
    getCurrentMonth,
    setOpen,
  } = props;

  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const [loadingButton, setLoadingButton] = useState(false);

  const handleClose = () => {
    setOpenDeleteDialog(false);
  };

  const dialogDeleteHandler = async () => {
    setLoadingButton(true);
    const newSnackBar = {
      snackbarOpen: true,
      snackbarType: 'success',
      snackbarMessage: `Successfully the ${snakBarName} deleted`,
      vertical: 'top',
      horizontal: 'center',
    };

    try {
      await commonDeleteApi(commonDeleteId, currentCalenderId);
      dispatch(displaySnackbar(newSnackBar));
      if (fetchData) {
        fetchData(getCurrentYear, getCurrentMonth);
        setOpenDeleteDialog(false);
      }
      if (setOpen) {
        setOpen(false);
      }
    } catch (e) {
      setOpenNotification(true);
      setNotificationMessage(e.response.data.message);
    } finally {
      setLoadingButton(false);
    }
  };

  return (
    <>
      <Dialog open={openDeleteDialog} PaperProps={PaperProps}>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {contentText}
          </DialogContentText>
        </DialogContent>
        {getYesNo(handleClose, dialogDeleteHandler, loadingButton)}
      </Dialog>
      <NotificationPopup />
    </>
  );
};

export default CommonDeleteDialog;
