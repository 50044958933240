import { ActionWrapperBox } from 'components/Common/common.styled';
import { ButtonIcon, RoundedIconVisibility } from 'components/Ui/styled';

export const renderAttendanceAuditAction = (
  params,
  handleViewAttendanceAudit
) => {
  return (
    <ActionWrapperBox>
      <ButtonIcon onClick={() => handleViewAttendanceAudit(params)}>
        <RoundedIconVisibility />
      </ButtonIcon>
    </ActionWrapperBox>
  );
};
