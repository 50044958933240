import { createSlice } from '@reduxjs/toolkit';
import initialState from './taskInitialState';
import {
  _getTaskForm,
  _setIsNewTask,
  _setTitle,
  _setDescription,
  _setDueOn,
  _setAssignToSupervisor,
  _setUser,
  _setTaskNote,
} from './taskAction';

export const taskSlice = createSlice({
  name: 'delta/task',
  initialState,
  reducers: {
    getTaskForm: _getTaskForm,
    setIsNewTask: _setIsNewTask,
    setTitle: _setTitle,
    setDescription: _setDescription,
    setDueOn: _setDueOn,
    setAssignToSupervisor: _setAssignToSupervisor,
    setUser: _setUser,
    setTaskNote: _setTaskNote,
    resetTask: () => initialState,
  },
});

export const {
  getTaskForm,
  setIsNewTask,
  setTitle,
  setDescription,
  setDueOn,
  setAssignToSupervisor,
  setUser,
  setTaskNote,
  resetTask,
} = taskSlice.actions;

export default taskSlice.reducer;
