import { AttendanceTagWrapper } from './Attendance.styled';
import { attendanceTags } from './common/constants';
import { renderAttendanceTagCells } from './utils/attendanceUtils';

const AttendanceTagCellStack = (props) => {
  const { tags } = props;

  return (
    <AttendanceTagWrapper>
      {renderAttendanceTagCells(attendanceTags, tags)}
    </AttendanceTagWrapper>
  );
};

export default AttendanceTagCellStack;
