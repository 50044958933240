/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import * as React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';

// Material Dashboard 2 PRO React examples
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import EventCalendar from 'examples/Calendar';

import calendarEventsData from 'layouts/applications/calendar/data/calendarEventsData';
import CalenderDialog from './CalenderDialog';
import CustomizedSnackbars from 'components/snackbar/Snackbar';
import { resetCalenderData, setSelectCalendar } from './store/CalenderSlice';
import { getCalendarsForUsers } from 'examples/Calendar/services/calendarsApi';
import { resetCategoriesData } from './components/Categories/store/calenderCategorySlice';
import { deltaAcademyCalendar } from 'constants';

function Calendar() {
  const dispatch = useDispatch();
  const [openCalender, setOpenCalender] = useState(false);
  const [isBackDropLoading, setIsBackDropLoading] = useState(false);
  const [calendarDropDownList, setCalendarDropDownList] = useState();

  const [calendarEvents, setCalendarEvents] = useState({
    data: [],
  });

  const currentCalenderId = useSelector(
    (state) => state?.Calender?.selectCalendar
  );

  const isDashboardCalendar = useSelector(
    (state) => state?.Calender?.isDashboardCalendar
  );

  useEffect(() => {
    fetchCalendarListData();
  }, []);

  useEffect(() => {
    let selectedCalendar;
    if (calendarDropDownList) {
      selectedCalendar = calendarDropDownList?.calendars[0]?.calendarId;
    }

    if (isDashboardCalendar) {
      selectedCalendar = deltaAcademyCalendar;
    }

    if (selectedCalendar) {
      dispatch(setSelectCalendar(selectedCalendar));
    }
  }, [calendarDropDownList]);

  useEffect(() => {
    if (calendarDropDownList?.calendars?.length === 0) {
      setIsBackDropLoading(false);
    }
  }, [calendarDropDownList]);

  const fetchCalendarListData = async () => {
    setIsBackDropLoading(true);
    const calendarList = await getCalendarsForUsers();
    setCalendarDropDownList(calendarList);
  };

  useEffect(() => {
    if (!isDashboardCalendar) {
      dispatch(resetCalenderData());
      dispatch(resetCategoriesData());
    }
  }, [isDashboardCalendar, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetCalenderData());
      dispatch(resetCategoriesData());
    };
  }, [dispatch]);

  return (
    <DashboardLayout>
      <CustomizedSnackbars />
      <DashboardNavbar />
      <MDBox pt={3}>
        <EventCalendar
          calendarEventnew={calendarEventsData}
          initialView='dayGridMonth'
          initialDate={new Date()}
          calendarEvents={calendarEvents?.data}
          setCalendarEvents={setCalendarEvents}
          currentCalenderId={currentCalenderId}
          fetchCalendarListData={fetchCalendarListData}
          calendarDropDownList={calendarDropDownList}
          setIsBackDropLoading={setIsBackDropLoading}
          isBackDropLoading={isBackDropLoading}
        />
      </MDBox>
      <CalenderDialog openCalender={openCalender} setOpen={setOpenCalender} />
      <Footer />
    </DashboardLayout>
  );
}

export default Calendar;
