export const _getTaskForm = (state, action) => action.payload;

export const _setIsNewTask = (state, action) => {
  state.item.isNewtask = action.payload;
};

export const _setTitle = (state, action) => {
  state.item.title = action.payload;
};

export const _setDescription = (state, action) => {
  state.item.description = action.payload;
};

export const _setDueOn = (state, action) => {
  state.item.dueOn = action.payload;
};

export const _setAssignToSupervisor = (state, action) => {
  state.item.assignToSupervisor = action.payload;
};

export const _setUser = (state, action) => {
  state.item.user = action.payload;
};

export const _setTaskNote = (state, action) => {
  state.item.note = action.payload;
};

export const _setTaskPagination = (state, action) => {
  state.taskPagination = [...state.taskPagination, action.payload];
};

export const _setTaskPaginationPreviousKey = (state, action) => {
  state.taskPaginationPreviousKey = action.payload;
};
