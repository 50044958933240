import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { GridForm } from 'components/Ui/styled';
import {
  PaperProps,
  permissionSuccess,
  permissionReferenceData,
  noneAccessErrorMessage,
} from 'constants';
import { useForm } from 'react-hook-form';
import FormInputDropdown from 'components/Common/Forms/FormInputDropDown';
import { useDispatch, useSelector } from 'react-redux';
import { putCalenderPermission } from './services/calenderApi';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  renderSaveCancelButtons,
  dropDownValidation,
  getDialogHeading,
  useNotification,
  onSave,
  emptyFunction,
} from 'utils/commonUtils';

const permissionSchema = yup.object().shape({
  calender: dropDownValidation,
  username: dropDownValidation,
  permission: dropDownValidation,
});

const PermissionDialog = (props) => {
  const dispatch = useDispatch();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const {
    open,
    setOpen,
    usersReferencedata,
    fetchCalendarDataOfParticularMonth,
    calendarDropDownList,
    fetchCalendarListData,
  } = props;
  const [loadingButton, setLoadingButton] = useState(false);

  const yearMonth = useSelector((state) => state.yearMonth);
  const selectedCalendar = useSelector(
    (state) => state?.Calender?.selectCalendar
  );
  const selectedUserName = useSelector((state) => state?.userDetails?.username);

  const current = new Date(yearMonth);
  const getCurrentYear = `${current.getFullYear()}`;
  const getCurrentMonth = `${current.getMonth() + 1}`;

  const defaultValue = {
    calender: '',
    username: '',
    permission: '',
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
    resolver: yupResolver(permissionSchema),
  });

  const { handleSubmit, control, reset, register, formState } = useFunction;
  const { errors } = formState;

  const handleClose = () => {
    reset(defaultValue);
    setOpen(false);
  };

  const onSubmit = async (data) => {
    const { calender, username, permission } = data;
    const payload = {
      permission: {
        calendarId: calender,
        username: username,
        permission: permission,
      },
    };
    if (
      selectedCalendar === calender &&
      selectedUserName === username &&
      permission === 'none'
    ) {
      setOpenNotification(true);
      setNotificationMessage(noneAccessErrorMessage);
      return;
    }
    onSave(
      permissionSuccess,
      false,
      emptyFunction,
      putCalenderPermission,
      payload,
      '',
      () => {
        fetchCalendarDataOfParticularMonth(getCurrentYear, getCurrentMonth);
      },
      handleClose,
      setOpenNotification,
      setNotificationMessage,
      dispatch,
      setLoadingButton,
      loadingButton
    );
  };

  return (
    <>
      <Dialog open={open} PaperProps={PaperProps}>
        {getDialogHeading('Permission', handleClose)}
        <GridForm container>
          <FormInputDropdown
            name="calender"
            type="text"
            control={control}
            options={calendarDropDownList?.calendars}
            isNameHasValue={true}
            isPropName={true}
            nameProperKey="calendarId"
            propName="name"
            label="Calenders"
            helperText={errors?.calender?.message}
            errors={!!errors.calender}
          />
          <FormInputDropdown
            name="username"
            type="text"
            control={control}
            options={usersReferencedata}
            isNameHasValue={true}
            nameProperKey="username"
            label="Users"
            isPropName={true}
            propName="username"
            helperText={errors?.username?.message}
            errors={!!errors.username}
          />
          <FormInputDropdown
            name="permission"
            type="text"
            control={control}
            options={permissionReferenceData}
            label="Permission"
            helperText={errors?.permission?.message}
            errors={!!errors.permission}
          />
          {renderSaveCancelButtons(
            handleClose,
            handleSubmit(onSubmit),
            loadingButton
          )}
        </GridForm>
      </Dialog>
      <NotificationPopup />
    </>
  );
};

export default PermissionDialog;
