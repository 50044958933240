import { createSlice } from '@reduxjs/toolkit';
import {
  _setAnnouncementPaginationData,
  _setAnnouncementPreviousKey,
} from './announcementAction';

const initialState = {
  announcementPaginationData: [],
  announcementPreviousKey: '',
};

export const announcementPaginationDataSlice = createSlice({
  name: 'delta',
  initialState,
  reducers: {
    setAnnouncementPaginationData: _setAnnouncementPaginationData,
    setAnnouncementPreviousKey: _setAnnouncementPreviousKey,
    resetAnnouncementPaginationData: () => initialState,
  },
});

export const {
  setAnnouncementPaginationData,
  setAnnouncementPreviousKey,
  resetAnnouncementPaginationData,
} = announcementPaginationDataSlice.actions;

export default announcementPaginationDataSlice.reducer;
