import React from 'react';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import { getDataGridHeading } from 'utils/commonUtils';
import TutoringTab from './tutoringTabs/TutoringTab';
import { Container } from 'components/Ui/styled';

const TutoringComponent = () => {
  const handleBlockOutATime = () => {};
  return (
    <>
      <MDBox>
        <Card>
          {getDataGridHeading(
            'Tutoring Sessions',
            'Block Out A Time',
            handleBlockOutATime
          )}
          <Container>
            <TutoringTab />
          </Container>
        </Card>
      </MDBox>
    </>
  );
};

export default TutoringComponent;
