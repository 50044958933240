import { createSlice } from '@reduxjs/toolkit';
import initialState from './studentNoteInitialState';
import {
  _getStudentNote,
  _setWeek,
  _setLabel,
  _setNote,
  _setInCompliance,
} from './studentNoteAction';

export const studentNoteSlice = createSlice({
  name: 'delta',
  initialState,
  reducers: {
    getStudentNote: _getStudentNote,
    setWeek: _setWeek,
    setLabel: _setLabel,
    setNote: _setNote,
    setInCompliance: _setInCompliance,
  },
});

export const { getStudentNote, setWeek, setLabel, setNote, setInCompliance } =
  studentNoteSlice.actions;

export default studentNoteSlice.reducer;
