import React, { useState, useEffect } from 'react';
import { PaperPropsWithoutScrollbar, intialPageSize } from 'constants';
import Dialog from '@mui/material/Dialog';
import { GridForm } from 'components/Ui/styled';
import {
  getDialogHeading,
  showBackdropLoader,
  useNotification,
} from 'utils/commonUtils';
import {
  calendarAccessColumns,
  getCalendarAccessDataGrid,
  getCalendarAccessList,
} from './CalendarAccessDataGrid';
import { getCalendarsDetails } from 'examples/Calendar/services/calendarsApi';
import MDBox from 'components/MDBox';

const CalendarAccessDialog = (props) => {
  const {
    open,
    setOpen,
    permissionCalendarId,
    setPermissionCalendarId,
    isPermissionCalendarLoading,
    setIsPermissionCalendarLoading,
  } = props;

  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();

  const [calendarName, setCalendarName] = useState(null);
  const [calendarAccess, setCalendarAccess] = useState({
    isLoading: false,
    rows: [],
    pageSize: intialPageSize,
    page: 1,
  });

  const handleClose = () => {
    setPermissionCalendarId(null);
    setOpen(false);
  };

  useEffect(() => {
    if (permissionCalendarId) {
      fetchCalendarAccess();
    }
  }, [permissionCalendarId]);

  const fetchCalendarAccess = async () => {
    setCalendarAccess({ ...calendarAccess, isLoading: true });
    try {
      const calendarPermissions = await getCalendarsDetails(
        permissionCalendarId
      );
      setCalendarName(calendarPermissions?.calendar?.name);
      setCalendarAccess({ ...calendarAccess, isLoading: false });
      setIsPermissionCalendarLoading(false);
      setCalendarAccess((previous) => ({
        ...previous,
        rows: getCalendarAccessList(calendarPermissions),
      }));
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
      setCalendarAccess({ ...calendarAccess, isLoading: false });
    }
  };

  return (
    <>
      <Dialog
        open={open}
        PaperProps={PaperPropsWithoutScrollbar}
        data-testid="calendar-access-dialog"
      >
        {isPermissionCalendarLoading ? (
          showBackdropLoader(isPermissionCalendarLoading)
        ) : (
          <>
            {getDialogHeading(`${calendarName} Access`, handleClose)}
            <GridForm container>
              {getCalendarAccessDataGrid(
                calendarAccess,
                calendarAccessColumns,
                setCalendarAccess
              )}
            </GridForm>
            <MDBox mb={5}></MDBox>
          </>
        )}
      </Dialog>
      <NotificationPopup />
    </>
  );
};
export default CalendarAccessDialog;
