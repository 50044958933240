import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { MassBox } from 'components/Ui/styled';
import { DialogStyle, intialPageSize } from 'constants';
import { getSharedFolderVersionsListData } from './SharedFolderVersionsDataGrid';
import { getSharedFolderVersions } from 'layouts/documentManagement/services/documentManagementApi';
import {
  getDialogHeading,
  showBackdropLoader,
  useNotification,
  emptyFunction,
  pageSizeChangeHandler,
} from 'utils/commonUtils';
import { sharedFoldersColumns } from '../sharedFolders/SharedFolderDataGrid';
import DeltaDataGrid from 'utils/CommonDataGrid/CommonDataGrid';

const SharedFolderVersions = (props) => {
  const {
    open,
    setOpen,
    sharedFolderId,
    setSharedFolderId,
    loading,
    setLoading,
  } = props;

  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();

  const [sharedFolderVersions, setSharedFolderVersions] = useState({
    isLoading: false,
    rows: [],
    pageSize: intialPageSize,
  });

  useEffect(() => {
    if (open) {
      fetchSharedFolderVersions();
    }
  }, [open]);

  const fetchSharedFolderVersions = async () => {
    setSharedFolderVersions({ ...sharedFolderVersions, isLoading: true });
    try {
      const sharedFolderData = await getSharedFolderVersions(sharedFolderId);
      setLoading(false);
      setSharedFolderVersions({
        ...sharedFolderVersions,
        isLoading: false,
      });
      setSharedFolderVersions((prev) => ({
        ...prev,
        rows: getSharedFolderVersionsListData(sharedFolderData),
      }));
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
      setSharedFolderVersions({
        ...sharedFolderVersions,
        isLoading: false,
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSharedFolderId(null);
  };

  const pageSizeChange = pageSizeChangeHandler(setSharedFolderVersions);

  return (
    <>
      {loading ? (
        showBackdropLoader(loading)
      ) : (
        <>
          <Dialog open={open} PaperProps={DialogStyle}>
            {getDialogHeading('Shared folder Versions', handleClose)}
            <MassBox>
              <DeltaDataGrid
                listData={sharedFolderVersions}
                columns={sharedFoldersColumns(
                  true,
                  false,
                  emptyFunction,
                  emptyFunction,
                  emptyFunction,
                  emptyFunction,
                  emptyFunction,
                  emptyFunction,
                  emptyFunction,
                  emptyFunction,
                  emptyFunction
                )}
                setListData={pageSizeChange}
              />
            </MassBox>
          </Dialog>
          <NotificationPopup />
        </>
      )}
    </>
  );
};

export default SharedFolderVersions;
