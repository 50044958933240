import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import { GridForm, ButtonIcon, PButton } from 'components/Ui/styled';
import { useSelector, useDispatch } from 'react-redux';
import { postCalendar, updateCalendar } from '../services/CalendarApi';
import { removePermissions, setNewCalendar } from '../store/AdminSlice';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  renderSaveCancelButtons,
  useNotification,
  onSave,
  getDialogHeading,
  fieldValidation,
} from 'utils/commonUtils';
import { calendarSuccess, PaperPropsCheck, characterLimit } from 'constants';
import Button from '@mui/material/Button';
import AddCalendarPermissionDialog from './AddCalendarPermissionDialog';
import ListItems from 'components/Common/ListItems';
import Grid from '@mui/material/Grid';
import RemoveIcon from '@mui/icons-material/Remove';

const calendarSchema = yup.object().shape({
  name: fieldValidation(characterLimit),
});

const CalendarDialog = (props) => {
  const dispatch = useDispatch();
  const {
    openCalendar,
    setOpenCalendar,
    fetchCalendarData,
    usersReferencedata,
  } = props;

  const [loadingButton, setLoadingButton] = useState(false);
  const [openPermission, setOpenPermission] = useState(false);

  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();

  const Calendars = useSelector((state) => state.CalenderAdmin);
  const { name, calendarId, newCalendar, permissions } = Calendars;

  const defaultValue = {
    name,
    permissions,
    calendarId,
  };
  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
    resolver: yupResolver(calendarSchema),
  });

  const { handleSubmit, control, register, reset, formState } = useFunction;
  const { errors } = formState;

  useEffect(() => {
    reset(defaultValue);
  }, [defaultValue.name, defaultValue.permissions, defaultValue.calendarId]);

  const handleClose = () => {
    setOpenCalendar(false);
  };

  const handleAddPermission = () => {
    setOpenPermission(true);
  };

  const handleRemovePermission = (item) => {
    dispatch(removePermissions(item));
  };

  const onSubmit = () => {
    const payload = {
      calendar: {
        name,
        permissions,
      },
    };
    onSave(
      calendarSuccess,
      newCalendar,
      postCalendar,
      updateCalendar,
      payload,
      calendarId,
      fetchCalendarData,
      handleClose,
      setOpenNotification,
      setNotificationMessage,
      dispatch,
      setLoadingButton,
      loadingButton
    );
  };

  return (
    <>
      <Dialog open={openCalendar} PaperProps={PaperPropsCheck}>
        {getDialogHeading('Calendar', handleClose)}
        <GridForm container>
          <FormInputText
            name="name"
            type="text"
            control={control}
            label="Calendar"
            helperText={errors?.name?.message}
            errors={!!errors.name}
            register={register('name', {
              onChange: (e) => dispatch(setNewCalendar(e.target.value)),
            })}
          />
          {permissions?.map((item, index) => {
            const { username, permission } = item;
            const title = `UserName: ${username}, Permission: ${permission}`;
            return (
              <Grid key={index} container spacing={1}>
                <Grid item xs={12} sm={10}>
                  <ListItems title={title} />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <ButtonIcon onClick={() => handleRemovePermission(item)}>
                    <RemoveIcon />
                  </ButtonIcon>
                </Grid>
              </Grid>
            );
          })}
          {newCalendar && (
            <PButton onClick={() => handleAddPermission()} variant="text">
              + Add Permission
            </PButton>
          )}
          {renderSaveCancelButtons(
            handleClose,
            handleSubmit(onSubmit),
            loadingButton
          )}
        </GridForm>
      </Dialog>
      <AddCalendarPermissionDialog
        open={openPermission}
        setOpen={setOpenPermission}
        usersReferencedata={usersReferencedata}
      />
      <NotificationPopup />
    </>
  );
};

export default CalendarDialog;
