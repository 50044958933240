import { createSlice } from '@reduxjs/toolkit';
import {
  _setNewCalendar,
  _setPermissions,
  _removePermissions,
  _updateCalendarData,
} from './AdminAction';
import initialState from './AdminInitialState';

export const calenderSlice = createSlice({
  name: 'calenderAdmin',
  initialState,
  reducers: {
    setNewCalendar: _setNewCalendar,
    setPermissions: _setPermissions,
    removePermissions: _removePermissions,
    updateCalendarData: _updateCalendarData,
  },
});

export const {
  setNewCalendar,
  setPermissions,
  removePermissions,
  updateCalendarData,
} = calenderSlice.actions;

export default calenderSlice.reducer;
