import { API } from 'aws-amplify';
import { ATTENDANCE_AUDIT_BASE_URL_APPEND } from 'constants';

export const getAttendanceAudits = async () => {
  const attendanceAudits = await API.get(
    'delta-api',
    `${ATTENDANCE_AUDIT_BASE_URL_APPEND}attendanceAudits`
  );
  return attendanceAudits;
};

export const getAttendanceAuditVersions = async (attendanceAuditId) => {
  const attendanceAuditVersions = await API.get(
    'delta-api',
    `${ATTENDANCE_AUDIT_BASE_URL_APPEND}attendanceAudits/${attendanceAuditId}/versions`
  );
  return attendanceAuditVersions;
};

export const getAttendanceAuditsArchive = async (
  pageSize,
  paginationKey,
  oldestFirst
) => {
  const attendanceAuditsArchive = await API.get(
    'delta-api',
    `${ATTENDANCE_AUDIT_BASE_URL_APPEND}attendanceAuditsArchive?`,
    {
      headers: {},
      queryStringParameters: {
        limit: pageSize,
        paginationKey: paginationKey,
        oldestFirst: oldestFirst,
      },
    }
  );
  return attendanceAuditsArchive;
};

export const postAttendanceAuditsArchive = async (attendanceAuditId) => {
  await API.post(
    'delta-api',
    `${ATTENDANCE_AUDIT_BASE_URL_APPEND}attendanceAudits/${attendanceAuditId}/archive`
  );
};

export const postAttendanceAuditsUnarchive = async (attendanceAuditId) => {
  await API.post(
    'delta-api',
    `${ATTENDANCE_AUDIT_BASE_URL_APPEND}attendanceAudits/${attendanceAuditId}/restore`
  );
};

export const postAttendanceAuditUserAccess = async (
  attendanceAuditId,
  username
) => {
  await API.post(
    'delta-api',
    `${ATTENDANCE_AUDIT_BASE_URL_APPEND}attendanceAudits/${attendanceAuditId}/userAccess/${username}`
  );
};

export const deleteAttendanceAuditUserAccess = async (
  attendanceAuditId,
  username
) => {
  await API.del(
    'delta-api',
    `${ATTENDANCE_AUDIT_BASE_URL_APPEND}attendanceAudits/${attendanceAuditId}/userAccess/${username}`
  );
};

export const putReplaceAccess = async (payload, attendanceAuditId) => {
  const myInit = {
    body: payload,
  };
  await API.put(
    'delta-api',
    `${ATTENDANCE_AUDIT_BASE_URL_APPEND}attendanceAudits/${attendanceAuditId}/userAccess`,
    myInit
  );
};

export const getAttendanceAudit = async (attendanceAuditId) => {
  const attendanceAudit = await API.get(
    'delta-api',
    `${ATTENDANCE_AUDIT_BASE_URL_APPEND}attendanceAudits/${attendanceAuditId}`
  );
  return attendanceAudit;
};

export const postAttendanceAudit = async (payload) => {
  const myPayload = {
    body: payload,
  };
  await API.post(
    'delta-api',
    `${ATTENDANCE_AUDIT_BASE_URL_APPEND}attendanceAudits`,
    myPayload
  );
};

export const putAttendanceAudit = async (payload, attendanceAuditId) => {
  const myPayload = {
    body: payload,
  };
  await API.put(
    'delta-api',
    `${ATTENDANCE_AUDIT_BASE_URL_APPEND}attendanceAudits/${attendanceAuditId}`,
    myPayload
  );
};
