import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import { HeadingMuiTypography, GridWrapper } from 'components/Ui/styled';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import { FormInputDate } from 'components/Common/Forms/FormInputDate';

const GeneralInfoTab = () => {
  const defaultValue = {
    firstName: '',
    lastName: '',
    homeAddress: '',
    dateOfBirth: '',
    personalPhoneNumber: '',
    personalEmailAddress: '',
    ecFirstName: '',
    ecLastName: '',
    ecHomeAddress: '',
    ecPhoneNumber: '',
    ecRelation: '',
    ecEmailAddress: '',
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
  });

  const { handleSubmit, reset, control, register, formState } = useFunction;
  const { errors } = formState;

  useEffect(() => {
    reset(defaultValue);
  }, [
    defaultValue.firstName,
    defaultValue.lastName,
    defaultValue.homeAddress,
    defaultValue.dateOfBirth,
    defaultValue.personalPhoneNumber,
    defaultValue.personalEmailAddress,
    defaultValue.ecFirstName,
    defaultValue.ecLastName,
    defaultValue.ecHomeAddress,
    defaultValue.ecPhoneNumber,
    defaultValue.ecRelation,
    defaultValue.ecEmailAddress,
  ]);

  const onSubmit = (data) => {
    console.log('data', data);
  };

  return (
    <>
      <HeadingMuiTypography variant="h6">
        Personal Information
      </HeadingMuiTypography>
      <GridWrapper container spacing={2}>
        <Grid container item xs={12} md={6}>
          <Grid container item spacing={2}>
            <Grid item xs={12} md={6}>
              <FormInputText
                name="firstName"
                type="text"
                control={control}
                label="First Name"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInputText
                name="lastName"
                type="text"
                control={control}
                label="Last Name"
              />
            </Grid>
          </Grid>
          <FormInputText
            name="homeAddress"
            type="text"
            control={control}
            label="Home Address"
          />
        </Grid>
        <Grid container item xs={12} md={6}>
          <Grid container item spacing={2}>
            <Grid item xs={12} md={6}>
              <FormInputDate
                name="dateOfBirth"
                control={control}
                label="Date Of Birth"
                helperText={errors?.dateOfBirth?.message}
                errors={!!errors.dateOfBirth}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInputText
                name="personalPhoneNumber"
                type="text"
                control={control}
                label="Personal Phone Number"
              />
            </Grid>
          </Grid>
          <FormInputText
            name="personalEmailAddress"
            type="text"
            control={control}
            label="Personal Email Address"
          />
        </Grid>
      </GridWrapper>
      <HeadingMuiTypography variant="h6">
        Emergency Contacts
      </HeadingMuiTypography>
      <GridWrapper container spacing={2}>
        <Grid container item xs={12} md={6}>
          <Grid container item spacing={2}>
            <Grid item xs={12} md={6}>
              <FormInputText
                name="ecFirstName"
                type="text"
                control={control}
                label="First Name"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInputText
                name="ecLastName"
                type="text"
                control={control}
                label="Last Name"
              />
            </Grid>
          </Grid>
          <FormInputText
            name="ecHomeAddress"
            type="text"
            control={control}
            label="Home Address"
          />
        </Grid>
        <Grid container item xs={12} md={6}>
          <Grid container item spacing={2}>
            <Grid item xs={12} md={6}>
              <FormInputText
                name="ecPhoneNumber"
                type="text"
                control={control}
                label="Phone Number"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInputText
                name="ecRelation"
                type="text"
                control={control}
                label="Relation"
              />
            </Grid>
          </Grid>
          <FormInputText
            name="ecEmailAddress"
            type="text"
            control={control}
            label="Email Address"
          />
        </Grid>
      </GridWrapper>
    </>
  );
};

export default GeneralInfoTab;
