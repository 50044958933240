export const _setBulkAssets = (state, action) => {
  state.bulkAssets = [...state.bulkAssets, action.payload];
};

export const _removeAsset = (state, action) => {
  return {
    ...state,
    bulkAssets: state.bulkAssets.filter(
      (asset) => asset.id !== action.payload.id
    ),
  };
};
