import { createSlice } from '@reduxjs/toolkit';
import initialState from './userLocationInitialState';
import { _setIsUserLocation } from './userLocationAction';

export const userLocationSlice = createSlice({
  name: 'delta/userLocation',
  initialState,
  reducers: {
    setIsUserLocation: _setIsUserLocation,
    resetUserLocation: () => initialState,
  },
});

export const { setIsUserLocation, resetUserLocation } =
  userLocationSlice.actions;

export default userLocationSlice.reducer;
