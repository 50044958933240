import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';

export const FormInputText = (props) => {
  const {
    name,
    control,
    label,
    type,
    shrink,
    multiline,
    rows,
    disabled,
    register,
    minValue,
    maxValue,
    errors,
    helperText,
    placeholder,
    readOnly,
    onKeyDown,
    endAdornment,
    testId,
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      register={register}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <TextField
          onChange={onChange}
          data-testid={testId || label}
          value={value == null ? '' : value}
          disabled={disabled}
          label={label}
          type={type}
          variant="standard"
          multiline={multiline}
          error={errors}
          helperText={helperText}
          placeholder={placeholder}
          rows={rows}
          InputLabelProps={{
            shrink: shrink,
          }}
          InputProps={{
            inputProps: { min: minValue, max: maxValue },
            readOnly: readOnly,
            onKeyDown: onKeyDown,
            endAdornment: endAdornment,
          }}
          style={{ width: '100%' }}
        />
      )}
    />
  );
};
