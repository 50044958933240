import { formatDate } from 'utils/commonUtils';
import { renderTrackingAction } from './TrackingAction';
import { renderCellExpand } from 'components/Common/GridCell/GridCellExpand';
import { TypographyLink } from 'components/Ui/styled';

export const trackingColumns = (
  handleRenameTracker,
  handleRenameColumn,
  handleStatusOption,
  handleColumns
) => [
  {
    field: 'action',
    headerName: 'Action',
    sortable: false,
    disableColumnMenu: true,
    width: 150,
    renderCell: (params) =>
      renderTrackingAction(
        params,
        handleRenameTracker,
        handleRenameColumn,
        handleStatusOption,
      ),
  },
  {
    headerName: 'Name',
    field: 'name',
    renderCell: renderCellExpand,
    width: 300,
  },
  {
    headerName: 'Description',
    field: 'columns',
    renderCell: renderCellExpand,
    flex: 1,
  },
  {
    headerName: 'Last Modified',
    field: 'updatedAt',
    width: 120,
  },
  {
    headerName: 'Columns',
    field: 'noOfColumns',
    renderCell: (params) => {
      return (
        <TypographyLink variant="h6" onClick={() => handleColumns(params)}>
          {params.row.noOfColumns}
        </TypographyLink>
      );
    },
    align: 'center',
    sortable: false,
    disableColumnMenu: true,
    width: 100,
  },
];

export const getTrackingListData = (trackingData) => {
  const items = trackingData?.trackers;
  if (!items || items.length === 0) {
    return [];
  }
  return items.map(
    ({
      trackerId,
      name,
      columns,
      statusOptions,
      hiddenColumnsByGrade,
      version,
      updatedAt,
    }) => ({
      id: trackerId,
      name,
      statusOptions,
      hiddenColumnsByGrade,
      version,
      updatedAt: formatDate(updatedAt),
      columns: columns.join(', '),
      arrayOfColumns: columns,
      noOfColumns: columns.length,
    })
  );
};
