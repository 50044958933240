import React, { useState } from 'react';
import { useMaterialUIController } from 'context';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import CalendarRoot from 'examples/Calendar/CalendarRoot';
import { showBackdropLoader } from 'utils/commonUtils';
import { useNavigate } from 'react-router-dom';
import { setIsDashboardCalendar } from 'layouts/applications/calendar/store/CalenderSlice';
import { useDispatch } from 'react-redux';
import { getCalenderEvent } from 'layouts/applications/calendar/services/calendarEventsApi';
import { getCalendarEvent } from 'layouts/applications/calendar/components/CalendarEvents/store/calenderEventSlice';
import { LightTooltip } from 'components/Ui/styled';
import CircularProgress from '@mui/material/CircularProgress';
import AddEventDialog from 'layouts/applications/calendar/components/CalendarEvents/AddEvent';

const DashboardCalendar = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { calendarEvents, eventLoading, handleEvents, currentCalenderId } =
    props;

  const [tooltip, setTooltip] = useState(false);
  const [eventDetailsFlag, setEventDetailsFlag] = useState(false);
  const [eventDetailsLoading, setEventDetailsLoading] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const handleEventMouseEnter = async (clickInfo) => {
    setEventDetailsFlag(true);
    setTooltip(true);
    setEventDetailsLoading(true);
    const { event } = await getCalenderEvent(
      currentCalenderId,
      clickInfo.event._def.extendedProps.eventId
    );
    dispatch(getCalendarEvent(event));
    setEventDetailsLoading(false);
  };

  const handleEventMouseLeave = () => {
    setEventDetailsFlag(false);
    setTooltip(false);
  };

  const handleClick = () => {
    navigate('/applications/calendar');
    dispatch(setIsDashboardCalendar(true));
  };

  return (
    <>
      {showBackdropLoader(eventLoading)}
      <CalendarRoot p={2} ownerState={{ darkMode }}>
        <FullCalendar
          headerToolbar={{
            left: 'title',
            center: '',
            right: 'prev next',
          }}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          events={calendarEvents?.data}
          displayEventTime={false}
          datesSet={(arg) => {
            handleEvents(arg);
          }}
          selectable={true}
          select={handleClick}
          eventMouseEnter={handleEventMouseEnter}
          eventMouseLeave={handleEventMouseLeave}
          initialView="dayGridMonth"
          height="100%"
        />
      </CalendarRoot>
      {tooltip && (
        <>
          <LightTooltip
            title={
              eventDetailsLoading ? (
                <CircularProgress color="inherit" />
              ) : (
                <AddEventDialog eventDetailsFlag={eventDetailsFlag} />
              )
            }
            open={tooltip}
            placement="left-end"
          >
            <div />
          </LightTooltip>
        </>
      )}
    </>
  );
};

export default DashboardCalendar;
