import { createSlice } from '@reduxjs/toolkit';
import initialState from './folderPathInitialState';
import { _setFolderPath, _resetFolderPath } from './folderPathAction';

export const folderPathSlice = createSlice({
  name: 'delta/folderPath',
  initialState,
  reducers: {
    setFolderPath: _setFolderPath,
    resetFolderPath: _resetFolderPath,
    //resetFolderPath: () => initialState,
  },
});

export const { setFolderPath, resetFolderPath } = folderPathSlice.actions;

export default folderPathSlice.reducer;
