import { createSlice } from '@reduxjs/toolkit';
import initialState from './calendarEventInitialState';
import {
  _setTitle,
  _setDescription,
  _setStart,
  _setEnd,
  _setStartTime,
  _setEndTime,
  _setAllDay,
  _setLocation,
  _setId,
  _newCalendarEvent,
  _getCalendarEvent,
  _setCategory,
  _setCategoryValue,
  _setCategoryId,
} from './calenderEventAction';

export const calendarEventSlice = createSlice({
  name: 'calendarEvent',
  initialState,
  reducers: {
    newCalendarEvent: _newCalendarEvent,
    getCalendarEvent: _getCalendarEvent,
    setTitle: _setTitle,
    setDescription: _setDescription,
    setStart: _setStart,
    setEnd: _setEnd,
    setStartTime: _setStartTime,
    setEndTime: _setEndTime,
    setAllDay: _setAllDay,
    setLocation: _setLocation,
    setId: _setId,
    setCategoryId: _setCategoryId,
    setCategoryValue: _setCategoryValue,
  },
});

export const {
  setCategoryId,
  newCalendarEvent,
  getCalendarEvent,
  setTitle,
  setDescription,
  setStart,
  setEnd,
  setStartTime,
  setEndTime,
  setAllDay,
  setLocation,
  setId,
  setCategoryValue,
} = calendarEventSlice.actions;

export default calendarEventSlice.reducer;
