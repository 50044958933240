import { API } from 'aws-amplify';
import { MY_PROFILE_BASE_URL_APPEND } from 'constants';

export const getMyProfile = async () => {
  const myProfile = await API.get(
    'delta-api',
    `${MY_PROFILE_BASE_URL_APPEND}me`
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data;
    });
  return myProfile;
};

export const createWatchProfile = async (payload) => {
  const myInit = {
    body: payload,
  };
  await API.post('delta-api', `${MY_PROFILE_BASE_URL_APPEND}watch`, myInit);
};

export const deleteWatchProfile = async (payload) => {
  const myInit = {
    body: payload,
  };
  await API.del('delta-api', `${MY_PROFILE_BASE_URL_APPEND}watch`, myInit);
};
