import { createSlice } from '@reduxjs/toolkit';
import {
  _setSharedFoldersArchivePagination,
  _setSharedFoldersArchivePaginationPreviousKey,
} from './sharedFoldersArchivePaginationAction';

const initialState = {
  sharedFoldersArchivePagination: [],
  sharedFoldersArchivePaginationPreviousKey: '',
};

export const sharedFoldersArchivePaginationSlice = createSlice({
  name: 'delta/sharedFoldersArchivePagination',
  initialState,
  reducers: {
    setSharedFoldersArchivePagination: _setSharedFoldersArchivePagination,
    setSharedFoldersArchivePaginationPreviousKey:
      _setSharedFoldersArchivePaginationPreviousKey,
    resetSharedFoldersArchivePagination: () => initialState,
  },
});

export const {
  setSharedFoldersArchivePagination,
  setSharedFoldersArchivePaginationPreviousKey,
  resetSharedFoldersArchivePagination,
} = sharedFoldersArchivePaginationSlice.actions;

export default sharedFoldersArchivePaginationSlice.reducer;
