import { rowsPerPage } from 'constants';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { getRowClassName, getRowHeight } from 'utils/commonUtils';

export const getStudentScoresDataGrid = (
  studentScores,
  columns,
  setStudentScores
) => {
  const { rows, pageSize, isLoading } = studentScores;

  return (
    <DataGridPro
      autoHeight
      getRowHeight={getRowHeight}
      rows={rows}
      columns={columns}
      pagination
      rowsPerPageOptions={rowsPerPage}
      pageSize={pageSize}
      getRowClassName={(params) => getRowClassName(params)}
      loading={isLoading}
      onPageSizeChange={(newPageSize) =>
        setStudentScores((prveState) => ({
          ...prveState,
          pageSize: newPageSize,
        }))
      }
    />
  );
};

export const studentScoresColumns = (isMaps, isOther) => [
  {
    headerName: 'Test Category',
    field: 'testCategory',
    width: 160,
  },
  {
    headerName: 'Test Name',
    field: 'testName',
    width: 300,
    hide: isMaps,
  },
  {
    headerName: 'Test Subcategory',
    field: 'testSubcategory',
    width: 180,
  },
  { headerName: 'School Year', field: 'schoolYear', width: 150 },
  {
    headerName: 'Rapid Guessing Percentage',
    field: 'rapidGuessingPercentage',
    width: 180,
    hide: isMaps,
  },
  { headerName: 'RIT Score', field: 'ritScore', width: 150, hide: isMaps },
  {
    headerName: 'Growth Projected',
    field: 'growthProjected',
    width: 150,
    hide: isMaps,
  },
  {
    headerName: 'Growth Observed',
    field: 'growthObserved',
    width: 150,
    hide: isMaps,
  },
  {
    headerName: 'Achievement Level',
    field: 'achievementLevel',
    width: 200,
    hide: isOther,
  },
  {
    headerName: 'Assessment Name',
    field: 'assessmentName',
    width: 200,
    hide: isOther,
  },
  {
    headerName: 'Level or Proficiency',
    field: 'levelOrProficiency',
    width: 200,
    hide: isOther,
  },
  {
    headerName: 'Score Result',
    field: 'scoreResult',
    width: 180,
    hide: isOther,
  },
];

export const getStudentScoresList = (selectedStudentScore) => {
  if (selectedStudentScore === null) {
    return [];
  }
  return selectedStudentScore?.map(({
    'Test Category': testCategory,
    'Test Name': testName,
    'Test Subcategory': testSubcategory,
    'School Year': schoolYear,
    'Rapid Guessing Percentage': rapidGuessingPercentage,
    'RIT Score': ritScore,
    'Growth - Projected': growthProjected,
    'Growth - Observed': growthObserved,
    'Achievement Level': achievementLevel,
    'Assessment Name': assessmentName,
    'Level or Proficiency': levelOrProficiency,
    'Score Result': scoreResult,
  }, index) => ({
    id: index,
    testCategory,
    testName,
    testSubcategory,
    schoolYear,
    rapidGuessingPercentage,
    ritScore,
    growthProjected,
    growthObserved,
    achievementLevel,
    assessmentName,
    levelOrProficiency,
    scoreResult,
  }));
};
