import { createSlice } from '@reduxjs/toolkit';
import { _setReportsForm } from './reportsAction';

const reportsSlice = createSlice({
  name: 'delta/Reports',
  initialState: null,
  reducers: {
    setReportsForm: _setReportsForm,
    resetReportsForm: () => null,
  },
});

export const { setReportsForm, resetReportsForm } = reportsSlice.actions;

export default reportsSlice.reducer;
