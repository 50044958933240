import { ButtonIcon, RoundedIconCloudUpload } from 'components/Ui/styled';
import { ActionWrapperBox } from 'components/Common/common.styled';

export const renderDocumentFolderAction = (
  params,
  handleUploadViewDocuments
) => {
  return (
    <ActionWrapperBox>
      <ButtonIcon onClick={() => handleUploadViewDocuments(params)}>
        <RoundedIconCloudUpload />
      </ButtonIcon>
    </ActionWrapperBox>
  );
};
