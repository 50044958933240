import { ActionWrapperBox } from 'components/Common/common.styled';
import { ButtonIcon } from 'components/Ui/styled';
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded';

export const renderAssignmentsAction = (params, handleUploadAssignments) => {
  return (
    <ActionWrapperBox>
      <ButtonIcon onClick={() => handleUploadAssignments(params)}>
        <CloudUploadRoundedIcon />
      </ButtonIcon>
    </ActionWrapperBox>
  );
};
