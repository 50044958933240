import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { GridForm } from 'components/Ui/styled';
import { useForm } from 'react-hook-form';
import FormInputDropdown from 'components/Common/Forms/FormInputDropDown';
import { useSelector, useDispatch } from 'react-redux';
import {
  PaperProps,
  permissionSuccess,
  permissionReferenceData,
} from 'constants';
import { putCalenderPermission } from '../services/CalendarApi';
import { displaySnackbar } from 'components/snackbar/store/SnackbarSlice';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  getDialogHeading,
  renderSaveCancelButtons,
  dropDownValidation,
  useNotification,
  getSnackBar,
} from 'utils/commonUtils';

const calenderPermissionSchema = yup.object().shape({
  calender: dropDownValidation,
  username: dropDownValidation,
  permission: dropDownValidation,
});

const CalenderPermissionDialog = (props) => {
  const dispatch = useDispatch();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const {
    open,
    setOpen,
    usersReferencedata,
    // fetchCalendarData,
    // fetchCalendarListData,
  } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const selectCalendarReferenceData = useSelector(
    (state) => state?.calendars?.calendars
  );

  const defaultValue = {
    calender: '',
    username: '',
    permission: '',
  };
  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
    resolver: yupResolver(calenderPermissionSchema),
  });
  const { handleSubmit, control, reset, register, formState } = useFunction;
  const { errors } = formState;
  const handleClose = () => {
    reset(defaultValue);
    setOpen(false);
  };
  const onSubmit = async (data) => {
    setLoadingButton(true);
    const { calender, username, permission } = data;
    const snackBar = getSnackBar(permissionSuccess);
    const payload = {
      permission: {
        calendarId: calender,
        username: username,
        permission: permission,
      },
    };
    await putCalenderPermission(payload)
      .then((response) => {
        dispatch(displaySnackbar(snackBar));
        // fetchCalendarData();
        // fetchCalendarListData();
        reset(defaultValue);
        setOpen(false);
      })
      .catch((e) => {
        setOpenNotification(true);
        setNotificationMessage(e.response.data.message);
      });
    setLoadingButton(false);
  };

  return (
    <>
      <Dialog open={open} PaperProps={PaperProps}>
        {getDialogHeading('Permission', handleClose)}
        <GridForm container>
          <FormInputDropdown
            name='calender'
            type='text'
            control={control}
            options={selectCalendarReferenceData}
            isNameHasValue={true}
            isPropName={true}
            nameProperKey='calendarId'
            propName='name'
            label='Calendars'
            helperText={errors?.calender?.message}
            errors={!!errors.calender}
          />
          <FormInputDropdown
            name='username'
            type='text'
            control={control}
            options={usersReferencedata}
            isNameHasValue={true}
            isPropName={true}
            nameProperKey='username'
            propName='username'
            label='Users'
            helperText={errors?.username?.message}
            errors={!!errors.username}
          />
          <FormInputDropdown
            name='permission'
            type='text'
            control={control}
            options={permissionReferenceData}
            label='Permission'
            helperText={errors?.permission?.message}
            errors={!!errors.permission}
          />
          {renderSaveCancelButtons(
            handleClose,
            handleSubmit(onSubmit),
            loadingButton
          )}
        </GridForm>
      </Dialog>
      <NotificationPopup />
    </>
  );
};

export default CalenderPermissionDialog;
