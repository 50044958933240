import React from 'react';
import { Controller } from 'react-hook-form';
import { Select, MenuItem } from '@mui/material';
import { ControlForm } from 'components/Ui/styled';
import { DropdownLabelText } from 'components/Ui/styled';

const MultiFormDropdownField = ({
  name,
  index,
  control,
  field,
  options,
  errors,
  label,
}) => {
  return (
    <ControlForm variant="standard">
      <DropdownLabelText primary={errors ? 'true' : undefined}>
        {label}
      </DropdownLabelText>
      <Controller
        name={`items[${index}].${name}`}
        control={control}
        defaultValue={field.name || ''}
        render={({ field: { onChange, value } }) => (
          <Select value={value} onChange={onChange}>
            {options?.map((option, i) => (
              <MenuItem key={i} value={option.id}>
                {option.value}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </ControlForm>
  );
};

export default MultiFormDropdownField;
