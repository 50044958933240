import { API, Auth } from 'aws-amplify';
import { ASSET_BASE_URL_APPEND } from 'layouts/inventory/constants';

export const getFundingType = async () => {
  const locationType = await API.get(
    'delta-api',
    `${ASSET_BASE_URL_APPEND}fundingTypes`
  );
  return locationType;
};

export const postFundingType = async (payload) => {
  const authToken = Auth.user.signInUserSession.accessToken.jwtToken;

  const myInit = {
    body: payload,
  };
  await API.post('delta-api', `${ASSET_BASE_URL_APPEND}fundingTypes`, myInit);
};

export const putFundingType = async (payload, id) => {
  const authToken = Auth.user.signInUserSession.accessToken.jwtToken;

  const myInit = {
    body: payload,
  };
  await API.put(
    'delta-api',
    `${ASSET_BASE_URL_APPEND}fundingTypes/${id}`,
    myInit
  );
};

export const deleteFundingType = async (id) => {
  await API.del('delta-api', `${ASSET_BASE_URL_APPEND}fundingTypes/${id}`, {});
};
