import { createSlice } from '@reduxjs/toolkit';
import { _setCategoryCalenderId } from './categoryAdminAction';

const initialState = {
  calenderId: '',
};

export const categoryAdminCalenderIdSlice = createSlice({
  name: 'categoryAdminCalenderIdSlice',
  initialState,
  reducers: {
    setCategoryCalenderId: _setCategoryCalenderId,
    resetCategoryCalenderId: () => initialState,
  },
});

export const { setCategoryCalenderId, resetCategoryCalenderId } =
  categoryAdminCalenderIdSlice.actions;

export default categoryAdminCalenderIdSlice.reducer;
