import { ActionWrapperBox } from 'components/Common/common.styled';
import { ButtonIcon } from 'components/Ui/styled';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export const CategoryAction = (props) => {
  const { params, updateCalendar, deleteCalendar } = props;
  const editClickHandler = () => {
    updateCalendar(params);
  };
  const deleteClickHandler = () => {
    deleteCalendar(params);
  };
  return (
    <ActionWrapperBox>
      <ButtonIcon onClick={editClickHandler}>
        <EditIcon />
      </ButtonIcon>
      <ButtonIcon onClick={deleteClickHandler}>
        <DeleteIcon />
      </ButtonIcon>
    </ActionWrapperBox>
  );
};
