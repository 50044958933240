import React, { useEffect } from 'react';
import { Dialog } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import FormInputDropdown from 'components/Common/Forms/FormInputDropDown';
import { GridForm } from 'components/Ui/styled';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  calendarPermissionReferenceData,
  PaperProps,
  duplicatesError,
  characterLimit,
} from 'constants';
import {
  renderSaveCancelButtons,
  dropDownValidation,
  fieldValidation,
  getDialogHeading,
  useNotification,
} from 'utils/commonUtils';
import { setPermissions } from '../store/AdminSlice';

const addCalendarPermissionSchema = yup.object().shape({
  username: fieldValidation(characterLimit),
  permission: dropDownValidation,
});

const AddCalendarPermissionDialog = (props) => {
  const dispatch = useDispatch();
  const { open, setOpen, usersReferencedata } = props;

  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();

  const calenderAdmin = useSelector((state) => state.CalenderAdmin);

  const defaultValue = {
    username: '',
    permission: '',
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
    resolver: yupResolver(addCalendarPermissionSchema),
  });
  const { handleSubmit, reset, control, formState } = useFunction;
  const { errors } = formState;

  const userNameOptions = usersReferencedata?.map((item) => ({
    id: item.username,
    value: item.username,
  }));

  useEffect(() => {
    reset(defaultValue);
  }, [defaultValue.username, defaultValue.permission]);

  const handleClose = () => {
    setOpen(false);
    reset(defaultValue);
  };

  const onSubmit = (data) => {
    const { username, permission } = data;
    const permissions = {
      username: username,
      permission: permission,
    };
    if (calenderAdmin?.permissions.some((item) => item.username === username)) {
      setOpenNotification(true);
      setNotificationMessage(duplicatesError);
      return;
    }
    dispatch(setPermissions(permissions));
    setOpen(false);
    reset(defaultValue);
  };

  return (
    <>
      <Dialog open={open} PaperProps={PaperProps}>
        {getDialogHeading('Add Permission', handleClose)}
        <GridForm container spacing={2}>
          <Grid item xs={12}>
            <FormInputDropdown
              name="username"
              type="text"
              control={control}
              options={userNameOptions}
              label="Username"
              helperText={errors?.username?.message}
              errors={!!errors.username}
            />
            <FormInputDropdown
              name="permission"
              type="text"
              control={control}
              options={calendarPermissionReferenceData}
              label="Permission"
              helperText={errors?.permission?.message}
              errors={!!errors.permission}
            />
          </Grid>
          <Grid item xs={12}>
            {renderSaveCancelButtons(handleClose, handleSubmit(onSubmit))}
          </Grid>
        </GridForm>
      </Dialog>
      <NotificationPopup />
    </>
  );
};

export default AddCalendarPermissionDialog;
