import { API, Auth } from 'aws-amplify';

export const getCategory = async (payload) => {
  const Category = await API.get(
    'delta-api',
    `calendar/categories/${payload}`
  );
  return Category;
};

export const postCategory = async (category,calendarID) => {
  const authToken = Auth.user.signInUserSession.accessToken.jwtToken;

  const myInit = {
    body: { category },
  };

  await API.post(
    'delta-api',
    `calendar/categories/${calendarID}`,
    myInit
  );
};

export const getCategoryId = async (categoryId, calendarId) => {
  const CategoryId = await API.get(
    'delta-api',
    `calendar/categories/${calendarId}/${categoryId}`
  );
  return CategoryId;
};

export const putCategory = async (category,calendarID) => {
  const authToken = Auth.user.signInUserSession.accessToken.jwtToken;
  const myInit = {
    body: { category },
  };
  await API.put(
    'delta-api',
    `calendar/categories/${calendarID}/${category.categoryId}`,
    myInit
  );
};

export const deleteCategory = async (category,calendarID) => {
  const authToken = Auth.user.signInUserSession.accessToken.jwtToken;
  

  await API.del(
    'delta-api',
    `calendar/categories/${calendarID}/${category.categoryId}`,
   
  );
};
