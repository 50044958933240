import { createSlice } from '@reduxjs/toolkit';
import {
  _setIsNewBuilding,
  _setBuildingForm,
  _setBuilding,
} from './BuildingAction';
import initialState from './BuildingsInitialState';

export const buildingSlice = createSlice({
  name: 'delta/building',
  initialState,
  reducers: {
    setIsNewBuilding: _setIsNewBuilding,
    setBuildingForm: _setBuildingForm,
    setBuilding: _setBuilding,
    resetBuildingForm: () => initialState,
  },
});

export const {
  setIsNewBuilding,
  setBuildingForm,
  setBuilding,
  resetBuildingForm,
} = buildingSlice.actions;

export default buildingSlice.reducer;
