import { API } from 'aws-amplify';
import { SYSTEM_CONFIGURATION_BASE_URL_APPEND } from 'constants';

export const getSystemConfigurations = async () => {
  const systemConfigurations = await API.get(
    'delta-api',
    `${SYSTEM_CONFIGURATION_BASE_URL_APPEND}`
  );
  return systemConfigurations;
};

export const putSystemConfigurations = async (payload) => {
  const myPayload = {
    body: payload,
  };
  await API.put(
    'delta-api',
    `${SYSTEM_CONFIGURATION_BASE_URL_APPEND}`,
    myPayload
  );
};
