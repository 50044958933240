import React from 'react';
import { NoInfoGrid, TypographyText } from 'components/Ui/styled';

const NoInfo = (props) => {
  const { message } = props;

  return (
    <NoInfoGrid container data-testid="no-info-container">
      <TypographyText>{message}</TypographyText>
    </NoInfoGrid>
  );
};

export default NoInfo;
