import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { HeadingBox } from 'components/Ui/styled';
import Card from '@mui/material/Card';

const MyStudents = () => {
  const navigate = useNavigate();

  const handleMyStudents = () => {
    navigate('/my-students');
  };

  return (
    <Card>
      <HeadingBox>
        <Button variant='text' onClick={handleMyStudents}>
          My Students
        </Button>
      </HeadingBox>
    </Card>
  );
};

export default MyStudents;
