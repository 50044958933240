import { createSlice } from '@reduxjs/toolkit';

const renderListSlice = createSlice({
  name: 'delta/renderList',
  initialState: null,
  reducers: {
    getRenderedList: (state, action) => action.payload,
    resetRenderedList: () => null,
  },
});

export const { getRenderedList, resetRenderedList } = renderListSlice.actions;

export default renderListSlice.reducer;
