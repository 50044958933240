export const _setTitle = (state, action) => {
  state.title = action.payload;
};

export const _setDescription = (state, action) => {
  state.description = action.payload;
};

export const _setStart = (state, action) => {
  state.startDate = action.payload;
};

export const _setEnd = (state, action) => {
  state.endDate = action.payload;
};

export const _setStartTime = (state, action) => {
  state.startTime = action.payload;
};

export const _setEndTime = (state, action) => {
  state.endTime = action.payload;
};

export const _setAllDay = (state, action) => {
  state.isAllDayEvent = action.payload;
};

export const _setLocation = (state, action) => {
  state.location = action.payload;
};

export const _setId = (state, action) => {
  state.id = action.payload;
};

export const _setCategoryId= (state, action) => {
  state.categoryId = action.payload;
};

export const _setCategoryValue = (state, action) => {
  state.category = action.payload;
};

export const _newCalendarEvent = (state, action) => action.payload;

export const _getCalendarEvent = (state, action) => action.payload;
