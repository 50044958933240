const initialState = {
  newEvent: false,
  title: null,
  description: null,
  startDate: '',
  startTime: '',
  endDate: '',
  endTime: '',
  location: null,
  isAllDayEvent: '',
  categoryId: '',
  id: '',
  category: [],
};

export default initialState;
