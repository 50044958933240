import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { notificationHeader } from 'constants';

function NotificationMessagePopup(props) {
  const { message, openPopUp, setNotificationMessage, setOpenNotification } =
    props;

  const handleOk = () => {
    setOpenNotification(false);
    setNotificationMessage('');
  };
  return (
    <Dialog fullWidth={true} maxWidth={'xs'} open={openPopUp}>
      <DialogTitle>{notificationHeader}</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          data-testid="notification-message"
        >
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleOk}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NotificationMessagePopup;
