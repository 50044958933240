import React from 'react';
import Box from '@mui/material/Box';
import { noInfoTasks } from 'constants';
import ListItems from 'components/Common/ListItems';
import { useRenderNoInfo } from 'utils/commonUtils';

const DashboardTasks = (props) => {
  const { taskList } = props;

  const noInfoComponent = useRenderNoInfo(taskList?.items, noInfoTasks);

  return (
    <>
      {noInfoComponent}
      {taskList?.items?.map((item, index) => {
        return (
          <Box key={index}>
            <ListItems title={item.title} />
          </Box>
        );
      })}
    </>
  );
};

export default DashboardTasks;
