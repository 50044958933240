import React, { useState, useEffect } from 'react';
import MDBox from 'components/MDBox';
import Card from '@mui/material/Card';
import { useDispatch } from 'react-redux';
import SharedFolderDialog from './SharedFolderDialog';
import SharedFolderAccessDialog from './SharedFolderAccessDialog';
import { getRenderedList } from 'utils/commonSlice/renderListSlice';
import { getGroupsList } from 'layouts/groups/services/userGroupApi';
import { intialPageSize, sharedFolderArchiveSuccess } from 'constants';
import SharedFolderVersions from '../sharedFolderVersions/SharedFolderVersions';
import {
  useNotification,
  getDataGridHeading,
  useListDialog,
  emptyFunction,
  onSave,
  pageSizeChangeHandler,
} from 'utils/commonUtils';
import {
  getSharedFolders,
  getSharedFolder,
  postSharedFolderArchive,
} from 'layouts/documentManagement/services/documentManagementApi';
import {
  getSharedFoldersListData,
  sharedFoldersColumns,
} from './SharedFolderDataGrid';
import {
  getSharedFolderForm,
  setIsNewSharedFolder,
  resetSharedFolderForm,
} from 'layouts/documentManagement/store/sharedFolderSlice/sharedFolderSlice';
import DeltaDataGrid from 'utils/CommonDataGrid/CommonDataGrid';

const SharedFolderList = () => {
  const dispatch = useDispatch();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const { setDialogName, setOpenViewDialog, ListDialog } = useListDialog();
  const [groupsDropDownList, setGroupsDropDownList] = useState([]);
  const [opensharedFolderVersionsDialog, setOpenSharedFolderVersionsDialog] =
    useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [sharedFolderId, setSharedFolderId] = useState(null);
  const [archiveLoading, setArchiveLoading] = useState(false);
  const [loadingSharedFolderVersions, setLoadingSharedFolderVersions] =
    useState(false);
  const [sharedFolderDialog, setSharedFolderDialog] = useState(false);
  const [sharedFolderAccessDialog, setSharedFolderAccessDialog] =
    useState(false);
  const [sharedFolders, setSharedFolders] = useState({
    isLoading: false,
    rows: [],
    pageSize: intialPageSize,
  });

  useEffect(() => {
    fetchSharedFolders();
    fetchGroupsData();
  }, []);

  const fetchGroupsData = async () => {
    const groupsList = await getGroupsList();
    setGroupsDropDownList(groupsList);
  };

  const fetchSharedFolders = async () => {
    setSharedFolders({ ...sharedFolders, isLoading: true });
    try {
      const sharedFoldersData = await getSharedFolders();
      setSharedFolders({ ...sharedFolders, isLoading: false });
      setSharedFolders((prev) => ({
        ...prev,
        rows: getSharedFoldersListData(sharedFoldersData),
      }));
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
      setSharedFolders({ ...sharedFolders, isLoading: false });
    }
  };

  const handleNewSharedFolder = () => {
    dispatch(resetSharedFolderForm());
    dispatch(setIsNewSharedFolder(true));
    setSharedFolderDialog(true);
  };

  const handleUpdateSharedFolder = async (params) => {
    const { row } = params;
    setIsFormLoading(true);
    const sharedFolderData = await getSharedFolder(row.id);
    dispatch(getSharedFolderForm(sharedFolderData));
    setIsFormLoading(false);
    setSharedFolderDialog(true);
  };

  const handleSharedFolderAccess = async (params) => {
    const { row } = params;
    setSharedFolderId(row.id);
    setSharedFolderAccessDialog(true);
  };

  const handleReadUser = (params) => {
    const { row } = params;
    dispatch(getRenderedList(row.readUserList));
    setDialogName('Users of read access');
    setOpenViewDialog(true);
  };

  const handleWriteUser = (params) => {
    const { row } = params;
    dispatch(getRenderedList(row.writeUserList));
    setDialogName('Users of write access');
    setOpenViewDialog(true);
  };

  const handleReadGroup = (params) => {
    const { row } = params;
    dispatch(getRenderedList(row.readGroupList));
    setDialogName('Groups of read access');
    setOpenViewDialog(true);
  };

  const handleWriteGroup = (params) => {
    const { row } = params;
    dispatch(getRenderedList(row.writeGroupList));
    setDialogName('Groups of write access');
    setOpenViewDialog(true);
  };

  const handleSharedFolderVersions = (params) => {
    setLoadingSharedFolderVersions(true);
    const { row } = params;
    setSharedFolderId(row.id);
    setOpenSharedFolderVersionsDialog(true);
  };

  const handleArchiveSharedFolders = (params) => {
    const { row } = params;
    onSave(
      sharedFolderArchiveSuccess,
      true,
      postSharedFolderArchive,
      emptyFunction,
      row.id,
      '',
      fetchSharedFolders,
      emptyFunction,
      setOpenNotification,
      setNotificationMessage,
      dispatch,
      setArchiveLoading,
      archiveLoading
    );
  };

  const pageSizeChange = pageSizeChangeHandler(setSharedFolders);

  return (
    <>
      <MDBox>
        <Card>
          {getDataGridHeading(
            'Shared Folders',
            'New Shared Folder',
            handleNewSharedFolder
          )}
          <DeltaDataGrid
            listData={sharedFolders}
            columns={sharedFoldersColumns(
              false,
              true,
              handleUpdateSharedFolder,
              handleSharedFolderAccess,
              handleArchiveSharedFolders,
              emptyFunction,
              handleReadUser,
              handleWriteUser,
              handleReadGroup,
              handleWriteGroup,
              handleSharedFolderVersions
            )}
            setListData={pageSizeChange}
          />
        </Card>
      </MDBox>
      <SharedFolderVersions
        open={opensharedFolderVersionsDialog}
        setOpen={setOpenSharedFolderVersionsDialog}
        sharedFolderId={sharedFolderId}
        setSharedFolderId={setSharedFolderId}
        loading={loadingSharedFolderVersions}
        setLoading={setLoadingSharedFolderVersions}
      />
      <SharedFolderDialog
        open={sharedFolderDialog}
        setOpen={setSharedFolderDialog}
        fetchSharedFolders={fetchSharedFolders}
        isFormLoading={isFormLoading}
      />
      <SharedFolderAccessDialog
        open={sharedFolderAccessDialog}
        setOpen={setSharedFolderAccessDialog}
        sharedFolderId={sharedFolderId}
        setSharedFolderId={setSharedFolderId}
        fetchSharedFolders={fetchSharedFolders}
        isFormLoading={isFormLoading}
        groupsDropDownList={groupsDropDownList}
      />
      <ListDialog />
      <NotificationPopup />
    </>
  );
};

export default SharedFolderList;
