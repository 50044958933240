import { renderEditDeleteIcon } from 'utils/commonUtils';

export const GridAction = (props) => {
  const { params, editClickHandler, deleteClickHandler } = props;

  const editActionClickHandler = () => {
    editClickHandler(params);
  };

  const deleteActionClickHandler = () => {
    deleteClickHandler(params);
  };

  return renderEditDeleteIcon(editActionClickHandler, deleteActionClickHandler);
};

export const renderActionItems = (
  params,
  editClickHandler,
  deleteClickHandler
) => {
  return (
    <GridAction
      params={params}
      editClickHandler={editClickHandler}
      deleteClickHandler={deleteClickHandler}
    />
  );
};
