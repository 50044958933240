import React, { useState, useEffect } from 'react';
import MDBox from 'components/MDBox';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import TaskDialog from './TaskDialog';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import CustomizedSnackbars from 'components/snackbar/Snackbar';
import { getMyProfile } from 'layouts/myProfile/service/myProfileApi';
import FormInputDropdown from 'components/Common/Forms/FormInputDropDown';
import { AttendanceFields } from 'layouts/applications/attendance/Attendance.styled';
import { usePermissionCustomHook } from 'layouts/users/userPermissions/utils/permissionsUtils';
import {
  intialPageSize,
  booleanOptions,
  specificTypeOftask,
  taskComplete,
  noneOption,
} from 'constants';
import { getTaskListData, taskColumns } from './TasksDataGrid';
import {
  getDataGridHeading,
  useResetSnackbar,
  useNotification,
  onSave,
  mapNoneOption,
} from 'utils/commonUtils';
import {
  getTaskForm,
  resetTask,
  setIsNewTask,
} from 'layouts/taskManagement/store/taskSlice/taskSlice';
import {
  getTasks,
  getTask,
  postMarkInComplete,
  postMarkComplete,
} from 'layouts/taskManagement/services/taskManagementApi';
import {
  setDataGridCounts,
  resetDataGridCounts,
} from 'utils/commonSlice/dataGridCounts/dataGridCountsSlice';
import {
  setTaskPagination,
  setTaskPaginationPreviousKey,
  resetTaskPagination,
} from 'layouts/taskManagement/store/taskSlice/taskPaginationSlice';
import {
  setIncludeCompleted,
  setOldestFirst,
  setLimitToType,
  setUser,
} from 'layouts/taskManagement/store/taskFilterSlice/taskFilterSlice';
import DeltaDataGrid from 'utils/CommonDataGrid/CommonDataGrid';

const Tasks = () => {
  useResetSnackbar();
  const dispatch = useDispatch();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const [openTaskDialog, setOpenTaskDialog] = useState(false);
  const [userDropDownList, setUserDropDownList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [taskCompletedLoading, setTaskCompletedLoading] = useState(false);
  const [taskPageKey, setTaskPageKey] = useState('');
  const [loadingRowId, setLoadingRowId] = useState(null);

  const [taskList, setTaskList] = useState({
    isLoading: false,
    rows: [],
    pageSize: intialPageSize,
    page: 1,
    total: 3000,
    currentPaginationKey: '',
    nextPaginationKey: '',
    count: '',
  });

  const { pageSize, page, nextPaginationKey, count } = taskList;

  const canCreatePersonalTasks = usePermissionCustomHook(
    'CAN_CREATE_PERSONAL_TASKS'
  );
  const canSuperviseTasks = usePermissionCustomHook('CAN_SUPERVISE_TASKS');
  const canCreatePersonalTasksOrCanSuperviseTasks =
    canCreatePersonalTasks || canSuperviseTasks;

  const dataGridCounts = useSelector(
    (state) => state?.totalDataGridCounts?.dataGridCounts
  );
  const totalNumber = dataGridCounts?.reduce((acc, curr) => acc + curr, 0);

  const taskPagination = useSelector(
    (state) => state?.taskPaginationKeyList?.taskPagination
  );

  const taskFilters = useSelector((state) => state?.taskFilters);

  const { includeCompleted, oldestFirst, limitToType, user } = taskFilters || {};

  useEffect(() => {
    if (page === 1) {
      dispatch(resetTaskPagination());
      dispatch(resetDataGridCounts());
      dispatch(setDataGridCounts(pageSize));
    }
  }, []);

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    const userList = await getMyProfile();
    setUserDropDownList(userList);
  };

  const { supervisedUsers } = userDropDownList ?? {};

  const allSupervisedUsers = [
    noneOption,
    ...mapNoneOption(supervisedUsers?.allSupervisedUsers),
  ];

  const supervisedReadWrite = [
    noneOption,
    ...mapNoneOption(supervisedUsers?.supervisedReadWrite),
  ];

  useEffect(() => {
    fetchTasks();
  }, [pageSize, page, user, includeCompleted, oldestFirst, limitToType]);

  const defaultValue = {
    includeCompleted: includeCompleted,
    oldestFirst: oldestFirst,
    limitToType: limitToType,
    user: user,
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
  });

  const { reset, control, register } = useFunction;

  const fetchTasks = async () => {
    setTaskList({ ...taskList, isLoading: true });
    try {
      const taskListData = await getTasks(
        pageSize,
        taskPageKey,
        includeCompleted,
        oldestFirst,
        limitToType,
        user
      );
      setTaskList({ ...taskList, isLoading: false });
      setTaskList({
        ...taskList,
        currentPaginationKey: taskListData?.currentPaginationKey,
        nextPaginationKey: taskListData?.nextPaginationKey,
        count: taskListData?.count,
      });
      setTaskList((prev) => ({
        ...prev,
        rows: getTaskListData(taskListData),
      }));
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
      setTaskList({ ...taskList, isLoading: false });
    }
  };

  useEffect(() => {
    if (!taskList?.isLoading) {
      if (count < pageSize) {
        setTaskList((prveState) => ({
          ...prveState,
          total: totalNumber,
        }));
      }
    }
  }, [count, pageSize]);

  useEffect(() => {
    reset(defaultValue);
  }, [
    defaultValue.includeCompleted,
    defaultValue.oldestFirst,
    defaultValue.limitToType,
    defaultValue.user,
  ]);

  const handleNewTask = () => {
    dispatch(resetTask());
    dispatch(setIsNewTask(true));
    setOpenTaskDialog(true);
  };

  const updateTask = async (params) => {
    const { row } = params;
    setIsLoading(true);
    const task = await getTask(row.id);
    dispatch(getTaskForm(task));
    setIsLoading(false);
    setOpenTaskDialog(true);
  };
  const handleClose = () => {};

  const handleTaskCompleted = async (params) => {
    setLoadingRowId(params.row.id);
    const { row } = params;
    onSave(
      taskComplete,
      row.completed,
      postMarkInComplete,
      postMarkComplete,
      row.id,
      '',
      fetchTasks,
      handleClose,
      setOpenNotification,
      setNotificationMessage,
      dispatch,
      setTaskCompletedLoading,
      taskCompletedLoading
    );
  };

  const redirect = () => {
    dispatch(resetTaskPagination());
    dispatch(resetDataGridCounts());
    dispatch(setDataGridCounts(pageSize));
    setTaskList((prveState) => ({
      ...prveState,
      page: 1,
      total: 3000,
    }));
    setTaskPageKey('');
  };

  const handleIncludeCompleted = (e) => {
    dispatch(setIncludeCompleted(e.target.value));
    redirect();
  };

  const handleOldestFirst = (e) => {
    dispatch(setOldestFirst(e.target.value));
    redirect();
    setTaskList((prveState) => ({
      ...prveState,
      page: 1,
      total: totalNumber,
    }));
  };

  const handleLimitToType = (e) => {
    dispatch(setLimitToType(e.target.value));
    dispatch(setUser(''));
    redirect();
  };

  const handleUser = (e) => {
    dispatch(setUser(e.target.value));
    redirect();
  };

  const handelPageChange = (page) => {
    setTaskList((prveState) => ({
      ...prveState,
      page: page + 1,
    }));
    const paginationIdValue = {
      id: page,
      value: nextPaginationKey,
    };
    if (taskPagination?.find((item) => item.id === page)) {
      const getPreviousPaginationKey = () => {
        const getPaginationKey = taskPagination?.find(
          (item) => item.id === page
        );
        return getPaginationKey?.value;
      };
      const previousPaginationKey = getPreviousPaginationKey();
      dispatch(setTaskPaginationPreviousKey(previousPaginationKey));
      setTaskPageKey(previousPaginationKey);
    } else {
      dispatch(setDataGridCounts(count));
      dispatch(setTaskPagination(paginationIdValue));
      setTaskPageKey(nextPaginationKey);
    }
    if (page < 1) {
      setTaskList((prveState) => ({
        ...prveState,
        total: 3000,
      }));
      dispatch(resetTaskPagination());
      dispatch(resetDataGridCounts());
      dispatch(setDataGridCounts(pageSize));
      setTaskPageKey('');
    }
  };

  const handleOnPageSizeChange = (newPageSize) => {
    if (count < pageSize) {
      setTaskList((prveState) => ({
        ...prveState,
        pageSize: newPageSize,
        page: 1,
        total: totalNumber,
      }));
    } else {
      setTaskList((prveState) => ({
        ...prveState,
        pageSize: newPageSize,
        page: 1,
        total: 3000,
      }));
      dispatch(resetTaskPagination());
      dispatch(resetDataGridCounts());
      dispatch(setDataGridCounts(newPageSize));
    }
  };

  return (
    <>
      <CustomizedSnackbars />
      <MDBox>
        <Card>
          {getDataGridHeading(
            'Tasks',
            canCreatePersonalTasksOrCanSuperviseTasks && 'New Task',
            canCreatePersonalTasksOrCanSuperviseTasks
              ? handleNewTask
              : undefined
          )}
          <AttendanceFields container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <FormInputDropdown
                name="includeCompleted"
                type="text"
                control={control}
                options={booleanOptions}
                defaultValue={defaultValue?.includeCompleted}
                label="Include Completed"
                register={register('includeCompleted', {
                  onChange: (e) => {
                    handleIncludeCompleted(e);
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormInputDropdown
                name="oldestFirst"
                type="text"
                control={control}
                options={booleanOptions}
                defaultValue={defaultValue?.oldestFirst}
                label="Oldest First"
                register={register('oldestFirst', {
                  onChange: (e) => {
                    handleOldestFirst(e);
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormInputDropdown
                name="limitToType"
                type="text"
                control={control}
                options={specificTypeOftask}
                defaultValue={defaultValue?.limitToType}
                label="Limit To Type"
                register={register('limitToType', {
                  onChange: (e) => {
                    handleLimitToType(e);
                  },
                })}
              />
            </Grid>
            {limitToType === 'supervisor' && canSuperviseTasks && (
              <Grid item xs={12} sm={6} md={3}>
                <FormInputDropdown
                  name="user"
                  type="text"
                  control={control}
                  options={allSupervisedUsers}
                  defaultValue={defaultValue?.user}
                  label="Supervised User"
                  register={register('user', {
                    onChange: (e) => {
                      handleUser(e);
                    },
                  })}
                />
              </Grid>
            )}
          </AttendanceFields>
          <DeltaDataGrid
            listData={taskList}
            columns={taskColumns(
              updateTask,
              handleTaskCompleted,
              taskCompletedLoading,
              loadingRowId
            )}
            handelPageChange={handelPageChange}
            setListData={handleOnPageSizeChange}
          />
        </Card>
      </MDBox>
      <TaskDialog
        open={openTaskDialog}
        setOpen={setOpenTaskDialog}
        fetchTasks={fetchTasks}
        isLoading={isLoading}
        supervisedReadWrite={supervisedReadWrite}
      />
      <NotificationPopup />
    </>
  );
};

export default Tasks;
