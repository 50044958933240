import React from 'react';
import MDTypography from 'components/MDTypography';
import { useSelector } from 'react-redux';
import { HeadingWrapperBox } from 'layouts/inventory/Inventory.styled';
import { Box, Card } from '@mui/material';

const MyHeading = () => {
  const myProfile = useSelector((state) => state.myProfile);
  return (
    <Card>
      <HeadingWrapperBox>
        <Box>
          <MDTypography variant='button' color='text' fontWeight='regular'>
            User name
          </MDTypography>
          <MDTypography variant='h5' fontWeight='medium'>
            {myProfile?.username}
          </MDTypography>
        </Box>
        <Box>
          <MDTypography variant='button' color='text' fontWeight='regular'>
            Super user
          </MDTypography>
          <MDTypography variant='h5' fontWeight='medium'>
            {myProfile?.isSuperuser ? 'Yes' : 'No'}
          </MDTypography>
        </Box>
      </HeadingWrapperBox>
    </Card>
  );
};

export default MyHeading;
