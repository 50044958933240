import { DataGridPro } from '@mui/x-data-grid-pro';
import { rowsPerPage } from 'constants';
import { getRowClassName } from 'utils/commonUtils';

export const getMyStudentsDataGrid = (
  myStudentsData,
  columns,
  setMyStudentsData
) => {
  const { rows, pageSize, isLoading } = myStudentsData;

  const getRowHeight = () => {
    'auto';
  };
  return (
    <DataGridPro
      autoHeight
      getRowHeight={getRowHeight}
      rows={rows}
      columns={columns}
      pagination
      rowsPerPageOptions={rowsPerPage}
      pageSize={pageSize}
      getRowClassName={(params) => getRowClassName(params)}
      loading={isLoading}
      onPageSizeChange={(newPageSize) =>
        setMyStudentsData((prveState) => ({
          ...prveState,
          pageSize: newPageSize,
        }))
      }
    />
  );
};
