import { createSlice } from '@reduxjs/toolkit';
import initialState from './systemConfigurationInitialState';
import {
  _getSystemConfigurationForm,
  _setIepResponsibleUsers,
} from './systemConfigurationAction';

export const systemConfigurationSlice = createSlice({
  name: 'delta/systemConfigurationForm',
  initialState,
  reducers: {
    getSystemConfigurationForm: _getSystemConfigurationForm,
    setIepResponsibleUsers: _setIepResponsibleUsers,
    resetSystemConfigurationForm: () => initialState,
  },
});

export const {
  getSystemConfigurationForm,
  setIepResponsibleUsers,
  resetSystemConfigurationForm,
} = systemConfigurationSlice.actions;

export default systemConfigurationSlice.reducer;
