import { createSlice } from '@reduxjs/toolkit';
import initialState from './assetTypeInitialState';
import { _setIsNewAssetType, _setAssetType, _setType } from './assetTypeAction';

export const assetTypeSlice = createSlice({
  name: 'delta/assetType',
  initialState,
  reducers: {
    setIsNewAssetType: _setIsNewAssetType,
    setAssetType: _setAssetType,
    setType: _setType,
    resetAssetType: () => initialState,
  },
});

export const { setIsNewAssetType, setAssetType, setType, resetAssetType } =
  assetTypeSlice.actions;

export default assetTypeSlice.reducer;
