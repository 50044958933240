import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { intialPageSize } from 'constants';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import {
  getDataGridHeading,
  showLoader,
  pageSizeChangeHandler,
} from 'utils/commonUtils';
import Card from '@mui/material/Card';
import DeltaDataGrid from 'utils/CommonDataGrid/CommonDataGrid';

const MyGroups = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [myGroups, setMyGroups] = useState({
    isLoading: false,
    rows: [],
    pageSize: intialPageSize,
    page: 1,
  });
  const { pageSize, page } = myGroups;

  const myProfile = useSelector((state) => state.myProfile);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (myProfile) {
      fetchMyProfile();
    }
  }, [pageSize, page, myProfile]);

  const fetchMyProfile = () => {
    setMyGroups((previous) => ({
      ...previous,
      rows: myProfile?.tokenGroups?.map((item, index) => ({
        id: index,
        tokenGroups: item,
      })),
    }));
  };

  const columns = [
    {
      headerName: 'My Groups',
      field: 'tokenGroups',
      flex: 1,
    },
  ];

  const pageSizeChange = pageSizeChangeHandler(setMyGroups);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        {isLoading ? (
          showLoader()
        ) : (
          <>
            <Card>
              {getDataGridHeading('My Groups')}
              <DeltaDataGrid
                listData={myGroups}
                columns={columns}
                setListData={pageSizeChange}
              />
            </Card>
          </>
        )}
      </DashboardLayout>
    </>
  );
};

export default MyGroups;
