import { createSlice } from '@reduxjs/toolkit';
import initialState from './calendarCategoryInitialState';
import {
  _setColor,
  _setCategory,
  _newCalenderCategory,
  _updateCalenderCategory,
  _setCategories,
} from './calendarCategoryAction';

export const calenderCategorySlice = createSlice({
  name: 'calendarCategory',
  initialState,
  reducers: {
    newCalenderCategory: _newCalenderCategory,
    updateCalenderCategory: _updateCalenderCategory,
    setColor: _setColor,
    setCategoryId: _setCategory,
    setCategories: _setCategories,
    resetCategoriesData: () => initialState,
  },
});

export const {
  newCalenderCategory,
  updateCalenderCategory,
  setColor,
  setCategoryId,
  setCategories,
  resetCategoriesData,
} = calenderCategorySlice.actions;

export default calenderCategorySlice.reducer;
