import React from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NotesHistory from './NotesHistory';
import { AccordianContainer } from 'components/Ui/styled';

const AssetNotes = (props) => {
  const { notesData } = props;
  return (
    <>
      <AccordianContainer>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography variant='h6'>Notes</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <NotesHistory notesData={notesData} />
        </AccordionDetails>
      </AccordianContainer>
    </>
  );
};

export default AssetNotes;
