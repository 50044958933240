import React, { memo, useRef, useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import {
  ExpandContainer,
  CellText,
  ExpandText,
  PopperStyle,
  ContainerBox,
} from './GridCellExpand.Styled';

export const renderCellExpand = (params) => {
  return (
    <GridCellExpand
      value={params.value || ''}
      width={params.colDef.computedWidth}
    />
  );
};

const isOverflown = (element) => {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
};

const GridCellExpand = memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = useRef(null);
  const cellDiv = useRef(null);
  const cellValue = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showFullCell, setShowFullCell] = useState(false);
  const [showPopper, setShowPopper] = useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }
    function handleKeyDown(nativeEvent) {
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <ExpandContainer
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ContainerBox ref={cellDiv} />
      <CellText ref={cellValue}> {value} </CellText>
      {showPopper && (
        <PopperStyle
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
        >
          <Paper elevation={1}>
            <ExpandText variant="h6">{value}</ExpandText>
          </Paper>
        </PopperStyle>
      )}
    </ExpandContainer>
  );
});
export default GridCellExpand;
