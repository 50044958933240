import { createSlice } from '@reduxjs/toolkit';
import initialState from './groupMembersInitialState';
import {
  _setAddedGroupMembers,
  _setRemovedGroupMembers,
} from './groupMemberAction';

export const groupMemberSlice = createSlice({
  name: 'groupMember',
  initialState,
  reducers: {
    setAddedGroupMembers: _setAddedGroupMembers,
    setRemovedGroupMembers: _setRemovedGroupMembers,
    resetGroupMembers: () => initialState,
  },
});

export const {
  setAddedGroupMembers,
  setRemovedGroupMembers,
  resetGroupMembers,
} = groupMemberSlice.actions;

export default groupMemberSlice.reducer;
