import { createSlice } from '@reduxjs/toolkit';
import initialState from './groupPermissionInitialState';
import {
  _getGroupPermissionForm,
  _setGroupPermissions,
} from './groupPermissionAction';

export const groupPermissionSlice = createSlice({
  name: 'delta',
  initialState,
  reducers: {
    getGroupPermissionForm: _getGroupPermissionForm,
    setGroupPermissions: _setGroupPermissions,
    resetGroupPermissions: () => initialState,
  },
});

export const {
  getGroupPermissionForm,
  setGroupPermissions,
  resetGroupPermissions,
} = groupPermissionSlice.actions;

export default groupPermissionSlice.reducer;
