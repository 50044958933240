import { TagCellTypography } from './Attendance.styled';

const AttendanceTagCell = (props) => {
  const { tagLabel, isLastIndex } = props;

  let newTagLabel = tagLabel;

  if (!isLastIndex) {
    newTagLabel += ',' + String.fromCharCode(160);
  }

  return <TagCellTypography variant='caption'>{newTagLabel}</TagCellTypography>;
};

export default AttendanceTagCell;
