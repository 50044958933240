import { API } from 'aws-amplify';
import { TASK_MANAGEMENT_BASE_URL_APPEND } from 'constants';

export const getTasks = async (
  pageSize,
  paginationKey,
  includeCompleted,
  oldestFirst,
  limitToType,
  user
) => {
  const tasks = await API.get(
    'delta-api',
    `${TASK_MANAGEMENT_BASE_URL_APPEND}tasks?`,
    {
      headers: {},
      queryStringParameters: {
        limit: pageSize,
        paginationKey: paginationKey,
        includeCompleted: includeCompleted,
        oldestFirst: oldestFirst,
        limitToType: limitToType,
        user: user,
      },
    }
  );
  return tasks;
};

export const getActiveTasks = async () => {
  const activeTasks = await API.get(
    'delta-api',
    `${TASK_MANAGEMENT_BASE_URL_APPEND}activeTasks`
  );
  return activeTasks;
};

export const getAllOverdueTasks = async () => {
  const allOverdueTasks = await API.get(
    'delta-api',
    `${TASK_MANAGEMENT_BASE_URL_APPEND}supervisor/allOverdue`
  );
  return allOverdueTasks;
};

export const getTask = async (id) => {
  const task = await API.get(
    'delta-api',
    `${TASK_MANAGEMENT_BASE_URL_APPEND}tasks/${id}`
  );
  return task;
};

export const postTask = async (payload) => {
  const myInit = {
    body: payload,
  };
  await API.post(
    'delta-api',
    `${TASK_MANAGEMENT_BASE_URL_APPEND}tasks`,
    myInit
  );
};

export const putTask = async (payload, id) => {
  const myInit = {
    body: payload,
  };
  await API.put(
    'delta-api',
    `${TASK_MANAGEMENT_BASE_URL_APPEND}tasks/${id}`,
    myInit
  );
};

export const postMarkComplete = async (taskId) => {
  await API.post(
    'delta-api',
    `${TASK_MANAGEMENT_BASE_URL_APPEND}tasks/${taskId}/markComplete`
  );
};

export const postMarkInComplete = async (taskId) => {
  await API.post(
    'delta-api',
    `${TASK_MANAGEMENT_BASE_URL_APPEND}tasks/${taskId}/markIncomplete`
  );
};

export const getMyTasks = async () => {
  const tasks = await API.get(
    'delta-api',
    `${TASK_MANAGEMENT_BASE_URL_APPEND}tasks`
  );
  return tasks;
};
