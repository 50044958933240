const initialState = {
  item: {
    newAnnouncement: false,
    id: '',
    title: '',
    msg: '',
    startsAt: '',
    endsAt: '',
    groups: [],
  },
};

export default initialState;
