import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Amplify from 'aws-amplify';
import { MaterialUIControllerProvider } from 'context';
import { store } from './redux/store';
import { Provider } from 'react-redux';

import { Auth } from 'aws-amplify';

import { LicenseInfo } from '@mui/x-license-pro';
LicenseInfo.setLicenseKey(
  '4c785f3decbd995d502e2004d6d4f9f6Tz01Mjg4NyxFPTE2OTc5MjY4MDQwMTAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

//import awsconfig from './aws-exports';
const awsconfig = {
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_user_files_s3_bucket: 'delta-image-storage111223657-staging',
  aws_user_files_s3_bucket_region: 'us-east-1',
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_USER_POOLS_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOLS_WEB_CLIENT_ID,
    refreshHandlers: {
      refreshToken: async () => {
        const currentSession = await Auth.currentSession();
        const refreshToken = currentSession.getRefreshToken();
        // If the token is expired, refresh it using the refresh token
        if (currentSession.isValid()) {
          return currentSession;
        } else if (refreshToken) {
          const {
            accessToken,
            idToken,
            refreshToken: newRefreshToken,
          } = await Auth.refreshTokens(refreshToken);
          return {
            ...currentSession,
            accessToken,
            idToken,
            refreshToken: newRefreshToken,
          };
        } else {
          throw new Error('No valid refresh token available');
        }
      },
    },
  },
  API: {
    endpoints: [
      {
        name: 'delta-api',
        endpoint: process.env.REACT_APP_API_ENDPOINT,
        custom_header: async () => {
          try {
            return {
              Authorization: `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
            };
          } catch (error) {
            console.log('error', error);
          }
        },
      },
      {
        name: 'asset-api',
        endpoint: process.env.REACT_APP_ASSET_ENDPOINT,
      },
    ],
  },
};

Amplify.configure(awsconfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <App />
      </MaterialUIControllerProvider>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
