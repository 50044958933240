export const _setIsNewGroup = (state, action) => {
  state.isNewGroup = action.payload;
};

export const _setGroupName = (state, action) => {
  state.groupName = action.payload;
};

export const _setDescription = (state, action) => {
  state.description = action.payload;
};
