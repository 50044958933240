import React, { useState, useEffect } from 'react';
import { showLoader } from 'utils/commonUtils';
import MyCards from './components/myCards/MyCards';
import { useSelector } from 'react-redux';
import MyHeading from './components/myHeading/MyHeading';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';

const MyProfile = () => {
  const [isLoading, setIsLoading] = useState(true);

  const myProfile = useSelector((state) => state.myProfile);

  useEffect(() => {
    if (myProfile) {
      setIsLoading(false);
    }
  }, [myProfile]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isLoading ? (
        showLoader()
      ) : (
        <>
          <MyHeading />
          <MyCards />
        </>
      )}
    </DashboardLayout>
  );
};

export default MyProfile;
