import { filterSchoolYear } from "constants";

export const filterScoresBySchoolYear = (
  scoresByCategory,
  testCategoriesField,
  schoolYear
) => {
  return scoresByCategory[testCategoriesField].filter(
    (item) => item[filterSchoolYear] === schoolYear
  );
};

export const extractKeyItem = (rows, key, resultKey) => {
  return rows.map((row) => {
    const keyItem = row[key];
    const keyResult = row[resultKey];
    return keyItem || keyResult;
  });
};