import React, { useState, useEffect } from 'react';
import MDBox from 'components/MDBox';
import Card from '@mui/material/Card';
import { useDispatch } from 'react-redux';
import { intialPageSize, attendanceAuditArchiveSuccess } from 'constants';
import {
  getDataGridHeading,
  useNotification,
  emptyFunction,
  useMembersState,
  onSave,
  useListDialog,
  useResetSnackbar,
  pageSizeChangeHandler,
} from 'utils/commonUtils';
import {
  attendanceAuditColumns,
  getAttendanceAuditListData,
} from './AttendanceAuditDataGrid';
import { getAttendanceAudits } from 'layouts/auditManagement/services/attendanceAuditsApi';
import AttendanceAuditVersions from '../attendanceAuditVersions/attendanceAuditVersions';
import { postAttendanceAuditsArchive } from 'layouts/auditManagement/services/attendanceAuditsApi';
import MembersDialog from 'layouts/groups/list/MembersDialog';
import ReplaceAccessDialog from './ReplaceAccess/ReplaceAccessDialog';
import { getReplaceAccessForm } from 'layouts/auditManagement/store/replaceAccessSlice/replaceAccessSlice';
import AttendanceAuditDialog from './AttendanceAuditDialog';
import { getAttendanceAudit } from 'layouts/auditManagement/services/attendanceAuditsApi';
import {
  getAttendanceAuditForm,
  setIsNewAttendanceAudit,
  setIncludePreviousSemesters,
  setIncludeDropped,
  resetAttendanceAuditForm,
} from 'layouts/auditManagement/store/attendanceAuditSlice/attendanceAuditSlice';
import { getSemesterDetails } from 'layouts/applications/attendance/services/attendanceApis';
import { getRenderedList } from 'utils/commonSlice/renderListSlice';
import DeltaDataGrid from 'utils/CommonDataGrid/CommonDataGrid';

const AttendanceAudits = () => {
  useResetSnackbar();
  const dispatch = useDispatch();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const { loading, membersDialog, handleMembers, setMembersDialog } =
    useMembersState();
  const { setDialogName, setOpenViewDialog, ListDialog } = useListDialog();
  const [loadingAuditVersions, setLoadingAuditVersions] = useState(false);
  const [attendanceAuditId, setAttendanceAuditId] = useState(null);
  const [
    openAttendanceAuditVersionsDialog,
    setOpenAttendanceAuditVersionsDialog,
  ] = useState(false);
  const [semesterDropDownList, setSemesterDropDownList] = useState(null);
  // const [loadingRowId, setLoadingRowId] = useState(null);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [archiveLoading, setArchiveLoading] = useState(false);
  const [attendanceAuditDialog, setAttendanceAuditDialog] = useState(false);
  const [replaceAccessDialog, setReplaceAccessDialog] = useState(false);
  const [attendanceAudits, setAttendanceAudits] = useState({
    isLoading: false,
    rows: [],
    pageSize: intialPageSize,
  });

  useEffect(() => {
    fetchAttendanceAudits();
    fetchSemesterListData();
  }, []);

  const fetchSemesterListData = async () => {
    const semesterList = await getSemesterDetails();
    setSemesterDropDownList(semesterList);
  };

  const fetchAttendanceAudits = async () => {
    setAttendanceAudits({ ...attendanceAudits, isLoading: true });
    try {
      const attendanceAuditsData = await getAttendanceAudits();
      setAttendanceAudits({ ...attendanceAudits, isLoading: false });
      setAttendanceAudits((prev) => ({
        ...prev,
        rows: getAttendanceAuditListData(attendanceAuditsData),
      }));
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
      setAttendanceAudits({ ...attendanceAudits, isLoading: false });
    }
  };

  const handleNewAttendanceAudit = () => {
    dispatch(resetAttendanceAuditForm());
    dispatch(setIsNewAttendanceAudit(true));
    dispatch(setIncludePreviousSemesters(false));
    dispatch(setIncludeDropped(false));
    setAttendanceAuditDialog(true);
  };

  const handleUpdateAttendanceAudit = async (params) => {
    const { row } = params;
    setAttendanceAuditId(row.id);
    setIsFormLoading(true);
    const attendanceAuditData = await getAttendanceAudit(row.id);
    dispatch(getAttendanceAuditForm(attendanceAuditData));
    setIsFormLoading(false);
    dispatch(setIncludePreviousSemesters(false));
    dispatch(setIncludeDropped(false));
    setAttendanceAuditDialog(true);
  };

  const handleStudents = (params) => {
    const { row } = params;
    dispatch(getRenderedList(row.studentList));
    setDialogName('Students');
    setOpenViewDialog(true);
  };

  const handleSemester = (params) => {
    const { row } = params;
    dispatch(getRenderedList(row.semesterList));
    setDialogName('Semesters');
    setOpenViewDialog(true);
  };

  const handleAccessUsers = handleMembers('Attendance Audit Access');

  const handleAttendanceAuditVersions = (params) => {
    setLoadingAuditVersions(true);
    const { row } = params;
    setAttendanceAuditId(row.id);
    setOpenAttendanceAuditVersionsDialog(true);
  };

  const handleArchiveAttendanceAudit = (params) => {
    const { row } = params;
    onSave(
      attendanceAuditArchiveSuccess,
      true,
      postAttendanceAuditsArchive,
      emptyFunction,
      row.id,
      '',
      fetchAttendanceAudits,
      emptyFunction,
      setOpenNotification,
      setNotificationMessage,
      dispatch,
      setArchiveLoading,
      archiveLoading
    );
  };

  const handleReplaceAccessAttendanceAudit = (params) => {
    const { row } = params;
    setAttendanceAuditId(row.id);
    const replaceAccessData = {
      replaceAccessUsers: row.accessUsersList,
    };
    dispatch(getReplaceAccessForm(replaceAccessData));
    setReplaceAccessDialog(true);
  };

  const pageSizeChange = pageSizeChangeHandler(setAttendanceAudits);

  return (
    <>
      <MDBox>
        <Card>
          {getDataGridHeading(
            'Attendance Audits',
            'New Attendance Audit',
            handleNewAttendanceAudit
          )}
          <DeltaDataGrid
            listData={attendanceAudits}
            columns={attendanceAuditColumns(
              handleUpdateAttendanceAudit,
              handleStudents,
              handleSemester,
              handleAccessUsers,
              handleAttendanceAuditVersions,
              false,
              true,
              handleArchiveAttendanceAudit,
              emptyFunction,
              handleReplaceAccessAttendanceAudit
            )}
            setListData={pageSizeChange}
          />
        </Card>
      </MDBox>
      <AttendanceAuditVersions
        open={openAttendanceAuditVersionsDialog}
        setOpen={setOpenAttendanceAuditVersionsDialog}
        attendanceAuditId={attendanceAuditId}
        setAttendanceAuditId={setAttendanceAuditId}
        loading={loadingAuditVersions}
        setLoading={setLoadingAuditVersions}
      />
      <MembersDialog
        setMembersDialog={setMembersDialog}
        membersDialog={membersDialog}
        fetchGroups={fetchAttendanceAudits}
        loading={loading}
      />
      <AttendanceAuditDialog
        open={attendanceAuditDialog}
        setOpen={setAttendanceAuditDialog}
        fetchAttendanceAudits={fetchAttendanceAudits}
        isFormLoading={isFormLoading}
        attendanceAuditId={attendanceAuditId}
        setAttendanceAuditId={setAttendanceAuditId}
        semesterDropDownList={semesterDropDownList}
      />
      <ReplaceAccessDialog
        open={replaceAccessDialog}
        setOpen={setReplaceAccessDialog}
        attendanceAuditId={attendanceAuditId}
        setAttendanceAuditId={setAttendanceAuditId}
        fetchAttendanceAudits={fetchAttendanceAudits}
      />
      <ListDialog />
      <NotificationPopup />
    </>
  );
};

export default AttendanceAudits;
