import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MDTypography from 'components/MDTypography';
import { getSubFoldersData } from '../store/subFoldersSlice/subFoldersSlice';
import { getFolderBrowse } from '../services/documentsApi';
import {
  resetFolderPath,
  setFolderPath,
} from '../store/folderPathSlice/folderPathSlice';
import {
  setFolderData,
  setUploadDocumentPath,
  setUploadDocumentNewPath,
} from '../store/uploadDocumentSlice/uploadDocumentSlice';
import { WhiteTooltip } from 'components/Ui/styled';
import { truncateText } from 'utils/commonUtils';

const FolderPath = (props) => {
  const { folderPath, setIsLoadingBackDrop } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const subFolders = useSelector((state) => state.subFolders);

  const modifiedFinalPath = (arr, name) => {
    const index = arr.findIndex((obj) => {
      return obj?.name === name;
    });
    if (index !== -1) {
      return arr.slice(0, index + 1);
    }
    return arr;
  };

  const handlePath = async (item) => {
    setIsLoadingBackDrop(true);
    const name = item?.name;
    const modifiedPath = modifiedFinalPath(folderPath, name);
    if (name === 'Root') {
      dispatch(resetFolderPath([]));
      const rootPath = {
        path: '/',
        name: 'Root',
      };
      dispatch(setFolderPath(rootPath));
    }
    dispatch(resetFolderPath(modifiedPath));
    navigate(`/documents/folders/subFolder`);
    dispatch(setFolderData(item));
    dispatch(setUploadDocumentPath(item.path));
    dispatch(setUploadDocumentNewPath(item.path));
    const subfoldersData = await getFolderBrowse(
      subFolders?.sharedFolderId,
      item.path
    );
    dispatch(getSubFoldersData(subfoldersData));
    setIsLoadingBackDrop(false);
  };

  return (
    <Box style={{ display: 'flex' }}>
      <MDTypography variant="h6">Path :</MDTypography>
      <Breadcrumbs style={{ marginLeft: '8px' }} aria-label="item">
        {folderPath?.map((item, index) => {
          const isLastBreadcrumb = index === folderPath.length - 1;
          return isLastBreadcrumb ? (
            <WhiteTooltip title={item.name} key={index}>
              <MDTypography
                variant="button"
                fontWeight="regular"
                textTransform="capitalize"
                key={index}
                sx={{ lineHeight: 1.9 }}
              >
                {truncateText(item.name)}
              </MDTypography>
            </WhiteTooltip>
          ) : (
            <Link
              style={{ cursor: 'pointer' }}
              key={index}
              onClick={() => handlePath(item)}
            >
              <WhiteTooltip title={item.name} key={index}>
                <MDTypography
                  component="span"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                  sx={{ lineHeight: 1.9 }}
                >
                  {truncateText(item.name)}
                </MDTypography>
              </WhiteTooltip>
            </Link>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
};

export default FolderPath;
