import { createSlice } from '@reduxjs/toolkit';
import { _getStudentData } from './studentDetailsAction';

export const studentDetailsSlice = createSlice({
  name: 'delta',
  initialState: null,
  reducers: {
    getStudentData: _getStudentData,
    resetStudentData: () => null,
  },
});

export const { getStudentData, resetStudentData } = studentDetailsSlice.actions;

export default studentDetailsSlice.reducer;
