import { createSlice } from '@reduxjs/toolkit';
import initialState from './reportsFilterInitialState';
import {
  _setStudentType,
  _setInstructor,
  _setCourse,
  _setGradeLevel,
  _setSchoolYears,
  _setTestCategory,
  _setTestSubcategories,
  _setStudentNumbers,
  _setProficiencyLevel,
  _setTestSubcategoriesList,
  _setByCategory,
  _setIsRawResults,
} from './reportsFilterAction';

export const reportsFilterSlice = createSlice({
  name: 'delta/reportsFilterSlice',
  initialState,
  reducers: {
    setStudentType: _setStudentType,
    setInstructor: _setInstructor,
    setCourse: _setCourse,
    setGradeLevel: _setGradeLevel,
    setSchoolYears: _setSchoolYears,
    setTestCategory: _setTestCategory,
    setTestSubcategories: _setTestSubcategories,
    setStudentNumbers: _setStudentNumbers,
    setProficiencyLevel: _setProficiencyLevel,
    setTestSubcategoriesList: _setTestSubcategoriesList,
    setByCategory: _setByCategory,
    setIsRawResults: _setIsRawResults,
    resetReportFilters: () => initialState,
  },
});

export const {
  setStudentType,
  setInstructor,
  setCourse,
  setGradeLevel,
  setSchoolYears,
  setTestCategory,
  setTestSubcategories,
  setStudentNumbers,
  setProficiencyLevel,
  setTestSubcategoriesList,
  setByCategory,
  setIsRawResults,
  resetReportFilters,
} = reportsFilterSlice.actions;

export default reportsFilterSlice.reducer;
