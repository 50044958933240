import { createSlice } from '@reduxjs/toolkit';
import { _setTrackerStatus } from './trackerStatusAction';

export const trackerStatusSlice = createSlice({
  name: 'delta/trackerStatus',
  initialState: null,
  reducers: {
    setTrackerStatus: _setTrackerStatus,
    resetTrackerStatus: () => null,
  },
});

export const { setTrackerStatus, resetTrackerStatus } =
  trackerStatusSlice.actions;

export default trackerStatusSlice.reducer;
