import React, { useEffect, useState } from 'react';
import FormInputDropdown from 'components/Common/Forms/FormInputDropDown';
import MDTypography from 'components/MDTypography';
import {
  intialPageSize,
  sharedFolderUnArchiveSuccess,
  booleanOptions,
} from 'constants';
import { AttendanceHeadingBox } from 'layouts/applications/attendance/Attendance.styled';
import { DropDownBox } from 'layouts/inventory/Inventory.styled';
import { useForm } from 'react-hook-form';
import {
  useNotification,
  useResetSnackbar,
  onSave,
  emptyFunction,
} from 'utils/commonUtils';
import {
  getSharedFoldersListData,
  sharedFoldersColumns,
} from '../sharedFolders/SharedFolderDataGrid';
import {
  getSharedFoldersArchive,
  postSharedFoldersUnarchive,
} from 'layouts/documentManagement/services/documentManagementApi';
import MDBox from 'components/MDBox';
import Card from '@mui/material/Card';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSharedFoldersArchivePaginationPreviousKey,
  resetSharedFoldersArchivePagination,
  setSharedFoldersArchivePagination,
} from 'layouts/documentManagement/store/sharedFoldersArchivePaginationSlice/sharedFoldersArchivePaginationSlice';
import {
  setDataGridCounts,
  resetDataGridCounts,
} from 'utils/commonSlice/dataGridCounts/dataGridCountsSlice';
import DeltaDataGrid from 'utils/CommonDataGrid/CommonDataGrid';

const SharedFoldersArchiveList = () => {
  useResetSnackbar();
  const dispatch = useDispatch();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const [sharedFoldersArchivePageKey, setSharedFoldersArchivePageKey] =
    useState('');
  const [oldestFirst, setOldestFirst] = useState(false);
  const [unArchiveLoading, setUnArchiveLoading] = useState(false);
  const [sharedFoldersArchiveList, setSharedFoldersArchiveList] = useState({
    isLoading: false,
    rows: [],
    pageSize: intialPageSize,
    page: 1,
    total: 3000,
    currentPaginationKey: '',
    nextPaginationKey: '',
    count: '',
  });
  const { pageSize, page, nextPaginationKey, count } = sharedFoldersArchiveList;

  const dataGridCounts = useSelector(
    (state) => state?.totalDataGridCounts?.dataGridCounts
  );
  const totalNumber = dataGridCounts?.reduce((acc, curr) => acc + curr, 0);

  const sharedFoldersArchivePagination = useSelector(
    (state) =>
      state?.sharedFoldersArchivePaginationKeyList
        ?.sharedFoldersArchivePagination
  );

  useEffect(() => {
    if (page === 1) {
      dispatch(resetSharedFoldersArchivePagination());
      dispatch(resetDataGridCounts());
      dispatch(setDataGridCounts(pageSize));
    }
  }, []);

  useEffect(() => {
    fetchAttendanceAuditsArchive();
  }, [pageSize, page, oldestFirst]);

  const defaultValue = {
    oldestFirst: oldestFirst,
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
  });

  const { reset, control, register } = useFunction;

  const fetchAttendanceAuditsArchive = async () => {
    setSharedFoldersArchiveList({
      ...sharedFoldersArchiveList,
      isLoading: true,
    });
    try {
      const sharedFoldersArchiveData = await getSharedFoldersArchive(
        pageSize,
        sharedFoldersArchivePageKey,
        oldestFirst
      );
      setSharedFoldersArchiveList({
        ...sharedFoldersArchiveList,
        isLoading: false,
      });
      setSharedFoldersArchiveList({
        ...sharedFoldersArchiveList,
        currentPaginationKey: sharedFoldersArchiveData?.currentPaginationKey,
        nextPaginationKey: sharedFoldersArchiveData?.nextPaginationKey,
        count: sharedFoldersArchiveData?.count,
      });
      setSharedFoldersArchiveList((prev) => ({
        ...prev,
        rows: getSharedFoldersListData(sharedFoldersArchiveData),
      }));
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
      setSharedFoldersArchiveList({
        ...sharedFoldersArchiveList,
        isLoading: false,
      });
    }
  };

  useEffect(() => {
    if (!sharedFoldersArchiveList?.isLoading) {
      if (count < pageSize) {
        setSharedFoldersArchiveList((prveState) => ({
          ...prveState,
          total: totalNumber,
        }));
      }
    }
  }, [count, pageSize]);

  useEffect(() => {
    reset(defaultValue);
  }, [defaultValue.oldestFirst]);

  const redirect = () => {
    dispatch(resetSharedFoldersArchivePagination());
    dispatch(resetDataGridCounts());
    dispatch(setDataGridCounts(pageSize));
    setSharedFoldersArchiveList((prveState) => ({
      ...prveState,
      page: 1,
      total: 3000,
    }));
    setSharedFoldersArchivePageKey('');
  };

  const handleOldestFirst = (e) => {
    setOldestFirst(e.target.value);
    redirect();
    setSharedFoldersArchiveList((prveState) => ({
      ...prveState,
      page: 1,
      total: totalNumber,
    }));
  };

  const handelPageChange = (page) => {
    setSharedFoldersArchiveList((prveState) => ({
      ...prveState,
      page: page + 1,
    }));
    const paginationIdValue = {
      id: page,
      value: nextPaginationKey,
    };
    if (sharedFoldersArchivePagination?.find((item) => item.id === page)) {
      const getPreviousPaginationKey = () => {
        const getPaginationKey = sharedFoldersArchivePagination?.find(
          (item) => item.id === page
        );
        return getPaginationKey?.value;
      };
      const previousPaginationKey = getPreviousPaginationKey();
      dispatch(
        setSharedFoldersArchivePaginationPreviousKey(previousPaginationKey)
      );
      setSharedFoldersArchivePageKey(previousPaginationKey);
    } else {
      dispatch(setDataGridCounts(count));
      dispatch(setSharedFoldersArchivePagination(paginationIdValue));
      setSharedFoldersArchivePageKey(nextPaginationKey);
    }
    if (page < 1) {
      setSharedFoldersArchiveList((prveState) => ({
        ...prveState,
        total: 3000,
      }));
      dispatch(resetSharedFoldersArchivePagination());
      dispatch(resetDataGridCounts());
      dispatch(setDataGridCounts(pageSize));
      setSharedFoldersArchivePageKey('');
    }
  };

  const handleOnPageSizeChange = (newPageSize) => {
    if (count < pageSize) {
      setSharedFoldersArchiveList((prveState) => ({
        ...prveState,
        pageSize: newPageSize,
        page: 1,
        total: totalNumber,
      }));
    } else {
      setSharedFoldersArchiveList((prveState) => ({
        ...prveState,
        pageSize: newPageSize,
        page: 1,
        total: 3000,
      }));
      dispatch(resetSharedFoldersArchivePagination());
      dispatch(resetDataGridCounts());
      dispatch(setDataGridCounts(newPageSize));
    }
  };

  const getHeadingWithFormFields = () => {
    return (
      <>
        <AttendanceHeadingBox>
          <MDTypography variant="h5" fontWeight="medium">
            Shared Folders Archived
          </MDTypography>
          <DropDownBox>
            <FormInputDropdown
              name="oldestFirst"
              type="text"
              control={control}
              options={booleanOptions}
              defaultValue={defaultValue?.oldestFirst}
              label="Oldest First"
              register={register('oldestFirst', {
                onChange: (e) => {
                  handleOldestFirst(e);
                },
              })}
            />
          </DropDownBox>
        </AttendanceHeadingBox>
      </>
    );
  };

  const handleUnarchiveSharedFolders = (params) => {
    const { row } = params;
    onSave(
      sharedFolderUnArchiveSuccess,
      true,
      postSharedFoldersUnarchive,
      emptyFunction,
      row.id,
      '',
      fetchAttendanceAuditsArchive,
      emptyFunction,
      setOpenNotification,
      setNotificationMessage,
      dispatch,
      setUnArchiveLoading,
      unArchiveLoading
    );
  };

  return (
    <>
      <MDBox>
        <Card>
          {getHeadingWithFormFields()}
          <DeltaDataGrid
            listData={sharedFoldersArchiveList}
            columns={sharedFoldersColumns(
              false,
              false,
              emptyFunction,
              emptyFunction,
              emptyFunction,
              handleUnarchiveSharedFolders,
              emptyFunction,
              emptyFunction,
              emptyFunction,
              emptyFunction,
              emptyFunction
            )}
            handelPageChange={handelPageChange}
            setListData={handleOnPageSizeChange}
          />
        </Card>
      </MDBox>
      <NotificationPopup />
    </>
  );
};

export default SharedFoldersArchiveList;
