import { API } from 'aws-amplify';
import { ASSET_BASE_URL_APPEND } from 'layouts/inventory/constants';

export const getAssetType = async () => {
  const assetType = await API.get(
    'delta-api',
    `${ASSET_BASE_URL_APPEND}assetTypes`
  );
  return assetType;
};

export const postAssetType = async (payload) => {
  const myInit = {
    body: payload,
  };
  await API.post('delta-api', `${ASSET_BASE_URL_APPEND}assetTypes`, myInit);
};

export const putAssetType = async (payload, id) => {
  const myInit = {
    body: payload,
  };
  await API.put(
    'delta-api',
    `${ASSET_BASE_URL_APPEND}assetTypes/${id}`,
    myInit
  );
};

export const deleteAssetTypeData = async (id) => {
  await API.del('delta-api', `${ASSET_BASE_URL_APPEND}assetTypes/${id}`, {});
};
