import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import {
  showBackdropLoader,
  getDialogHeading,
  useNotification,
  pageSizeChangeHandler,
  permissionCheck,
} from 'utils/commonUtils';
import { intialPageSize, userCountPaperProps } from 'constants';
import { getTracker } from 'layouts/studentTracking/services/studentTrackerApi';
import DeltaDataGrid from 'utils/CommonDataGrid/CommonDataGrid';
import MDTypography from 'components/MDTypography';
import Button from '@mui/material/Button';
import AddStatusDialog from './AddStatusDialog';
import { statusOptionColumns } from './StatusOptionDataGrid';
import RemoveStatusDialog from './RemoveStatusDialog';
import { DialogHeading, DialogDataGrid } from 'components/Ui/styled';
import { canManageStudentTrackersPermissionMessage } from 'constants';
import { usePermissionCustomHook } from 'layouts/users/userPermissions/utils/permissionsUtils';

const StatusOptionsDialog = (props) => {
  const {
    trackerId,
    statusOptionsDialog,
    setStatusOptionsDialog,
    statusOptionsDialogLoading,
    fetchTrackers
  } = props;

  const { handleErrorResponse, NotificationPopup } = useNotification();

  const [addStatusDialog, setAddStatusDialog] = useState(false);
  const [removeStatusDialog, setRemoveStatusDialog] = useState(false);
  const [eliminateOptionName, setEliminateOptionName] = useState(null);
  const [statusOptions, setStatusOptions] = useState({
    isLoading: false,
    rows: [],
    trackerName: '',
    pageSize: intialPageSize,
  });

  const canManageStudentTrackers = usePermissionCustomHook(
    'CAN_MANAGE_STUDENT_TRACKERS'
  );

  useEffect(() => {
    if (statusOptionsDialog) {
      fetchStatusOptions();
    }
  }, [statusOptionsDialog]);

  const fetchStatusOptions = async () => {
    setStatusOptions({ ...statusOptions, isLoading: true });
    try {
      const tracker = await getTracker(trackerId);
      setStatusOptions({
        ...statusOptions,
        isLoading: false,
        trackerName: tracker?.name,
      });
      setStatusOptions((prev) => ({
        ...prev,
        rows:
          tracker?.statusOptions?.map((status, index) => ({
            id: index,
            statusOption: status,
          })) || [],
      }));
    } catch (error) {
      handleErrorResponse(error?.response?.data?.message);
      setStatusOptions({ ...statusOptions, isLoading: false });
    }
  };

  const pageSizeChangeTracking = pageSizeChangeHandler(setStatusOptions);

  const handleClose = () => {
    setStatusOptionsDialog(false);
    setStatusOptions({ ...statusOptions, rows: [], trackerName: '' });
  };

  const handleNewStatus = () => {
    permissionCheck(
      canManageStudentTrackers,
      () => setAddStatusDialog(true),
      handleErrorResponse,
      canManageStudentTrackersPermissionMessage
    );
  };

  const handleRemoveStatus = (params) => {
    permissionCheck(
      canManageStudentTrackers,
      (params) => {
        const { row } = params;
        setEliminateOptionName(row.statusOption);
        setRemoveStatusDialog(true);
      },
      handleErrorResponse,
      canManageStudentTrackersPermissionMessage,
      params
    );
  };

  return (
    <>
      {showBackdropLoader(statusOptionsDialogLoading)}
      <Dialog open={statusOptionsDialog} PaperProps={userCountPaperProps}>
        {getDialogHeading('Status Options', handleClose, 'statusOptions')}
        <DialogHeading container>
          <MDTypography variant="h6">
            Tracker Name: {statusOptions?.trackerName}
          </MDTypography>
          <Button variant="text" onClick={handleNewStatus}>
            Add Status
          </Button>
        </DialogHeading>
        <DialogDataGrid>
          <DeltaDataGrid
            listData={statusOptions}
            columns={statusOptionColumns(handleRemoveStatus)}
            setListData={pageSizeChangeTracking}
          />
        </DialogDataGrid>
      </Dialog>
      <AddStatusDialog
        trackerId={trackerId}
        addStatusDialog={addStatusDialog}
        setAddStatusDialog={setAddStatusDialog}
        fetchStatusOptions={fetchStatusOptions}
        fetchTrackers={fetchTrackers}
      />
      <RemoveStatusDialog
        trackerId={trackerId}
        removeStatusDialog={removeStatusDialog}
        setRemoveStatusDialog={setRemoveStatusDialog}
        fetchStatusOptions={fetchStatusOptions}
        eliminateOptionName={eliminateOptionName}
        statusOptions={statusOptions}
        fetchTrackers={fetchTrackers}
      />
      <NotificationPopup />
    </>
  );
};

export default StatusOptionsDialog;
