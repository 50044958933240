import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  groupName: '',
  formName: '',
  members: [
    {
      username: '',
      userId: '',
      email: '',
      givenName: '',
      familyName: '',
    },
  ],
};

const groupUsersSlice = createSlice({
  name: 'delta/groupUsers',
  initialState: initialState,
  reducers: {
    groupUsers: (state, action) => action.payload,
    setFormName: (state, action) => {
      return {
        ...state,
        formName: action.payload,
      };
    },
    resetGroupUsers: () => [],
  },
});

export const { resetGroupUsers, groupUsers, setFormName } =
  groupUsersSlice.actions;

export default groupUsersSlice.reducer;
