import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';

const FileUploadField = ({ name, label, control, index }) => (
  <Controller
    name={`items[${index}].${name}`}
    control={control}
    defaultValue={null}
    render={({ field: { onChange, value, ref } }) => (
      <>
        <TextField
          label={label}
          variant="standard"
          value={value ? value.name : ''}
          onClick={() =>
            document.getElementById(`upload-${name}-${index}`).click()
          }
          style={{ width: '100%' }}
          InputProps={{
            readOnly: true,
          }}
        />
        <input
          id={`upload-${name}-${index}`}
          type="file"
          style={{ display: 'none' }}
          onChange={(e) => {
            onChange(e.target.files[0]);
          }}
          ref={ref}
        />
      </>
    )}
  />
);

export default FileUploadField;
