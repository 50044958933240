import { API } from 'aws-amplify';
import {
  STUDENTS_BASE_URL_APPEND,
  SEARCH_STUDENTS_BASE_URL_APPEND,
  STUDENT_ADDITIONAL_INFO_BASE_URL_APPEND,
} from 'constants';

export const getStudentDetails = async (studentId) => {
  const studentDetails = await API.get(
    'delta-api',
    `${STUDENTS_BASE_URL_APPEND}${studentId}`
  );
  return studentDetails;
};

export const getStudentAdditionalInfo = async (studentId) => {
  const studentDetails = await API.get(
    'delta-api',
    `${STUDENT_ADDITIONAL_INFO_BASE_URL_APPEND}${studentId}/additionalInfo`
  );
  return studentDetails;
};

export const putStudentAdditionalInfo = async (payload, studentId) => {
  const myData = {
    body: payload,
  };
  await API.put(
    'delta-api',
    `${STUDENT_ADDITIONAL_INFO_BASE_URL_APPEND}${studentId}/additionalInfo`,
    myData
  );
};

export const getSearchedStudents = async (
  studentSearchQuery,
  includePreviousSemesters,
  includeDropped,
  numResults
) => {
  const searchedStudents = await API.get(
    'delta-api',
    `${SEARCH_STUDENTS_BASE_URL_APPEND}?`,
    {
      headers: {},
      queryStringParameters: {
        q: studentSearchQuery,
        includePreviousSemesters: includePreviousSemesters,
        includeDropped: includeDropped,
        numResults: numResults,
      },
    }
  );
  return searchedStudents;
};

export const getSearchStudentDetails = async (studentId) => {
  const studentDetails = await API.get(
    'delta-api',
    `${STUDENT_ADDITIONAL_INFO_BASE_URL_APPEND}${studentId}`
  );
  return studentDetails;
};

export const putStudentUpdateNote = async (payload, studentId) => {
  const myData = {
    body: payload,
  };
  await API.put(
    'delta-api',
    `${STUDENT_ADDITIONAL_INFO_BASE_URL_APPEND}${studentId}/updateNote`,
    myData
  );
};