import { ActionWrapperBox } from 'components/Common/common.styled';
import { ButtonIcon, EditActionIcon } from 'components/Ui/styled';

export const renderTaskAction = (params, updateTask) => {
  return (
    <ActionWrapperBox>
      <ButtonIcon
        disabled={params.row.completed ? true : false}
        onClick={() => updateTask(params)}
      >
        <EditActionIcon />
      </ButtonIcon>
    </ActionWrapperBox>
  );
};
