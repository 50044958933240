import { createSlice } from '@reduxjs/toolkit';

export const attendanceAuditReportSlice = createSlice({
  name: 'delta/attendanceAuditReport',
  initialState: null,
  reducers: {
    setAttendanceAuditReport: (state, action) => action.payload,
    resetAttendanceAuditReport: () => null,
  },
});

export const { setAttendanceAuditReport, resetAttendanceAuditReport } =
  attendanceAuditReportSlice.actions;

export default attendanceAuditReportSlice.reducer;
