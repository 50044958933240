import { createSlice } from '@reduxjs/toolkit';
import {
  _setNewLocationFlag,
  _setBuildingId,
  _setLocation,
  _setTypeId,
  _updateLocationData,
} from './LocationAction';
import initialState from './LocationInitialState';

export const LocationSlice = createSlice({
  name: 'Location',
  initialState,
  reducers: {
    setNewLocationFlag: _setNewLocationFlag,
    updateLocationData: _updateLocationData,
    setBuildingId: _setBuildingId,
    setLocation: _setLocation,
    setTypeId: _setTypeId,
    resetLocation: () => initialState,
  },
});

export const {
  updateLocationData,
  setNewLocationFlag,
  setBuildingId,
  setLocation,
  setTypeId,
  resetLocation,
} = LocationSlice.actions;

export default LocationSlice.reducer;
