import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import { GridForm, GridButtonWrapper } from 'components/Ui/styled';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import {
  getDialogHeading,
  useNotification,
  renderSaveCancelButtons,
  onSave,
  emptyFunction,
  dropDownValidation,
} from 'utils/commonUtils';
import { PaperProps, statusSuccess } from 'constants';
import { postRemoveStatus } from 'layouts/studentTracking/services/studentTrackerApi';
import FormInputDropdown from 'components/Common/Forms/FormInputDropDown';

const schema = yup.object().shape({
  replaceWithOptionName: dropDownValidation,
});

const RemoveStatusDialog = (props) => {
  const {
    trackerId,
    removeStatusDialog,
    setRemoveStatusDialog,
    fetchStatusOptions,
    fetchTrackers,
    eliminateOptionName,
    statusOptions,
  } = props;

  const dispatch = useDispatch();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();

  const [loadingButton, setLoadingButton] = useState(false);

  const defaultValue = {
    eliminateOptionName: eliminateOptionName,
    replaceWithOptionName: '',
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset, control, formState } = useFunction;
  const { errors } = formState;

  useEffect(() => {
    reset(defaultValue);
  }, [defaultValue.eliminateOptionName, defaultValue.replaceWithOptionName]);

  const handleClose = () => {
    reset(defaultValue);
    setRemoveStatusDialog(false);
  };

  const fetchTrackersAndStatusOptions = () => {
    fetchStatusOptions();
    fetchTrackers();
  };

  const onSubmit = async (data) => {
    const { eliminateOptionName, replaceWithOptionName } = data;
    const payload = {
      eliminateOptionName,
      replaceWithOptionName,
    };
    onSave(
      statusSuccess,
      true,
      postRemoveStatus,
      emptyFunction,
      payload,
      '',
      fetchTrackersAndStatusOptions,
      handleClose,
      setOpenNotification,
      setNotificationMessage,
      dispatch,
      setLoadingButton,
      loadingButton,
      trackerId
    );
  };

  const statusOptionsDropdown = statusOptions?.rows?.map((item) => ({
    id: item.statusOption,
    value: item.statusOption,
  }));

  return (
    <>
      <Dialog open={removeStatusDialog} PaperProps={PaperProps}>
        {getDialogHeading('Replace Status', handleClose, 'replaceStatus')}
        <GridForm container spacing={2}>
          <Grid item xs={12}>
            <FormInputText
              name="eliminateOptionName"
              type="text"
              control={control}
              label="Eliminate Option Name"
              readOnly
            />
            <FormInputDropdown
              name="replaceWithOptionName"
              control={control}
              options={statusOptionsDropdown}
              defaultValue={defaultValue?.replaceWithOptionName}
              label="Replace With Option Name"
              helperText={errors?.replaceWithOptionName?.message}
              errors={!!errors.replaceWithOptionName}
            />
          </Grid>
        </GridForm>
        <GridButtonWrapper container>
          {renderSaveCancelButtons(
            handleClose,
            handleSubmit(onSubmit),
            loadingButton
          )}
        </GridButtonWrapper>
      </Dialog>
      <NotificationPopup />
    </>
  );
};

export default RemoveStatusDialog;
