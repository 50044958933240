import { API } from 'aws-amplify';

// Get Calendar Events
export const getCalenderEvents = async (
  payload,
  getCurrentYear,
  getCurrentMonth
) => {
  const events = await API.get(
    'delta-api',
    `calendar/events/${payload}?year=${getCurrentYear}&month=${getCurrentMonth}`
  );
  return events;
};

// Post Calendar Event
export const postCalenderEvents = async (payload, currentCalenderId) => {
  const myInit = {
    body: payload,
  };
  await API.post('delta-api', `calendar/events/${currentCalenderId}`, myInit);
};

// Edit existing calendar Event
export const putCalenderEvent = async (payload, currentCalenderId, eventId) => {
  const myInit = {
    body: payload,
  };
  await API.put(
    'delta-api',
    `calendar/events/${currentCalenderId}/${eventId}`,
    myInit
  );
};

// get perticular calendar event in fields while editing
export const getCalenderEvent = async (calenderId, eventId) => {
  const events = await API.get(
    'delta-api',
    `calendar/events/${calenderId}/${eventId}`
  );
  return events;
};

// delete existing event
export const deleteCalenderEvent = async (postParams, currentCalenderId) => {
  const events = await API.del(
    'delta-api',
    `calendar/events/${currentCalenderId}/${postParams.event.eventId}`
  );
  return events;
};
