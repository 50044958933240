import { API } from 'aws-amplify';
import { ANNOUNCEMENT_BASE_URL_APPEND } from 'constants';

export const getAnnouncements = async (
  pageSize,
  announcementPageKey,
  page,
  retrieveExpired,
  adminListAll
) => {
  let announcements = '';
  let url = `announcements?limit=${pageSize}`;
  if (retrieveExpired === true) {
    if (page !== 1) {
      url += `&paginationKey=${announcementPageKey}`;
    }
    url += `&retrieveExpired=${retrieveExpired}`;
  }
  if (adminListAll === true) {
    url += `&adminListAll=${adminListAll}`;
  }
  announcements = await API.get(
    'delta-api',
    `${ANNOUNCEMENT_BASE_URL_APPEND}${url}`
  );
  return announcements;
};

export const getAnnouncement = async (id) => {
  const announcement = await API.get(
    'delta-api',
    `${ANNOUNCEMENT_BASE_URL_APPEND}announcements/${id}`
  );
  return announcement;
};

export const createAnnouncement = async (payload) => {
  const myInit = {
    body: payload,
  };
  await API.post(
    'delta-api',
    `${ANNOUNCEMENT_BASE_URL_APPEND}announcements`,
    myInit
  );
};

export const updateAnnouncement = async (payload, id) => {
  const myInit = {
    body: payload,
  };
  await API.put(
    'delta-api',
    `${ANNOUNCEMENT_BASE_URL_APPEND}announcements/${id}`,
    myInit
  );
};

export const deleteAnnouncement = async (id) => {
  await API.del(
    'delta-api',
    `${ANNOUNCEMENT_BASE_URL_APPEND}announcements/${id}`
  );
};

export const getAnnouncementsForNotifications = async () => {
  const announcements = await API.get(
    'delta-api',
    `${ANNOUNCEMENT_BASE_URL_APPEND}announcements`
  );
  return announcements;
};
