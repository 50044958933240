import { createSlice } from '@reduxjs/toolkit';
import initialState from './uploadDocumentInitialState';
import {
  _setUploadDocumentPath,
  _setUploadDocumentNewPath,
  _setFolderData,
  _setNewSubFolder,
  _setSubFolderName,
} from './uploadDocumentAction';

export const uploadDocumentSlice = createSlice({
  name: 'delta/uploadDocument',
  initialState,
  reducers: {
    setUploadDocumentPath: _setUploadDocumentPath,
    setUploadDocumentNewPath: _setUploadDocumentNewPath,
    setFolderData: _setFolderData,
    setNewSubFolder: _setNewSubFolder,
    setSubFolderName: _setSubFolderName,
    resetUploadDocumentForm: () => initialState,
  },
});

export const {
  setUploadDocumentPath,
  setUploadDocumentNewPath,
  setFolderData,
  setNewSubFolder,
  setSubFolderName,
  resetUploadDocumentForm,
} = uploadDocumentSlice.actions;

export default uploadDocumentSlice.reducer;
