import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers';
import { Controller } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePickeDesktop } from 'components/Ui/styled';

const MultiFormDateField = ({ name, label, control, index }) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <Controller
      name={`items[${index}].${name}`}
      control={control}
      defaultValue={null}
      render={({ field: { onChange, value } }) => (
        <DatePickeDesktop
          label={label}
          inputFormat="MM/dd/yyyy"
          value={value || null}
          onChange={onChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              fullWidth
            />
          )}
        />
      )}
    />
  </LocalizationProvider>
);

export default MultiFormDateField;