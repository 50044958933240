import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import MDBox from 'components/MDBox';
import { useNavigate } from 'react-router-dom';
import { getFolderBrowse, getFolders } from '../services/documentsApi';
import { useSelector, useDispatch } from 'react-redux';
import {
  RoundedIconFolder,
  DocumentGrid,
  FullCard,
  WhiteTooltip,
  TextTypography,
} from 'components/Ui/styled';
import {
  showLoader,
  getDataGridHeading,
  truncateText,
} from 'utils/commonUtils';
import {
  getFoldersData,
  resetFoldersData,
} from '../store/foldersSlice/foldersSlice';
import { getSubFoldersData } from '../store/subFoldersSlice/subFoldersSlice';
import {
  resetFolderPath,
  setFolderPath,
} from '../store/folderPathSlice/folderPathSlice';
import {
  setFolderData,
  setUploadDocumentPath,
  setUploadDocumentNewPath,
} from '../store/uploadDocumentSlice/uploadDocumentSlice';

const FolderList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetchFolders();
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetFoldersData());
    };
  }, []);

  const fetchFolders = async () => {
    const foldersData = await getFolders();
    dispatch(getFoldersData(foldersData?.items));
    setIsLoading(false);
  };

  const folders = useSelector((state) => state.folders);

  const handleFolder = async (item) => {
    dispatch(resetFolderPath([]));
    navigate(`/documents/folders/subFolder`);
    const rootPath = {
      path: '/',
      name: 'Root',
    };
    dispatch(setFolderPath(rootPath));
    dispatch(setFolderData(rootPath));
    dispatch(setUploadDocumentPath(rootPath.path));
    dispatch(setUploadDocumentNewPath(rootPath.path));
    const subfoldersData = await getFolderBrowse(item.id, '/');
    dispatch(getSubFoldersData(subfoldersData));
  };

  return (
    <>
      {isLoading ? (
        showLoader()
      ) : (
        <>
          <MDBox>
            <FullCard>
              {getDataGridHeading('Folders')}
              <MDBox pt={2}></MDBox>
              <Grid container spacing={2} style={{ padding: '0px 24px' }}>
                {folders?.map((item, index) => {
                  return (
                    <DocumentGrid
                      item
                      xs={12}
                      sm={4}
                      md={1}
                      key={index}
                      onClick={() => handleFolder(item)}
                    >
                      <RoundedIconFolder />
                      <WhiteTooltip title={item.name}>
                        <TextTypography variant="body2">
                          {truncateText(item.name)}
                        </TextTypography>
                      </WhiteTooltip>
                    </DocumentGrid>
                  );
                })}
              </Grid>
            </FullCard>
          </MDBox>
        </>
      )}
    </>
  );
};

export default FolderList;
