import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { DialogStyleBox } from 'components/Ui/styled';
import { getDialogHeading, pageSizeChangeHandler } from 'utils/commonUtils';
import { DialogStyle, intialPageSize } from 'constants';
import DeltaDataGrid from 'utils/CommonDataGrid/CommonDataGrid';

const StudentProfileCourseDialog = (props) => {
  const { open, setOpen, studentProfileCourses, setOpenStudentProfileCourses } =
    props;

  const [courseList, setCourseList] = useState({
    isLoading: false,
    rows: [],
    pageSize: intialPageSize,
    page: 1,
  });

  const { pageSize, page } = courseList;

  useEffect(() => {
    if (studentProfileCourses) {
      fetchDataCourseInformation();
    }
  }, [pageSize, page, studentProfileCourses]);

  const fetchDataCourseInformation = () => {
    setCourseList((previous) => ({
      ...previous,
      rows: studentProfileCourses?.map((item, index) => ({
        id: index,
        instructor: item.instructor,
        courseName: item.courseName,
        courseNumber: item.courseNumber,
      })),
    }));
  };

  const handleClose = () => {
    setOpen(false);
    setOpenStudentProfileCourses(null);
  };

  const columns = [
    {
      headerName: 'Instructor',
      field: 'instructor',
      flex: 1,
    },
    {
      headerName: 'Course Name',
      field: 'courseName',
      flex: 1,
    },
    {
      headerName: 'Course Number',
      field: 'courseNumber',
      flex: 1,
    },
  ];

  const pageSizeChange = pageSizeChangeHandler(setCourseList);

  return (
    <>
      <Dialog open={open} PaperProps={DialogStyle}>
        {getDialogHeading('Student Courses', handleClose)}
        <DialogStyleBox>
          <DeltaDataGrid
            listData={courseList}
            columns={columns}
            setListData={pageSizeChange}
          />
        </DialogStyleBox>
      </Dialog>
    </>
  );
};

export default StudentProfileCourseDialog;
