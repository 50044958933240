import React, { useState, useEffect } from 'react';
import MDBox from 'components/MDBox';
import Card from '@mui/material/Card';
import Tabs from '@mui/material/Tabs';
import { useForm } from 'react-hook-form';
import MDTypography from 'components/MDTypography';
import { useDispatch, useSelector } from 'react-redux';
import { statusExtender } from '../../common/constants';
import { DropDownBox } from 'layouts/inventory/Inventory.styled';
import { showLoader, showBackdropLoader } from 'utils/commonUtils';
import { renderAttendanceDialogEdit } from '../../AttendanceDialogEdit';
import { getAttendanceAuditReport } from '../services/attendanceAuditApi';
import FormInputDropdown from 'components/Common/Forms/FormInputDropDown';
import { AttendanceHeadingBox, TabWrapperBox } from '../../Attendance.styled';
import { setIsAttendanceAudit, setTabValue } from '../../store/semesterSlice';
import {
  generateAttendanceTabPanel,
  generateAttendanceTabs,
  getGradeRows,
  getGradeValue,
  getGridCellClass,
  getTabSchoolGrade,
  renderAttendanceCell,
} from '../../utils/attendanceUtils';
import {
  resetAttendanceAuditReport,
  setAttendanceAuditReport,
} from '../store/attendanceAuditReportSlice';

const AttendanceAuditReportList = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingTab, setIsLoadingTab] = useState(false);
  const [selectedSemester, setSelectedSemester] = useState('');
  const [tableData, setTableData] = useState({ columns: [], rows: [] });
  const [gradeTableData, setGradeTableData] = useState({
    ...tableData,
  });

  const { rows, columns } = tableData;

  const attendanceAuditReportId = useSelector(
    (state) => state.attendanceAuditReportId
  );

  const attendanceAuditReport = useSelector(
    (state) => state.attendanceAuditReport
  );

  const semesterList = useSelector((state) => state.semesterList);

  const { tabValue, isAttendanceAudit } = semesterList;

  const defaultValue = {
    selectedSemester: selectedSemester,
    tabValue: tabValue,
  };

  const useFunction = useForm({
    defaultValues: defaultValue,
  });
  const { control, register, reset } = useFunction;

  useEffect(() => {
    if (attendanceAuditReportId) {
      dispatch(resetAttendanceAuditReport());
      fetchAttendanceAudit();
    }
  }, [attendanceAuditReportId]);

  useEffect(() => {
    if (attendanceAuditReport) {
      setSelectedSemester(attendanceAuditReport?.auditSemesters?.[0]);
    }
  }, [attendanceAuditReport]);

  const semesterDropDownList = attendanceAuditReport?.auditSemesters?.map(
    (item, index) => ({
      id: item,
      value: item,
    })
  );

  useEffect(() => {
    if (!isLoading && defaultValue.selectedSemester) {
      setIsLoadingTab(true);
      fetchAttendanceAuditList();
      setTimeout(() => {
        setIsLoadingTab(false);
      }, [1000]);
    }
  }, [
    isLoading,
    defaultValue.selectedSemester,
    attendanceAuditReportId,
    tabValue,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetAttendanceAuditReport());
    };
  }, [dispatch]);

  const fetchAttendanceAudit = async () => {
    const attendanceAuditReport = await getAttendanceAuditReport(
      attendanceAuditReportId
    );
    dispatch(setAttendanceAuditReport(attendanceAuditReport));
    setIsLoading(false);
  };

  const fetchAttendanceAuditList = async () => {
    const { schoolWeeksBySemester, attendanceRecordsBySemester } =
      attendanceAuditReport;
    const semester = selectedSemester;
    const { [semester]: schoolWeeks } = schoolWeeksBySemester;
    const { [semester]: attendanceRecords } = attendanceRecordsBySemester;
    var columns = [
      {
        headerName: 'Student Name',
        field: 'name',
        width: 225,
        cellClassName: (params) =>
          params.row.schoolId == 59952
            ? 'gameChangers'
            : params.row.schoolId == 92660
            ? 'rapa'
            : params.row.schoolId == 59953
            ? 'daySchool'
            : '',
      },
    ];
    schoolWeeks?.map(({ descr }) =>
      columns.push({
        headerName: descr,
        field: descr + statusExtender,
        width: 150,
        align: 'center',
        cellClassName: (params) => getGridCellClass(params),
        renderEditCell: (params) =>
          renderAttendanceDialogEdit(params, false, fetchAttendanceAuditList),
        renderCell: (params) => renderAttendanceCell(params, descr),
        editable: true,
      })
    );
    const gradeValue = getGradeValue(tabValue);
    let students;
    const filteredTabAttendanceRecords = attendanceRecords?.filter(
      (record) => record.grade === gradeValue.toString()
    );
    students = filteredTabAttendanceRecords?.map((student) => {
      for (let i = 0; i < student.notes.length; i++) {
        let note = student.notes[i];
        student[note.week + '-Status'] = note.label;
        student[note.week + '-Note'] = note.note;
      }
      return {
        ...student,
        name: student.lastName + ', ' + student.firstName,
      };
    });
    students = students.sort((a, b) => (a.name > b.name ? 1 : -1));
    const rows = students.map((student) => {
      return { ...student, id: student.studentId, actions: student.studentId };
    });
    setTableData({ columns: columns, rows: rows });
  };

  useEffect(() => {
    dispatch(setIsAttendanceAudit(true));
    return () => {
      dispatch(setIsAttendanceAudit(false));
    };
  }, []);

  useEffect(() => {
    reset(defaultValue);
  }, [defaultValue.selectedSemester]);

  const setGradeRows = () => {
    const currentSchoolGrade = getTabSchoolGrade(tabValue);
    const gradeRows = getGradeRows(rows, currentSchoolGrade);
    setGradeTableData(() => {
      return { columns, rows: gradeRows };
    });
  };

  useEffect(() => {
    if (rows.length !== 0) {
      setGradeRows();
      setIsLoading(false);
    }
  }, [rows, tabValue]);

  const handleTabChange = (event, newTabValue) => {
    dispatch(setTabValue(newTabValue));
  };

  const handleSemesterChange = (e) => {
    setIsLoadingTab(true);
    dispatch(setSelectedSemester(e.target.value));
  };

  const getHeadingWithFormFields = () => {
    return (
      <>
        <AttendanceHeadingBox>
          <MDTypography variant="h5" fontWeight="medium">
            Attendance Report
          </MDTypography>
          <DropDownBox>
            <FormInputDropdown
              name="selectedSemester"
              type="text"
              control={control}
              options={semesterDropDownList}
              label="Select Semester"
              register={register('selectedSemester', {
                onChange: (e) => {
                  handleSemesterChange(e);
                },
              })}
            />
          </DropDownBox>
        </AttendanceHeadingBox>
      </>
    );
  };

  return (
    <>
      {isLoading ? (
        showLoader()
      ) : (
        <>
          <MDBox>
            <Card>
              {getHeadingWithFormFields()}
              <TabWrapperBox>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="School grades"
                >
                  {generateAttendanceTabs()}
                </Tabs>
                {isLoadingTab && showBackdropLoader(isLoadingTab)}
                {generateAttendanceTabPanel(
                  tabValue,
                  gradeTableData,
                  '',
                  isAttendanceAudit
                )}
              </TabWrapperBox>
            </Card>
          </MDBox>
        </>
      )}
    </>
  );
};

export default AttendanceAuditReportList;
