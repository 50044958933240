import React, { useEffect, useState } from 'react';
import MDBox from 'components/MDBox';
import { useSelector } from 'react-redux';
import { intialPageSize } from 'constants';
import { GridWrapper } from 'components/Ui/styled';
import { pageSizeChangeHandler } from 'utils/commonUtils';
import DeltaDataGrid from 'utils/CommonDataGrid/CommonDataGrid';
import { createColumn } from './ScheduleTabUtils/ScheduleTabCommonUtils';

const ScheduleTab = () => {
  const [coursesList, setCoursesList] = useState({
    isLoading: false,
    rows: [],
    pageSize: intialPageSize,
  });

  const courses =
    useSelector((state) => state?.studentDetails?.profile?.courses) || [];

  useEffect(() => {
    fetchStudentCourse();
  }, [courses]);

  const fetchStudentCourse = () => {
    setCoursesList((previous) => ({
      ...previous,
      rows: courses?.map((item, index) => ({
        ...item,
        id: index,
      })),
    }));
  };

  const pageSizeChange = pageSizeChangeHandler(setCoursesList);

  const coursesListColumns = [
    createColumn('courseName', 'Course Name'),
    createColumn('courseNumber', 'Course Number'),
    createColumn('instructor', 'Instructor'),
  ];

  return (
    <>
      <MDBox pt={3}></MDBox>
      <GridWrapper container spacing={2}>
        <DeltaDataGrid
          listData={coursesList}
          columns={coursesListColumns}
          setListData={pageSizeChange}
        />
      </GridWrapper>
    </>
  );
};

export default ScheduleTab;
