import { createSlice } from '@reduxjs/toolkit';

export const myPermissionsSlice = createSlice({
  name: 'delta',
  initialState: null,
  reducers: {
    getMyPermissionsData: (state, action) => action.payload,
    resetMyPermissionsData: () => null,
  },
});

export const { getMyPermissionsData, resetMyPermissionsData } =
  myPermissionsSlice.actions;

export default myPermissionsSlice.reducer;
