import React from 'react';
import { Grid } from '@mui/material';
import { FormInputDate } from 'components/Common/Forms/FormInputDate';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import { GridForm, HeadingMuiTypography } from 'components/Ui/styled';

const EmergencyContactForm = (props) => {
  const { control } = props;
  return (
    <>
      <HeadingMuiTypography variant="h6">
        Emergency Contact
      </HeadingMuiTypography>
      <GridForm container spacing={1}>
        <Grid item xs={12} sm={6}>
          <FormInputText
            name="emergencyFirstName"
            type="text"
            control={control}
            label="First Name"
            testId="emergencyFirstName"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInputText
            name="emergencyLastName"
            type="text"
            control={control}
            label="Last Name"
            testId="emergencyLastName"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormInputText
            name="emergencyEmail"
            type="text"
            control={control}
            label="Email"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormInputText
            name="emergencyPhoneNumber"
            type="text"
            control={control}
            label="Phone Number"
            testId="emergencyPhoneNumber"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormInputText
            name="emergencyHomeAddress"
            type="text"
            control={control}
            label="Home Address"
            testId="emergencyHomeAddress"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormInputText
            name="emergencyRelation"
            type="text"
            control={control}
            label="Relation"
            testId="emergencyRelation"
          />
        </Grid>
      </GridForm>
    </>
  );
};

export default EmergencyContactForm;
