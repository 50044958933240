import { createSlice } from '@reduxjs/toolkit';
import { _setAssetsPaginationData, _setAssetsPreviousKey } from './assetAction';

const initialState = {
  assetsPaginationData: [],
  assetsPreviousKey: '',
};

export const assetsPaginationDataSlice = createSlice({
  name: 'delta',
  initialState,
  reducers: {
    setAssetsPaginationData: _setAssetsPaginationData,
    setAssetsPreviousKey: _setAssetsPreviousKey,
    resetAssetsPaginationData: () => initialState,
  },
});

export const {
  setAssetsPaginationData,
  setAssetsPreviousKey,
  resetAssetsPaginationData,
} = assetsPaginationDataSlice.actions;

export default assetsPaginationDataSlice.reducer;
