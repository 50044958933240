export const _setAssets = (state, action) => action.payload;

export const _setNewAssetFlag = (state, action) => {
  state.newAssetFlag = action.payload;
};

export const _setNewNotesFlag = (state, action) => {
  state.newNotesFlag = action.payload;
};

export const _setAssetTag = (state, action) => {
  state.assetTag = action.payload;
};

export const _setTypeId = (state, action) => {
  state.typeId = action.payload;
};

export const _setModel = (state, action) => {
  state.model = action.payload;
};

export const _setLocationId = (state, action) => {
  state.locationId = action.payload;
};

export const _setSerial = (state, action) => {
  state.serial = action.payload;
};

export const _setBuildingId = (state, action) => {
  state.buildingId = action.payload;
};

export const _setAcquired = (state, action) => {
  state.acquired = action.payload;
};

export const _setVerified = (state, action) => {
  state.verified = action.payload;
};

export const _setFundingId = (state, action) => {
  state.fundingId = action.payload;
};

export const _setNotesDate = (state, action) => {
  state.notes[state.selectedNotesId].date = action.payload;
};

export const _setNotes = (state, action) => {
  state.notes[state.selectedNotesId].note = action.payload;
};

export const _setSelectedNotesId = (state, action) => {
  state.selectedNotesId = action.payload;
};

export const _setManu = (state, action) => {
  state.manu = action.payload;
};

export const _setNewNotes = (state, action) => {
  state.notes = [...state.notes, action.payload];
};

export const _setMacAddress = (state, action) => {
  state.macAddress = action.payload;
};

export const _setIpAddress = (state, action) => {
  state.ipAddress = action.payload;
};

export const _setAssetsPaginationData = (state, action) => {
  state.assetsPaginationData = [...state.assetsPaginationData, action.payload];
};

export const _setAssetsPreviousKey = (state, action) => {
  state.assetsPreviousKey = action.payload;
};
