import { createSlice } from '@reduxjs/toolkit';

const calendarsSlice = createSlice({
  name: 'delta',
  initialState: null,
  reducers: {
    getCalendarsData: (state, action) => action.payload,
    resetCalendarsData: () => null,
  },
});

export const { getCalendarsData, resetCalendarsData } = calendarsSlice.actions;

export default calendarsSlice.reducer;
