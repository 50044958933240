import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import Dialog from '@mui/material/Dialog';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { putFileUpload } from '../services/documentsApi';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import { displaySnackbar } from 'components/snackbar/store/SnackbarSlice';
import {
  GridForm,
  ButtonIcon,
  UploadBox,
  UploadText,
  RoundedCloudUpload,
  ButtonSpinnerProgress,
} from 'components/Ui/styled';
import {
  renderSaveCancelButtons,
  useNotification,
  getDialogHeading,
  fieldValidation,
  getSnackBar,
} from 'utils/commonUtils';
import { booleanOptions, PaperPropsCheck, characterLimit } from 'constants';
import FormInputDropdown from 'components/Common/Forms/FormInputDropDown';
import {
  setNewSubFolder,
  setSubFolderName,
  setUploadDocumentPath,
} from '../store/uploadDocumentSlice/uploadDocumentSlice';

const DocumentsUploadDialog = (props) => {
  const { open, setOpen, fetchFolders } = props;
  const dispatch = useDispatch();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const [key, setKey] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUploadUrl, setFileUploadUrl] = useState(null);
  const [isUploadAccess, setIsUploadAccess] = useState(false);
  const [uploadAccessError, setUploadAccessError] = useState(null);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const subFolders = useSelector((state) => state.subFolders);
  const uploadDocumentForm = useSelector((state) => state.uploadDocumentForm);

  const schemaProperties = {
    path: fieldValidation(4000),
    key: fieldValidation(4000),
  };

  if (uploadDocumentForm?.newSubFolder) {
    schemaProperties.subFolderName = fieldValidation(characterLimit);
  }

  const uploadDocumentSchema = yup.object().shape(schemaProperties);

  const defaultValue = {
    path: uploadDocumentForm?.uploadDocumentNewPath,
    key: key,
    newSubFolder: uploadDocumentForm?.newSubFolder,
    subFolderName: uploadDocumentForm?.subFolderName,
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
    resolver: yupResolver(uploadDocumentSchema),
  });

  const { handleSubmit, reset, control, register, formState } = useFunction;
  const { errors } = formState;

  useEffect(() => {
    if (selectedFile) {
      fileUpload();
    }
  }, [selectedFile]);

  const fileUpload = async () => {
    setLoadingButton(true);
    const payload = {
      path: uploadDocumentForm?.uploadDocumentNewPath,
      key,
    };
    try {
      const fileUploadUrl = await putFileUpload(
        payload,
        subFolders?.sharedFolderId
      );
      setFileUploadUrl(fileUploadUrl?.url);
      setLoadingButton(false);
    } catch (error) {
      setIsUploadAccess(true);
      setLoadingButton(false);
      setUploadAccessError(error.response.data.message);
    }
  };

  useEffect(() => {
    reset(defaultValue);
  }, [
    defaultValue.path,
    defaultValue.key,
    defaultValue.newSubFolder,
    defaultValue.subFolderName,
  ]);

  const handleClose = () => {
    setSelectedFile(null);
    setKey(null);
    setFileUploadUrl(null);
    setIsUploadAccess(false);
    setUploadAccessError(null);
    reset(defaultValue);
    setOpen(false);
    dispatch(setUploadDocumentPath(uploadDocumentForm?.uploadDocumentPath));
    dispatch(setSubFolderName(''));
    dispatch(setNewSubFolder(false));
  };

  const changeHandler = (event) => {
    const selected = event.target.files[0];
    if (!selected) {
      return;
    }
    setSelectedFile(selected);
    setIsFilePicked(true);
    setKey(event.target.files[0]?.name);
  };

  const onSubmit = async () => {
    setIsLoading(true);
    const snackBar = getSnackBar('File uploaded successfully');
    if (isUploadAccess) {
      setOpenNotification(true);
      setNotificationMessage(uploadAccessError);
    } else {
      try {
        await axios.put(fileUploadUrl, selectedFile, {
          headers: {
            'Content-Type': '',
          },
        });
        handleClose();
        dispatch(displaySnackbar(snackBar));
        fetchFolders(uploadDocumentForm?.folderData);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
    setIsLoading(false);
  };

  const handleSubFolderName = (e) => {
    dispatch(setSubFolderName(e.target.value));
  };

  return (
    <>
      <Dialog open={open} PaperProps={PaperPropsCheck}>
        {getDialogHeading('Document Upload', handleClose)}
        <GridForm container spacing={2}>
          <Grid item xs={12}>
            <FormInputText
              name="path"
              type="text"
              control={control}
              label="Path"
              helperText={errors?.path?.message}
              errors={!!errors.path}
              disabled={true}
            />
            <FormInputText
              name="key"
              type="text"
              control={control}
              label="File Name"
              helperText={errors?.key?.message}
              errors={!!errors.key}
              disabled={true}
            />
            <FormInputDropdown
              name="newSubFolder"
              type="text"
              control={control}
              options={booleanOptions}
              label="Do you want to upload a document in new sub folder ?"
              register={register('newSubFolder', {
                onChange: (e) => dispatch(setNewSubFolder(e.target.value)),
              })}
            />
            {uploadDocumentForm?.newSubFolder && (
              <FormInputText
                name="subFolderName"
                type="text"
                control={control}
                label="Sub Folder Name"
                helperText={errors?.subFolderName?.message}
                errors={!!errors.subFolderName}
                register={register('subFolderName', {
                  onChange: (e) => {
                    handleSubFolderName(e);
                  },
                })}
              />
            )}
          </Grid>
        </GridForm>
        <GridForm container spacing={2}>
          <Grid item xs={12}>
            <UploadBox>
              <ButtonIcon style={{ padding: '0px' }}>
                <label
                  htmlFor="contained-button-file"
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <UploadText variant="h6">Choose file to upload</UploadText>
                  <RoundedCloudUpload fontSize="large" />
                  <Input
                    //accept='image/*'
                    id="contained-button-file"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={changeHandler}
                  />
                </label>
              </ButtonIcon>
              {loadingButton && <ButtonSpinnerProgress size={24} />}
            </UploadBox>
          </Grid>
          {renderSaveCancelButtons(
            handleClose,
            handleSubmit(onSubmit),
            isLoading
          )}
        </GridForm>
      </Dialog>
      <NotificationPopup />
    </>
  );
};

export default DocumentsUploadDialog;
