import { createSlice } from '@reduxjs/toolkit';
import initialState from './userManagementInitialState';
import {
  _setEmail,
  _setFamilyName,
  _setGivenName,
  _setUserName,
} from './userReferenceAction';

const userManagementFormSlice = createSlice({
  name: 'delta/userManagementFormSlice',
  initialState,
  reducers: {
    updateUserManagementForm: (state, action) => action.payload,
    newUserManagementForm: (state, action) => action.payload,
    deleteUserManagementForm: (state, action) => action.payload,
    setResendInvitation: (state, action) => action.payload,
    setEmail: _setEmail,
    setGivenName: _setGivenName,
    setFamilyName: _setFamilyName,
    setUserName: _setUserName,
    resetUserManagement: () => null,
  },
});

export const {
  resetUserManagements,
  updateUserManagementForm,
  setEmail,
  setGivenName,
  setFamilyName,
  setUserName,
  newUserManagementForm,
  deleteUserManagementForm,
  setResendInvitation,
} = userManagementFormSlice.actions;

export default userManagementFormSlice.reducer;
