import { createSlice } from '@reduxjs/toolkit';
import { _setScoresOptions } from './scoresOptionsAction';

export const scoresOptionsSlice = createSlice({
  name: 'delta/scoresOptions',
  initialState: null,
  reducers: {
    setScoresOptions: _setScoresOptions,
    resetScoresOptions: () => null,
  },
});

export const { setScoresOptions, resetScoresOptions } =
scoresOptionsSlice.actions;

export default scoresOptionsSlice.reducer;
