import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import { GridForm } from 'components/Ui/styled';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { PaperProps, fundingTypeSuccess, characterLimit } from 'constants';
import { postFundingType, putFundingType } from './services/FundingTypeApi';
import {
  renderSaveCancelButtons,
  useNotification,
  getDialogHeading,
  onSave,
  fieldValidation,
} from 'utils/commonUtils';

const fundingTypeSchema = yup.object().shape({
  funding: fieldValidation(characterLimit),
});

const FundingTypeDialog = (props) => {
  const [loadingButton, setLoadingButton] = useState(false);
  const dispatch = useDispatch();
  const { open, setOpen, fetchFundingTypeData } = props;
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();

  const FundingType = useSelector((state) => state?.fundingType);
  const { newFundingType, id, funding } = FundingType;

  const defaultValue = { id: id, funding: funding };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
    resolver: yupResolver(fundingTypeSchema),
  });
  const { handleSubmit, reset, control, formState } = useFunction;
  const { errors } = formState;

  useEffect(() => {
    reset(defaultValue);
  }, [defaultValue.id, defaultValue.funding]);

  const handleClose = () => {
    setOpen(false);
    reset(defaultValue);
  };

  const onSubmit = (data) => {
    const { funding } = data;
    const payload = {
      data: { funding },
    };
    onSave(
      fundingTypeSuccess,
      newFundingType,
      postFundingType,
      putFundingType,
      payload,
      id,
      fetchFundingTypeData,
      handleClose,
      setOpenNotification,
      setNotificationMessage,
      dispatch,
      setLoadingButton
    );
  };

  return (
    <>
      <Dialog open={open} PaperProps={PaperProps}>
        {getDialogHeading('Funding Type', handleClose)}
        <GridForm container>
          <FormInputText
            name='funding'
            type='text'
            control={control}
            required={true}
            label='Funding'
            helperText={errors?.funding?.message}
            errors={!!errors.funding}
          />
          {renderSaveCancelButtons(
            handleClose,
            handleSubmit(onSubmit),
            loadingButton
          )}
        </GridForm>
      </Dialog>
      <NotificationPopup />
    </>
  );
};

export default FundingTypeDialog;
