export const _setIncludeCompleted = (state, action) => {
  state.includeCompleted = action.payload;
};

export const _setOldestFirst = (state, action) => {
  state.oldestFirst = action.payload;
};

export const _setLimitToType = (state, action) => {
  state.limitToType = action.payload;
};

export const _setUser = (state, action) => {
  state.user = action.payload;
};
