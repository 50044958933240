import { ActionWrapperBox } from 'components/Common/common.styled';
import { ButtonIcon, RoundedIconVisibility } from 'components/Ui/styled';

export const renderReportAction = (params, viewReport) => {
  return (
    <ActionWrapperBox>
      <ButtonIcon onClick={() => viewReport(params)}>
        <RoundedIconVisibility />
      </ButtonIcon>
    </ActionWrapperBox>
  );
};
