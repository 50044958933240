import { rowsPerPage } from 'constants';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { getRowClassName, getRowHeight } from 'utils/commonUtils';
import { renderCellExpand } from 'components/Common/GridCell/GridCellExpand';
import { renderDocumentFolderAction } from './DocumentsTabDataGridAction';

export const getDocumentsFolderDataGrid = (
  documentsFolders,
  documentsFoldersColumns,
  setDocumentsFolders
) => {
  const { rows, pageSize, isLoading } = documentsFolders;

  return (
    <DataGridPro
      autoHeight
      getRowHeight={getRowHeight}
      rows={rows}
      columns={documentsFoldersColumns}
      pagination
      rowsPerPageOptions={rowsPerPage}
      pageSize={pageSize}
      getRowClassName={(params) => getRowClassName(params)}
      loading={isLoading}
      onPageSizeChange={(newPageSize) =>
        setDocumentsFolders((prveState) => ({
          ...prveState,
          pageSize: newPageSize,
        }))
      }
    />
  );
};

export const documentsFoldersColumns = (handleUploadViewDocuments) => [
  {
    field: 'action',
    headerName: 'Upload/View',
    sortable: false,
    disableColumnMenu: true,
    width: 120,
    renderCell: (params) =>
      renderDocumentFolderAction(params, handleUploadViewDocuments),
  },
  {
    headerName: 'Name',
    field: 'name',
    renderCell: renderCellExpand,
    flex: 1,
  },
  {
    headerName: 'Description',
    field: 'description',
    renderCell: renderCellExpand,
    flex: 1,
  },
];

export const getDocumentFoldersListData = (documentFoldersData) => {
  const items = documentFoldersData?.items;
  if (!items || items.length === 0) {
    return [];
  }
  return items.map((item) => ({
    ...item,
    id: item.id,
    name: item.name,
    description: item.description,
  }));
};
