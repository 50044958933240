import React, { useState } from 'react';
import Card from '@mui/material/Card';
import { DataGrid } from '@mui/x-data-grid';
import { getDataGridHeading } from 'utils/commonUtils';
import StaffDialog from './StaffDialog';

const AllStaffComponent = () => {
  const [isStaffDialog, setIsStaffDialog] = useState(false);

  const handleNewStaff = () => {
    setIsStaffDialog(true);
  };

  const columns = [
    {
      field: 'staffName',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'jobTitle',
      headerName: 'Job Title',
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'phone',
      headerName: 'Phone',
      flex: 1,
    },
  ];

  const rows = [
    {
      id: 1,
      staffName: 'Bobby Johnson',
      jobTitle: 'Manager',
      email: 'bobby@gmail.com',
      phone: '1111111111',
    },
    {
      id: 2,
      staffName: 'Johnson',
      jobTitle: 'Admin',
      email: 'johnson@gmail.com',
      phone: '2222222222',
    },
    {
      id: 3,
      staffName: 'Abrar',
      jobTitle: 'Developer',
      email: 'abrar@gmail.com',
      phone: '3333333333',
    },
    {
      id: 4,
      staffName: 'Juno',
      jobTitle: 'Manager',
      email: 'juno@gmail.com',
      phone: '4444444444',
    },
    {
      id: 5,
      staffName: 'Hiddu',
      jobTitle: 'Tester',
      email: 'hiddu@gmail.com',
      phone: '5555555555',
    },
  ];

  return (
    <>
      <Card>
        {getDataGridHeading('Staff', 'New Staff', handleNewStaff)}
        <DataGrid autoHeight rows={rows} columns={columns} />
        <StaffDialog
          isStaffDialog={isStaffDialog}
          setIsStaffDialog={setIsStaffDialog}
        />
      </Card>
    </>
  );
};

export default AllStaffComponent;
