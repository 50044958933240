import MDBox from 'components/MDBox';
import { styled } from '@mui/material/styles';
import Box from '@mui/system/Box';

export const HeadingWrapperBox = styled(MDBox)`
  display: flex;
  justify-content: space-between;
  margin-bottom: -20px;
  align-items: self-start;
  &.MuiBox-root {
    padding: ${(props) =>
      `${props.theme.palette.spacing[1] * 2}px ${
        props.theme.palette.spacing[1] * 3
      }px`};
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
`;

export const DropDownBox = styled(Box)`
  width: 20%;
`;
