import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import {
  getDialogHeading,
  showBackdropLoader,
  emptyFunction,
  useNotification,
  useListDialog,
  pageSizeChangeHandler,
} from 'utils/commonUtils';
import { useDispatch } from 'react-redux';
import { DialogStyle, intialPageSize } from 'constants';
import { attendanceAuditColumns } from '../attendanceAudits/AttendanceAuditDataGrid';
import { getAttendanceAuditVersions } from 'layouts/auditManagement/services/attendanceAuditsApi';
import { getAttendanceAuditVersionsListData } from './AttendanceAuditVersionsDataGrid';
import { MassBox } from 'components/Ui/styled';
import { getRenderedList } from 'utils/commonSlice/renderListSlice';
import DeltaDataGrid from 'utils/CommonDataGrid/CommonDataGrid';

const AttendanceAuditVersions = (props) => {
  const dispatch = useDispatch();
  const {
    open,
    setOpen,
    attendanceAuditId,
    setAttendanceAuditId,
    loading,
    setLoading,
  } = props;
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const { setDialogName, setOpenViewDialog, ListDialog } = useListDialog();
  const [attendanceAuditVersions, setAttendanceAuditVersions] = useState({
    isLoading: false,
    rows: [],
    pageSize: intialPageSize,
  });

  useEffect(() => {
    if (open) {
      fetchAttendanceAuditVersions();
    }
  }, [open]);

  const fetchAttendanceAuditVersions = async () => {
    setAttendanceAuditVersions({ ...attendanceAuditVersions, isLoading: true });
    try {
      const attendanceAuditsData = await getAttendanceAuditVersions(
        attendanceAuditId
      );
      setLoading(false);
      setAttendanceAuditVersions({
        ...attendanceAuditVersions,
        isLoading: false,
      });
      setAttendanceAuditVersions((prev) => ({
        ...prev,
        rows: getAttendanceAuditVersionsListData(attendanceAuditsData),
      }));
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
      setAttendanceAuditVersions({
        ...attendanceAuditVersions,
        isLoading: false,
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
    setAttendanceAuditId(null);
  };

  const handleStudents = (params) => {
    const { row } = params;
    dispatch(getRenderedList(row.studentList));
    setDialogName('Students');
    setOpenViewDialog(true);
  };

  const handleSemester = (params) => {
    const { row } = params;
    dispatch(getRenderedList(row.semesterList));
    setDialogName('Semesters');
    setOpenViewDialog(true);
  };

  // const handleAccessUsers = (params) => {
  //   const { row } = params;
  //   console.log('ROW', row);
  // };

  const pageSizeChange = pageSizeChangeHandler(setAttendanceAuditVersions);

  return (
    <>
      {loading ? (
        showBackdropLoader(loading)
      ) : (
        <>
          <Dialog open={open} PaperProps={DialogStyle}>
            {getDialogHeading('Attendance Audit Versions', handleClose)}
            <MassBox>
              <DeltaDataGrid
                listData={attendanceAuditVersions}
                columns={attendanceAuditColumns(
                  emptyFunction,
                  handleStudents,
                  handleSemester,
                  emptyFunction,
                  emptyFunction,
                  open,
                  false,
                  emptyFunction,
                  emptyFunction,
                  emptyFunction
                )}
                setListData={pageSizeChange}
              />
            </MassBox>
          </Dialog>
          <ListDialog />
          <NotificationPopup />
        </>
      )}
    </>
  );
};

export default AttendanceAuditVersions;
