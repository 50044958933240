import { ActionWrapperBox } from 'components/Common/common.styled';
import Tooltip from '@mui/material/Tooltip';
import {
  ButtonIcon,
  EditActionIcon,
  RoundedIconGroups,
  RoundedIconArchive,
  RoundedIconUnarchive,
} from 'components/Ui/styled';

export const renderAttendanceAuditAction = (
  params,
  handleUpdateAttendanceAudit,
  handleArchiveAttendanceAudit,
  handleUnarchiveAttendanceAudit,
  handleReplaceAccessAttendanceAudit
) => {
  return (
    <ActionWrapperBox>
      {!params.row.archived ? (
        <>
          <ButtonIcon onClick={() => handleUpdateAttendanceAudit(params)}>
            <EditActionIcon />
          </ButtonIcon>
          <ButtonIcon onClick={() => handleArchiveAttendanceAudit(params)}>
            <Tooltip title='Archive' arrow={false} placement='top'>
              <RoundedIconArchive />
            </Tooltip>
          </ButtonIcon>
          <ButtonIcon
            onClick={() => handleReplaceAccessAttendanceAudit(params)}
          >
            <Tooltip
              title='Replace existing access'
              arrow={false}
              placement='top'
            >
              <RoundedIconGroups />
            </Tooltip>
          </ButtonIcon>
        </>
      ) : (
        <ButtonIcon onClick={() => handleUnarchiveAttendanceAudit(params)}>
          <Tooltip title='Un-Archive' arrow={false} placement='top'>
            <RoundedIconUnarchive />
          </Tooltip>
        </ButtonIcon>
      )}
    </ActionWrapperBox>
  );
};
