import { API } from 'aws-amplify';
import { DOCUMENTS_BASE_URL_APPEND } from 'constants';

export const getFolders = async () => {
  const folders = await API.get(
    'delta-api',
    `${DOCUMENTS_BASE_URL_APPEND}folders`
  );
  return folders;
};

export const getFolder = async (folderId) => {
  const folder = await API.get(
    'delta-api',
    `${DOCUMENTS_BASE_URL_APPEND}folders/${folderId}`
  );
  return folder;
};

export const getFolderBrowse = async (folderId, path) => {
  const folderBrowse = await API.get(
    'delta-api',
    `${DOCUMENTS_BASE_URL_APPEND}folders/${folderId}/browse?`,
    {
      headers: {},
      queryStringParameters: {
        path: path,
      },
    }
  );
  return folderBrowse;
};

export const putFileUpload = async (payload, folderId) => {
  const myPayload = {
    body: payload,
  };
  const uploadUrl = await API.put(
    'delta-api',
    `${DOCUMENTS_BASE_URL_APPEND}folders/${folderId}/upload`,
    myPayload
  );
  return uploadUrl;
};

export const putFileDownload = async (payload, folderId) => {
  const myPayload = {
    body: payload,
  };
  const downloadUrl = await API.put(
    'delta-api',
    `${DOCUMENTS_BASE_URL_APPEND}folders/${folderId}/download`,
    myPayload
  );
  return downloadUrl;
};
