import { FieldText } from 'components/Ui/styled';

const ReportsField = (props) => {
  const { value, label } = props;

  return (
    <FieldText
      value={value}
      label={label}
      variant="standard"
      InputProps={{
        readOnly: true,
      }}
    />
  );
};

export default ReportsField;
