import { renderStatusAction } from './StatusAction';

export const statusOptionColumns = (handleRemoveStatus) => [
  {
    field: 'action',
    headerName: 'Action',
    sortable: false,
    disableColumnMenu: true,
    width: 80,
    renderCell: (params) => renderStatusAction(params, handleRemoveStatus),
  },
  {
    headerName: 'Status',
    field: 'statusOption',
    flex: 1,
  },
];
