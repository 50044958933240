import { createSlice } from '@reduxjs/toolkit';
import initialState from './assetTagsInitialState';
import { _setAssetTags } from './assetTagsAction';

export const assetTagsSlice = createSlice({
  name: 'assetTags',
  initialState,
  reducers: {
    setAssetTags: _setAssetTags,
    resetAssetTags: () => initialState,
  },
});

export const { setAssetTags, resetAssetTags } = assetTagsSlice.actions;

export default assetTagsSlice.reducer;
