export const _setSharedFoldersArchivePagination = (state, action) => {
  state.sharedFoldersArchivePagination = [
    ...state.sharedFoldersArchivePagination,
    action.payload,
  ];
};

export const _setSharedFoldersArchivePaginationPreviousKey = (
  state,
  action
) => {
  state.sharedFoldersArchivePaginationPreviousKey = action.payload;
};
