import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { tagsSuccess } from 'constants';
import { requiredField } from 'constants';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getSnackBar } from 'utils/commonUtils';
import { GridWrapper } from 'components/Ui/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { DropdownInputList } from 'utils/commonUtils';
import { AccordianContainer } from 'components/Ui/styled';
import { renderSaveCancelButtons } from 'utils/commonUtils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails, Typography } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import FormInputDropdown from 'components/Common/Forms/FormInputDropDown';
import { displaySnackbar } from 'components/snackbar/store/SnackbarSlice';
import { attendanceTags } from 'layouts/applications/attendance/common/constants';
import { putStudentTag } from 'layouts/applications/attendance/services/attendanceApis';

const locationSchema = yup.object().shape({
  tags: yup.array().min(1, requiredField),
});

const AssignProgramAccordian = (props) => {
  const {
    tags,
    profile,
    resourceId,
    handleClose,
    handleErrorResponse,
    handleTags,
    isAssignProgramExpanded,
    handleAssignProgramAccordian,
  } = props;

  const dispatch = useDispatch();

  const [loadingAssign, setLoadingAssign] = useState(false);

  const defaultValue = {
    tags: tags,
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
    resolver: yupResolver(locationSchema),
  });

  const { handleSubmit, control, register, formState, reset } = useFunction;
  const { errors } = formState;

  useEffect(() => {
    reset(defaultValue);
  }, [defaultValue.tags]);

  const onSubmit = async (e) => {
    const snackBar = getSnackBar(tagsSuccess);
    try {
      setLoadingAssign(true);
      const tags = e?.tags.map((tag) => tag.toLowerCase());

      const postParams = {
        semester: profile?.lastActiveSemester,
        tags: [...new Set(tags)],
      };

      const queryStringParam = {
        studentId: resourceId,
      };
      await putStudentTag(postParams, queryStringParam);
      dispatch(displaySnackbar(snackBar));
      setLoadingAssign(false);
    } catch (error) {
      setLoadingAssign(false);
      handleErrorResponse(error?.response?.data?.message);
    }
  };

  return (
    <GridWrapper container spacing={2}>
      <Grid item xs={12} md={8}>
        <AccordianContainer
          expanded={isAssignProgramExpanded}
          onChange={handleAssignProgramAccordian}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="d7">
              Assign A Tags ( IEP, ELL, etc., )
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormInputDropdown
              name="tags"
              type="text"
              control={control}
              multiple={true}
              checked={tags}
              renderValue={(selected) => (
                <DropdownInputList selectedValues={selected} />
              )}
              helperText={errors?.tags?.message}
              errors={!!errors.tags}
              options={attendanceTags}
              label="Tags"
              register={register('tags', {
                onChange: (e) => handleTags(e),
              })}
            />
            {renderSaveCancelButtons(
              handleClose,
              handleSubmit(onSubmit),
              loadingAssign
            )}
          </AccordionDetails>
        </AccordianContainer>
      </Grid>
    </GridWrapper>
  );
};

AssignProgramAccordian.defaultProps = {
  tags: [],
  profile: {},
  resourceId: '',
  isAssignProgramExpanded: false,
};

AssignProgramAccordian.propTypes = {
  tags: PropTypes.array,
  profile: PropTypes.object,
  resourceId: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  handleErrorResponse: PropTypes.func.isRequired,
  handleTags: PropTypes.func.isRequired,
  isAssignProgramExpanded: PropTypes.bool,
  handleAssignProgramAccordian: PropTypes.func.isRequired,
};

export default AssignProgramAccordian;
