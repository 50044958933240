import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import { GridForm, GridButtonWrapper } from 'components/Ui/styled';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import {
  getDialogHeading,
  useNotification,
  renderSaveCancelButtons,
  onSave,
  fieldValidation,
  emptyFunction,
} from 'utils/commonUtils';
import { PaperProps, statusSuccess, characterLimit } from 'constants';
import { postAddStatus } from 'layouts/studentTracking/services/studentTrackerApi';

const schema = yup.object().shape({
  optionName: fieldValidation(characterLimit),
});

const AddStatusDialog = (props) => {
  const {
    trackerId,
    addStatusDialog,
    setAddStatusDialog,
    fetchStatusOptions,
    fetchTrackers,
  } = props;

  const dispatch = useDispatch();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();

  const [loadingButton, setLoadingButton] = useState(false);

  const defaultValue = {
    optionName: '',
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset, control, formState } = useFunction;
  const { errors } = formState;

  useEffect(() => {
    reset(defaultValue);
  }, [defaultValue.optionName]);

  const handleClose = () => {
    reset(defaultValue);
    setAddStatusDialog(false);
  };

  const fetchTrackersAndStatusOptions = () => {
    fetchStatusOptions();
    fetchTrackers();
  };

  const onSubmit = async (data) => {
    const { optionName } = data;
    const payload = {
      optionName,
    };
    onSave(
      statusSuccess,
      true,
      postAddStatus,
      emptyFunction,
      payload,
      '',
      fetchTrackersAndStatusOptions,
      handleClose,
      setOpenNotification,
      setNotificationMessage,
      dispatch,
      setLoadingButton,
      loadingButton,
      trackerId
    );
  };

  return (
    <>
      <Dialog open={addStatusDialog} PaperProps={PaperProps}>
        {getDialogHeading('Status', handleClose, 'statusHeading')}
        <GridForm container spacing={2}>
          <Grid item xs={12}>
            <FormInputText
              name="optionName"
              type="text"
              control={control}
              label="Status"
              helperText={errors?.optionName?.message}
              errors={!!errors.optionName}
            />
          </Grid>
        </GridForm>
        <GridButtonWrapper container>
          {renderSaveCancelButtons(
            handleClose,
            handleSubmit(onSubmit),
            loadingButton
          )}
        </GridButtonWrapper>
      </Dialog>
      <NotificationPopup />
    </>
  );
};

export default AddStatusDialog;
