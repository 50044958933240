import { createSlice } from '@reduxjs/toolkit';
import initialState from './autoAssetInitialState';
import { _setAutoAssets } from './autoAssetActions';

export const autoAssetSlice = createSlice({
  name: 'autoAsset',
  initialState,
  reducers: {
    setAutoAssets: _setAutoAssets,
    resetAutoAssets: () => initialState,
  },
});

export const { setAutoAssets, resetAutoAssets } = autoAssetSlice.actions;

export default autoAssetSlice.reducer;
