import { createSlice } from '@reduxjs/toolkit';
import initialState from './attendanceAuditInitialState';
import {
  _getAttendanceAuditForm,
  _setIsNewAttendanceAudit,
  _setName,
  _setSemesters,
  _setIncludePreviousSemesters,
  _setIncludeDropped,
  _setStudentIds,
  _setNewStudentIds,
} from './attendanceAuditAction';

export const attendanceAuditSlice = createSlice({
  name: 'delta/attendanceAuditForm',
  initialState,
  reducers: {
    getAttendanceAuditForm: _getAttendanceAuditForm,
    setIsNewAttendanceAudit: _setIsNewAttendanceAudit,
    setName: _setName,
    setSemesters: _setSemesters,
    setIncludePreviousSemesters: _setIncludePreviousSemesters,
    setIncludeDropped: _setIncludeDropped,
    setStudentIds: _setStudentIds,
    setNewStudentIds: _setNewStudentIds,
    resetAttendanceAuditForm: () => initialState,
  },
});

export const {
  getAttendanceAuditForm,
  setIsNewAttendanceAudit,
  setName,
  setSemesters,
  setIncludePreviousSemesters,
  setIncludeDropped,
  setStudentIds,
  setNewStudentIds,
  resetAttendanceAuditForm,
} = attendanceAuditSlice.actions;

export default attendanceAuditSlice.reducer;
