// ==============================|| OVERRIDES - DATA GRID ||============================== //
import { gridClasses } from '@mui/x-data-grid';
import { alpha } from '@mui/material/styles';
import colors from 'assets/theme/base/colors';
import borders from 'assets/theme/base/borders';
import spacing from 'assets/theme/base/spacing';

const { white, grey, gradients, black, scrollbarColors } = colors;
const { primary, secondary } = gradients;
const ODD_OPACITY = 0.2;
const selectedOpacity = 0.3;
const hoverOpacity = 0.2;
const { borderWidth, borderColor } = borders;

const DataGrid = {
  styleOverrides: {
    root: {
      [`& .${gridClasses.row}.even`]: {
        backgroundColor: grey[200],
        '&:hover, &.Mui-hovered': {
          backgroundColor: alpha(primary.main, ODD_OPACITY),
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
        },
        '&.Mui-selected': {
          backgroundColor: alpha(primary.main, ODD_OPACITY + selectedOpacity),
          '&:hover, &.Mui-hovered': {
            backgroundColor: alpha(
              primary.main,
              ODD_OPACITY + selectedOpacity + hoverOpacity
            ),
            '@media (hover: none)': {
              backgroundColor: alpha(
                primary.main,
                ODD_OPACITY + selectedOpacity
              ),
            },
          },
        },
      },
      [`& .${gridClasses.cell}.gameChangers`]: {
        backgroundColor: 'rgb(255,255,0)',
        '&:hover, &.Mui-hovered': {
          backgroundColor: alpha('rgb(255,255,0)', 0.5),
          '@media (hover: none)': {
            backgroundColor: 'yellow',
          },
        },
      },
      [`& .${gridClasses.cell}.rapa`]: {
        backgroundColor: 'rgb(255,165,0)',
        '&:hover, &.Mui-hovered': {
          backgroundColor: alpha('rgb(255,165,0)', 0.5),
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
        },
      },
      [`& .${gridClasses.cell}.daySchool`]: {
        backgroundColor: 'rgb(0,191,255)',
        '&:hover, &.Mui-hovered': {
          backgroundColor: alpha('rgb(0,191,255)', 0.5),
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
        },
      },
      '&:hover': {
        color: black.main,
        backgroundColor: white.main,
      },
      '.MuiDataGrid-menuIconButton': {
        '&:hover': {
          color: '#000000',
          backgroundColor: '#dcdcdc',
        },
      },
      '.MuiDataGrid-iconButtonContainer ': {
        '&:hover': {
          color: '#000000',
          backgroundColor: '#dcdcdc',
          borderRadius: '15px',
        },
      },
      '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
        width: `${spacing[0] * 2}px`,
        height: `${spacing[0] * 2}px`,
      },
      '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
        background: scrollbarColors.gainsboro,
        borderRadius: `${spacing[0] * 1.25}px`,
      },
      '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
        background: scrollbarColors.grey61,
      },
    },
    columnHeader: {
      padding: 'unset',
    },
    pinnedColumnHeaders: {
      padding: '0px 6px 0px 6px',
      backgroundColor: grey[600],
      borderTopLeftRadius: 'unset',
      borderTopRightRadius: 'unset',
    },
    columnHeaderDraggableContainer: {
      width: '90%',
      padding: '0px 10px',
    },
    columnHeaders: {
      padding: '0px 6px 0px 6px',
      backgroundColor: grey[600],
      borderTopLeftRadius: 'unset',
      borderTopRightRadius: 'unset',
      '& .MuiDataGrid-pinnedColumnHeaders': {
        padding: '0px 6px 0px 6px',
        backgroundColor: grey[600],
        borderTopLeftRadius: 'unset',
        borderTopRightRadius: 'unset',
      },
    },
    columnHeaderTitle: {
      fontSize: '15px',
      whiteSpace: 'normal',
      lineHeight: '20px',
      color: white.main,
    },
    row: {
      background: white.main,
    },
    cell: {
      padding: '0px 5px',
      borderRight: `${borderWidth[1]} solid ${borderColor}`,
    },
    cellContent: {
      fontSize: '15px',
      whiteSpace: 'normal',
      lineHeight: '18px',
      padding: '15px 0px',
    },
    iconButtonContainer: {
      width: 'unset',
    },
    iconSeparator: {
      display: 'none',
    },
    footerContainer: {
      backgroundColor: '#D3D3D3',
      height: '44px',
      minHeight: 'unset',
    },
  },
};

export default DataGrid;
