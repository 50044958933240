const initialState = {
  newCategory: false,
  color: '',
  category: '',
  calendarId: '',
  categoryId: '',
  categories:[]
};

export default initialState;
