import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import List from '@mui/material/List';
import { WrapperBox } from 'components/Ui/styled';
import { useDispatch } from 'react-redux';
import {
  getDialogHeading,
  renderSaveCancelButtons,
  emptyFunction,
  onSave,
  useNotification,
} from 'utils/commonUtils';
import { putReorderTrackerColumns } from 'layouts/studentTracking/services/studentTrackerApi';
import { reorderColumnSuccess, PaperProps } from 'constants';
import ReorderColumnDraggable from './ReorderColumnDraggable';

const ReorderColumnDialog = (props) => {
  const {
    open,
    setOpen,
    trackerId,
    setTrackerId,
    fetchTrackers,
    reorderColumns,
    setReorderColumns,
  } = props;

  const dispatch = useDispatch();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();

  const [loadingButton, setLoadingButton] = useState(false);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(reorderColumns);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setReorderColumns(items);
  };

  const handleClose = () => {
    setTrackerId(null);
    setOpen(false);
  };

  const handleSave = () => {
    const payload = {
      columnOrder: reorderColumns,
    };
    onSave(
      reorderColumnSuccess,
      false,
      emptyFunction,
      putReorderTrackerColumns,
      payload,
      trackerId,
      fetchTrackers,
      handleClose,
      setOpenNotification,
      setNotificationMessage,
      dispatch,
      setLoadingButton,
      loadingButton
    );
  };

  return (
    <>
      <Dialog open={open} PaperProps={PaperProps}>
        {getDialogHeading('Reorder tracking Columns', handleClose)}
        <WrapperBox>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="reorderColumns">
              {(provided) => (
                <List {...provided.droppableProps} ref={provided.innerRef}>
                  {reorderColumns?.map((item, index) => {
                    return (
                      <ReorderColumnDraggable
                        key={index}
                        item={item}
                        index={index}
                      />
                    );
                  })}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        </WrapperBox>
        {renderSaveCancelButtons(handleClose, handleSave, loadingButton)}
      </Dialog>
      <NotificationPopup />
    </>
  );
};

export default ReorderColumnDialog;
