const initialState = {
  item: {
    isNewtask: false,
    id: null,
    title: null,
    description: null,
    dueOn: '',
    assignToSupervisor: false,
    user: null,
    note: null,
  },
};

export default initialState;
