import React from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import Delta from 'main/Delta';

function App({ signOut, user }) {
  return <Delta signOut={signOut} user={user} />;
}

export default withAuthenticator(App, {
  loginMechanisms: ['username'],
});
