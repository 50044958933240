import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { MultiFormGrid, IconButtonMB } from 'components/Ui/styled';

const MultiFormTextField = (props) => {
  const {
    field,
    index,
    fields,
    label,
    control,
    append,
    remove,
    errors,
    maxColumns,
  } = props;

  const names = `fields[${index}].value`;
  const isFirstField = index === 0;
  const canAddMoreFields = fields.length < maxColumns;
  const error = !!errors?.fields?.[index]?.value;
  const helperText = errors?.fields?.[index]?.value?.message;
  const defaultValue = field.value;

  return (
    <MultiFormGrid item xs={12} key={field.id}>
      <Controller
        name={names}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <TextField
            {...field}
            label={label}
            style={{ width: '100%' }}
            variant="standard"
            error={error}
            helperText={helperText}
          />
        )}
      />
      {isFirstField && canAddMoreFields && (
        <IconButtonMB onClick={() => append({ value: '' })}>
          <AddCircleOutlineOutlinedIcon />
        </IconButtonMB>
      )}
      {!isFirstField && (
        <IconButtonMB onClick={() => remove(index)}>
          <DeleteOutlineOutlinedIcon />
        </IconButtonMB>
      )}
    </MultiFormGrid>
  );
};

export default MultiFormTextField;
