import { createSlice } from '@reduxjs/toolkit';
import initialState from './taskFilterInitialState';
import {
  _setIncludeCompleted,
  _setOldestFirst,
  _setLimitToType,
  _setUser,
} from './taskFilterAction';

export const taskFilterSlice = createSlice({
  name: 'delta/taskFilter',
  initialState,
  reducers: {
    setIncludeCompleted: _setIncludeCompleted,
    setOldestFirst: _setOldestFirst,
    setLimitToType: _setLimitToType,
    setUser: _setUser,
    resetTaskFilter: () => initialState,
  },
});

export const {
  setIncludeCompleted,
  setOldestFirst,
  setLimitToType,
  setUser,
  resetTaskFilter,
} = taskFilterSlice.actions;

export default taskFilterSlice.reducer;
