import { createSlice } from '@reduxjs/toolkit';
import initialState from './replaceAccessInitialState';
import {
  _getReplaceAccessForm,
  _setReplaceAccessUsers,
} from './replaceAccessAction';

export const replaceAccessSlice = createSlice({
  name: 'delta',
  initialState,
  reducers: {
    getReplaceAccessForm: _getReplaceAccessForm,
    setReplaceAccessUsers: _setReplaceAccessUsers,
    resetReplaceAccess: () => initialState,
  },
});

export const {
  getReplaceAccessForm,
  setReplaceAccessUsers,
  resetReplaceAccess,
} = replaceAccessSlice.actions;

export default replaceAccessSlice.reducer;
