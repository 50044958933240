import React, { useEffect } from 'react';
import MDBox from 'components/MDBox';
import List from '@mui/material/List';
import Dialog from '@mui/material/Dialog';
import { useForm } from 'react-hook-form';
import { getGradeValue } from 'layouts/applications/attendance/utils/attendanceUtils';
import { putHideUnHideColumn } from 'layouts/studentTracking/services/studentTrackerApi';
import {
  useNotification,
  getDialogHeading,
  getSnackBar,
  showBackdropLoader,
} from 'utils/commonUtils';
import { WrapperBox } from 'components/Ui/styled';
import { useDispatch } from 'react-redux';
import { displaySnackbar } from 'components/snackbar/store/SnackbarSlice';
import HideColumnSwitch from './HideColumnSwitch';
import { PaperProps, columnHideSuccess, columnUnHideSuccess } from 'constants';

const HideColumnDialog = (props) => {
  const {
    trackerId,
    hideColumnDialog,
    setHideColumnDialog,
    hideColumns,
    getTableData,
    tabValue,
    isHideColumnDialogLoading,
  } = props;

  const dispatch = useDispatch();

  const { handleErrorResponse, NotificationPopup } = useNotification();

  const defaultValue = {};

  hideColumns?.forEach((column) => {
    defaultValue[column.columnName] = column.hide;
  });

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
  });

  const { control, reset, register } = useFunction;

  const handleClose = () => {
    setHideColumnDialog(false);
    getTableData();
  };

  useEffect(() => {
    reset(defaultValue);
  }, [hideColumnDialog]);

  const handleHideUnhide = async (columnName, value) => {
    const gradeValue = getGradeValue(tabValue);
    const payload = { columnName };
    const snackBar = getSnackBar(
      value ? columnHideSuccess : columnUnHideSuccess
    );
    try {
      await putHideUnHideColumn(payload, trackerId, gradeValue);
      dispatch(displaySnackbar(snackBar));
    } catch (error) {
      handleErrorResponse(error?.response?.data?.message);
    }
  };

  return (
    <>
      {showBackdropLoader(isHideColumnDialogLoading)}
      <Dialog open={hideColumnDialog} PaperProps={PaperProps}>
        {getDialogHeading('Hide/Un-hide Columns', handleClose)}
        <WrapperBox>
          <List>
            {hideColumns?.map((item, index) => {
              return (
                <HideColumnSwitch
                  key={index}
                  item={item}
                  control={control}
                  register={register}
                  handleHideUnhide={handleHideUnhide}
                />
              );
            })}
          </List>
        </WrapperBox>
        <MDBox pt={2}></MDBox>
        <NotificationPopup />
      </Dialog>
    </>
  );
};

export default HideColumnDialog;
