export const _getAttendanceAuditForm = (state, action) => action.payload;

export const _setIsNewAttendanceAudit = (state, action) => {
  state.item.isNewAttendanceAudit = action.payload;
};

export const _setName = (state, action) => {
  state.item.name = action.payload;
};

export const _setSemesters = (state, action) => {
  state.item.reportParams.semesters = action.payload;
};

export const _setIncludePreviousSemesters = (state, action) => {
  state.item.includePreviousSemesters = action.payload;
};

export const _setIncludeDropped = (state, action) => {
  state.item.includeDropped = action.payload;
};

export const _setStudentIds = (state, action) => {
  state.item.reportParams.studentIds = action.payload;
};

export const _setNewStudentIds = (state, action) => {
  state.item.reportParams.studentIds = [
    ...state.item.reportParams.studentIds,
    action.payload,
  ];
};
