import { createSlice } from '@reduxjs/toolkit';
import initialState from './studentTrackerFilterInitialState';
import {
  _setTrackerId,
  _setStudentType,
  _setInstructor,
  _setCourse,
  _setTabValue,
} from './studentTrackerFilterAction';

export const studentTrackerFilterSlice = createSlice({
  name: 'delta/studentTrackerFilter',
  initialState,
  reducers: {
    setTrackerId: _setTrackerId,
    setStudentType: _setStudentType,
    setInstructor: _setInstructor,
    setCourse: _setCourse,
    setTabValue: _setTabValue,
    resetStudentTrackerFilter: () => initialState,
  },
});

export const {
  setTrackerId,
  setStudentType,
  setInstructor,
  setCourse,
  setTabValue,
  resetStudentTrackerFilter,
} = studentTrackerFilterSlice.actions;

export default studentTrackerFilterSlice.reducer;
