export const _setTrackerId = (state, action) => {
  state.trackerId = action.payload;
};

export const _setStudentType = (state, action) => {
  state.studentType = action.payload;
};

export const _setInstructor = (state, action) => {
  state.instructor = action.payload;
};

export const _setCourse = (state, action) => {
  state.course = action.payload;
};

export const _setTabValue = (state, action) => {
  state.tabValue = action.payload;
};
