import { API } from 'aws-amplify';
import { ONLINE_ATTENDANCE_AUDIT_BASE_URL_APPEND } from 'constants';

export const getAttendanceAudits = async () => {
  const attendanceAudits = await API.get(
    'delta-api',
    `${ONLINE_ATTENDANCE_AUDIT_BASE_URL_APPEND}`
  );
  return attendanceAudits;
};

export const getAttendanceAuditReport = async (auditId) => {
  const attendanceAuditReport = await API.get(
    'delta-api',
    `${ONLINE_ATTENDANCE_AUDIT_BASE_URL_APPEND}${auditId}/report`
  );
  return attendanceAuditReport;
};
