import { useSelector } from 'react-redux';

export const usePermissionCustomHook = (permissionName) => {
  const myPermissions = useSelector(
    (state) => state.myPermissions?.permissions
  );
  return hasPermission(permissionName, myPermissions);
};

export const hasPermission = (permissionName, myPermissions) => {
  if (myPermissions?.includes(permissionName)) {
    return true;
  } else {
    return false;
  }
};
