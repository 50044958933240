import { rowsPerPage } from 'constants';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { TypographyLink } from 'components/Ui/styled';
import { getRowClassName, getRowHeight, formatDate } from 'utils/commonUtils';
import { renderAttendanceAuditAction } from './AttendanceAuditAction';
import { renderCellExpand } from 'components/Common/GridCell/GridCellExpand';

export const getAttendanceAuditDataGrid = (
  attendanceAudits,
  columns,
  setAttendanceAudits
) => {
  const { rows, pageSize, isLoading } = attendanceAudits;

  return (
    <DataGridPro
      autoHeight
      getRowHeight={getRowHeight}
      rows={rows}
      columns={columns}
      pagination
      rowsPerPageOptions={rowsPerPage}
      pageSize={pageSize}
      getRowClassName={(params) => getRowClassName(params)}
      loading={isLoading}
      onPageSizeChange={(newPageSize) =>
        setAttendanceAudits((prveState) => ({
          ...prveState,
          pageSize: newPageSize,
        }))
      }
    />
  );
};

export const attendanceAuditColumns = (
  updateAttendanceAudit,
  handleStudents,
  handleSemester,
  handleAccessUsers,
  handleAttendanceAuditVersions,
  openAttendanceAuditVersionsDialog,
  typographyLink,
  handleArchiveAttendanceAudit,
  handleUnarchiveAttendanceAudit,
  handleReplaceAccessAttendanceAudit
) => [
  {
    field: 'action',
    headerName: 'Action',
    sortable: false,
    disableColumnMenu: true,
    hide: openAttendanceAuditVersionsDialog,
    width: 120,
    renderCell: (params) =>
      renderAttendanceAuditAction(
        params,
        updateAttendanceAudit,
        handleArchiveAttendanceAudit,
        handleUnarchiveAttendanceAudit,
        handleReplaceAccessAttendanceAudit
      ),
  },
  {
    headerName: 'Name',
    field: 'name',
    renderCell: renderCellExpand,
    width: 300,
  },
  {
    headerName: 'Report Type',
    field: 'reportType',
    renderCell: renderCellExpand,
    width: 200,
  },
  {
    headerName: 'Students',
    field: 'students',
    renderCell: (params) => {
      return (
        <TypographyLink variant='h6' onClick={() => handleStudents(params)}>
          {params.row.students}
        </TypographyLink>
      );
    },
    align: 'center',
    sortable: false,
    disableColumnMenu: true,
    width: 140,
  },
  {
    headerName: 'Access Users',
    field: 'accessUsers',
    renderCell: (params) => {
      if (typographyLink) {
        return (
          <TypographyLink
            variant='h6'
            onClick={() => handleAccessUsers(params)}
          >
            {params.row.accessUsers}
          </TypographyLink>
        );
      } else {
        return <span>{params.row.accessUsers}</span>;
      }
    },
    align: 'center',
    sortable: false,
    disableColumnMenu: true,
    width: 140,
  },
  {
    headerName: 'Versions',
    field: 'version',
    renderCell: (params) => {
      if (typographyLink) {
        return (
          <TypographyLink
            variant='h6'
            onClick={() => handleAttendanceAuditVersions(params)}
          >
            {params.row.version}
          </TypographyLink>
        );
      } else {
        return <span>{params.row.version}</span>;
      }
    },
    align: 'center',
    sortable: false,
    disableColumnMenu: true,
    width: 140,
  },
  {
    headerName: 'Semesters',
    field: 'semesters',
    renderCell: (params) => {
      return (
        <TypographyLink variant='h6' onClick={() => handleSemester(params)}>
          {params.row.semesters}
        </TypographyLink>
      );
    },
    align: 'center',
    sortable: false,
    disableColumnMenu: true,
    width: 140,
  },
  {
    headerName: 'Created By',
    field: 'createdBy',
    renderCell: renderCellExpand,
    width: 180,
  },
  { headerName: 'Created At', field: 'createdAt', width: 150 },
  {
    headerName: 'Modified By',
    field: 'modifiedBy',
    renderCell: renderCellExpand,
    width: 180,
  },
  { headerName: 'Modified At', field: 'modifiedAt', width: 150 },
  { headerName: 'Archived', field: 'archived', type: 'boolean', width: 150 },
];

export const getAttendanceAuditListData = (attendanceAuditsData) => {
  const items = attendanceAuditsData?.items;
  if (!items || items.length === 0) {
    return [];
  }
  return items.map((item) => ({
    ...item,
    id: item.id,
    name: item.name,
    reportType: item.reportParams.reportType,
    students: item.reportParams.studentIds.length,
    studentList: item.reportParams.studentIds,
    accessUsers: item.accessUsers.length,
    accessUsersList: item.accessUsers,
    createdBy: item.createdBy,
    createdAt: formatDate(item.createdAt),
    version: item.version,
    semesters: item.reportParams.semesters.length,
    semesterList: item.reportParams.semesters,
    modifiedBy: item.modifiedBy,
    modifiedAt: formatDate(item.modifiedAt),
    archived: item.archived,
  }));
};
