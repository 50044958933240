import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { ButtonWrapper, DialogBox, TypographyUser } from 'components/Ui/styled';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  postGroupMembers,
  postGroupReadWriteSupervisors,
  postGroupReadOnlySupervisors,
  deleteGroupMembers,
  deleteGroupReadWriteSupervisors,
  deleteGroupReadOnlySupervisors,
} from '../services/userGroupApi';
import { displaySnackbar } from 'components/snackbar/store/SnackbarSlice';
import {
  userCountPaperProps,
  groupMembersSaved,
  groupMembersDeleted,
  groupMembersSavedAndDeleted,
} from 'constants';
import {
  CardCheckbox,
  CardDivider,
  LeftShiftButton,
  RightShiftButton,
  UserCountList,
} from './groupStyled';
import MDBox from 'components/MDBox';
import {
  resetGroupMembers,
  setAddedGroupMembers,
  setRemovedGroupMembers,
} from '../store/groupMembers/groupMemberSlice';
import {
  getSnackBar,
  useNotification,
  getButtonSpinner,
  showBackdropLoader,
} from 'utils/commonUtils';
import {
  postUserLocation,
  deleteUserLocation,
} from 'layouts/users/services/userApis';
import {
  postAttendanceAuditUserAccess,
  deleteAttendanceAuditUserAccess,
} from 'layouts/auditManagement/services/attendanceAuditsApi';

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const MembersDialog = (props) => {
  const dispatch = useDispatch();
  const {
    setMembersDialog,
    membersDialog,
    fetchGroups,
    canManageGroups,
    canManageGroupMembership,
    loading,
    listName,
  } = props;
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();

  const [permission, setPermission] = useState(false);

  const availableUsersList = useSelector((state) => state?.availableUsersList);

  const groupUsers = useSelector((state) => state?.groupUsers);
  const formName = useSelector((state) => state?.groupUsers?.formName);
  const {
    groupName,
    username,
    members,
    groupMemberList,
    groupReadWriteSupervisorList,
    groupReadOnlySupervisorList,
    assignedLocations,
    assignedUsers,
    accessUsersList,
  } = groupUsers;
  const memberList = availableUsersList?.filter((item) => {
    if (formName === 'Members') {
      return !groupMemberList?.includes(item.username);
    }
    if (formName === 'Read/Write Supervisors') {
      return !groupReadWriteSupervisorList?.includes(item.username);
    }
    if (formName === 'Read Only Supervisors') {
      return !groupReadOnlySupervisorList?.includes(item.username);
    }
    if (formName === 'Assigned Locations') {
      const getLocation = assignedLocations?.find(
        (items) => items.id === item.id
      );
      return !getLocation?.val;
    }
    if (formName === 'Assigned Users') {
      return !assignedUsers?.includes(item.username);
    }
    if (formName === 'Attendance Audit Access') {
      return !accessUsersList?.includes(item.username);
    }
  });
  const groupMembers = availableUsersList?.filter((item) => {
    if (formName === 'Members') {
      return groupMemberList?.includes(item.username);
    }
    if (formName === 'Read/Write Supervisors') {
      return groupReadWriteSupervisorList?.includes(item.username);
    }
    if (formName === 'Read Only Supervisors') {
      return groupReadOnlySupervisorList?.includes(item.username);
    }
    if (formName === 'Assigned Locations') {
      const getLocation = assignedLocations?.find(
        (items) => items.id === item.id
      );
      return getLocation?.val;
    }
    if (formName === 'Assigned Users') {
      return assignedUsers?.includes(item.username);
    }
    if (formName === 'Attendance Audit Access') {
      return accessUsersList?.includes(item.username);
    }
  });

  const addedGroupMembers = useSelector(
    (state) => state?.groupMembers?.addedGroupMembers
  );
  const removedGroupMembers = useSelector(
    (state) => state?.groupMembers?.removedGroupMembers
  );

  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const defaultValue = {
    groupUsers: groupUsers,
    allMembers: members,
    groupName: groupName,
  };

  const useFunction = useForm({
    defaultValues: defaultValue,
  });
  const { handleSubmit, reset } = useFunction;

  useEffect(() => {
    reset(defaultValue);
    setLeft(groupMembers);
    setRight(memberList);
  }, [
    defaultValue.groupUsers,
    defaultValue.allMembers,
    defaultValue.groupName,
  ]);

  useEffect(() => {
    if (formName === 'Members') {
      setPermission(false);
      if (canManageGroupMembership) {
        setPermission(true);
      }
    } else if (formName !== 'Members') {
      setPermission(false);
      if (canManageGroups) {
        setPermission(true);
      } else {
        setPermission(true);
      }
    }
  }, [formName]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    dispatch(setAddedGroupMembers(rightChecked));
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    dispatch(setRemovedGroupMembers(leftChecked));
  };

  const handleClose = () => {
    setMembersDialog(false);
    dispatch(resetGroupMembers());
    setChecked([]);
    reset(defaultValue);
  };

  const onSubmit = async () => {
    setLoadingButton(true);
    const snackBarSaved = getSnackBar(groupMembersSaved);
    const snackBarDeleted = getSnackBar(groupMembersDeleted);
    const snackBarSavedAndDeleted = getSnackBar(groupMembersSavedAndDeleted);
    try {
      if (addedGroupMembers.length !== 0) {
        for (let i = 0; i < addedGroupMembers.length; i++) {
          for (let j = 0; j < addedGroupMembers[i].length; j++) {
            if (formName === 'Members') {
              await postGroupMembers(
                groupName,
                addedGroupMembers[i][j].username
              );
            }
            if (formName === 'Read/Write Supervisors') {
              await postGroupReadWriteSupervisors(
                groupName,
                addedGroupMembers[i][j].username
              );
            }
            if (formName === 'Read Only Supervisors') {
              await postGroupReadOnlySupervisors(
                groupName,
                addedGroupMembers[i][j].username
              );
            }
            if (formName === 'Assigned Locations') {
              await postUserLocation(addedGroupMembers[i][j].id, username);
            }
            if (formName === 'Assigned Users') {
              await postUserLocation(
                groupUsers?.id,
                addedGroupMembers[i][j].username
              );
            }
            if (formName === 'Attendance Audit Access') {
              await postAttendanceAuditUserAccess(
                groupUsers?.id,
                addedGroupMembers[i][j].username
              );
            }
          }
        }
        if (
          removedGroupMembers.length === 0 &&
          addedGroupMembers.length !== 0
        ) {
          dispatch(displaySnackbar(snackBarSaved));
        }
      }
      if (removedGroupMembers.length !== 0) {
        for (let i = 0; i < removedGroupMembers.length; i++) {
          for (let j = 0; j < removedGroupMembers[i].length; j++) {
            if (formName === 'Members') {
              await deleteGroupMembers(
                groupName,
                removedGroupMembers[i][j].username
              );
            }
            if (formName === 'Read/Write Supervisors') {
              await deleteGroupReadWriteSupervisors(
                groupName,
                removedGroupMembers[i][j].username
              );
            }
            if (formName === 'Read Only Supervisors') {
              await deleteGroupReadOnlySupervisors(
                groupName,
                removedGroupMembers[i][j].username
              );
            }
            if (formName === 'Assigned Locations') {
              await deleteUserLocation(removedGroupMembers[i][j].id, username);
            }
            if (formName === 'Assigned Users') {
              await deleteUserLocation(
                groupUsers?.id,
                removedGroupMembers[i][j].username
              );
            }
            if (formName === 'Attendance Audit Access') {
              await deleteAttendanceAuditUserAccess(
                groupUsers?.id,
                removedGroupMembers[i][j].username
              );
            }
          }
        }
        if (
          addedGroupMembers.length === 0 &&
          !removedGroupMembers.length !== 0
        ) {
          dispatch(displaySnackbar(snackBarDeleted));
        }
      }
      if (addedGroupMembers.length !== 0 && removedGroupMembers.length !== 0) {
        dispatch(displaySnackbar(snackBarSavedAndDeleted));
      }
      fetchGroups();
      dispatch(resetGroupMembers());
      setMembersDialog(false);
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
    }
    setLoadingButton(false);
  };

  const membersList = (title, items) => (
    <Card>
      <TypographyUser style={{ justifyContent: 'center' }}>
        {title}
      </TypographyUser>
      <CardDivider />
      <UserCountList dense component='div' role='list'>
        {items?.map((value, index) => {
          const labelId = `transfer-list-all-item-${value}-label`;
          const displayValue =
            value.username || value.location || 'Unknown Property';
          return (
            <ListItem
              key={index}
              role='listitem'
              onClick={permission ? handleToggle(value) : undefined}
            >
              <ListItemIcon>
                <CardCheckbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              {/* <ListItemText id={labelId} primary={`${value.location}`} /> */}
              <ListItemText id={labelId} primary={`${displayValue}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </UserCountList>
    </Card>
  );
  const disableButton =
    addedGroupMembers.length !== 0 || removedGroupMembers.length !== 0
      ? false
      : true;

  const listHeading = listName ? listName : 'Users';
  return (
    <>
      {showBackdropLoader(loading)}
      <Dialog open={membersDialog} PaperProps={userCountPaperProps}>
        <DialogBox>
          <TypographyUser variant='h5'>{formName}</TypographyUser>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogBox>
        <Grid container spacing={2} justifyContent='center' alignItems='center'>
          <Grid item>{membersList(formName, left)}</Grid>
          <Grid item>
            <Grid container direction='column' alignItems='center'>
              <RightShiftButton
                sx={{ my: 0.5 }}
                variant='outlined'
                size='small'
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label='move selected right'
              >
                &gt;
              </RightShiftButton>
              <LeftShiftButton
                sx={{ my: 0.5 }}
                variant='outlined'
                size='small'
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label='move selected left'
              >
                &lt;
              </LeftShiftButton>
            </Grid>
          </Grid>
          <Grid item>{membersList(`Available ${listHeading}`, right)}</Grid>
          {permission && (
            <ButtonWrapper container style={{ padding: '8px 16px' }}>
              <Button variant='text' onClick={handleClose}>
                Cancel
              </Button>
              {getButtonSpinner(
                loadingButton,
                handleSubmit(onSubmit),
                'save',
                disableButton
              )}
            </ButtonWrapper>
          )}
        </Grid>
        {!permission && <MDBox pt={3}></MDBox>}
      </Dialog>
      <NotificationPopup />
    </>
  );
};

export default MembersDialog;
