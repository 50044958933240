import Box from '@mui/material/Box';
import MDBox from 'components/MDBox';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

export const TabWrapperBox = styled(MDBox)`
  text-align: right;
  & .super-app-green {
    background-color: #54c054;
    color: white;
  }
  & .super-app-red {
    background-color: #f44335;
    color: white;
  }
  & .super-app-other {
    background-color: #e78080;
    color: white;
  }
`;

export const AttendanceWrapperBox = styled(MDBox)`
  &.MuiBox-root {
    padding: ${(props) =>
      `${props.theme.palette.spacing[1] * 2}px ${
        props.theme.palette.spacing[1] * 0
      }px`};
  }
`;

export const GridWrapper = styled('div')`
  height: 685px;
  width: 100%;
`;

export const AttendanceButton = styled(Button)`
  color: rgba(0, 0, 0, 0.87);
  pointer-events: none;
  font-weight: 400;
`;

export const AttendanceButtonYellow = styled(AttendanceButton)`
  background-color: yellow;
`;

export const AttendanceButtonRed = styled(AttendanceButton)`
  background-color: red;
`;

export const AttendanceButtonGreen = styled(AttendanceButton)`
  background-color: green;
`;

export const AttendanceButtonBlue = styled(AttendanceButton)`
  background-color: rgb(0, 191, 255);
`;

export const AttendanceButtonOrange = styled(AttendanceButton)`
  background-color: rgb(255, 165, 0);
`;

export const TagCellTypography = styled(Typography)`
  font-size: 10px;
`;

export const AttendanceHeadingBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
`;

export const AttendanceDropDown = styled(MDBox)`
  &.MuiBox-root {
    width: 16%;
  }
`;

export const AttendanceNotesWrapper = styled(Grid)`
  padding: 0px 10px;
  border-radius: 5px;
  background-color: #dcdcdc;
  margin-bottom: 10px;
`;

export const AttendanceNotesList = styled(Box)`
  display: flex;
`;

export const AttendanceNotesTitle = styled(Typography)`
  padding: 0px 5px 0px 0px;
`;

export const AttendanceTagWrapper = styled(Stack)`
  display: contents;
`;

export const AttendanceFields = styled(Grid)`
  padding: 0px 16px;
`;
