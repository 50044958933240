import { createSlice } from '@reduxjs/toolkit';

const calendarDetailsSlice = createSlice({
  name: 'delta',
  initialState: null,
  reducers: {
    getCalendarDetails: (state, action) => action.payload,
    resetCalendarDetails: () => null,
  },
});

export const { getCalendarDetails, resetCalendarDetails } =
  calendarDetailsSlice.actions;

export default calendarDetailsSlice.reducer;
