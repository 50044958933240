import { createSlice } from '@reduxjs/toolkit';
import { _setPaginationKey } from './announcementAction';

const initialState = {
  PaginationKey: '',
};

export const announcementPaginationSlice = createSlice({
  name: 'AnnouncementPagination',
  initialState,
  reducers: {
    setPaginationKey: _setPaginationKey,
  },
});

export const { setPaginationKey } = announcementPaginationSlice.actions;

export default announcementPaginationSlice.reducer;
