import { createSlice } from '@reduxjs/toolkit';

const yearMonthSlice = createSlice({
  name: 'delta',
  initialState: null,
  reducers: {
    getYearMonthData: (state, action) => action.payload,
    resetYearMonthData: () => null,
  },
});

export const { getYearMonthData, resetYearMonthData } = yearMonthSlice.actions;

export default yearMonthSlice.reducer;
