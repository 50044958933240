import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import CategoryDialog from './CategoryDialog';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import Category from './Category';
import { newCalenderCategory } from 'layouts/applications/calendar/components/Categories/store/calenderCategorySlice';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { categoryCard } from 'constants';
import { useCalendarPermissionCustomHook } from 'layouts/applications/calenderAdmin/utils/calendarUtils';

const Categories = (props) => {
  const { fetchCalendarDataOfParticularMonth, calendarDropDownList } = props;
  const dispatch = useDispatch();
  const addCategory = useCalendarPermissionCustomHook('addCategory');

  const calendarCategories = useSelector((state) => state.calendarCategory);
  const { categories } = calendarCategories;

  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);

  const handleOpenCategoryDialog = () => {
    const newCategory = {
      newCategory: true,
      color: '',
      category: '',
      name: '',
      calendarId: '',
      categoryId: '',
      categories: categories,
    };
    dispatch(newCalenderCategory(newCategory));
    setOpenCategoryDialog(true);
  };

  return (
    <>
      <Card sx={{ height: '100%' }}>
        <MDBox pt={2} px={2} style={categoryCard}>
          <MDTypography variant='h6' fontWeight='medium'>
            Categories
          </MDTypography>
          {addCategory && (
            <MDButton
              onClick={handleOpenCategoryDialog}
              variant='text'
              color='dark'
              iconOnly
            >
              <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
            </MDButton>
          )}
        </MDBox>
        <Grid container pt={1} px={2} style={{ flexDirection: 'column' }}>
          {categories?.map((item, index) => (
            <Category
              setOpenCategoryDialog={setOpenCategoryDialog}
              key={index}
              calendarCategory={item}
              categories={categories}
              fetchCalendarDataOfParticularMonth={
                fetchCalendarDataOfParticularMonth
              }
            ></Category>
          ))}
        </Grid>
        <Box style={{ marginBottom: '20px' }}></Box>
      </Card>
      <CategoryDialog
        fetchCalendarDataOfParticularMonth={fetchCalendarDataOfParticularMonth}
        open={openCategoryDialog}
        setOpen={setOpenCategoryDialog}
      />
    </>
  );
};

export default Categories;
