import React from 'react';
import Card from '@mui/material/Card';
import StudentProfileTabs from './studentProfileTabs/StudentProfileTabs';

const StudentProfileComponent = () => {
  return (
    <Card style={{ padding: '16px 24px' }}>
      <StudentProfileTabs />
    </Card>
  );
};

export default StudentProfileComponent;
