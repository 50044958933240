// Material Dashboard 2 PRO React components
import { AttendanceWrapperBox } from './Attendance.styled';
import GradeDataTable from './GradeDataTable';

const TabPanel = (props) => {
  const {
    children,
    tabValue,
    gradeIndex,
    tabIndex,
    gradeTableData,
    isAttendanceAudit,
    ...other
  } = props;

  return (
    <div
      role='tabpanel'
      hidden={tabValue !== tabIndex}
      id={`simple-tabpanel-${tabIndex}`}
      aria-labelledby={`simple-tab-${tabIndex}`}
      {...other}
    >
      {tabValue === tabIndex && (
        <AttendanceWrapperBox>
          <GradeDataTable
            gradeTableData={gradeTableData}
            isAttendanceAudit={isAttendanceAudit}
          ></GradeDataTable>
        </AttendanceWrapperBox>
      )}
    </div>
  );
};

export default TabPanel;
