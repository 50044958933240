import { createSlice } from '@reduxjs/toolkit';
import { _getMyProfileData } from './myProfileActions';

export const myProfileSlice = createSlice({
  name: 'delta',
  initialState: null,
  reducers: {
    getMyProfileData: _getMyProfileData,
    resetMyProfileData: () => null,
  },
});

export const { getMyProfileData, resetMyProfileData } = myProfileSlice.actions;

export default myProfileSlice.reducer;
