import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import { useSelector } from 'react-redux';
import { intialPageSize } from 'constants';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import { getDataGridHeading, pageSizeChangeHandler } from 'utils/commonUtils';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { getSchools } from 'layouts/applications/schools/service/schoolsApi';
import DeltaDataGrid from 'utils/CommonDataGrid/CommonDataGrid';

const MyWatchingSchools = () => {
  const [schoolList, setSchoolList] = useState();
  const [myWatchingSchools, setMyWatchingSchools] = useState({
    isLoading: false,
    rows: [],
    pageSize: intialPageSize,
    page: 1,
  });
  const { pageSize, page } = myWatchingSchools;

  const myProfile = useSelector((state) => state.myProfile);

  useEffect(() => {
    fetchSchoolsData();
  }, []);

  useEffect(() => {
    if (myProfile && schoolList) {
      fetchMyProfile();
    }
  }, [pageSize, page, myProfile, schoolList]);

  const getSchoolName = (item) => {
    const getSchool = schoolList?.items?.find((items) => items.id === item);
    return getSchool?.name;
  };

  const fetchMyProfile = () => {
    setMyWatchingSchools((previous) => ({
      ...previous,
      rows: myProfile?.metadata?.watchingSchools?.map((item, index) => ({
        id: index,
        mySchools: item,
        name: getSchoolName(item),
      })),
    }));
  };

  const fetchSchoolsData = async () => {
    setMyWatchingSchools({ ...myWatchingSchools, isLoading: true });
    const schoolsDataList = await getSchools();
    setSchoolList(schoolsDataList);
    setMyWatchingSchools({ ...myWatchingSchools, isLoading: false });
  };

  const columns = [
    {
      headerName: 'My Schools',
      field: 'mySchools',
      flex: 1,
    },
    {
      headerName: 'Name',
      field: 'name',
      flex: 1,
    },
  ];

  const pageSizeChange = pageSizeChangeHandler(setMyWatchingSchools);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <Card>
          {getDataGridHeading('My Schools')}
          <DeltaDataGrid
            listData={myWatchingSchools}
            columns={columns}
            setListData={pageSizeChange}
          />
        </Card>
      </DashboardLayout>
    </>
  );
};

export default MyWatchingSchools;
