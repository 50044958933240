export const announcementsColumns =  [
  {
    headerName: 'Title',
    field: 'title',
    flex: 1,
  },
  {
    headerName: 'Message',
    field: 'msg',
    flex: 1,
  },
];

export const getAnnouncementsListData = (announcementsData) => {
  const items = announcementsData?.items;
  if (!items || items.length === 0) {
    return [];
  }
  return items.map((item) => ({
    ...item,
    id: item.id,
  }));
};
