import React, { useState, useEffect } from 'react';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Card from '@mui/material/Card';
import { useDispatch } from 'react-redux';
import CustomizedSnackbars from 'components/snackbar/Snackbar';
import { intialPageSize, watchSuccess } from 'constants';
import { displaySnackbar } from 'components/snackbar/store/SnackbarSlice';
import { getSchools } from '../service/schoolsApi';
import Checkbox from '@mui/material/Checkbox';
import { HeadingWrapperBox } from 'layouts/inventory/Inventory.styled';
import {
  createWatchProfile,
  deleteWatchProfile,
} from 'layouts/myProfile/service/myProfileApi';
import {
  RoundedIconStarBorder,
  RoundedIconStarRate,
  ButtonSpinnerProgress,
} from 'components/Ui/styled';
import Button from '@mui/material/Button';
import {
  getSnackBar,
  useNotification,
  pageSizeChangeHandler,
} from 'utils/commonUtils';
import DeltaDataGrid from 'utils/CommonDataGrid/CommonDataGrid';

const Schools = () => {
  const dispatch = useDispatch();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const [watchLoading, setWatchLoading] = useState(false);
  const [schoolsData, setSchoolsData] = useState({
    isLoading: false,
    rows: [],
    pageSize: intialPageSize,
  });

  useEffect(() => {
    fetchSchoolsData();
  }, []);

  const fetchSchoolsData = async () => {
    setSchoolsData((previusState) => ({ ...previusState, isLoading: true }));
    const schoolsDataList = await getSchools();
    setSchoolsData((previous) => ({
      ...previous,
      rows: schoolsDataList?.items.map((item) => ({
        ...item,
        id: item.id,
        name: item.name,
      })),
    }));
    setSchoolsData((previusState) => ({ ...previusState, isLoading: false }));
  };

  const handleWatch = async (params) => {
    const { row } = params;
    const { id, watched } = row;
    setWatchLoading((prevWatchLoading) => ({
      ...prevWatchLoading,
      [id]: true,
    }));
    const payload = {
      data: {
        watchType: 'school',
        watchId: id,
      },
    };
    try {
      if (watched) {
        await deleteWatchProfile(payload);
      } else {
        await createWatchProfile(payload);
      }
      await fetchSchoolsData();
      dispatch(displaySnackbar(getSnackBar(watchSuccess)));
    } catch (e) {
      setOpenNotification(true);
      setNotificationMessage(e.response.data.message);
    } finally {
      setWatchLoading((prevWatchLoading) => ({
        ...prevWatchLoading,
        [id]: false,
      }));
    }
  };

  const columns = [
    {
      headerName: 'Watch',
      field: 'watch',
      width: 100,
      align: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const isLoading = watchLoading[params.row.id] || false;
        return (
          <Button variant="text" disabled={isLoading}>
            <Checkbox
              disabled={isLoading}
              checked={params.row.watched}
              onClick={() => handleWatch(params)}
              icon={<RoundedIconStarBorder />}
              checkedIcon={<RoundedIconStarRate />}
            />
            {isLoading && <ButtonSpinnerProgress size={24} />}
          </Button>
        );
      },
    },
    { headerName: 'Id', field: 'id', width: 100 },
    { headerName: 'Name', field: 'name', flex: 1 },
  ];

  const pageSizeChange = pageSizeChangeHandler(setSchoolsData);

  return (
    <DashboardLayout>
      <CustomizedSnackbars />
      <DashboardNavbar />
      <MDBox>
        <Card>
          <HeadingWrapperBox>
            <MDTypography variant="h5" fontWeight="medium">
              Schools
            </MDTypography>
          </HeadingWrapperBox>
          <DeltaDataGrid
            listData={schoolsData}
            columns={columns}
            setListData={pageSizeChange}
          />
        </Card>
      </MDBox>
      <NotificationPopup />
    </DashboardLayout>
  );
};

export default Schools;
