import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import {
  CircularProgressWithLabelContainer,
  CircularProgressWithLabelWrapper,
} from 'components/Ui/styled';

function CircularProgressWithLabel(props) {
  return (
    <CircularProgressWithLabelContainer>
      <CircularProgress variant='determinate' {...props} />
      <CircularProgressWithLabelWrapper>
        <Typography variant='caption' component='div' color='text.secondary'>
          {`${Math.round(props.value)}%`}
        </Typography>
      </CircularProgressWithLabelWrapper>
    </CircularProgressWithLabelContainer>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function PercentageLoader({ progress }) {
  return <CircularProgressWithLabel color='inherit' value={progress} />;
}
