export const _setStudentType = (state, action) => {
  state.studentType = action.payload;
};

export const _setInstructor = (state, action) => {
  state.instructor = action.payload;
};

export const _setCourse = (state, action) => {
  state.course = action.payload;
};

export const _setGradeLevel = (state, action) => {
  state.gradeLevel = action.payload;
};

export const _setSchoolYears = (state, action) => {
  state.schoolYears = action.payload;
};

export const _setTestCategory = (state, action) => {
  state.testCategory = action.payload;
};

export const _setTestSubcategories = (state, action) => {
  state.testSubcategories = action.payload;
};

export const _setStudentNumbers = (state, action) => {
  state.studentNumbers = action.payload;
};

export const _setProficiencyLevel = (state, action) => {
  state.filterOptions.proficiencyLevel = action.payload;
};

export const _setTestSubcategoriesList = (state, action) => {
  state.testSubcategoriesList = action.payload;
};

export const _setByCategory = (state, action) => {
  state.byCategory = action.payload;
};

export const _setIsRawResults = (state, action) => {
  state.isRawResults = action.payload;
};