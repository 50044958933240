import React, { useState } from 'react';
import Tab from '@mui/material/Tab';
import TabPanel, { a11yProps } from 'components/Common/TabPanel/TabPanel';
import StudentProfileTab from './StudentProfileTab';
import ScheduleTab from './ScheduleTab';
import ScoresTab from './scoresTab/ScoresTab';
import DashboardPasswordTab from './DashboardPasswordTab';
import { StickyTab, TabContainer } from 'components/Ui/styled';

const StudentProfileTabs = () => {
  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <TabContainer>
      <StickyTab
        value={value}
        onChange={handleTabChange}
        aria-label="studentProfileTabs"
      >
        <Tab label="Student Profile" {...a11yProps(0)} />
        <Tab label="Schedule" {...a11yProps(1)} />
        <Tab label="Testing Data" {...a11yProps(2)} />
        <Tab label="Dashboard Password" {...a11yProps(3)} />
      </StickyTab>
      <TabPanel value={value} index={0}>
        <StudentProfileTab />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ScheduleTab />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ScoresTab />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <DashboardPasswordTab />
      </TabPanel>
    </TabContainer>
  );
};

export default StudentProfileTabs;
