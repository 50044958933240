import { DataGridPro } from '@mui/x-data-grid-pro';
import { rowsPerPage } from 'constants';
import { getRowClassName, getRowHeight } from 'utils/commonUtils';

export const getCalendarAccessDataGrid = (
  calendarAccess,
  columns,
  setCalendarAccess
) => {
  const { rows, pageSize, isLoading } = calendarAccess;

  return (
    <DataGridPro
      autoHeight
      getRowHeight={getRowHeight}
      rows={rows}
      columns={columns}
      pagination
      rowsPerPageOptions={rowsPerPage}
      pageSize={pageSize}
      getRowClassName={(params) => getRowClassName(params)}
      loading={isLoading}
      onPageSizeChange={(newPageSize) =>
        setCalendarAccess((prveState) => ({
          ...prveState,
          pageSize: newPageSize,
        }))
      }
    />
  );
};

export const calendarAccessColumns = [
  {
    headerName: 'User',
    field: 'username',
    flex: 1,
  },
  {
    headerName: 'Access',
    field: 'permission',
    flex: 1,
  },
];

export const getCalendarAccessList = (calendarAccessData) => {
  const items = calendarAccessData?.calendar?.permissions;
  if (!items || items.length === 0) {
    return [];
  }
  return items?.map((item, index) => ({
    ...item,
    id: index,
    username: item.username,
    permission: item.permission,
  }));
};
