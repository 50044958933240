import React from 'react';
import { Controller } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { handleBooleanProp } from 'utils/commonUtils';
import { DatePickeDesktop, HelperText } from 'components/Ui/styled';
import { TextField } from '@mui/material';

export const FormInputDate = (props) => {
  const {
    name,
    control,
    label,
    register,
    errors,
    helperText,
    minDate,
    maxDate,
    readOnly,
  } = props;

  const getValue = props.value ? new Date(props.value) : null;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        name={name}
        control={control}
        register={register}
        render={({
          field: { onChange, value },
          fieldState: { error },
          formState,
        }) => {
          return (
            <>
              <DatePickeDesktop
                primary={handleBooleanProp(errors)}
                label={label}
                inputFormat="MM/dd/yyyy"
                minDate={minDate}
                maxDate={maxDate}
                value={getValue === '' ? null : getValue}
                inputProps={{ readOnly: true }}
                onChange={onChange}
                readOnly={readOnly}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    error={errors}
                    helperText={helperText}
                    {...params}
                  />
                )}
              />
            </>
          );
        }}
      />
      <HelperText>{helperText}</HelperText>
    </LocalizationProvider>
  );
};
