import { API } from 'aws-amplify';

export const getCalendarsForAdmin = async () => {
  const calendars = await API.get('delta-api', `calendar/adminListCalendars`);
  return calendars;
};

export const getCalendarsForUsers = async () => {
  const calendars = await API.get('delta-api', `calendar/calendarsForUser`);
  return calendars;
};

export const getCalendarsDetails = async (currentCalenderId) => {
  const calendarsDetails = await API.get(
    'delta-api',
    `calendar/calendars/${currentCalenderId}`
  );
  return calendarsDetails;
};
