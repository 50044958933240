import { API } from 'aws-amplify';
import { DOCUMENT_MANAGEMENT_BASE_URL_APPEND } from 'constants';

export const getSharedFolders = async () => {
  const sharedFolders = await API.get(
    'delta-api',
    `${DOCUMENT_MANAGEMENT_BASE_URL_APPEND}sharedFolders`
  );
  return sharedFolders;
};

export const getSharedFolder = async (sharedFolderId) => {
  const sharedFolders = await API.get(
    'delta-api',
    `${DOCUMENT_MANAGEMENT_BASE_URL_APPEND}sharedFolders/${sharedFolderId}`
  );
  return sharedFolders;
};

export const postSharedFolder = async (payload) => {
  const myPayload = {
    body: payload,
  };
  await API.post(
    'delta-api',
    `${DOCUMENT_MANAGEMENT_BASE_URL_APPEND}sharedFolders`,
    myPayload
  );
};

export const putSharedFolder = async (payload, sharedFolderId) => {
  const myPayload = {
    body: payload,
  };
  await API.put(
    'delta-api',
    `${DOCUMENT_MANAGEMENT_BASE_URL_APPEND}sharedFolders/${sharedFolderId}`,
    myPayload
  );
};

export const getSharedFolderVersions = async (sharedFolderId) => {
  const sharedFolderVersions = await API.get(
    'delta-api',
    `${DOCUMENT_MANAGEMENT_BASE_URL_APPEND}sharedFolders/${sharedFolderId}/versions`
  );
  return sharedFolderVersions;
};

export const putSharedFolderUserAccess = async (payload, sharedFolderId) => {
  const myPayload = {
    body: payload,
  };
  await API.put(
    'delta-api',
    `${DOCUMENT_MANAGEMENT_BASE_URL_APPEND}sharedFolders/${sharedFolderId}/userAccess`,
    myPayload
  );
};

export const putSharedFolderGroupAccess = async (payload, sharedFolderId) => {
  const myPayload = {
    body: payload,
  };
  await API.put(
    'delta-api',
    `${DOCUMENT_MANAGEMENT_BASE_URL_APPEND}sharedFolders/${sharedFolderId}/groupAccess`,
    myPayload
  );
};

export const getSharedFoldersArchive = async (
  pageSize,
  paginationKey,
  oldestFirst
) => {
  const sharedFoldersArchive = await API.get(
    'delta-api',
    `${DOCUMENT_MANAGEMENT_BASE_URL_APPEND}sharedFoldersArchive?`,
    {
      headers: {},
      queryStringParameters: {
        limit: pageSize,
        paginationKey: paginationKey,
        oldestFirst: oldestFirst,
      },
    }
  );
  return sharedFoldersArchive;
};

export const postSharedFolderArchive = async (sharedFolderId) => {
  await API.post(
    'delta-api',
    `${DOCUMENT_MANAGEMENT_BASE_URL_APPEND}sharedFolders/${sharedFolderId}/archive`
  );
};

export const postSharedFoldersUnarchive = async (sharedFolderId) => {
  await API.post(
    'delta-api',
    `${DOCUMENT_MANAGEMENT_BASE_URL_APPEND}sharedFolders/${sharedFolderId}/restore`
  );
};
