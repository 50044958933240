import React from 'react';
import { FormInputSwitch } from 'components/Common/Forms/FormInputSwitch';

const HideColumnSwitch = (props) => {
  const { item, control, register, handleHideUnhide } = props;
  return (
    <FormInputSwitch
      control={control}
      name={item.columnName}
      label={item.columnName}
      register={register(`${item.columnName}`, {
        onChange: (e) => handleHideUnhide(item.columnName, e.target.value),
      })}
    />
  );
};

export default HideColumnSwitch;
