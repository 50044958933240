import React from 'react';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import { GridWrapper } from './Attendance.styled';

const GradeDataTable = (props) => {
  const apiRef = useGridApiRef();
  const { gradeTableData: tableData, isAttendanceAudit } = props;

  const pinnedColumns = {
    left: isAttendanceAudit ? ['name'] : ['watch', 'name', 'tags'],
  };

  return (
    <GridWrapper>
      <DataGridPro
        apiRef={apiRef}
        rows={tableData.rows}
        columns={tableData.columns}
        rowHeight={48}
        isRowSelectable={() => false}
        initialState={{
          pinnedColumns: pinnedColumns,
        }}
      />
    </GridWrapper>
  );
};

export default GradeDataTable;
