const initialState = {
  newUser: false,
  username: null,
  userId: '',
  email: null,
  givenName: null,
  familyName: null,
};

export default initialState;
