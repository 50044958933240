import { LoaderDiv } from 'layouts/inventory/Inventory.styled';
import React, { Suspense } from 'react';
import LoadingSpinner from './LoadingSpinner';

function LoadingWraper(props) {
  return (
    <Suspense
      fallback={
        <LoaderDiv>
          <LoadingSpinner />
        </LoaderDiv>
      }
    >
      {props.children}
    </Suspense>
  );
}

export default LoadingWraper;
