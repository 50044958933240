export const _setCalenderData = (state, action) => {
  state.calendarData = action.payload;
};

export const _setCalenderId = (state, action) => {
  state.currentCalenderId = action.payload;
};

export const _setSelectCalendar = (state, action) => {
  state.selectCalendar = action.payload;
};

export const _setIsDashboardCalendar = (state, action) => {
  state.isDashboardCalendar = action.payload;
};
