import snackbarInitialState from './SnackbarInitialState';

export const _displaySnackbar = (state, action) => {
  const { snackbarOpen, snackbarMessage, snackbarType, vertical,
    horizontal, } = action.payload
  return {
    ...state,
    snackbarOpen,
    snackbarType,
    snackbarMessage,
    vertical,
    horizontal,
  }
}

export const _resetSnackbar = () => snackbarInitialState;

export const _clearSnackbar = (state) => { state.snackbarOpen = false }
