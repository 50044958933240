import { createSlice } from '@reduxjs/toolkit';

const foldersSlice = createSlice({
  name: 'delta/folders',
  initialState: null,
  reducers: {
    getFoldersData: (state, action) => action.payload,
    resetFoldersData: () => null,
  },
});

export const { getFoldersData, resetFoldersData } = foldersSlice.actions;

export default foldersSlice.reducer;
