import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { CustomListbox } from 'components/Ui/styled';
import Box from '@mui/material/Box';

export const FormInputAutoCompleteSearch = (props) => {
  const {
    options,
    setSelectedInput,
    setInputSearchQuery,
    label,
    isSearchloading,
    placeholder,
    onKeyDown,
    onBlur,
    getOptionLabel,
    onClose,
  } = props;

  return (
    <Autocomplete
      options={options}
      getOptionLabel={getOptionLabel}
      onChange={(event, value) => setSelectedInput(value)}
      ListboxComponent={CustomListbox}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={label}
          placeholder={placeholder}
          onChange={(event) => setInputSearchQuery(event.target.value)}
          InputProps={{
            onKeyDown: onKeyDown,
            ...params.InputProps,
            endAdornment: (
              <>
                {isSearchloading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                <Box onClick={onClose}>{params.InputProps.endAdornment}</Box>
              </>
            ),
          }}
          style={{ width: '100%' }}
          onBlur={onBlur}
        />
      )}
    />
  );
};
