import { createSlice } from '@reduxjs/toolkit';
import initialState from './sharedFolderInitialState';
import {
  _getSharedFolderForm,
  _setIsNewSharedFolder,
} from './sharedFolderAction';

export const sharedFolderSlice = createSlice({
  name: 'delta/sharedFolderForm',
  initialState,
  reducers: {
    getSharedFolderForm: _getSharedFolderForm,
    setIsNewSharedFolder: _setIsNewSharedFolder,
    resetSharedFolderForm: () => initialState,
  },
});

export const {
  getSharedFolderForm,
  setIsNewSharedFolder,
  resetSharedFolderForm,
} = sharedFolderSlice.actions;

export default sharedFolderSlice.reducer;
