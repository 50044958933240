export const _getAnnouncementForm = (state, action) => action.payload;

export const _setNewAnnouncement = (state, action) => {
  state.item.newAnnouncement = action.payload;
};

export const _setTitle = (state, action) => {
  state.item.title = action.payload;
};

export const _setMsg = (state, action) => {
  state.item.msg = action.payload;
};

export const _setStartsAt = (state, action) => {
  state.item.startsAt = action.payload;
};

export const _setEndsAt = (state, action) => {
  state.item.endsAt = action.payload;
};

export const _setGroups = (state, action) => {
  state.item.groups = action.payload;
};

export const _setPaginationKey = (state, action) => {
  state.PaginationKey = action.payload;
};

export const _setAnnouncementPaginationData = (state, action) => {
  state.announcementPaginationData = [
    ...state.announcementPaginationData,
    action.payload,
  ];
};

export const _setAnnouncementPreviousKey = (state, action) => {
  state.announcementPreviousKey = action.payload;
};