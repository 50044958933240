import { Dialog, DialogContent, DialogContentText } from '@mui/material';
import PropTypes from 'prop-types';
import { PaperPropsCheck } from 'constants';
import { getYesNo } from 'utils/commonUtils';

const ConfirmationDialog = (props) => {
  const {
    openConfirmationDialog,
    setOpenConfirmationDialog,
    title,
    onSubmit,
    handleSubmit,
    loadingButton,
    isSubmit,
    handleClick,
  } = props;

  const handleClose = () => {
    setOpenConfirmationDialog(false);
  };
  
  return (
    <>
      <Dialog open={openConfirmationDialog} PaperProps={PaperPropsCheck}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {title}
          </DialogContentText>
        </DialogContent>
        {getYesNo(
          handleClose,
          isSubmit ? handleSubmit(onSubmit) : handleClick,
          loadingButton
        )}
      </Dialog>
    </>
  );
};

ConfirmationDialog.defaultProps = {
  openConfirmationDialog: false,
  setOpenConfirmationDialog: () => {},
  title: '',
  onSubmit: () => {},
  handleSubmit: () => {},
  loadingButton: false,
  isSubmit: false,
  handleClick: () => {},
};

ConfirmationDialog.propTypes = {
  openConfirmationDialog: PropTypes.bool,
  setOpenConfirmationDialog: PropTypes.func,
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  loadingButton: PropTypes.bool,
  isSubmit: PropTypes.bool,
  handleClick: PropTypes.func,
};

export default ConfirmationDialog;
