const initialState = {
  item: {
    isNewAttendanceAudit: false,
    name: null,
    includePreviousSemesters: false,
    includeDropped: false,
    reportParams: {
      semesters: [],
      studentIds: [],
    },
  },
};

export default initialState;
