import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import MDBox from 'components/MDBox';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import { useForm } from 'react-hook-form';
import ComplexStatisticsCard from 'examples/Cards/StatisticsCards/ComplexStatisticsCard';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import DomainVerificationRoundedIcon from '@mui/icons-material/DomainVerificationRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import CalendarViewWeekRoundedIcon from '@mui/icons-material/CalendarViewWeekRounded';
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';
import AlignHorizontalLeftRoundedIcon from '@mui/icons-material/AlignHorizontalLeftRounded';
import AlignHorizontalRightRoundedIcon from '@mui/icons-material/AlignHorizontalRightRounded';
import LaptopMacRoundedIcon from '@mui/icons-material/LaptopMacRounded';
import NotesRoundedIcon from '@mui/icons-material/NotesRounded';
import HorizontalBarChart from 'examples/Charts/BarCharts/HorizontalBarChart';
import TotalStudentsChart from './StudentsCharts/TotalStudents';
import StudentsInComplianceChart from './StudentsCharts/StudentsInCompliance';
import { getStats } from './services/statsApi';
import { useSelector, useDispatch } from 'react-redux';
import { getStatsDetails } from './store/statsSlice';
import { format } from 'date-fns';
import {
  dateFnsFormat,
  deltaAcademyCalendar,
  yearMonthFormat,
} from 'constants';
import {
  DashboardCard,
  DashboardLeftGrid,
  HeadingMuiTypography,
  DashboardDialogHeader,
  AnnouncementCard,
  TypographyHeading,
  DashboardCalendarGrid,
  BoxCenter,
} from 'components/Ui/styled';
import {
  showLoader,
  handleBooleanProp,
  showBackdropLoader,
  useNotification,
  timezoneOffset,
} from 'utils/commonUtils';
import DashboardAnnouncements from './components/Announcements/DashboardAnnouncements';
import DashboardCalendar from './components/Calendar/DashboardCalendar';
import moment from 'moment';
import { getCategory } from 'layouts/applications/calendar/components/services/categoryApi';
import { getCalenderEvents } from 'layouts/applications/calendar/services/calendarEventsApi';
import Card from '@mui/material/Card';
import { getYearMonthData } from 'examples/Calendar/store/yearMonthSlice';
import { setCategories } from 'layouts/applications/calendar/components/Categories/store/calenderCategorySlice';
import DashboardTasks from './components/MyTasks/DashboardTasks';
import DashboardAssets from './components/MyAssets/DashboardAssets';
import { getMyTasks } from 'layouts/taskManagement/services/taskManagementApi';
import { getMyProfile } from 'layouts/myProfile/service/myProfileApi';
import { useNavigate } from 'react-router-dom';
import DashboardLocations from './components/Calendar/MyLocations/DashboardLocations';
import { usePermissionCustomHook } from 'layouts/users/userPermissions/utils/permissionsUtils';
import FormInputDropdown from 'components/Common/Forms/FormInputDropDown';
import { getCalendarsForUsers } from 'examples/Calendar/services/calendarsApi';
import { setSelectCalendar } from 'layouts/applications/calendar/store/CalenderSlice';

function Analytics() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { NotificationPopup, handleErrorResponse } = useNotification();

  const [isLoading, setIsLoading] = useState(true);
  const [isBackDropLoading, setIsBackDropLoading] = useState(false);
  const [calendarEvents, setCalendarEvents] = useState({
    data: [],
  });

  const [calendarDropDownList, setCalendarDropDownList] = useState();
  const [calendarEventsError, setCalendarEventsError] = useState(null);
  const [calendarError, setCalendarError] = useState(false);
  const [callEvents, setCallEvents] = useState(false);
  const [eventLoading, setEventLoading] = useState(false);
  const [taskList, setTaskList] = useState();
  const [myProfile, setMyProfile] = useState();
  const currentYearMonth = new Date();
  const currentYear = `${currentYearMonth.getFullYear()}`;
  const currentMonth = `${currentYearMonth.getMonth() + 1}`;
  const isLogout = useSelector((state) => state.logout.isLogout);
  const currentCalenderId = useSelector(
    (state) => state?.Calender?.selectCalendar
  );

  const canViewAttendanceData = usePermissionCustomHook(
    'CAN_VIEW_ATTENDANCE_DATA'
  );

  const canViewCalendars = usePermissionCustomHook('CAN_VIEW_CALENDARS');
  const canReadAssetData = usePermissionCustomHook('CAN_READ_ASSET_DATA');
  const defaultCalenderId = calendarDropDownList?.calendars[0]?.calendarId;

  useEffect(() => {
    if (!isLogout) {
      fetchCalendarListData();
    }
  }, []);

  useEffect(() => {
    let selectedCalendar;
    if (calendarDropDownList) {
      selectedCalendar = defaultCalenderId;
    }
    if (selectedCalendar) {
      dispatch(setSelectCalendar(selectedCalendar));
    }
  }, [calendarDropDownList]);

  useEffect(() => {
    if (!isLogout) {
      fetchTasksAndAssets();
    }
  }, []);

  useEffect(() => {
    if (!isLogout) {
      if (canViewAttendanceData) {
        getStatsDataDetails();
      }
    }
  }, [canViewAttendanceData]);

  useEffect(() => {
    if (currentCalenderId && !isLogout && defaultCalenderId) {
      if (canViewCalendars) {
        getDeltaAcademyCalendar();
      }
    }
  }, [currentCalenderId, canViewCalendars, defaultCalenderId]);

  useEffect(() => {
    return () => {
      setCalendarEvents(() => ({
        data: [],
      }));
      setCalendarEventsError(null);
      setCalendarError(false);
    };
  }, []);

  const getStatsDataDetails = async () => {
    try {
      await getStatsData();
      if (!canViewCalendars) {
        setIsLoading(false);
      }
    } catch (error) {
      handleErrorResponse(error?.response?.data?.message);
    }
  };

  const getStatsData = async () => {
    try {
      const statsDetails = await getStats();
      dispatch(getStatsDetails(statsDetails));
      if (currentCalenderId !== deltaAcademyCalendar) {
        setIsLoading(false);
      }
    } catch (error) {
      handleErrorResponse(error?.response?.data?.message);
    }
  };

  const fetchTasksAndAssets = async () => {
    try {
      const taskListData = await getMyTasks();
      const assetListData = await getMyProfile();
      setTaskList(taskListData);
      setMyProfile(assetListData);
      if (!canViewAttendanceData && !canViewCalendars) {
        setIsLoading(false);
      }
    } catch (error) {
      handleErrorResponse(error?.response?.data?.message);
    }
  };

  const fetchCalendarListData = async () => {
    try {
      const calendarList = await getCalendarsForUsers();
      setCalendarDropDownList(calendarList);
    } catch (error) {
      handleErrorResponse(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (calendarEventsError) {
      setCalendarError(true);
      setIsLoading(false);
    }
  }, [calendarEventsError]);

  const getDeltaAcademyCalendar = async () => {
    try {
      if (!isLoading) {
        setIsBackDropLoading(true);
      }
      await fetchDeltaAcademyCalendar(currentYear, currentMonth);
      setIsBackDropLoading(false);
      setIsLoading(false);
    } catch (error) {
      handleErrorResponse(error?.response?.data?.message);
    }
  };

  const fetchDeltaAcademyCalendar = async (getCurrentYear, getCurrentMonth) => {
    try {
      const json = await getCalenderEvents(
        currentCalenderId,
        getCurrentYear,
        getCurrentMonth
      )
        .then((res) => res)
        .catch((e) => setCalendarEventsError(e.response.data.message));
      const getCategoryList = await getCategory(currentCalenderId);
      const categoryData = getCategoryList?.categories;
      const getCategoryColor = (categoryId) => {
        const getColor = categoryData?.find(
          (item) => item.categoryId === categoryId
        );
        return getColor?.color;
      };
      const { events } = json;
      const eventsData = events?.map((item, index) => ({
        ...item,
        id: item.eventId,
        title: item.title,
        description: item.description,
        start: item.startDate,
        end: moment(new Date(item.endDate))
          .add(timezoneOffset, 'day')
          .startOf('day')
          .toDate(),
        startTime: item.startDate,
        endTime: item.endDate,
        allDay: item.isAllDayEvent,
        location: item.location,
        categoryId: item.categoryId,
        color: getCategoryColor(item.categoryId),
      }));
      setCalendarEvents(() => ({
        data: eventsData,
      }));
      dispatch(setCategories(categoryData));
      setEventLoading(false);
    } catch (error) {
      handleErrorResponse(error?.response?.data?.message);
    }
  };

  const handleEvents = async (arg) => {
    const currentdate = format(new Date(), yearMonthFormat);
    const currentEventDate = format(
      new Date(arg.view.currentStart),
      yearMonthFormat
    );
    const current = new Date(arg.view.currentStart);
    const getCurrentMonth = `${current.getMonth() + 1}`;
    const getCurrentYear = `${current.getFullYear()}`;
    if (currentdate === currentEventDate) {
      if (callEvents) {
        setEventLoading(true);
        fetchDeltaAcademyCalendar(getCurrentYear, getCurrentMonth);
      }
      dispatch(
        getYearMonthData(format(new Date(arg.view.currentStart), dateFnsFormat))
      );
    } else {
      setEventLoading(true);
      fetchDeltaAcademyCalendar(getCurrentYear, getCurrentMonth);
      dispatch(
        getYearMonthData(format(new Date(arg.view.currentStart), dateFnsFormat))
      );
      setCallEvents(true);
    }
  };

  const onlineAttendanceStats = useSelector((state) => state.statsData);

  const lastRosterSyncDate = onlineAttendanceStats?.lastRosterSync
    ? format(new Date(onlineAttendanceStats?.lastRosterSync), dateFnsFormat)
    : '';

  const lastAutomatedComplianceCheckDate = onlineAttendanceStats?.lastRosterSync
    ? format(new Date(onlineAttendanceStats?.lastRosterSync), dateFnsFormat)
    : '';

  const statsItems = [
    {
      title: 'Students Not InCompliance',
      count: onlineAttendanceStats?.numStudentsNotInCompliance,
      icon: <AlignHorizontalRightRoundedIcon />,
      color: 'dark',
    },
    {
      title: 'Students Marked Compliant Run',
      count: onlineAttendanceStats?.numStudentsMarkedCompliantLatestRun,
      icon: <NotesRoundedIcon />,
      color: 'dark',
    },
    {
      title: 'Online Students Without Classes',
      count: onlineAttendanceStats?.numOnlineStudentsWithoutClasses,
      icon: <LaptopMacRoundedIcon />,
      color: 'dark',
    },
    {
      title: 'Current Semester',
      count: onlineAttendanceStats?.currentSemester,
      icon: <CalendarMonthRoundedIcon />,
      color: 'dark',
    },
    {
      title: 'Current School Week',
      count: onlineAttendanceStats?.currentSchoolWeek,
      icon: <CalendarViewWeekRoundedIcon />,
      color: 'dark',
    },
    {
      title: 'Last Roster Sync',
      count: lastRosterSyncDate,
      icon: <SyncRoundedIcon />,
      color: 'dark',
    },
    {
      title: 'Last Automated Compliance Check',
      count: lastAutomatedComplianceCheckDate,
      icon: <DomainVerificationRoundedIcon />,
      color: 'dark',
    },
  ];

  const studentsByGradeData = {
    labels: ['6th', '7th', '8th', '9th', '10th', '11th', '12th'],
    datasets: [
      {
        label: 'Grade',
        color: 'dark',
        data: [
          onlineAttendanceStats?.studentsByGrade?.[6],
          onlineAttendanceStats?.studentsByGrade?.[7],
          onlineAttendanceStats?.studentsByGrade?.[8],
          onlineAttendanceStats?.studentsByGrade?.[9],
          onlineAttendanceStats?.studentsByGrade?.[10],
          onlineAttendanceStats?.studentsByGrade?.[11],
          onlineAttendanceStats?.studentsByGrade?.[12],
        ],
      },
    ],
  };

  const handleTasksClick = () => {
    navigate('/task-management/tasks');
  };

  const handleAssetsClick = () => {
    if (canReadAssetData) {
      navigate('/applications/assets');
    }
  };

  const handleLocationClick = () => {
    if (canReadAssetData) {
      navigate('/applications/user-locations');
    }
  };

  const handleAnnouncementsClick = () => {
    navigate('/applications/announcements');
  };

  const defaultValue = {
    selectCalendar: currentCalenderId ? currentCalenderId : '',
  };

  const useFunction = useForm({
    defaultValues: defaultValue,
  });

  const { control, register, reset } = useFunction;

  const calendarOptionList = calendarDropDownList?.calendars?.map(
    (item, index) => ({
      id: item.calendarId,
      value: item.name,
    })
  );

  useEffect(() => {
    reset(defaultValue);
  }, [defaultValue.selectCalendar]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isLoading ? (
        showLoader()
      ) : (
        <>
          {isBackDropLoading && showBackdropLoader(isBackDropLoading)}
          <Grid container spacing={3}>
            <DashboardLeftGrid item xs={12} sm={6}>
              <AnnouncementCard
                ispermission={handleBooleanProp(true)}
                onClick={handleAnnouncementsClick}
                data-testid="Announcements"
              >
                <HeadingMuiTypography variant="h6">
                  Announcements
                </HeadingMuiTypography>
                <DashboardAnnouncements />
                <MDBox pt={2}></MDBox>
              </AnnouncementCard>
              {!canViewCalendars && <MDBox pt={4}></MDBox>}
              {canViewAttendanceData && (
                <HorizontalBarChart
                  title="Students By Grade"
                  chart={studentsByGradeData}
                />
              )}
            </DashboardLeftGrid>
            {canViewCalendars && (
              <Grid item xs={12} sm={6}>
                <Card>
                  <BoxCenter>
                    <FormInputDropdown
                      name="selectCalendar"
                      type="text"
                      control={control}
                      options={calendarOptionList}
                      label="Select Calendar"
                      register={register('selectCalendar', {
                        onChange: (e) =>
                          dispatch(setSelectCalendar(e.target.value)),
                      })}
                    />
                  </BoxCenter>
                  <DashboardCalendarGrid>
                    <DashboardCalendar
                      calendarEvents={calendarEvents}
                      eventLoading={eventLoading}
                      handleEvents={handleEvents}
                      currentCalenderId={currentCalenderId}
                    />
                  </DashboardCalendarGrid>
                </Card>
              </Grid>
            )}
          </Grid>
          {canViewAttendanceData && (
            <>
              <MDBox pt={3.5}></MDBox>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <ComplexStatisticsCard
                    title="Total Students"
                    count={onlineAttendanceStats?.totalStudents}
                    icon={<PersonRoundedIcon />}
                    color="dark"
                  />
                  <MDBox pt={3}></MDBox>
                  <TotalStudentsChart />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ComplexStatisticsCard
                    title="Students In Compliance"
                    count={onlineAttendanceStats?.numStudentsInCompliance}
                    icon={<AlignHorizontalLeftRoundedIcon />}
                    color="dark"
                  />
                  <MDBox pt={3}></MDBox>
                  <StudentsInComplianceChart />
                </Grid>
              </Grid>
              <MDBox pt={2}></MDBox>
              <Grid container spacing={3}>
                {statsItems.map((item, index) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <ComplexStatisticsCard
                        title={item.title}
                        count={item.count}
                        icon={item.icon}
                        color={item.color}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </>
          )}
          <MDBox pt={3.5}></MDBox>
          <Grid container spacing={3}>
            <DashboardLeftGrid
              item
              xs={12}
              sm={6}
              ispermission={handleBooleanProp(true)}
              onClick={handleTasksClick}
            >
              <DashboardDialogHeader>
                <TypographyHeading variant="h6">My Tasks</TypographyHeading>
              </DashboardDialogHeader>
              <DashboardCard>
                <DashboardTasks taskList={taskList} />
              </DashboardCard>
            </DashboardLeftGrid>
            <DashboardLeftGrid
              item
              xs={12}
              sm={6}
              ispermission={handleBooleanProp(canReadAssetData)}
              onClick={handleAssetsClick}
            >
              <DashboardDialogHeader>
                <TypographyHeading variant="h6">My Assets</TypographyHeading>
              </DashboardDialogHeader>
              <DashboardCard>
                <DashboardAssets myProfile={myProfile} />
              </DashboardCard>
            </DashboardLeftGrid>
          </Grid>
          <MDBox pt={3.5}></MDBox>
          <Grid container spacing={3}>
            <DashboardLeftGrid
              item
              xs={12}
              sm={6}
              ispermission={handleBooleanProp(canReadAssetData)}
              onClick={handleLocationClick}
            >
              <DashboardDialogHeader>
                <TypographyHeading variant="h6">My Locations</TypographyHeading>
              </DashboardDialogHeader>
              <DashboardCard>
                <DashboardLocations myProfile={myProfile} />
              </DashboardCard>
            </DashboardLeftGrid>
          </Grid>
        </>
      )}
      <Footer />
      <NotificationPopup />
    </DashboardLayout>
  );
}

export default Analytics;
