import { createSlice } from '@reduxjs/toolkit';
import { _clearSnackbar, _displaySnackbar, _resetSnackbar } from './SnackbarAction';
import snackbarInitialState from './SnackbarInitialState';

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: snackbarInitialState,
  reducers: {
    displaySnackbar:_displaySnackbar,
    resetSnackbar:_resetSnackbar,
    clearSnackbar:_clearSnackbar
  },
});

export const { displaySnackbar, clearSnackbar, resetSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
