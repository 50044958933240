import { API, Auth } from 'aws-amplify';

export const getCategoryAdmin = async (currentCalenderId) => {
  const Category = await API.get(
    'delta-api',
    `calendar/categories/${currentCalenderId}`
  );
  return Category;
};

export const getCategoryAdminId = async (calendarId, categoryId) => {
  const CategoryId = await API.get(
    'delta-api',
    `calendar/categories/${calendarId}/${categoryId}`
  );
  return CategoryId;
};

export const postCategoryAdmin = async (category, selectCalendarId) => {
  const authToken = Auth.user.signInUserSession.accessToken.jwtToken;

  const myInit = {
    body: { category },
  };

  await API.post(
    'delta-api',
    `calendar/categories/${selectCalendarId}`,
    myInit
  );
};

export const putCategoryAdmin = async (category, selectCalendarId) => {
  const authToken = Auth.user.signInUserSession.accessToken.jwtToken;
  const myInit = {
    body: { category },
  };
  await API.put(
    'delta-api',
    `calendar/categories/${selectCalendarId}/${category.categoryId}`,
    myInit
  );
};

export const deleteCategoryAdmin = async (category, selectCalendarId) => {
  await API.del(
    'delta-api',
    `calendar/categories/${selectCalendarId}/${category.categoryId}`
  );
};
