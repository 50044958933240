import React, { useEffect } from 'react';
import {
  ButtonIcon,
  GridWrapper,
  GridCenterWrapper,
  RoundedIconVisibility,
} from 'components/Ui/styled';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { DataGrid } from '@mui/x-data-grid';
import { ActionWrapperBox } from 'components/Common/common.styled';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded';
import { companyOptions } from 'constants';
import FormInputDropdown from 'components/Common/Forms/FormInputDropDown';

function EmploymentTab() {
  const defaultValue = {
    jobTitle: '',
    company: '',
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: 'defaultValue',
  });

  const { handleSubmit, reset, control, register, formState } = useFunction;
  const { errors } = formState;

  useEffect(() => {
    reset(defaultValue);
  }, [defaultValue.jobTitle, defaultValue.company]);

  const renderUpdatetAction = () => {
    return (
      <ActionWrapperBox>
        <ButtonIcon
          onClick={() => {
            console.log('Upload');
          }}
        >
          <CloudUploadRoundedIcon />
        </ButtonIcon>
      </ActionWrapperBox>
    );
  };
  const renderViewAction = () => {
    return (
      <ActionWrapperBox>
        <ButtonIcon
          onClick={() => {
            console.log('View');
          }}
        >
          <RoundedIconVisibility />
        </ButtonIcon>
      </ActionWrapperBox>
    );
  };

  const columns = [
    {
      field: 'upload',
      headerName: 'Upload',
      renderCell: (params) => renderUpdatetAction(),
    },
    {
      field: 'view',
      headerName: 'View',
      renderCell: (params) => renderViewAction(),
    },
    {
      field: 'employeeDetail',
      headerName: 'Documents',
      width: 190,
      flex: 1,
    },
    {
      field: 'expiresOn',
      headerName: 'Expires On',
      flex: 1,
    },

    {
      field: 'expiredDocuments',
      headerName: 'Expired Documents',
      flex: 1,
    },
  ];

  const rows = [
    {
      id: 1,
      employeeDetail: 'Employment Contract',
      upload: 'Upload',
      view: 'View',
      expiresOn: '05 / 24',
    },
    {
      id: 2,
      employeeDetail: 'Employee Resume',
      upload: 'Upload',
      view: 'View',
      expiresOn: '',
    },
    {
      id: 3,
      employeeDetail: 'W - 4',
      upload: 'Upload',
      view: 'View',
      expiresOn: '',
    },
    {
      id: 4,
      employeeDetail: 'I - 9',
      upload: 'Upload',
      view: 'View',
      expiresOn: '',
    },
  ];

  const handleDirectDepositForm = () => {};

  const handleRequestNewForm = () => {};

  return (
    <>
      <GridCenterWrapper container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <FormInputText
            name="jobTitle"
            type="text"
            control={control}
            label="Job Title"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormInputDropdown
            name="company"
            control={control}
            options={companyOptions}
            defaultValue={defaultValue?.company}
            label="Company"
            filter
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Button variant="text" onClick={handleDirectDepositForm}>
            Direct Deposit Form
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Button variant="text" onClick={handleRequestNewForm}>
            Request New Form
          </Button>
        </Grid>
      </GridCenterWrapper>
      <GridWrapper>
        <DataGrid autoHeight rows={rows} columns={columns} />
      </GridWrapper>
    </>
  );
}

export default EmploymentTab;
